
export const MediaStreamTrackType = {
  Audio: 'audio',
  Video: 'video',
}
export const MediaDeviceType = {
  AudioInput: "audioinput",
  AudioOutput: "audiooutput",
  VideoInput: "videoinput",
}
