import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

const Countdown = ({time = 5, onEnded, className="", showHours = false, showMinutes = false, hideLeadingZero=true}) => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState(null)

  const getTimeRemaining = (e) => {
    const total =
        Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
        (total / 1000 / 60) % 60
    );
    const hours = Math.floor(
        (total / 1000 / 60 / 60) % 24
    );
    return {
        total,
        hours,
        minutes,
        seconds,
    };
  };
  const clearTimer = (e) => {
    setTimer(time);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
        startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + time);
    return deadline;
  };
  
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } =
        getTimeRemaining(e);
    if (total >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        let finalTime = ""
        if(showHours) {
          finalTime += (hours > 9 ? hours : "0" + hours) + ":"
        }
        if(showMinutes) {
          finalTime += (minutes > 9 ? minutes : "0" + minutes) + ":"
        }
        finalTime += (seconds > 9 ? seconds : !hideLeadingZero ?"0" + seconds : seconds)
        setTimer(finalTime);
        if(onEnded && finalTime == 0) {
          onEnded()
        }
    }
  };
  useEffect(() => {
    clearTimer(getDeadTime());
    return ()=>{
      clearInterval(Ref.current)
    }
  }, []);
  return (
    <span>
       {timer} 
    </span>
  )
}
export default Countdown