import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import Icon from "../../../../icon/icon";
import './select-date-time.scss'

export const SelectTime = ({ value, label, onTimeChange }) => {
    // Function to format the time zone into a GMT string
    const formatTimeZoneOffset = (offset) => {
        const hours = offset / 60;
        const sign = hours >= 0 ? '+' : '-';
        return `GMT${sign}${Math.abs(hours).toString().padStart(2, '0')}`;
    };

    // Get current date and time
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes().toString().padStart(2, '0');
    const currentAmPm = currentHour >= 12 ? 'PM' : 'AM';
    const currentHour12 = ((currentHour + 11) % 12 + 1).toString().padStart(2, '0'); // Convert 24h to 12h format
    const currentTimeZone = formatTimeZoneOffset(-now.getTimezoneOffset()); // Get local time zone offset and convert it to GMT format

    // State initialization using current time
    const [showSelector, setShowSelector] = useState('');
    const [selectedHour, setSelectedHour] = useState(value ? value.hour : currentHour12);
    const [selectedMinute, setSelectedMinute] = useState(value ? value.minute : currentMinute);
    const [selectedAmPm, setSelectedAmPm] = useState(value ? value.amPm : currentAmPm);
    const [selectedTimeZone, setSelectedTimeZone] = useState(value ? value.timeZone : currentTimeZone);

    const hours = useMemo(() => Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0')), []);
    const minutes = useMemo(() => Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')), []);
    const amPmOptions = useMemo(() => ['AM', 'PM'], []);
    const timeZones = useMemo(() => ['GMT-12', 'GMT-11', 'GMT-10', 'GMT-9', 'GMT-8', 'GMT-7', 'GMT-6', 'GMT-5', 'GMT-4', 'GMT-3', 'GMT-2', 'GMT-1', 'GMT', 'GMT+1', 'GMT+2', 'GMT+3', 'GMT+4', 'GMT+5', 'GMT+6', 'GMT+7', 'GMT+8', 'GMT+9', 'GMT+10', 'GMT+11', 'GMT+12'], []);

    useEffect(() => {
        const timeObj = {
            hour: selectedHour,
            minute: selectedMinute,
            amPm: selectedAmPm,
            timeZone: selectedTimeZone
        };
        onTimeChange(timeObj);
    }, [selectedHour, selectedMinute, selectedAmPm, selectedTimeZone]);

    const handleSelector = useCallback((selectorType) => {
        setShowSelector(showSelector === selectorType ? '' : selectorType);
    }, [showSelector]);

    const selectTime = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectTime.current && !selectTime.current.contains(event.target)) {
                setShowSelector('');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <>
            <span className={'select-date-time-label'}>{label}</span>
            <div className={'select-date-time-container'} ref={selectTime}>
                <div className={'select-container'}>
                    <div className={'selected-value'} onClick={() => handleSelector('hour')}>
                        <span>{selectedHour}</span>
                        <Icon name={'caret-down'} color={'#909090'} size={12}/>
                    </div>
                    {showSelector === 'hour' && (
                        <div className={'list-selector-container'}>
                            {hours.map((hour, index) => (
                                <span onClick={() => {
                                    setSelectedHour(hour)
                                    setShowSelector('')
                                }} key={`hour-${index}`} className={'list-item'}>
                                    {hour}
                                </span>
                            ))}
                        </div>
                    )}
                </div>

                <div className={'select-container'}>
                    <div className={'selected-value'} onClick={() => handleSelector('minute')}>
                        <span>{selectedMinute}</span>
                        <Icon name={'caret-down'} color={'#909090'} size={12}/>
                    </div>
                    {showSelector === 'minute' && (
                        <div className={'list-selector-container'}>
                            {minutes.map((minute, index) => (
                                <span onClick={() => {
                                    setSelectedMinute(minute)
                                    setShowSelector('')
                                }} key={`minute-${index}`} className={'list-item'}>
                                    {minute}
                                </span>
                            ))}
                        </div>
                    )}
                </div>

                <div className={'select-container'}>
                    <div className={'selected-value'} onClick={() => handleSelector('amPm')}>
                        <span>{selectedAmPm}</span>
                        <Icon name={'caret-down'} color={'#909090'} size={12}/>
                    </div>
                    {showSelector === 'amPm' && (
                        <div className={'list-selector-container'}>
                            {amPmOptions.map((amPm, index) => (
                                <span onClick={() => {
                                    setSelectedAmPm(amPm)
                                    setShowSelector('')
                                }} key={`amPm-${index}`} className={'list-item'}>
                                    {amPm}
                                </span>
                            ))}
                        </div>
                    )}
                </div>

                <div className={'select-container'}>
                    <div className={'selected-value'} onClick={() => handleSelector('timeZone')}>
                        <span>{selectedTimeZone}</span>
                        <Icon name={'caret-down'} color={'#909090'} size={12}/>
                    </div>
                    {showSelector === 'timeZone' && (
                        <div className={'list-selector-container'}>
                            {timeZones.map((timeZone, index) => (
                                <span onClick={() => {
                                    setSelectedTimeZone(timeZone.replace('GMT', ''))
                                    setShowSelector('')
                                }} key={`timeZone-${index}`} className={'list-item'}>
                                    {timeZone}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};