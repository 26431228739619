import React, {useEffect, useRef, useState} from "react";
import {useConversationMessages, useUserMediaDevices, VideoStream} from "@apirtc/react-lib";
import WindowLayout from "components/windowLayout/window-layout"
import { useChatroom } from "stores/chatroom";
import LeaveRoom from "../../../../windowLayout/title-bar/room-controls/leave-room";
import apiRTC from "@apirtc/apirtc";
import CopyLink from "../../../../windowLayout/title-bar/room-controls/copy-link";
import {SeeParticipants} from "./SeeParticipants";
import Chat from "./Chat";
import DevicesModal from "./DevicesModal";
import Icon from "components/icon/icon";
import MouseDirectionDetector from "../../../../button-component/movingdetection";
import {Canvas} from "../../../../canva/Canva";
import {MediaScreen} from "./MediaScreen";
import {ToolBar} from "../toolbar/toolBar";

export const EmptyRoomChanel = ({
    session,
    subscribedStreams,
    localStream,
    setOptions,
    contacts,
    clearRoom,
    backgroundImage,
    setBackgroundImage,
    noiseCancelling,
    setNoiseCancelling,
    videoEffect,
    setVideoEffect,
    conversation,
    current,
    displayName,
    kick,
    changeCamera,
    changeMicrophone,
    audioDevices,
    videoDevices,
    userMediaDevices,
    muted,
    toggleMute,
    sharing,
    toggleScreenSharing,
    videoMuted,
    toggleVideoMute,
    toggleDeafened,
    shareStream,
    processedStream
}) => {
    const chatroomRef = useRef()
    const {displayChatroom, setDisplayChatroom} = useChatroom()
    const [showGuests, setShowGuests] = useState(true)
    const [micFullActive, setMicFullActive] = useState(false)
    const [settingsFullActive, setSettingsFullActive] = useState(false)
    const [fullscreenMonitored, setFullscreenMonitored] = useState(false)
    const [videoFullActive, setVideoFullActive] = useState(false)
    const [addPeopleFullActive, setAddPeopleFullActive] = useState(false)
    const [invitePeopleEmptyRoom, setInvitePeopleEmptyRoom] = useState(true)
    const [addPeopleRightFullActive, setAddPeopleRightFullActive] = useState(false)
    const [showMessagesFullActive, setShowMessagesFullActive] = useState(false)
    const [backgroundBlurr, setBackgroundBlurr] =useState(false)
    const [activeOption,setActiveOption] = useState(false)
    const [ disableMuted, setDisableMuted ] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(false)
    const containerRef = useRef(null);
    const [showWhiteBoard,setShowWhiteBoard] = useState(false)
    const {is669px} = MediaScreen()
    const [showSettingsModalEmpty,setShowSettingsModalEmpty] = useState(false)

    const handleCloseContainer = () => {
        setMicFullActive(false)
        setVideoFullActive(false)
        setAddPeopleFullActive(false)
        setSettingsFullActive(false)
    };

    const handleContainerClick = (event) => {
        if (containerRef.current && containerRef.current === event.target) {
            handleCloseContainer();
        }
    };
    const isFullscreenRef = useRef()
    isFullscreenRef.current = isFullscreen
    if(!fullscreenMonitored) {
        setFullscreenMonitored(true)
        document.addEventListener("fullscreenchange", (e) => {
            if(isFullscreenRef && isFullscreenRef.current !== undefined) {
                setIsFullscreen(!isFullscreenRef.current)
            }
        })
    }
    const toggleFullscreen = () => {
        if(chatroomRef && chatroomRef.current) {
            if(!isFullscreen) {
                chatroomRef.current.requestFullscreen()
            } else {
                document.exitFullscreen()
            }
        }
    }

    //Testing for hover menu

    const [lastY, setLastY] = useState(null); // Store the last Y position
    const [isMovingUp, setIsMovingUp] = useState(false); // Store if the mouse is moving up

    useEffect(() => {
        const handleMouseMove = (event) => {
            const currentY = event.clientY;
            if (lastY !== null) { // Ensure we have a lastY to compare with
                // Check if the current Y position is less than the last Y position
                if (currentY < lastY) {
                    setIsMovingUp(true);
                } else {
                    setIsMovingUp(false);
                }
            }
            setLastY(currentY); // Update the lastY position
        };

        // Add the event listener
        window.addEventListener('mousemove', handleMouseMove);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [lastY]);


    const chatStyle = {
        ... (showWhiteBoard ? { borderLeft: '1px solid #DBDBDB' } : {}),
    };

    return(
        <WindowLayout>
            <div className="chatroom" id="mememe-container" ref={chatroomRef}>
                <div className={`containerChatRoom ${displayChatroom || invitePeopleEmptyRoom ? "chatActive": ''} ${invitePeopleEmptyRoom ? 'invitePeopleEmptyRoom' : ''}`}>
                    <div className={`Video ${showWhiteBoard ? 'canvas-active' : ""}`}>
                        <Canvas isOpen={showWhiteBoard} onClose={(e)=>{
                            setShowWhiteBoard(e)
                        }}/>

                        <div className={`SelectedParticipant ${sharing ? 'shareScreenActive' : ''}`}>

                                {!sharing && processedStream && processedStream.isVideoEnabled() ? (
                                    <>
                                        <VideoStream stream={processedStream} muted={true}></VideoStream>
                                        <span className="Name" style={{textTransform: "capitalize", bottom: 'unset', top: '15px'}}>{displayName}</span>

                                    </>
                                ) : sharing && shareStream && shareStream.hasVideo() ? (
                                    <VideoStream stream={shareStream} muted={true}></VideoStream>
                                ) : (
                                    <div className='video-off-empty-room'>
                                        <img src='/images/camera-off.png' alt={'avt'}/>
                                    </div>
                                ) }

                        </div>
                        {invitePeopleEmptyRoom && !showSettingsModalEmpty ? (
                            <ToolBar
                                desktop
                                current={current}
                                actionMicFullActive={micFullActive}
                                setActionMicFullActive={setMicFullActive}
                                actionVideoFullActive={videoFullActive}
                                actionAddPeopleFullActive={addPeopleFullActive}
                                actionSettingsFullActive={settingsFullActive}
                                localStream={localStream}
                                backgroundImage={backgroundImage}
                                setBackgroundImage={setBackgroundImage}
                                noiseCancelling={noiseCancelling}
                                setNoiseCancelling={setNoiseCancelling}
                                setVideoEffect={setVideoEffect}
                                changeCamera={changeCamera}
                                videoEffect={videoEffect}
                                changeMicrophone={changeMicrophone}
                                audioDevices={audioDevices}
                                videoDevices={videoDevices}
                                userMediaDevices={userMediaDevices}
                                displayName={displayName}
                                whiteBoard={showWhiteBoard}
                                muted={muted}
                                toggleMute={toggleMute}
                                sharing={sharing}
                                toggleScreenSharing={toggleScreenSharing}
                                videoMuted={videoMuted}
                                toggleVideoMute={toggleVideoMute}
                                processedStream={processedStream}
                                isMovingUp={isMovingUp}
                                chatroomRef={chatroomRef}
                                isFullscreen={isFullscreen}
                                toggleWhiteBoard={setShowWhiteBoard}
                                toggleSettings={setSettingsFullActive}
                                toggleAddPeopleFullActive={setAddPeopleFullActive}
                                toggleVideoActive={setVideoFullActive}
                                toggleMicActive={setMicFullActive}
                                containerRef={containerRef}
                                emptyRoom
                                displayChatroom={displayChatroom}
                                setDisplayChatroom={setDisplayChatroom}
                                setAddPeopleRightFullActive={setAddPeopleRightFullActive}
                                addPeopleRightFullActive={addPeopleRightFullActive}
                                setShowMessagesFullActive={setShowMessagesFullActive}
                                showMessagesFullActive={showMessagesFullActive}
                                setShowGuests={setShowGuests}
                                setVideoFullActive={setVideoFullActive}
                                videoFullActive={videoFullActive}
                                setAddPeopleFullActive={setAddPeopleFullActive}
                                addPeopleFullActive={addPeopleFullActive}
                                setMicFullActive={setMicFullActive}
                                micFullActive={micFullActive}
                                setSettingsFullActive={setSettingsFullActive}
                                settingsFullActive={settingsFullActive}
                                showWhiteBoard={showWhiteBoard}
                                setShowWhiteBoard={setShowWhiteBoard}

                                toggleShowSettingsModalEmpty={setShowSettingsModalEmpty}
                            />
                        ) : null}
                        <div className='leave-room-container'>
                            <LeaveRoom/>
                        </div>
                    </div>
                    <div className="Chat" style={chatStyle}>
                        {
                            invitePeopleEmptyRoom && showSettingsModalEmpty ? (
                                <ToolBar
                                    current={current}
                                    desktop={false}
                                    localStream={localStream}
                                    backgroundImage={backgroundImage}
                                    setBackgroundImage={setBackgroundImage}
                                    noiseCancelling={noiseCancelling}
                                    setNoiseCancelling={setNoiseCancelling}
                                    setVideoEffect={setVideoEffect}
                                    changeCamera={changeCamera}
                                    videoEffect={videoEffect}
                                    changeMicrophone={changeMicrophone}
                                    audioDevices={audioDevices}
                                    videoDevices={videoDevices}
                                    userMediaDevices={userMediaDevices}
                                    displayName={displayName}
                                    whiteBoard={showWhiteBoard}
                                    muted={muted}
                                    toggleMute={toggleMute}
                                    sharing={sharing}
                                    toggleScreenSharing={toggleScreenSharing}
                                    videoMuted={videoMuted}
                                    toggleVideoMute={toggleVideoMute}
                                    processedStream={processedStream}
                                    isMovingUp={isMovingUp}
                                    chatroomRef={chatroomRef}
                                    isFullscreen={isFullscreen}
                                    toggleWhiteBoard={setShowWhiteBoard}
                                    toggleSettings={setSettingsFullActive}
                                    toggleAddPeopleFullActive={setAddPeopleFullActive}
                                    toggleVideoActive={setVideoFullActive}
                                    toggleMicActive={setMicFullActive}
                                    containerRef={containerRef}
/*
                                    handleContainerClick={(e)=>handleContainerClick(e)}
*/
                                    emptyRoom={true}
                                    hideToolBar={true}
                                    toggleShowSettingsModalEmpty={setShowSettingsModalEmpty}
                                />
                            ) : invitePeopleEmptyRoom ? (
                                <SeeParticipants toAdd current={current}/>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </WindowLayout>
    )
}