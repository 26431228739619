import { create } from 'zustand'

export const useToast = create((set, get) => ({
  link: "",
  message: "",
  count: 0,
  icon:"",
  lastCount: 0,
  equalizeCount() {
    const { count } = get()
    set({lastCount: count})
  },
  addToast(link = null, message, icon="info") {
    const { count } = get()
    set({
      link,
      icon,
      message,
      count: count + 1
    })
  }
}))
