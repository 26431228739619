import { create } from "zustand"

export const useGroups = create((set, get) => ({
  groups: [],
  meetings: [],
  invitedGroups: [],
  selectedGroup: null,
  groupOwner: null,
  selectedGroupId: null,
  groupByName: null,
  searchGroupByName(name) {
    const { groups } = get();
    const foundGroup = groups.find(group => group.name.toLowerCase().includes(name.toLowerCase()));
    set({ groupByName: foundGroup ? foundGroup : null });
  },
  selectGroup(id) {
    const { groups,invitedGroups } = get()
    const selectedGroup = groups.find(g=>g._id === id) || invitedGroups.find(g=>g._id === id)
    console.log(selectedGroup,'finded')
    const owner = selectedGroup.owner;
    const groupOwner = selectedGroup.members.find(memb => memb._id === owner);
    set({selectedGroup: selectedGroup})
    set({groupOwner: groupOwner})

  },
  updateGroup: async ({groupId,name,description,file}) => {
    try {
      const response = await window.$comms.request("modifyGroup", {groupId,name,description,file})
      return true
    } catch (e) {
      return false
    }
  },
  addMember: async ({groupId, memberId}) => {
    try {
      const response = await window.$comms.request("addMember", {groupId, memberId})
      console.log(response)
      return response
    } catch (e) {
      console.log(e)
      return e
    }
  },
  addModerator: async ({memberId, groupId,canKick}) => {
    try {
      //const {groups} = get()
      const response = await window.$comms.request("groupAddModerator", {memberId, groupId,canKick})
      return response
    } catch (e) {
      return e
    }
  },
  removeModerator: async ({memberId, groupId}) => {
    try {
      //const {groups} = get()
      const response = await window.$comms.request("groupRemoveModerator", {memberId, groupId})
      return response
    } catch (e) {
      return e
    }
  },
  searchUser: async ({email}) => {
    try {
      const response = await window.$comms.request("users", {email})
      return response
    } catch (e) {
      return false
    }
  },
  removeMember: async ({groupId, memberId}) => {
    try {
      const {groups} = get()
      const response = await window.$comms.request("kickMember", {groupId, memberId})
      set(
        {
          groups: [
            response.group,
            ...groups.filter(g=>g._id !== response.group._id),
          ],
          selectedGroup: response.group
      })
      return true
    } catch (e) {
      return false
    }
  },
  createGroup: async ({name,description, file}) => {
    try {
      const {groups} = get()
      console.log(file,'received file')
      const response = await window.$comms.request("createGroup", { name,description, file })
      console.log(response,'response')
      set(
        {
          groups: [
            response.group,
            ...groups
          ],
          selectedGroup: groups.length > 0 ? groups[0] : response.group
    })
      return true
    } catch (e) {
      return e.reason
    }
  },
  deleteGroup: async ({id}) => {
    try {
      const response = await window.$comms.request("deleteGroup", { id })
      const {groups} = get()
      if(response.groups.length > 0) {
        set({groups: response.groups, selectedGroup: response.groups[0]})
      } else {
        set({groups: response.groups, selectedGroup: null})
      }
      console.log(response)
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  },
  getGroups: async () => {
    try {
      const response = await window.$comms.request("getGroups")
      const { selectedGroup } = get()
      let lastGroupId
      if(selectedGroup) {
        lastGroupId = selectedGroup._id
      }
      set({invitedGroups:response.invitedToGroups})
      const found = response.groups.find((g)=>g._id === lastGroupId)
      if(found) {
        set({groups: response.groups, selectedGroup: found })
      } else if(response.groups && response.groups.length > 0) {
        set({groups: response.groups, selectedGroup: response.groups[0] })
      } else {
        set({groups: response.groups })
      }
      return true
    } catch (e) {
      return false
    }
  },
  createMeeting: async ({date, name,description,file,id}) => {
    try {
      const { groups} = get()
      
      const response = await window.$comms.request("createMeeting", {file:file,date: date, name: name,description:description, id: id})
      if(response && response.group) {
        const finalGroups = [
          response.group,
          ...groups.filter(group=>{return group._id !== response.group._id})
        ]
        set({
          groups: finalGroups,
          selectedGroup: response.group
        })
      }
      return response
    } catch (e) {
      return e
    }
  },
  acceptGroupInvitation: async ({groupId}) => {
    try {
      const response = await window.$comms.request("groupAcceptInvitation",{groupId})
      const { groups,invitedGroups } = get()
      if (response.success) {
        const selectedGroup = invitedGroups.find(group => group._id === groupId);
        const updatedGroups = [...groups, selectedGroup];
        const updatedInvitations = invitedGroups.filter(group => group._id !== groupId);
        set({ groups: updatedGroups, invitedGroups: updatedInvitations });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false
    }
  },
  declineGroupInvitation: async ({groupId}) => {
    try {
      const response = await window.$comms.request("groupDeclineInvitation",{groupId})
      const { invitedGroups } = get()
      if (response.success) {
          const updatedInvitations = invitedGroups.filter(meeting => meeting._id !== groupId);
          set({ invitedGroups: updatedInvitations });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false
    }
  },
  deleteGroupPicture: async ({groupId}) => {
    try {
      const response = await window.$comms.request("groupDeletePicture",{groupId:groupId})
      return response
    } catch (e) {
      return e
    }
  },
}))

window.$comms.addListener("groupDeletedGroup", (group) => {
  const groups = useGroups.getState().groups;
  const groupsInvitations = useGroups.getState().invitedGroups;
  console.log(group,'delete group')
  const remainingGroups = groups.filter(g => g._id !== group);
  const remainingGroupsInvitations = groupsInvitations.filter(g => g._id !== group);
  useGroups.setState({
    groups: remainingGroups,
    invitedGroups: remainingGroupsInvitations });
});
// Add a listener for the "groupInvitedToGroup" event
window.$comms.addListener("groupInvitedToGroup", (group) => {
  // Retrieve the current state of groups and invited groups
  const { groups, invitedGroups } = useGroups.getState();

  // Check if the group already exists in the `groups` or `invitedGroups` arrays
  const groupExistsInGroups = groups.some(existingGroup => existingGroup._id === group._id);
  const groupExistsInInvitedGroups = invitedGroups.some(existingGroup => existingGroup._id === group._id);

  // If the group doesn't exist in either array, add it to `invitedGroups`
  if (!groupExistsInGroups && !groupExistsInInvitedGroups) {
    const updatedInvitedGroups = [...invitedGroups, group];

    // Update the state to reflect the new invited group
    useGroups.setState({ invitedGroups: updatedInvitedGroups });
    console.log("Group added to invited groups:", group);
  } else {
    console.log("Group already exists in the existing groups or invited groups:", group);
  }
});

window.$comms.addListener("groupUserAcceptedInvitation", (group) => {
  // Retrieve the current state
  const groups = useGroups.getState().groups
  const groupInvitations = useGroups.getState().invitedGroups;


  // Check if the meeting already exists in the meetings array
  const existingGroupIndex = groups.findIndex(m => m._id === group._id);

  // If it exists, update the meeting information
  if (existingGroupIndex !== -1) {
    groups[existingGroupIndex] = group;
  } else {
    // Otherwise, add it to the meetings array
    groups.push(group);
  }

  // Remove the meeting from invitations
  const remainingInvitations = groupInvitations.filter(m => m._id !== group._id);

  // Update the state with the new lists
  useGroups.setState({
    groups: [...groups], // Use spread to trigger state change
    invitedGroups: remainingInvitations
  });
});

window.$comms.addListener("groupUserDeclinedInvitation", (group) => {
  // Retrieve the current state
  const groups = useGroups.getState().groups;
  const groupInvitations = useGroups.getState().invitedGroups;


  // Find the index of the meeting in the meetings array
  const existingGroupIndex = groups.findIndex(m => m._id === group._id);

  // If it exists, update the meeting information
  if (existingGroupIndex !== -1) {
    groups[existingGroupIndex] = group;
  }

  // Remove the meeting from invitations
  const remainingInvitations = groupInvitations.filter(m => m._id !== group._id);

  // Update the state with the new lists
  useGroups.setState({
    groups: [...groups], // Spread operator to update the meetings array
    invitedGroups: remainingInvitations
  });
});