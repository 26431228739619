import Input from "components/input/input"
import Window from "../window"
import {useEffect, useState} from "react"
import Checkbox from "components/checkbox"
import { useAuthentication } from "stores/authentication";
import { Link, useNavigate } from "react-router-dom";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import Footer from "../../../layout/footer";
import WindowLayout from "../../../windowLayout/window-layout";

const Login = () => {
  const navigate = useNavigate()
  const { login, isAuthenticated,user,passwordUpdated,setPasswordUpdated } = useAuthentication()

  useEffect(() => {
    if(user)
      navigate('/')
  }, []);

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  const [rememberMe,setRememberMe]=useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [errors, setErrors] = useState('')
  const emailValidations = [
    {
      message: 'This format is not accepted',
      check: (input) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(input);
      }
    },
    {
      message: "Requires not to be empty",
      check: (input) => {
        return input.length >= 0;
      }
    },
    {
      message: "Requires an '@' symbol",
      check: (input) => {
        return input.includes('@');
      }
    },
    {
      message: "Requires a domain name after '@'",
      check: (input) => {
        let parts = input.split('@');
        return parts[1] && parts[1].includes('.');
      }
    },
    {
      message: "Requires a '.' after the domain name",
      check: (input) => {
        let domainPart = input.substring(input.lastIndexOf("@") + 1);
        return domainPart.includes('.') && domainPart.indexOf('.') !== 0;
      }
    },
    {
      message: "Requires a valid top-level domain (e.g., .com, .ro)",
      check: (input) => {
        let domainPart = input.substring(input.lastIndexOf("@") + 1);
        return domainPart.match(/\.[a-zA-Z]{2,}$/);
      }
    },
    {
      message: "Cannot contain '<', '>', or other unsafe characters",
      check: (input) => {
        return !input.match(/[<>]/);
      }
    },
    {
      message: "Email must not contain spaces or special characters like '()<>[]:,;\\\"!#$%&'*+/=?^_`{|}~'",
      check: (input) => {
        return !input.match(/[\s()<>[\]:,;\\"!#$%&'*+/=?^_`{|}~]/);
      }
    }
  ];
  const passwordValidations = [
    {
      message: "Requires not to be empty",
      check: (input) => {
        return input.length > 0;
      }
    }
  ]
  const checkEmail = () => {
    for (let i = 0; i < emailValidations.length; i++) {
      if (!emailValidations[i].check(email)) {
        setErrors(errors + ' email')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkPassword = () =>{
    for (let i = 0; i < passwordValidations.length; i++) {
      if (!passwordValidations[i].check(password)) {
        setErrors(errors + ' password')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkCreatRoomForm = ()=>{
   if(checkEmail() && checkPassword()){
     return true
   }
  }
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [])
  useEffect(()=>{
    if (passwordUpdated)
      setTimeout(()=>{
        setPasswordUpdated(null)
      },5000)
  },[passwordUpdated])
  const resetState = () => {
    setIsLoading(false)
    setError("")
    setSuccess("")
    setEmail("")
    setPassword("")
    setTermsAgreed(false)
  }
  const attemptLogin = async (e) => {
    e.preventDefault()
    if(checkCreatRoomForm()) {
      setErrors("")
      const result = await login({
        remember: rememberMe,
        email: email.toLowerCase(),
        password
      })
      if (result === true) {
        setIsLoading(true)
        resetState()
        setSuccess("Logged in successfully!")
        setTimeout(() => {
          navigate(`/`)
        }, 2000)
      } else if (result === "NOT_VERIFIED") {
        setIsLoading(true)
        setError("Email not verified!")
        setIsLoading(false)
        setTimeout(() => {
          navigate(`/verify?email=${email}`)
        }, 2000)
      } else {
        // I want to show the error on this state that the account is incorrect
        setErrors("Incorrect email or password.");
        setIsLoading(false)
      }
    }

  }
  return (
   <WindowLayout>
     <Window
         title="Login"
         message="Logging in"
         isLoading={isLoading}
         minHeight="296px"
         error={error}
         success={success}>
      <div className="w-100 flex-row d-flex dashboad-container">
        <div className='dashboard-image-container'>
          <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
        </div>
        <div className="login-register dashboard-content-container ">
          <div className='dashboard-content'>
            <div className='back-button'>
              <Link to={'/'}>
                <div className='back-icon-container'>
                  <BackIcon/>
                </div>
              </Link>
            </div>
            <h2 className='dashboard-title'>{passwordUpdated ? passwordUpdated : 'Welcome To Boom!'}</h2>
            <div className='dashboard-tabs-buttons-container'>
              <span className='dashboard-login-title'>Login To Your Account</span>
            </div>
            <form onSubmit={attemptLogin} noValidate style={{marginBottom: 0}}>
              <Input
                  name="email"
                  label="Email"
                  type="email"
                  value={email}
                  autoComplete="username"
                  required
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  placeholder={'Enter email address'}
                  foundError={errors.includes('email')}
                  errorAfterSubmit={errors}
                  validations={emailValidations}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    checkEmail()
                  }} />
              <Input
                  name="password"
                  label="Password"
                  autoComplete="new-password"
                  placeholder={'Enter your password'}
                  type="password"
                  minLength="6"
                  validations={passwordValidations}
                  foundError={errors.includes('password')}
                  errorAfterSubmit={errors}
                  maxLength="32"
                  value={password}
                  required
                  onChange={(e) => {
                    setPassword(e.target.value)
                    checkPassword()
                  }} />
              <div style={{display:"flex", justifyContent: "space-between", width: 'clamp(200px, 100%, 685px)'}}>
                <Checkbox
                    value={rememberMe}
                    onClick={() => {
                      setRememberMe(!rememberMe)
                    }}
                    required
                    foundError={errors.includes('rememberMe')}
                    label="Remember me"/>
                <Link className='need-help-button' style={{minWidth:"fit-content", cursor: 'pointer'}} to="/recovery">Need Help? Lost Your Password?</Link>
              </div>

              <input
                  className={
                    `SecondaryButton halfDashboardButton ${disableSubmit ?
                        "disabled" : ""
                    }`}
                  type="submit"
                  value="Login"
                  required />
              <div className='link-to-register-login'>
                <p className="">Don't have an account?</p>
                <Link to={'/register'}>Sign Up</Link>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </div>
      </Window>
   </WindowLayout>
  )
}
export default Login