import React, { useEffect } from "react"
import { useState } from "react"
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useRef } from "react"
import {shopModal} from "../../../../layout/shopModal";
import Icon from "../../../../icon/icon";
import {getInitials} from "../../../../../utils";
import {UserProfilePicture} from "../../../../user-profile-picture";

const Message = ({sender, message, date, messages, forceUpdate, sendMessage, userData, isOwner}) => {
  const [seen, setSeen] = useState(false)
  const [refreshedTime, setRefreshedTime] = useState(false)
  const [refreshTimeInterval, setRefreshTimeInterval] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showCustomEmojiPicker, setShowCustomEmojiPicker] = useState(false)
  const see = () => {
    if(!seen) {
      setSeen(true)
    }
  }
  const {setDeleteMe,setDeleteEveryone,setForDelete,setShow,setIsOwner} = shopModal()
  const deleteAction = (owner) => {
    setDeleteMe(deleteMe)
    setDeleteEveryone(deleteMeForAll)
    if(owner === 'owner')
      setIsOwner(true)
    setForDelete(true)
    setShow(true)
  }

  const deleteMe = () => {
    const index = messages.findIndex(m=>{return JSON.parse(m.content).uuid === message.uuid})
    if(index !== -1) {
      const parsed = JSON.parse(messages[index].content)
      parsed.show = false
      messages[index].content = JSON.stringify(parsed)
      forceUpdate()
    }
  }
  const deleteMeForAll = () => {
    sendMessage(JSON.stringify({
      target: message.uuid,
      command: "delete"
    }, userData))
    deleteMe()
  }
  const localReact = (emoji, target) => {
    messages.forEach(msg=>{
      const parsed = JSON.parse(msg.content)
      if(parsed.uuid === target) {
        if(!parsed.reactions) {
          parsed.reactions = {}
        }
        parsed.reactions['local'] = emoji
      }
      msg.content = JSON.stringify(parsed)
    })
  }
  const reactToMe = (emoji) => {
    sendMessage(JSON.stringify({
      target: message.uuid,
      emoji: emoji
    }), userData)
    localReact(emoji, message.uuid)
    setShowCustomEmojiPicker(false)
    setShowEmojiPicker(false)
    forceUpdate()
  }
  let reactions = {}
  if(message.reactions && message.reactions) {
    const formatted = {}
    const keys = Object.keys(message.reactions)
    keys.forEach(username=>{
      if(formatted[message.reactions[username]] === undefined) {
        formatted[message.reactions[username]] = 1
      } else {
        formatted[message.reactions[username]] += 1
      }
    })
    reactions = formatted
  }
  const getFixedMessageTime = () => {
    const split = date.split(":")
    const hours = split[0]
    const minutes = split[1]
    const differential = (hours > 1) || minutes > 3 ? (3 * 60) : 0
    const timeInSeconds = (hours * 60 * 60 + minutes * 60) - differential
    const resultingHours = Math.floor((timeInSeconds / 60 / 60))
    const resultingMinutes = (timeInSeconds / 60) - (resultingHours * 60)
    return [resultingHours, resultingMinutes]
  }
  const getTimeNow = () => {
    const currentTime = new Date()
    const currentHour = currentTime.getHours()
    const currentMinutes = currentTime.getMinutes()
    return [currentHour, currentMinutes]
  }
  const getFormattedTime = () => {
    const [resultingHours, resultingMinutes] = getFixedMessageTime()
    const formattedHours = `${resultingHours < 12 ? `0${resultingHours}` : resultingHours}`
    const formattedMinutes = `${resultingMinutes < 12 ? `0${resultingMinutes}` : resultingMinutes}`
    // Just now thing
    const [currentHour, currentMinutes] = getTimeNow()
    const showJustNow = currentHour === resultingHours && currentMinutes === resultingMinutes
    return showJustNow ? 'Just now' : `${formattedHours}:${formattedMinutes}`
  }
  const intervalRef = useRef()
  intervalRef.current = refreshTimeInterval
  const toggleRefreshTime = () => {
    if(getFixedMessageTime() !== 'Just now') {
      setRefreshedTime(true)
      if(intervalRef && intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }
  if(!refreshTimeInterval) {
    setRefreshTimeInterval(
      setInterval(toggleRefreshTime, 60*1000)
    )
  }
  useEffect(()=>{
    return ()=>{
      // console.log("Clearing Interval now!")
      clearInterval(refreshTimeInterval)
    }
  },[refreshTimeInterval])
  useEffect(()=>{
    const interval = setTimeout(()=>{
      see()
    }, 3000)
    return ()=>{
      clearTimeout(interval)
    }
  }, [])
  return (
    <div className={`message ${!seen ? 'new' : ''}`} onMouseEnter={see}>
      <div>
        {sender.isLoggedIn ? (
            <UserProfilePicture width={53} height={53} fontSize={18} userId={sender.loggedInId} userFirstName={sender.loggedInFirstName} userLastName={sender.loggedInLastName} />
        ) : (
            <div style={{width:53, height:53, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:4, backgroundColor:'#F4F4F4'}}>
              <p style={{margin:0, textTransform:"uppercase", color:'#090721', fontSize:18}}>{getInitials(sender.nickname)}</p>
            </div>
        )}
      </div>
      <div className='messageContent'>
        <span className='messageNickname'>{sender.nickname}</span>
        <div className='text-message-container'>
          <span className='messageText'>{message.text}</span>
        </div>
        <div className='options-in-message'>
          <div className='firstLineMessage'>
            <span className='messageDate' style={{color: '#838383'}}>{getFormattedTime()}</span>
          </div>

          {
              Object.keys(reactions) && Object.keys(reactions).length > 0 && (
                  <div className="Reactions">
                    {
                      Object.keys(reactions).map((key, idx) => {
                        return (<div key={idx} className="Reaction">
                          <div>{key}</div>
                          <span className='reaction-number'> {reactions[key]}+</span>
                        </div>)
                      })
                    }
                  </div>
              )
          }
          {/*<div className={'pencil-icon'}>
            <Icon  name={'grey-pencil'} size={16} color={'#979797'}/>
          </div>*/}
          <div className={'smile-icon'} onClick={() => setShowEmojiPicker(true)}>
            <Icon  name={'grey-smile'} size={16} color={'#979797'}/>
          </div>
          <div className={'trash-icon'} onClick={() => {
            deleteAction(isOwner ? 'owner' : 'guest')
          }}>
            <Icon  name={'grey-trash'} size={16} color={'#979797'}/>
          </div>

        </div>
        {
            showEmojiPicker && (
                <div className="ReactionPicker" onMouseLeave={() => setShowEmojiPicker(false)}>
                  <img src='/images/Polygon_1.svg' alt='polygon' width={22} height={22} />
              <li onClick={(e)=> {reactToMe('❤️')}}>❤️</li>
              <li onClick={(e)=> {reactToMe('🤑')}}>🤑</li>
              <li onClick={(e)=> {reactToMe('😆')}}>😆</li>
              <li onClick={(e)=> {reactToMe('😊')}}>😊</li>
              <li className='add-new-emoji' onClick={(e)=>{e.stopPropagation(); setShowCustomEmojiPicker(true)}}>+</li>
              {
                showCustomEmojiPicker && (
                  <Picker
                    data={data}
                    onMouseLeave={(e)=>{e.stopPropagation();}}
                    onEmojiSelect={(e)=>{ reactToMe(e.native)}}
                    onClickOutside={(e)=>{e.stopPropagation(); setShowCustomEmojiPicker(false)}} />
                )
              }
            </div>
          )
        }

      </div>
    </div>
  )
}
export default Message