import React from "react";
import Flag from "../flag/Flag";
import {useEffect, useRef, useState} from "react";
import './select-country-phone-code.scss'
import Icon from "../icon/icon";
import {countries} from "../../assets/countries";
const SelectCountryPhoneCode = ({value,style,disabled,onChange,validations,required,foundError,countryCode}) => {
    const [errors, setErrors] = useState([])
    const parsePhoneNumber = (fullNumber) => {
        // Check if the phone number includes a country code
        console.log(fullNumber)
        fullNumber = String(fullNumber);
        const match = countries.find(phone => fullNumber.startsWith(phone.phone));
        if (!match) return { code: '', number: fullNumber }; // If no country code is found, return only the number
        // Extract country code and the rest of the number
        return {
            code: match.code,
            number:fullNumber.slice(match.code.length).trim()
        };
    };
    const [phoneNumber,setPhoneNumber] = useState(value ? parsePhoneNumber(value).number : '')

    const [currentSelected,setCurrentSelected] = useState(countryCode ? countries.find((country)=>country.code === countryCode) :{
        code: 'AD',
        label: 'Andorra',
        phone: '376'
    })
    const [showOptions,setShowOptions] = useState(false)

    const containerRef = useRef(null);
    const lastKeyPressed = useRef('');
    const keyPressCount = useRef(0);
    const optionRefs = useRef(countries.reduce((acc, _, index) => {
        acc[index] = React.createRef();
        return acc;
    }, {}));

    const scrollToRef = (index) => {
        if (optionRefs.current[index] && optionRefs.current[index].current) {
            optionRefs.current[index].current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    };

    useEffect(() => {
        // Function to check if the click is outside the container
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        // Add event listener when the component is mounted
        document.addEventListener("mousedown", handleClickOutside);

        // Return a function to remove the event listener on component unmount
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);


    const checkValidations = () => {
        const result = []
        if (!validations)
            return
        validations.forEach((validation, idx) => {
            if(!validation.check(value)) {
                result.push(
                    <div className="inputError" key={`phone-error-${idx}`}>
                        <img className='error-icon-inputs' src='/images/danger-triangle.svg' alt="error-icon"/>
                        {validation.message}
                    </div>
                )
            }
        })
        setErrors(result)
    }
    useEffect(()=>{
        if((value && value.length > 0) || foundError) {
            checkValidations()
        } else {
            if(errors.length > 0) {
                setErrors([])
            }
        }
    },[foundError,value])

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (!showOptions) return;

            const { key } = event;
            if (!/^[a-zA-Z]$/.test(key)) return; // Ensure it's a letter

            if (lastKeyPressed.current !== key) {
                lastKeyPressed.current = key;
                keyPressCount.current = 0;
            }

            const matchingCountries = countries.filter(country => country.label.toUpperCase().startsWith(key.toUpperCase()));
            if (matchingCountries.length > 0) {
                const nextCountryIndex = keyPressCount.current % matchingCountries.length; // Cycle through matches
                setCurrentSelected(matchingCountries[nextCountryIndex]);
                keyPressCount.current++;
                const scrollTo = countries.findIndex(country => country.code === matchingCountries[nextCountryIndex].code);
                // Scroll to the matched country option
                scrollToRef(scrollTo);
            }
        };

        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [showOptions]);



    return <div ref={containerRef} style={style} className={`select-country-phone-container ${disabled ? 'disabledInput' : ''}`}>
            <label htmlFor={'phone-number'}>Phone</label>
            <div className={'container-input'}>
                <div
                    onClick={()=> {
                        if (!disabled)
                            setShowOptions(!showOptions)
                    }}
                    aria-disabled={disabled}
                    style={{
                        display:"flex",
                        gap:10,
                        alignItems:"center",
                        paddingRight: 10,
                        minWidth: 120,
                        borderRight: '1px solid #DBDBDB'}}>
                    <Flag countryCode={currentSelected} />
                    <p className={'phone-code'}>{`+ ${currentSelected.phone}`}</p>
                    <Icon name={'fa-angle-down'}  color={'#909090'} size={20}/>
                </div>
                <input
                    required={required}
                    placeholder={phoneNumber}
                    disabled={disabled}
                    onChange={(e)=>{
                        console.log(e.target.value,'phone number')
                    onChange('+'+currentSelected.phone+e.target.value)
                }}
                    name={'phone-number'}
                    pattern="[0-9]*"
                    maxLength={10}
                    id={'phone-number'}
                    onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onClick={()=>setShowOptions(false)} type={"number"}/>
            </div>
        <div style={{display:"flex",flexDirection:"column"}}>
            {errors}
        </div>
        {showOptions ? (
            <div className={'options-container'}>
                <div className={'items-container'}>
                    {countries.map((country,index)=>{
                        return(
                           <div ref={optionRefs.current[index]} key={'phone-'+index} className={'item-option-phone'} onClick={()=>{
                               setCurrentSelected(country)
                               setShowOptions(false)
                           }}>
                               <Flag countryCode={country} />
                               <p >{`+ ${country.phone}`}</p>
                               <p >{`+ ${country.label}`}</p>
                           </div>
                        )
                    })}
                </div>
            </div>
        ) : null}
        </div>

}

export default SelectCountryPhoneCode;
