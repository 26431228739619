import JoinRoom from "./join"
import CreateRoom from "./create"
import './dashboard.scss'
import WindowLayout from "components/windowLayout/window-layout"
import React, {useState} from "react";
import Footer from "../../layout/footer";
import { useSearchParams } from "react-router-dom";
import Cropper from "react-cropper";
import Input from "../../input/input";
import ImageUploadAndCrop from "../../upload-image/image-upload-and-crop";

const Rooms = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const room = searchParams.get('room');
    const[showTab,setShowTab] = useState(room ? 'joinMeeting' : 'createMeeting');
    const [testImage,setTestImage] = useState();
    const [testImageURl,testImageURL] = useState('')

    const [cropper,setCropper] = useState()

    const getNewAvatarUrl = (e) => {
        if (e.target.files) {
            testImageURL(URL.createObjectURL(e.target.files[0]));
        }
    };
    const filePickHandler = (files) => {
        const file = files
        const objectUrl = URL.createObjectURL(file)
        const img = document.createElement("img")
        img.onload = () => {
            console.log(img.width)
            console.log(img.height)
            if(img.width !== 128 || img.height !== 128) {
                console.log("Image needs to be 128px by 128px in dimensions!")
            }
            URL.revokeObjectURL(objectUrl)
        }
        img.src = objectUrl
        img.setAttribute("style", "display: none")
        document.body.appendChild(img)
        if(file.size > 64000)
            console.log('prea mare')
        setTestImage(files)

    }
    const getCropData = async () => {
        if (cropper) {
            const canvas = cropper.getCroppedCanvas();

            // Convert the canvas to a Blob with a specified quality
            const blobPromise = new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 0.9); // Adjust the quality as needed
            });

            const blob = await blobPromise;

            // Resize the image to a maximum size of 64 KB
            const resizedBlob = await resizeImage(blob, 64000);
            console.log(resizedBlob,'resizedBlob')
            const file = new File([resizedBlob], "newAvatar.jpg", { type: "image/jpeg" });

            if (file) {
                filePickHandler(file)
            }
            else {
                console.log('error')
            }
        }
    };
    const resizeImage = async (blob, maxSize) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const scaleFactor = Math.min(1, maxSize / (img.width * img.height));

                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;

                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob((resizedBlob) => {
                        resolve(resizedBlob);
                    }, 'image/jpeg', 0.9); // Adjust the quality as needed
                };
                img.src = e.target.result;
                console.log(img,'img')
            };
            reader.readAsDataURL(blob);
        });
    };


    return (
        <WindowLayout>
        <div className="w-100 flex-row d-flex dashboad-container">
            <div className='dashboard-image-container'>
                <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
            </div>
            <div className="dashboard-content-container home-page " style={{marginTop: '29px'}}>
                <div className='dashboard-content'>
                    <h2 className='dashboard-title'>Welcome To Boom!</h2>

                   {/* <ImageUploadAndCrop />*/}

                    <div className='dashboard-tabs-buttons-container'>
                        <button className={`dashboard-tabs-button ${showTab === 'createMeeting' ? 'active' : ''}`}
                                onClick={() => {
                                    setShowTab('createMeeting')
                                }}>Create A Meeting
                        </button>
                        <button className={`dashboard-tabs-button ${showTab === 'joinMeeting' ? 'active' : ''}`}
                                onClick={() => {
                                    setShowTab('joinMeeting')
                                }}>Join A Meeting
                        </button>
                    </div>
                    {showTab === 'createMeeting' ? (
                        <CreateRoom className="createRoom"/>

                    ) : (
                        <JoinRoom className="joinRoom" defaultRoom={room}/>

                    )}
                </div>
                <Footer/>

            </div>
        </div>
        </WindowLayout>
    )
}

export default Rooms