import "./title-bar.scss"
import Icon from "components/icon/icon"
import Logo from "components/logo"
import UserControl from "./user/user-control"
import Beta from "components/beta/beta"
import LeaveRoom from "./room-controls/leave-room"
import OpenChat from "./room-controls/open-chat"
import { useRooms } from "stores/rooms"
import CopyLink from "./room-controls/copy-link"
import {shopModal} from "../../layout/shopModal";
import {ButtonComponent} from "../../button-component/button-component";
import {useNavigate} from "react-router-dom";
import {MediaScreen} from "../../pages/Rooms/chatroom/encrypted/MediaScreen";
import {useGroups} from "../../../stores/groups";
import {useAuthentication} from "../../../stores/authentication";
import useNavigationStore from "../../../stores/navigation-bar";
import {useState} from "react";


const TitleBar = () => {
  const { current: isInRoom } = useRooms()
  const {setModalGroups,setShow} = shopModal()
  const navigate =useNavigate()
  const {isMobile} = MediaScreen()
  const { isAuthenticated } = useAuthentication()
  const {addLastNavigation} = useNavigationStore()
  const { pathname } = location;

  const position = {
    position: pathname.includes('/register')  ?  'fixed' : 'relative'

  }
  return (
    <header style={position} className="titleBarContainer container-fluid">
      <div className="row h-100" >
        <div className="col d-flex align-items-center" style={{paddingLeft: '24px'}}>
          <div className="d-flex align-items-center">
            <div className="betaLogo " style={{minWidth: "100px", marginRight: "15px"}}>
              <Logo/>
              {/* <Beta/> */}
            </div>
            <div style={{width: "4px", height: "1px"}}></div>
          </div>
        </div>
        <div className="col d-flex flex-direction-row justify-content-end align-items-center gap-1" style={{paddingRight: '24px'}}>
          {isAuthenticated ? (
              <ButtonComponent
                  ClassName={'group-button-page'}
                  bgOnHover={'transparent'}
                  buttonColor={'#835FF7'}
                  IconColor={'#fff'}
                  IconSize={20}
                  IconColorHover={'#835FF7'}
                  OnClick={()=>{
                    addLastNavigation('/manage-groups')
                    navigate('/manage-groups')
                  }}
                  iconName={!isMobile ? 'group' : ''}
                  Text={!isMobile ? 'Manage Groups' : 'M.G.'}
              />
          ) : null}
          {isAuthenticated ? (
              <ButtonComponent
                  ClassName={'group-button-page'}
                  bgOnHover={'transparent'}
                  buttonColor={'#24233E'}
                  IconColor={'#fff'}
                  IconSize={20}
                  IconColorHover={'#24233E'}
                  OnClick={()=>{
                    addLastNavigation('/manage-meetings')
                    navigate('/manage-meetings')
                  }}
                  iconName={!isMobile ? 'group' : ''}
                  Text={!isMobile ? 'Manage Meetings' : 'M.M.'}
              />
          ) : null}
          <UserControl/>
        </div>
      </div>
    </header>
  )
}
export default TitleBar