import React, {useEffect, useState} from "react";


export const UserProfilePicture = (
    {
        width=52,
        maxWidth = 'unset',
        minHeight = 'unset',
        height=52,
        fontSize=18,
        groupImage=false,
        groupImageURL,
        userId,
        userFirstName,
        userLastName,
        foundImage = () => {}
    }) => {
    const [profilePicture,setProfilePicture] = useState(`https://gsvoiceapp.fra1.digitaloceanspaces.com/pfps/${userId}.png`)
    const [showAlternativePictureProfile,setShowAlternativePictureProfile] = useState(false)
    useEffect(() => {
        setProfilePicture(`https://gsvoiceapp.fra1.digitaloceanspaces.com/pfps/${userId}.png`)
        setShowAlternativePictureProfile(false)
    }, [userId]);
    const handleImageMemberError = () => {
        if (!showAlternativePictureProfile) { // Only update if the state is not already set
            setShowAlternativePictureProfile(true);
            foundImage(true)
        }
    }

    const [showAlternativeGroupProfile,setShowAlternativePictureGroup] = useState(false)

    const handleImageGropError = () => {
        if (!showAlternativeGroupProfile) { // Only update if the state is not already set
            setShowAlternativePictureGroup(true);
        }
    }

    if (groupImage) {
        return (
            <>
                {showAlternativeGroupProfile || !groupImageURL ? (
                    <img src={'/images/group-image-default.png'} alt='group-picture' style={{
                        backgroundColor: '#EAE9EC',
                        maxWidth: maxWidth,
                        minHeight: minHeight,
                        width: width,
                        height: height,
                        objectFit: "cover",
                        borderRadius: 4
                    }}/>
                ) : (
                    <img
                        src={groupImageURL} onError={handleImageGropError}
                         style={{borderRadius: 4,maxWidth:maxWidth ,minHeight: minHeight ,width:width, height: height, objectFit: "cover"}}
                         alt='profile-picture' width={width} height={height}/>
                )}
            </>
        )
    }

    return(
        <>
            {showAlternativePictureProfile ? (
                <div style={{width:width,minWidth:width, height:height, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:4, backgroundColor:'#F4F4F4'}}>
                    <p style={{margin:0, textTransform:"uppercase", color:'#090721', fontSize:fontSize}}>{userFirstName[0]}{userLastName[0]}</p>
                </div>
            ) : (
                <img src={profilePicture} onError={handleImageMemberError} style={{borderRadius:4,width:width,height:height,objectFit:"cover"}} alt='profile-picture' width={width} height={height}/>
            )}
        </>
    )
}