import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../../../../stores/authentication";
import {useGroups} from "../../../../stores/groups";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import {ButtonComponent} from "../../../button-component/button-component";
import Icon from "../../../icon/icon";
import {SelectRole} from "../../../select-role/selectRole";
import {GroupPastMeetings} from "../../../group-modal/group-past-meetings";
import {Search} from "../../../search-bar/search";
import {Manage} from "../../../../stores/manage";
import {QuickListGroups} from "./components/quick-list-groups/quick-list-groups";
import {QuickListMembers} from "./components/quick-list-members/quick-list-members";
import WindowLayout from "../../../windowLayout/window-layout";
import {UserProfilePicture} from "../../../user-profile-picture";
import {MediaScreen} from "../../Rooms/chatroom/encrypted/MediaScreen";

export const SpecificGroupInvite = () => {
    const query = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const { isAuthenticated,user} = useAuthentication()
    const [groupId, setGroupId] =useState(query.get('id')) ;
    const {selectGroup,selectedGroup,getGroups,addMember,searchUser,groupOwner } = useGroups()
    const [searchQuery, setSearchQuery] = useState('');
    const [foundUserInSearch,setFoundUserInSearch] = useState(null)
    const [newUser,setNewUser] = useState(false)
    const [addUserResponseMessage,setAddUserResponseMessage] = useState('')
    const [windowShow,setWindowShow] = useState('membersEmpty')
    const {isMobile,isTablet} = MediaScreen()

    useEffect(() => {
        if (!isAuthenticated)
            navigate('/login')
        async function fetchData() {
            await getGroups();
            await selectGroup(query.get('id'));
        }
        fetchData();
    }, []);

    const renderContent = () => {
        switch (windowShow) {
            case 'membersEmpty':
                return (
                    <div className={'empty-members'}>
                        <span className={'title-window'}>{selectedGroup?.name} members</span>
                        <span className={'under-title-window'} style={{textTransform: 'unset'}}>
                            {selectedGroup.members.length > 1 ?
                                ('To add a member to your list, type the email of a BOOOM member in the search box above and hit \'Enter\'.') :
                                ('Your group is empty. To add the first member to your list, type the email of a BOOOM member in the search box above and hit \'Enter\'.')}
                        </span>
                    </div>
                );
            case 'foundInSearch':
                if (foundUserInSearch)
                    return (
                        <div className={'found-user-in-group'}>
                            <div className={'found-message'}>
                                <span className={'title-window'}>{newUser ? 'Success' : addUserResponseMessage ? 'Error' : 'Group Members'}</span>
                                <span className={'under-title-window'}>{newUser ? 'User Added Successfully To The Group' : addUserResponseMessage ? addUserResponseMessage : 'We have found a member matching your search'}</span>
                            </div>
                            <div className={'user-found-card-to-add'}>
                                <div className='profile-image-container'
                                     style={{height: 86, width: 86, minWidth: "unset"}}>
                                    <UserProfilePicture width={86} height={86} fontSize={18} userId={foundUserInSearch._id} userFirstName={foundUserInSearch.firstName} userLastName={foundUserInSearch.lastName[0]} />

                                </div>
                                <div className={'user-info-container'}>
                                        <span
                                            className={'user-name'}>{foundUserInSearch.firstName} {foundUserInSearch.lastName}</span>
                                    <span className={'user-email'}>{foundUserInSearch.email}</span>
                                </div>
                                <ButtonComponent
                                    ClassName={'add-to-this-group'}
                                    iconName={'empty-plus'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#42D3D8'}
                                    IconColor={'#fff'}
                                    IconSize={20}
                                    IconColorHover={'#42D3D8'}
                                    Text={'Add To This Group'}
                                    OnClick={async () => {
                                        try {
                                            const result = await addMember({
                                                groupId: selectedGroup._id,
                                                memberId: foundUserInSearch._id
                                            })
                                            if (result.success) {
                                                setNewUser(true)
                                            }
                                            else if(!result.success) {
                                                setAddUserResponseMessage(result.reason)
                                            }
                                            setTimeout(() => {
                                                setNewUser(false)
                                                setFoundUserInSearch(null)
                                                setAddUserResponseMessage('')
                                                setSearchQuery('')
                                                setWindowShow('membersEmpty')
                                            }, 3000);
                                        } catch (e) {
                                            setAddUserResponseMessage('There was an error trying to add this member. Please try again later.')
                                            setTimeout(() => {
                                                setNewUser(false)
                                                setFoundUserInSearch(null)
                                                setAddUserResponseMessage('')
                                                setSearchQuery('')
                                                setWindowShow('membersEmpty')
                                            }, 3000);
                                            console.log(e)
                                        }
                                    }}
                                />
                            </div>

                        </div>
                    );
                break;
            case 'notFoundInSearch':
                return (
                    <div className={'found-user-in-group'}>
                        <div className={'found-message'}>
                            <span className={'title-window'}>Member Not Found</span>
                            <span className={'under-title-window'}>Make sure the email address it's correct!</span>
                        </div>

                    </div>
                );
            default:
                return <p>Another</p>;
        }
    };

    if (!selectedGroup || !groupOwner)
        return <p>Loading</p>

    return (
        <WindowLayout>
            <div className={'group-settings-container'}>
                <div className='back-button' style={{display: "flex", justifyContent: "space-between", flexDirection:isMobile ? 'column' : 'row', gap:isMobile ? '12px' : '0' }}>
                    <div className={'back-with-breadcrumbs'} style={{alignItems:isTablet ? 'flex-start': 'center'}} >
                        <div className='back-icon-container' style={{minWidth:52}} onClick={() => {
                            navigate(-1)
                        }}>
                            <BackIcon/>
                        </div>
                        <div className={'breadcrumbs-container'}>
                            <span>Manage groups</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span onClick={() => {
                                navigate('/manage-groups')
                            }}>My Groups</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span onClick={() => {
                                navigate(`/manage-groups/my-group?id=${selectedGroup._id}`)
                            }}>{selectedGroup?.name}</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span className={'active'}>Invite</span>
                        </div>

                    </div>
                    <div className={'global-button-group-settings'}>
                        <ButtonComponent
                            ClassName={'group-button-page'}
                            iconName={'empty-plus'}
                            bgOnHover={'transparent'}
                            style={{maxWidth:isMobile ? 'unset' : '',width:'100%'}}
                            buttonColor={'#42D3D8'}
                            IconColorHover={'#42D3D8'}
                            IconColor={'#fff'}
                            IconSize={20}
                            Text={!isTablet || isMobile ? 'Create A New Group' : ''}
                            OnClick={() => {
                                navigate('/manage-groups/create-group')
                            }}
                        />
                    </div>
                </div>
                <div className={'header-container'} style={{flexDirection: "row", alignItems: "center",flexWrap:"wrap"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: 12, flex: 1}}>
                    <span className={'title-window with-edit'}>
                        {selectedGroup?.name}
                        {groupOwner._id === user._id ? (
                            <Icon
                                onClick={()=>{navigate(`/manage-groups/edit-group?id=${selectedGroup._id}`)}}
                                className={'edit-icon'}
                                name={'edit'}
                                size={22}
                                withStroke
                                color={'#090721'}/>
                        ) : null}
                    </span>
                        <span
                            className={'under-title-window'}>{selectedGroup?.description ?? 'Lets gather some ideas together'}</span>
                    </div>
                    <div style={{maxWidth: '565px', width:isMobile ? '100%' :'50%'}}>
                        <Search withKeyPress value={searchQuery} setSearchQuery={async (e) => {
                            try {
                                const result = await searchUser({email: e})
                                if (result) {
                                    setFoundUserInSearch(result.user)
                                    setWindowShow('foundInSearch')
                                }
                                if(!result.success) {
                                    setWindowShow('notFoundInSearch')
                                    setFoundUserInSearch(null)
                                    setTimeout(() => {
                                        setWindowShow('membersEmpty')
                                    }, 2000);
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        }}/>
                    </div>

                </div>
                <div className={'content-group-details-container'}>
                    <div className={'left-container'}>
                        <UserProfilePicture groupImage width={'100%'} maxWidth={652} minHeight={450} height={'100%'} groupImageURL={selectedGroup.picture} />
                    </div>
                    <div className={'right-container'}>
                        {renderContent()}
                    </div>

                </div>
                {/*<QuickListMembers selectedGroup={selectedGroup} membersList={selectedGroup?.members}/>*/}
            </div>
        </WindowLayout>
    )
}