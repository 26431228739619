import { useState } from "react"
import "./input.scss"
import { useRef } from "react"
import { useEffect } from "react"
import Icon from "components/icon/icon"
const Input = ({
  name,
  value,
  label,
  addFile,
  onChange,
  type="text",
  required,
  pattern,
  minLength="3",
  maxLength="30",
  size="50",
  style={},
  autoComplete,
  title,
  foundError,
  disabled = false,
  placeholder='placeholder',
  capitalize = false,
  validations=[],
  errorAfterSubmit = false,
  readOnly,
  defaultValue,
  dark,
  imageUrl
}) => {
  const [isFocused, setIsFocused] = useState(value && value.length !== 0 || defaultValue && defaultValue.length !== 0)
  const [errors, setErrors] = useState([])
  const inputRef = useRef()
  const [imagePreviewUrl, setImagePreviewUrl] = useState(imageUrl);
  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    }

    reader.readAsDataURL(file);
  }

  const checkValidations = () => {
    const result = []
    if(errorAfterSubmit === 'Incorrect email or password.') {
      result.push(
          <div className="inputError" key={`Incorrect email or password.`}>
            <img className='error-icon-inputs' src='/images/danger-triangle.svg' alt="error-icon"/>
            Incorrect email or password.
          </div>
      )
    }
    validations.forEach((validation, idx) => {
      if(!validation.check(value)) {
        result.push(
          <div className="inputError" key={`${name}${type}${idx}`}>
            <img className='error-icon-inputs' src='/images/danger-triangle.svg' alt="error-icon"/>
              {validation.message}
          </div>
        )
      }
    })
    setErrors(result.slice(0, 2))
  }
  useEffect(()=>{
    if((value && value.length > 0) || foundError) {
      checkValidations()
    } else {
      if(errors.length > 0) {
        setErrors([])
      }
    }
  },[foundError,value])
  return (
    <div className={`inputWrapper ${errors.length > 0 ? "errorsPresent" : ""} ${dark ? 'dark' : ''}`} style={style}>
      <div className="inputContainer">
        {label && (
            <label
                onClick={()=>{
                  if(inputRef && inputRef.current) {
                    inputRef.current.focus()
                  }
                }
                }
                htmlFor={name}
                className={isFocused ? "selected" : ""}>
              {label}
            </label>
        )}
        {addFile ? (
            <div className={'add-file-input-container'}>
              <p className={'add-file-text'}>{imagePreviewUrl? 'Click To Change The Image' : placeholder}</p>
              <input type="file"
                     id="avatar"
                     name="avatar"
                     accept="image/png, image/jpeg"
                     onChange={(e)=> {
                       handleImageChange(e)
                       onChange(e)
                     }} />
              {imagePreviewUrl && (
                  <img src={imagePreviewUrl} alt="Image preview" />
              ) }
            </div>
        ) : (
            <input
                className={`${capitalize ? "capitalize" : ""}`}
                minLength={minLength}
                maxLength={maxLength}
                ref={inputRef}
                placeholder={placeholder}
                value={value}
                id={name}
                disabled={disabled}
                title={title}
                onFocus={()=>setIsFocused(true)}
                type={type}
                size={size}
                pattern={pattern}
                required={required}
                autoComplete={autoComplete}
                onBlur={()=>{
                  if(!value || value && value.length === 0) {
                    setIsFocused(false)
                  }
                }}
                readOnly={readOnly}
                defaultValue={defaultValue}
                onChange={onChange}/>
        )}

      </div>
      {errors}
    </div>
  )
}
export default Input