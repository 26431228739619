import { create } from 'zustand'
export const logActivity = create((set, get) => ({
    items: [],
    lastMembersInvited: [],
    addItem: (item) => {
        set((state) => ({
            items: [...state.items, item]
        }));
    },
    setLastMembersInvited: (item) => {
        const { lastMembersInvited } = get();
        // Check if the item already exists to avoid duplicates
        if (!lastMembersInvited.some(member => member.userId === item.userId)) {
            const updatedList = [...lastMembersInvited, item];
            set({ lastMembersInvited: updatedList });
            window.localStorage.setItem("lastMembersInvited", JSON.stringify(updatedList));
        }
    },

    getLastMembersInvited: () => {
        const storedData = window.localStorage.getItem('lastMembersInvited');
        console.log("Retrieved from localStorage:", storedData);
        let lastMembersInvited;
        try {
            lastMembersInvited = JSON.parse(storedData) || [];
        } catch (e) {
            console.error("Parsing error in getLastMembersInvited:", e);
            lastMembersInvited = []; // default to an empty array if there's a parsing error
        }
        set({ lastMembersInvited });
    }
}))
