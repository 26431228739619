import "./Chatroom.scss"
import "./ChatRoomGrid.scss"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import { VideoStream, useConversationMessages } from "@apirtc/react-lib";
import WindowLayout from "components/windowLayout/window-layout"
import { MediaScreen } from "./MediaScreen";
import { useChatroom } from "stores/chatroom";
import Chat from "./Chat";
import React, { useState } from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import { useEffect } from "react";
import { useRef } from "react";
import LeaveRoom from "../../../../windowLayout/title-bar/room-controls/leave-room";
import CopyLink from "../../../../windowLayout/title-bar/room-controls/copy-link";
import { getInitials } from "utils";
import {SeeParticipants} from "./SeeParticipants";
import DevicesModal from "./DevicesModal";
import {LeftArrow} from "../../../../icon/LeftArrow";
import {RightArrow} from "../../../../icon/RightArrow";
import {Canvas} from "../../../../canva/Canva";
import {ToolBar} from "../toolbar/toolBar";
import {CloseIcon} from "../SVGComponents/closeIcon";
import {LinkSendEmail} from "../../../../windowLayout/title-bar/room-controls/link-send-email";
import {logActivity} from "../../../../../stores/log-activity";
import { useAuthentication } from "stores/authentication";
import {UserProfilePicture} from "../../../../user-profile-picture";
window.setApirtcReactLibLogLevel = null
// My API key
const Encrypted = ({
  session,
  subscribedStreams,
  localStream,
  processedStream,
  setOptions,
  contacts,
  clearRoom,
  backgroundImage,
  setBackgroundImage,
  noiseCancelling,
  setNoiseCancelling,
  videoEffect,
  setVideoEffect,
  conversation,
  current,
  displayName,
  kick,
  changeCamera,
  changeMicrophone,
  audioDevices,
  videoDevices,
  userMediaDevices,
  muted,
  toggleMute,
  sharing,
  toggleScreenSharing,
  videoMuted,
  toggleVideoMute,
  toggleDeafened,
  shareStream,
}) => {
  const [fullscreenMonitored, setFullscreenMonitored] = useState(false)
  const [showGuests, setShowGuests] = useState(true)
  const [micFullActive, setMicFullActive] = useState(false)
  const [settingsFullActive, setSettingsFullActive] = useState(false)
  const [fullScreenFullActive, setFullScreenFullActive] = useState(false)
  const [showGuestsOnGrid,setShowGuestsOnGrid] = useState(false)
  const [videoFullActive, setVideoFullActive] = useState(false)
  const [addPeopleFullActive, setAddPeopleFullActive] = useState(false)
  const [addPeopleRightFullActive, setAddPeopleRightFullActive] = useState(false)
  const [showMessagesFullActive, setShowMessagesFullActive] = useState(false)
  const [backgroundBlurr, setBackgroundBlurr] =useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const {displayChatroom, setDisplayChatroom} = useChatroom()
  const [selectedStream, selectStream] = useState(0)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [showMoreOptionsGuest, setShowMoreOptionsGuest] =useState(false)
  const [ disableMuted, setDisableMuted ] = useState(false)
  const [collapseButtons, setCollapseButtons] = useState(true)
  const [deafened, setDeafened] = useState(false)
  const { messages, sendMessage } = useConversationMessages(conversation);
  const [initializeAS, setInitializeAS] = useState(false)
  const [lastSpeaker, setLastSpeaker] = useState(-1)
  const [manualSelect, setManualSelect] = useState(false)
  const [forcedUpdateVariable, setForcedUpdateVariable] = useState(false)
  const [mutedUsers, setMutedUsers] = useState([])
  const [activeOption,setActiveOption] = useState(false)
  const [showWhiteBoard,setShowWhiteBoard] = useState(false)
  const chatroomRef = useRef()
  const messagesRef = useRef(null)
  const containerRef = useRef(null);
  const {isMobile,isTablet,isSmallLaptopScreen,isDesktopOrLaptop} = MediaScreen()
  const distance = isMobile || isTablet ? 12 : 24
  const [inviteEmail, setInviteEmail] = useState("")
  const [alreadySent, setAlreadySent] = useState([])
  const validEmail = inviteEmail && inviteEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
  const { addItem } = logActivity()
  const { user } = useAuthentication()
  const {setLastMembersInvited,lastMembersInvited,getLastMembersInvited} = logActivity()




  //Carousel
  const [currentSlide, setCurrentSlide] = useState({
    lengthPixels: 0,
    count: 0
  });
  useEffect(()=>{
    checkSliderLoad()
  },[contacts,displayChatroom])

  const sendMailInvitation = (valueEmail) => {
    if((validEmail && inviteEmail && inviteEmail.length > 4) || valueEmail ) {
      const sendEmailTo = valueEmail ?? inviteEmail
      addItem({
        label:'Email',
        value: sendEmailTo
      })
      if(!alreadySent.includes(sendEmailTo)) {
        window.$comms.request("mailInvite", { name: current.name, email: sendEmailTo, link: window.location.href })
        setAlreadySent([...alreadySent, sendEmailTo])
        setTimeout(()=>{
          setInviteEmail("")
        }, 3000)
      } else {
        setTimeout(()=>{
          setInviteEmail("")
        }, 3000)
      }
    }
  }

  const checkSliderLoad = () => {
    const containerSlider = document.querySelector('.SmallVideosContainer ');
    const lengthOfContainer = containerSlider ? containerSlider.getBoundingClientRect().width : 0;
    const lenghtOfChild =containerSlider ? containerSlider.querySelector('.Participant').getBoundingClientRect().width : 0
    const NumberOfChilds =containerSlider ? containerSlider.children.length: 0;
    let calcWidhOfChild = 0
    if(isMobile) {
      calcWidhOfChild = (lengthOfContainer / 2) - distance;
    }
    else if(displayChatroom && isTablet ){
      calcWidhOfChild = (lengthOfContainer / 3) - distance;
    }
    else if(!displayChatroom && isTablet ){
      calcWidhOfChild = (lengthOfContainer / 4) - distance;
    }
    else if(displayChatroom && (isSmallLaptopScreen || isDesktopOrLaptop))
       calcWidhOfChild = (lengthOfContainer / 4) - distance;
    else
      calcWidhOfChild = (lengthOfContainer / 5) - distance;

    containerSlider.querySelectorAll('.Participant').forEach((e)=>{
      e.style.width = calcWidhOfChild + 'px'
      e.style.minWidth = calcWidhOfChild + 'px'
    })
    const inviteButton = containerSlider ? containerSlider.querySelector('.guests-invite-container') : null
    inviteButton.style.width = calcWidhOfChild + "px"
    inviteButton.style.maxWidth = calcWidhOfChild + "px"
    return {
      containerSlider:containerSlider,
      lengthOfContainer:lengthOfContainer,
      lenghtOfChild:lenghtOfChild,
      NumberOfChilds:NumberOfChilds
    }
  }

  const isLastItemShown = () => {
    const { lengthOfContainer, lenghtOfChild, NumberOfChilds } = checkSliderLoad();

    const totalWidthOfItems = NumberOfChilds * lenghtOfChild + (NumberOfChilds - 1) * distance;

    const remainingWidthToShow = totalWidthOfItems + currentSlide.lengthPixels;
    return remainingWidthToShow <= lengthOfContainer;
  };

  const nextSlide = () => {

    const {containerSlider, lengthOfContainer, lenghtOfChild,NumberOfChilds} = checkSliderLoad()
    if(NumberOfChilds * lenghtOfChild + (NumberOfChilds - 1) * distance < lengthOfContainer || isLastItemShown())
      return;
    else {
      containerSlider.style.transform = 'translateX(' + (currentSlide.lengthPixels - lenghtOfChild - distance) + 'px)';
      setCurrentSlide({
        lengthPixels:currentSlide.lengthPixels - lenghtOfChild - distance,
        count: currentSlide.count + 1
      })
    };
  };

  const prevSlide = () => {
    if(currentSlide.count === 0)
      return;
    else {
      const {containerSlider, lengthOfContainer, lenghtOfChild} = checkSliderLoad()
      containerSlider.style.transform = 'translateX(' + (currentSlide.lengthPixels + lenghtOfChild + distance) + 'px)';
      setCurrentSlide({
        lengthPixels:currentSlide.lengthPixels + lenghtOfChild + distance,
        count: currentSlide.count - 1
      })
    }
  };
  //stop Carousel


  const forceUpdate = () => {
    setForcedUpdateVariable(!forcedUpdateVariable)
  }
  useEffect(()=>{
    messagesRef.current = messages
  },[messages, messagesRef])
  const handleMessage = (msg) => {
    const parsed = JSON.parse(msg.content)
    if(parsed.target !== undefined) {
      const found = messagesRef.current.findIndex(m=>{
        return parsed.target === JSON.parse(m.content).uuid
      })
      if(found !== -1) {
        const parsedTargetMessage = JSON.parse(messagesRef.current[found].content)
        if(parsed.emoji !== undefined) {
          if(parsedTargetMessage.reactions === undefined) {
            parsedTargetMessage.reactions = {}
          }
          parsedTargetMessage.reactions[msg.sender.userData.username] = parsed.emoji
          messagesRef.current[found].content = JSON.stringify(parsedTargetMessage)
        }
        if(parsed.command === 'delete') {
          if(msg.sender_id !== undefined && messagesRef.current[found].sender_id !== undefined && msg.sender_id === messagesRef.current[found].sender_id) {
            parsedTargetMessage.show = false
            messagesRef.current[found].content = JSON.stringify(parsedTargetMessage)
          }
        }
      }
    }
  }
  if(session) {
    session.setUserData({
      nickname: displayName,
      isLoggedIn: user?._id,
      loggedInId: user ? user._id : '',
      loggedInFirstName: user ? user.firstName : '',
      loggedInLastName: user ? user.lastName : '',
      loggedInEmail: user ? user.email : '',
    })
    if(!initializeAS && conversation) {
      conversation.on("message", handleMessage)
      const ua = session.getUserAgent()
      window.localStorage.setItem("tempId", ua.username)
      ua.enableCallStatsMonitoring(true, { interval: 500 });
      ua.enableActiveSpeakerDetecting(true, { threshold: 0.4 });
      conversation.on("audioAmplitude", (amplitudeInfo) => {
        if(amplitudeInfo.callId !== null) {
          if(amplitudeInfo.isSpeaking && amplitudeInfo.amplitude > 90) {
            setLastSpeaker(amplitudeInfo.callId)
          }
        }
      })
      setInitializeAS(true)
    }
  }
  if(!manualSelect && contacts && contacts.length > 0 && lastSpeaker !== undefined && lastSpeaker !== -1) {
    if(subscribedStreams) {
      const activeStream = subscribedStreams.find(s=>{
        return s.callId === lastSpeaker
      })
      if(activeStream) {
        const contactIndex = contacts.findIndex(c=> { return c.getId() === activeStream.contact.getId()})
        if(contactIndex !== -1) {
          setLastSpeaker(-1)
          selectStream(contactIndex+1)
        }
      }
    }
  }
  const isFullscreenRef = useRef()
  isFullscreenRef.current = isFullscreen
  if(!fullscreenMonitored) {
    setFullscreenMonitored(true)
    document.addEventListener("fullscreenchange", (e) => {
      if(isFullscreenRef && isFullscreenRef.current !== undefined) {
        setIsFullscreen(!isFullscreenRef.current)
      }
    })
  }
  const toggleFullscreen = () => {
    if(chatroomRef && chatroomRef.current) {
      if(!isFullscreen) {
        chatroomRef.current.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    }
  }

  useEffect(()=>{
    const interval = setTimeout(()=>{
      window.location = "/maxtime"
    }, 1000*10*60*30)
    return () => {
      clearTimeout(interval)
    }
  }, [])
  const formattedStreams = [{}]
  contacts.forEach(contact => {
    if(contact.userData && contact.userData.nickname) {
      const hasStream = subscribedStreams.find(subscribedStream => {
        if(subscribedStream.contact && subscribedStream.contact.userData && subscribedStream.contact.userData.nickname) {
          return subscribedStream.contact.userData.nickname === contact.userData.nickname
        }
      })
      if(hasStream) {
        formattedStreams.push(hasStream)
      } else {
        formattedStreams.push({
          hasAudio: ()=>false,
          hasVideo: ()=>false,
          contact: contact
        })
      }
    }
  })
  const kickParticipant = (userId) => {
    kick({roomId: current.id, userToRemoveId: userId})
  }
  const chatStyle = {
    ... (showWhiteBoard ? { borderLeft: '1px solid #DBDBDB' } : {}),
    ... (displayChatroom ? {} : { display: 'none' })
  };

  //Testing for hover menu

  const [lastY, setLastY] = useState(null); // Store the last Y position
  const [isMovingUp, setIsMovingUp] = useState(false); // Store if the mouse is moving up

  useEffect(() => {
    const handleMouseMove = (event) => {
      const currentY = event.clientY;
      if (lastY !== null) { // Ensure we have a lastY to compare with
        // Check if the current Y position is less than the last Y position
        if (currentY < lastY) {
          setIsMovingUp(true);
        } else {
          setIsMovingUp(false);
        }
      }
      setLastY(currentY); // Update the lastY position
    };

    // Add the event listener
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [lastY]);

  useEffect(()=>{
    getLastMembersInvited()
    contacts.forEach((item)=>{
      console.log(item.userData.isLoggedIn,'item.userData.isLoggedIn')
      if (item.userData.isLoggedIn) {
        setLastMembersInvited({
          userId: item.userData.loggedInId,
          email: item.userData.loggedInEmail,
          firstName: item.userData.loggedInFirstName,
          lastName: item.userData.loggedInLastName
        })
      }
    })
  },[contacts])


  return (
    <WindowLayout className={'rotate-container'}>
      <div className="chatroom" id="mememe-container" ref={chatroomRef}>
        <div className={`containerChatRoom ${showGuestsOnGrid ? 'gridGuests' : ''} ${showGuests ? 'showGuest' : ''} ${displayChatroom ? "chatActive":""}`}>
          <div className={`Video ${showWhiteBoard ? 'canvas-active' : ""}`}>
              <Canvas isOpen={showWhiteBoard} onClose={(e)=>{
                setShowWhiteBoard(e)
              }}/>
              <div className={`SelectedParticipant ${sharing ? 'shareScreenActive' : ''}`}>
                { // Us
                  selectedStream === 0 && (processedStream && !sharing && processedStream.hasVideo() && processedStream.isVideoEnabled()) ? (
                    <>
                      <VideoStream stream={processedStream} muted={true}></VideoStream>

                      <span className="Name" style={{textTransform: "capitalize", bottom: 'unset', top: '15px'}}>{displayName}</span>

                    </>
                  ) : selectedStream === 0 && (sharing && shareStream && shareStream.hasVideo()) ? (
                    <>
                      <VideoStream stream={shareStream} muted={true}></VideoStream>

                      <span className="Name" style={{textTransform: "capitalize", bottom: 'unset', top: '15px'}}>{displayName}</span>

                    </>
                  ) : selectedStream === 0 ? (
                    <>
                      <div className="user">
                         <div className='user-icon-name'>
                                  <span>{getInitials(displayName)}</span>
                              </div>
                      </div>

                      <span className="Name" style={{textTransform: "capitalize", bottom: 'unset', top: '15px'}}>{displayName}</span>

                    </>
                  ) : /* Them*/ formattedStreams[selectedStream] && formattedStreams[selectedStream].hasVideo() && formattedStreams[selectedStream].isVideoEnabled() ? (
                    <>
                      <VideoStream stream={formattedStreams[selectedStream]} muted={true}></VideoStream>

                      <span className="Name" style={{textTransform: "capitalize", bottom: 'unset', top: '15px'}}>{formattedStreams[selectedStream].contact.userData.nickname}</span>

                    </>
                  ) : (
                    formattedStreams[selectedStream] ? (
                      <div>
                        <div className="user">
                           <div className='user-icon-name'>
                                  <span>{getInitials(formattedStreams[selectedStream].contact.userData.nickname)}</span>
                              </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="user">
                           <div className='user-icon-name'>
                                  <span>User Left</span>
                              </div>
                        </div>
                      </div>
                    )
                  )
                }
              </div>

            <ToolBar
                desktop
                current={current}
                actionMicFullActive={micFullActive}
                setActionMicFullActive={setMicFullActive}
                actionVideoFullActive={videoFullActive}
                actionAddPeopleFullActive={addPeopleFullActive}
                actionSettingsFullActive={settingsFullActive}
                localStream={localStream}
                backgroundImage={backgroundImage}
                setBackgroundImage={setBackgroundImage}
                noiseCancelling={noiseCancelling}
                setNoiseCancelling={setNoiseCancelling}
                setVideoEffect={setVideoEffect}
                changeCamera={changeCamera}
                videoEffect={videoEffect}
                changeMicrophone={changeMicrophone}
                audioDevices={audioDevices}
                videoDevices={videoDevices}
                userMediaDevices={userMediaDevices}
                displayName={displayName}
                whiteBoard={showWhiteBoard}
                muted={muted}
                toggleMute={toggleMute}
                sharing={sharing}
                toggleScreenSharing={toggleScreenSharing}
                videoMuted={videoMuted}
                toggleVideoMute={toggleVideoMute}
                processedStream={processedStream}
                isMovingUp={isMovingUp}
                chatroomRef={chatroomRef}
                isFullscreen={isFullscreen}
                toggleWhiteBoard={setShowWhiteBoard}
                toggleSettings={setSettingsFullActive}
                toggleAddPeopleFullActive={setAddPeopleFullActive}
                toggleVideoActive={setVideoFullActive}
                toggleMicActive={setMicFullActive}
                containerRef={containerRef}
                displayChatroom={displayChatroom}
                setDisplayChatroom={setDisplayChatroom}
                setAddPeopleRightFullActive={setAddPeopleRightFullActive}
                addPeopleRightFullActive={addPeopleRightFullActive}
                setShowMessagesFullActive={setShowMessagesFullActive}
                showMessagesFullActive={showMessagesFullActive}
                setShowGuests={setShowGuests}
                setVideoFullActive={setVideoFullActive}
                videoFullActive={videoFullActive}
                setAddPeopleFullActive={setAddPeopleFullActive}
                addPeopleFullActive={addPeopleFullActive}
                setMicFullActive={setMicFullActive}
                micFullActive={micFullActive}
                setSettingsFullActive={setSettingsFullActive}
                settingsFullActive={settingsFullActive}
                showWhiteBoard={showWhiteBoard}
                setShowWhiteBoard={setShowWhiteBoard}

            />
              <div className='arrow' style={{zIndex:2}} onClick={()=>setShowGuests(!showGuests)}>
                <div className={`arrow-image-container ${showGuests ? 'rotate' : ''}`}>
                  <img src='/images/fa-angle-down.svg' alt='arrow-down-image'/>
                </div>
              </div>
              <div className='leave-room-container'>
                <LeaveRoom/>
              </div>

            </div>
            <div className="Chat" style={chatStyle}>
              <button className="close-chat-icon " onClick={()=>{
                setDisplayChatroom(false)
                setShowMessagesFullActive(false)
              }}>
                <CloseIcon fill={'#000'}/>
              </button>
              {addPeopleRightFullActive && (
                 <SeeParticipants  session={session} contacts={contacts} current={current} deafened={deafened} toggleDeafened={toggleDeafened} showInviteScreen={addPeopleFullActive} setShowInviteScreen={setAddPeopleFullActive} kick={kick}/>
              )}
              { !addPeopleRightFullActive && session && session.getUserData() && <Chat forceUpdate={forceUpdate} sendMessage={sendMessage} messages={messages} userData={session.getUserData()}/>}
            </div>
            <div className={`Guests ${showGuests ? "showGuestActive" : ''}`}>
              <div className='Guests-container'>
                {((isDesktopOrLaptop || isSmallLaptopScreen) && contacts.length + 1 >= 5 ) || (isTablet && contacts.length + 1 >= 4) || (isMobile && contacts.length + 1 >= 2 ) ? (
                    <>
                      <button className="left-arrow" onClick={prevSlide}>
                        <LeftArrow />
                      </button>
                      <button className="right-arrow" onClick={nextSlide}>
                        <RightArrow />
                      </button>
                    </>
                ) : null}

                <div className='carousel-container'>
                  <div className={`SmallVideosContainer ${isMobile && subscribedStreams.length > 0 ? 'withCarousel' : isTablet && subscribedStreams.length > 1 ? 'withCarousel': !isTablet && subscribedStreams.length > 2 ? 'withCarousel' : '' }`}>
                    <>
                      <div className="Participant currentUserScreen" onClick={()=>selectStream(0)}>
                        <div className="VideoContainer" style={{position: "relative"}}>
                          { sharing && shareStream && <VideoStream stream={shareStream} muted={true}></VideoStream> }
                          { processedStream && !sharing && processedStream.hasVideo() && processedStream.isVideoEnabled() && <VideoStream stream={processedStream} muted={true}></VideoStream> }
                          { !sharing && !shareStream && (!processedStream || (processedStream && (!processedStream.hasVideo() || !processedStream.isVideoEnabled()))) && (
                              <div className="user">
                                <div className='user-icon-name'>
                                  <span>{getInitials(displayName)}</span>
                                </div>

                              </div>

                          ) }
                        </div>
                      </div>
                      {
                        contacts.map((item,index)=>{
                          const contactStream = subscribedStreams.find(cStream=>{
                            if(cStream.contact && cStream.contact.userData && cStream.contact.userData.nickname) {
                              return cStream.contact.userData.nickname === item.userData.nickname
                            }
                            return false
                          })
                          return(
                              <div className={`Participant  ${index === current ? 'slide active' : 'slide'}`} key={index} onClick={()=>selectStream(index+1)}>
                                <SwiperSlide className="individual-card-container">
                                  <div className="VideoContainer" style={{position: "relative"}}>
                                    {
                                      contactStream && contactStream.hasVideo() && contactStream.isVideoEnabled() ? (
                                          <>
                                            <VideoStream stream={contactStream} muted={deafened || mutedUsers.includes(item.userData.username)} key={index}></VideoStream>
                                          </>
                                      ) : contactStream && contactStream.hasAudio() ? (

                                          <div className="user">
                                            <div style={{visibility: "hidden", maxHeight: "0px", maxWidth: "0px"}}>
                                              <VideoStream stream={contactStream} muted={deafened || mutedUsers.includes(item.userData.username)} key={index}></VideoStream>
                                            </div>
                                            <div className='user-icon-name'>
                                              <span>{getInitials(item.userData.nickname)}</span>
                                            </div>
                                          </div>
                                      ):(
                                          <div className="user">
                                            <div className='user-icon-name'>
                                              <span>{getInitials(item.userData.nickname)}</span>
                                            </div>
                                          </div>
                                      )
                                    }

                                  </div>
                                  <div className='options-guests'>
                                    {showMoreOptionsGuest ? (
                                        <div className='options-guests-more'>
                                          <div className='container-more'>
                                            {
                                              current && current.isOwner ? (
                                                  <div onClick={()=>{kickParticipant(item.userData.username)}} className='item-more'>
                                                    <span>Kick</span>
                                                  </div>
                                              ) : null
                                            }
                                            <div onClick={()=>{
                                              if(mutedUsers.includes(item.userData.username)) {
                                                setMutedUsers(mutedUsers.filter(id=>id !== item.userData.username))
                                              } else {
                                                setMutedUsers([...mutedUsers, item.userData.username])
                                              }
                                            }}className='item-more'>
                                              <span>{mutedUsers.includes(item.userData.username) ? "UnMute" : "Mute"}</span>
                                            </div>
                                          </div>
                                        </div>
                                    ) : null}
                                    <div className='more-options-item white-background ' onClick={()=>{setShowMoreOptionsGuest(!showMoreOptionsGuest)}}>
                                      <img src='/images/black-menu-dots-vertical.svg' alt='menu-dots'/>
                                    </div>
                                    <div className={`more-options-item ${(contactStream && contactStream.hasAudio() && contactStream.isAudioEnabled()) ? 'active-background' : 'red-background'}`}>
                                      <img src='/images/white-microphone.svg' alt='microphone'/>
                                    </div>
                                    <div className={`more-options-item  ${contactStream && contactStream.hasVideo() && contactStream.isVideoEnabled() ? 'active-background' : 'red-background'}`}>
                                      <img src='/images/white-video-camera.svg' alt='video'/>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              </div>
                          )
                        })
                      }
                        <div className='guests-invite-container ' onClick={()=>{
                          setAddPeopleFullActive(true)
                          setShowGuests(false)
                        }} >
                          <div className='container-content Participant' style={{width: '100%'}}>
                            <img src='/images/plus.svg' alt='plus-icon' />
                            <span>Invite</span>
                          </div>
                        </div>

                    </>
                  </div>
                </div>
                <div className={'room-options'}>
                   {/*<div className={`options-item ${showGuestsOnGrid ? 'grid-active' : ''}`} onClick={()=>{
                     setShowGuestsOnGrid(!showGuestsOnGrid)
                   }}>
                      Todo: Gabriel, iti apartine feature-ul asta ca e design mostly
                     {showGuestsOnGrid ? (
                         <img src='/images/white-apps.svg' alt='show'/>
                     ) : (
                         <img src='/images/grey-apps%20(1).svg' alt='show'/>
                     )}
                  </div>*/}
                  <div className='options-item' onClick={()=>setDeafened(!deafened)}>
                    <img src={`/images/${deafened ? "red-microphone-slash%201.svg" : 'gray-microphone.svg'}`} alt='microphone'/> 
                  </div>
                </div>
              </div>
              <div className='call-options' style={showGuestsOnGrid ? {display:'block'}: {display:"none"}}>
                <div className='options-container'>
                  <div className='option'>
                    <div className={`item-container ${micFullActive ? 'opened-menu-attach' : ''}`}>
                      <div className='item-image'
                           onClick={()=>{
                               toggleMute()
                           }}>
                        {muted ? (
                            <img src='/images/red-microphone-slash%201.svg' alt={'microphone'}/>
                        ) : micFullActive ? (
                            <img src='/images/white-microphone.svg' alt={'microphone'}/>
                        ) : (
                            <img src='/images/microphone.svg' alt={'microphone'}/>
                        )}
                      </div>
                      <div className='arrow-image' onClick={()=> {
                        setMicFullActive(!micFullActive)
                        setVideoFullActive(false)
                        setAddPeopleFullActive(false)
                        setSettingsFullActive(false)
                      }} >
                        {micFullActive ? (
                            <img src='/images/white-angle-down.svg' alt={'arrow-down'}/>
                        ) : (
                            <img src='/images/black-angle-down.svg' alt={'arrow-down'}/>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='option'>
                    <div className={`item-container ${videoFullActive ? 'opened-menu-attach' : ''}`}>
                      <div className='item-image' onClick={()=> {
                        toggleVideoMute()
                      }}>
                        {videoMuted ? (
                            <img src='/images/red-video-camera.svg' width={24} height={24} style={{width:'24px', height: '24px'}} className='closed-camera' alt={'video'}/>
                        ) : videoFullActive ? (
                            <img src='/images/white-video-camera.svg' alt={'video'}/>
                        ) : (
                            <img src='/images/video-camera.svg' alt={'video'}/>
                        )}
                      </div>
                      <div className='arrow-image' onClick={()=> {
                        setVideoFullActive(!videoFullActive)
                        setMicFullActive(false)
                        setAddPeopleFullActive(false)
                        setSettingsFullActive(false)
                      }}>
                        {videoFullActive ? (
                            <img src='/images/white-angle-down.svg' alt={'arrow-down'}/>
                        ) : (
                            <img src='/images/black-angle-down.svg' alt={'arrow-down'}/>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='option'>
                    <div className={`item-container ${addPeopleRightFullActive ? 'opened-menu-attach' : addPeopleFullActive ? 'opened-menu-attach center-popup' : ''}`}>
                      <div className='item-image' onClick={()=> {
                        setAddPeopleRightFullActive(!addPeopleRightFullActive)
                        setDisplayChatroom(!displayChatroom)
                      }}>
                        {addPeopleRightFullActive ? (
                            <img src='/images/white-user-add.svg' alt={'add'}/>
                        ) : addPeopleFullActive ? (
                            <img src='/images/purple-user-add.svg' alt={'add'}/>
                        ) : (
                            <img src='/images/user-add.svg' alt={'add'}/>
                        )}
                      </div>
                      <div className='arrow-image' onClick={()=>{
                        setAddPeopleFullActive(!addPeopleFullActive)
                        setVideoFullActive(false)
                        setMicFullActive(false)
                        setSettingsFullActive(false)
                        setShowGuests(false)
                      }}>
                        {addPeopleRightFullActive ? (
                            <img src='/images/white-angle-down.svg' alt={'arrow-down'}/>
                        ) : addPeopleFullActive ? (
                            <img src='/images/purpleangle-up.svg' alt={'add'}/>
                        ) : (
                            <img src='/images/black-angle-down.svg' alt={'arrow-down'}/>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='option'>
                    <div className={`item-container with-no-arrow ${sharing ? 'opened-menu-attach' : ''}`} onClick={toggleScreenSharing}>
                      <div className='item-image'>
                        {sharing ? (
                            <>
                              <img src='/images/white-share.svg' alt={'sharescreen'}/>{sharing}
                            </>
                        ) : (
                            <>
                              <img src='/images/share.svg' alt={'sharescreen'}/>{sharing}
                            </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='option'>
                    <div className={`item-container with-no-arrow ${showMessagesFullActive ? 'opened-menu-attach' : ''}`} onClick={()=> {
                      setShowMessagesFullActive(!showMessagesFullActive)
                      setDisplayChatroom(!displayChatroom)}}>
                      <div className='item-image'>
                        {showMessagesFullActive ? (
                            <img src='/images/white-comment-alt.svg' alt={'chat-icon'}/>
                        ) : (
                            <img src='/images/comment-alt.svg' alt={'chat-icon'}/>

                        )}
                      </div>
                    </div>
                  </div>
                  <div className='option'>
                    <div className={`item-container with-no-arrow ${settingsFullActive ? 'opened-menu-attach' : ''}`}
                         onClick={()=>{
                           console.log('aici')
                           setSettingsFullActive(!settingsFullActive)
                           setAddPeopleFullActive(false)
                           setVideoFullActive(false)
                           setMicFullActive(false)
                           setShowGuests(false)}}>
                      <div className='item-image'>
                        {settingsFullActive ? (
                            <img src='/images/white-settings.svg' alt={'settings'}/>
                        ) : (
                            <img src='/images/settings.svg' alt={'settings'}/>

                        )}
                      </div>
                    </div>
                  </div>
                  <div className='option'>
                    <div onClick={toggleFullscreen} className={`item-container with-no-arrow ${isFullscreen ? 'opened-menu-attach' : ''}`}>
                      <div className='item-image'>
                        {isFullscreen ? (
                            <img src='/images/white-expand.svg' alt={'expand'}/>
                        ): (
                            <img src='/images/expand.svg' alt={'expand'}/>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className='leave-room-container' style={showGuestsOnGrid ? {display:'block'}: {display:"none"}}>
                <LeaveRoom/>
              </div>
              {micFullActive || videoFullActive || settingsFullActive || addPeopleFullActive ? (
                  <div style={showGuestsOnGrid ? {display:'block'}: {display:"none"}}
                      className={`popup-ahead-option-menu ${micFullActive ? 'mic-options small-popup' : videoFullActive ? 'video-options small-popup' : settingsFullActive ? 'large-popup set-options' : addPeopleFullActive ? 'center-screen' : ''}`}>
                    <div className='popup-position'>
                      <div className={`container-content-popup ${addPeopleFullActive ? 'middle-screen' : ''}`}>
                        {micFullActive ? (
                            <>
                              <div className='section-header-popup'>
                                <img src='/images/white-microphone.svg' alt='microphone'/>
                                <span> Select Microphone</span>
                              </div>
                              <div className='options-container-popup'>
                                {
                                  [{label: "No Microphone", value: ""}, ...audioDevices].map(
                                      (microphone, micIdx) => {
                                        return (
                                            <div key={`micIdx${micIdx}`} onClick={()=>{
                                              changeMicrophone(microphone)
                                              setMicFullActive(false)
                                            }} className='item-pop-select'>
                                          <span>
                                            {microphone.label}
                                          </span>
                                            </div>
                                        )
                                      }
                                  )
                                }
                              </div>
                            </>
                        ) : videoFullActive ? (
                            <>
                              <div className='section-header-popup'>
                                <img src='/images/white-video-camera.svg' alt='camera'/>
                                <span> Video Device</span>
                              </div>
                              <div className='options-container-popup'>
                                {
                                  [{label: "No Camera", value: ""}, ...videoDevices].map(
                                      (camera, camIdx) => {
                                        return (
                                            <div key={`cam${camIdx}`} onClick={(e)=>{
                                              changeCamera(camera)
                                              setVideoFullActive(false)
                                            }} className='item-pop-select'>
                                          <span>
                                            {camera.label}
                                          </span>
                                            </div>
                                        )
                                      }
                                  )
                                }
                              </div>
                            </>
                        ) : settingsFullActive ? (
                            <div className='container-popup-settings'>
                              <div className='options-menu'>
                                <span className='title'>Settings</span>
                                <div className='item-menu'>
                                  <img src='/images/white-microphone.svg' alt={'microphone'}/>
                                  <span>Audio Device</span>
                                </div>
                                <div className='item-menu'>
                                  <img src='/images/white-video-camera.svg' alt={'video'}/>
                                  <span>Video Device</span>
                                </div>
                                <div className='item-menu'>
                                  <img src='/images/white-picture.svg' alt={'picture'}/>
                                  <span>Background </span>
                                </div>
                                <div className='item-menu'>
                                  <img src='/images/white-bell.svg' alt={'bell'}/>
                                  <span>Notification</span>
                                </div>
                                <div className='item-menu'>
                                  <img src='/images/white-profile-user.svg' alt={'profile'}/>
                                  <span>Profile</span>
                                </div>
                                <div className='item-menu'>
                                  <img src='/images/white-microphone.svg' alt={'microphone'}/>
                                  <span>Audio Device</span>
                                </div>
                                <div className='item-menu'>
                                  <img src='/images/white-settings-sliders.svg' alt={'settings-slider'}/>
                                  <span>General</span>
                                </div>
                              </div>
                              <div className='option-item-menu'>
                                <span className='item-title'>Choose Background</span>
                                <div className='background-images-container'>
                                  <div className='image-container'>
                                    <img src='/images/image1.png' alt='image1'/>
                                  </div>
                                  <div className='image-container'>
                                    <img src='/images/image2.png' alt='image2'/>
                                  </div>
                                  <div className='image-container'>
                                    <img src='/images/image1.png' alt='image1'/>
                                  </div>
                                  <div className='image-container'>
                                    <img src='/images/image2.png' className='active' alt='image2'/>
                                  </div>
                                  <div className='image-container'>
                                    <img src='/images/image1.png' alt='image1'/>
                                  </div>
                                  <div className='image-container'>
                                    <img src='/images/image2.png' alt='image2'/>
                                  </div>
                                </div>
                                <button className='save-changes'>
                                  Save
                                </button>
                                <div style={{display:"flex", justifyContent:'space-between'}}>
                                  <span className='item-name'>Blurr Background</span>
                                  <div className={`switcher-button ${backgroundBlurr ? 'active' : ''}`} onClick={(e)=>{e.stopPropagation(); setBackgroundBlurr(!backgroundBlurr)}}>
                                    <span className='circle'></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                        ) : addPeopleFullActive ? (
                            <div className='container-popup-add-people'>
                              <div className='link-invite-container'>
                                <img src='/images/magic-wand.svg' style={{marginBottom: '28px'}} alt='magic-wand' width={40} height={40}/>
                                <span className='title'>Invite People To <br/> Get Started Your Meeting</span>
                                <div className='invite-via-email'>
                                  <img src='/images/white-envelope.svg' alt='envelope'/>
                                  <input type='email' value={inviteEmail} onChange={e=>setInviteEmail(e.target.value)} placeholder="Recipents email"/>
                                </div>
                                <LinkSendEmail disabled={!validEmail} onClick={sendMailInvitation} />
                                <div className='alternative-text'>
                                  <span>Or</span>
                                </div>
                                <CopyLink/>
                              </div>
                              <div className='list-previously-invited'>
                                <span className='popup-title'>Previously Invited</span>
                                {lastMembersInvited.length ? (
                                    <div className='container-list-people'>
                                      {lastMembersInvited.map((member,index)=>{
                                        return(
                                            <div className='guest-item' key={`previous-member-${index}`}>
                                              <div className='more-information-container'>
                                                <div className='profile-image-container'>
                                                  <UserProfilePicture width={36} height={36} userId={member.userId} userFirstName={member.firstName} userLastName={member.lastName[0]} />
                                                </div>
                                                <div className='information'>
                                                                              <span className='person-name' style={{textTransform:'capitalize'}}>
                                                                                {member.firstName} {member.lastName}
                                                                              </span>
                                                  <span className='person-email'>{member.email}</span>
                                                </div>
                                              </div>
                                              <button className='invite-button' onClick={()=>{
                                                sendMailInvitation(member.email)
                                              }}>Invite</button>
                                            </div>
                                        )
                                      })}
                                    </div>
                                ) : (
                                    <span className='tab-text' style={{
                                      display: 'block',
                                      color: '#fff',
                                      fontFamily: '"Poppins", "sans-serif"',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: 300,
                                      lineHeight:'normal',
                                      marginBottom: '16px'
                                    }}>No previous members found...</span>
                                )}
                              </div>
                            </div>
                        ) : null
                        }
                        <div className='triangle'>
                          <img src='/images/Polygon_1.svg' alt='polygon' />
                        </div>
                      </div>
                    </div>
                  </div>
              ) : null}

            </div>
        </div>
      </div>
    </WindowLayout>
  )
}
export default Encrypted