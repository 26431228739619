import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"
const Logo = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 1171.7 525.62"
    style={{cursor: location.pathname !== "/" ? "pointer" : ""}}
    onClick={()=>{
      if(location.pathname !== "/")
        navigate("/")
    }}
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={-1386.25}
        x2={-1259.2}
        y1={871.68}
        y2={751.14}
        gradientTransform="translate(1673.91 -463.32)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.17} stopColor="#825ef6" />
        <stop offset={0.58} stopColor="#3721b7" />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient"
        id="linear-gradient-2"
        x1={-1321.46}
        x2={-1194.41}
        y1={939.96}
        y2={819.42}
      />
      <linearGradient
        id="linear-gradient-3"
        x1={654.59}
        x2={764.3}
        y1={497.68}
        y2={413.24}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.15} stopColor="#3c32a3" />
        <stop offset={0.7} stopColor="#09067c" />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient-3"
        id="linear-gradient-4"
        x1={588.74}
        x2={698.45}
        y1={412.12}
        y2={327.68}
      />
      <radialGradient
        id="radial-gradient"
        cx={392.93}
        cy={377.8}
        r={253.83}
        fx={392.93}
        fy={377.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#3721b7" />
        <stop offset={0.62} stopColor="#825ef6" />
      </radialGradient>
      <radialGradient
        id="radial-gradient-2"
        cx={512.71}
        cy={443.65}
        r={222.02}
        fx={310.4}
        fy={352.21}
        gradientTransform="rotate(-37.7 423.005 474.245) scale(1 1.14)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.75} stopColor="#3c32a3" />
        <stop offset={1} stopColor="#000060" />
      </radialGradient>
      <style>
        {
          ".cls-12,.cls-2,.cls-3,.cls-6{stroke-width:0}.cls-2{fill:none}.cls-3{fill:#42d3d8}.cls-6{fill:#3c32a3}.cls-12{fill:#825ef6}"
        }
      </style>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <path
        d="M356.71 340.96H274.2V184.78h81.55l-5.73-6.82-66.18-66.18c-11.61-11.61-30.44-11.61-42.06 0l-130.01 130c-11.61 11.61-11.61 30.44 0 42.06l130.01 130.01c10.31 10.31 26.3 11.45 37.89 3.45s77.05-76.34 77.05-76.34ZM621.58 125.45l-13.68-13.68c-11.62-11.61-30.45-11.61-42.06 0l-73 73h186.72l-57.98-59.33ZM565.84 413.85c11.61 11.61 30.44 11.61 42.06 0l72.89-72.89H492.95l72.89 72.89ZM922.97 340.96h-108l72.89 72.89c11.62 11.61 30.45 11.61 42.06 0l130.01-130.01c11.61-11.61 11.61-30.44 0-42.06L929.92 111.77c-11.61-11.61-30.44-11.61-42.06 0l-73 73h108.11v156.18Z"
        className="cls-2"
      />
      <path
        d="m360.7 340.96 64.14 64.14-30.41 30.41 30.41-30.41-64.14-64.14z"
        className="cls-12"
      />
      <path
        d="m681.55 340.96 7.7 7.7 58.36 57.18-85.51 86.06 85.78-85.78-65.16-65.16h-1.17z"
        className="cls-6"
      />
      <path
        d="M1142.36 191.96 979.74 29.35c-39.13-39.13-102.57-39.13-141.7 0l-90.16 90.16 65.27 65.27h1.71l73-73c11.62-11.61 30.45-11.61 42.06 0l130.01 130.01c11.61 11.61 11.61 30.44 0 42.06L929.92 413.86c-11.61 11.61-30.44 11.61-42.06 0l-72.89-72.89H682.72l65.16 65.15 90.16 90.16c39.13 39.13 102.57 39.13 141.7 0l162.62-162.61c39.13-39.13 39.13-102.57 0-141.7Z"
        className="cls-3"
      />
      <path
        d="M279.67 417.3c1.47-1.01 2.87-2.15 4.17-3.45l72.89-72.89h-.02l-77.05 76.34Z"
        style={{
          strokeWidth: 0,
          fill: "url(#linear-gradient)",
        }}
      />
      <path
        d="m343.45 486.49 50.98-50.98-50.98 50.98z"
        style={{
          fill: "url(#linear-gradient-2)",
          strokeWidth: 0,
        }}
      />
      <path
        d="M360.7 340.96h-3.97l-72.89 72.89c-1.3 1.3-2.71 2.44-4.17 3.45l63.78 69.19 50.98-50.98 30.41-30.41-64.14-64.14Z"
        className="cls-12"
      />
      <path
        d="m356.73 340.96-72.89 72.89c-1.3 1.3-2.71 2.44-4.17 3.45-11.59 8-27.57 6.86-37.89-3.45L111.77 283.84c-11.61-11.61-11.61-30.44 0-42.06l130.01-130.01c11.62-11.61 30.45-11.61 42.06 0l66.18 66.18 5.73 6.82h133.3l-64.22-64.25-91.18-91.18c-39.13-39.13-102.57-39.13-141.7 0L29.35 191.96c-39.13 39.13-39.13 102.57 0 141.7l162.61 162.61c39.13 39.13 102.57 39.13 141.7 0l9.78-9.78-33.3-36.13 33.3 36.13 50.98-50.98 30.41-30.41-64.14-64.14h-3.97Z"
        style={{
          fill: "url(#radial-gradient)",
          strokeWidth: 0,
        }}
      />
      <path
        d="m747.88 119.51-90.16-90.16c-39.13-39.13-102.57-39.13-141.7 0l-91.18 91.18 64.22 64.25h3.78l73-73c11.61-11.61 30.44-11.61 42.06 0l13.68 13.68 57.98 59.33h133.6l-65.27-65.27Z"
        className="cls-6"
      />
      <path
        d="m662.06 491.94.04-.04-.04.04z"
        style={{
          fill: "url(#linear-gradient-3)",
          strokeWidth: 0,
        }}
      />
      <path
        d="m689.25 348.66-7.7-7.7h-.62l-35.73 35.73-36.04 36.05 52.9 79.2.04-.04 85.51-86.06-58.36-57.18z"
        className="cls-6"
      />
      <path
        d="m689.25 348.66-7.7-7.7h-.77l-72.89 72.89c-11.62 11.61-30.45 11.61-42.06 0l-72.89-72.89H360.69l64.14 64.14 91.18 91.18c39.13 39.13 102.57 39.13 141.7 0l4.34-4.34.04-.04 85.51-86.06-58.35-57.18Z"
        style={{
          fill: "url(#radial-gradient-2)",
          strokeWidth: 0,
        }}
      />
      <path
        d="M680.93 340.96h.62l-.31-.31-.31.31z"
        style={{
          fill: "#fff",
          strokeWidth: 0,
        }}
      />
      <path
        d="M680.93 340.96h.62l-.31-.31-.31.31z"
        style={{
          fill: "url(#linear-gradient-4)",
          strokeWidth: 0,
        }}
      />
      <path
        d="M302.52 266.71c4.23 5.22 6.34 11.34 6.34 18.37 0 9.78-3.27 17.49-9.8 23.15-6.54 5.66-16.01 8.48-28.43 8.48H220.7V200.37h48.95c11.75 0 20.79 2.58 27.1 7.74 6.32 5.17 9.48 12.42 9.48 21.75 0 7.25-1.89 13.18-5.68 17.8-3.79 4.61-8.76 7.64-14.91 9.06 7.03 1.43 12.66 4.75 16.89 9.97Zm-58.58-17.55h20.93c5.82 0 10.24-1.29 13.26-3.87 3.02-2.58 4.53-6.29 4.53-11.12s-1.48-8.57-4.45-11.2c-2.97-2.64-7.52-3.95-13.67-3.95h-20.6v30.15Zm36.25 44.58c3.29-2.69 4.94-6.62 4.94-11.78s-1.68-9.14-5.02-11.94c-3.36-2.8-8.11-4.2-14.26-4.2h-21.91v31.97h22.08c6.15 0 10.88-1.34 14.17-4.04Z"
        className="cls-12"
      />
      <path
        d="M444.94 206.31c8.84 5.05 15.82 12.11 20.92 21.17 5.11 9.06 7.66 19.31 7.66 30.73s-2.55 21.7-7.66 30.82c-5.11 9.12-12.09 16.21-20.92 21.26-8.85 5.05-18.7 7.58-29.58 7.58s-20.74-2.53-29.58-7.58c-8.85-5.05-15.82-12.14-20.93-21.26s-7.66-19.39-7.66-30.82 2.55-21.67 7.66-30.73 12.08-16.12 20.93-21.17c8.84-5.05 18.7-7.58 29.58-7.58s20.74 2.53 29.58 7.58Zm-47.54 18.45c-5.17 3.08-9.2 7.5-12.11 13.27s-4.37 12.5-4.37 20.18 1.45 14.42 4.37 20.19c2.91 5.77 6.95 10.19 12.11 13.26 5.16 3.08 11.15 4.61 17.96 4.61s12.8-1.54 17.96-4.61c5.16-3.07 9.2-7.5 12.11-13.26 2.91-5.77 4.37-12.5 4.37-20.19s-1.46-14.42-4.37-20.18c-2.91-5.77-6.95-10.19-12.11-13.27-5.17-3.07-11.15-4.61-17.96-4.61s-12.8 1.54-17.96 4.61ZM789.07 206.31c8.84 5.05 15.82 12.11 20.92 21.17 5.11 9.06 7.66 19.31 7.66 30.73s-2.55 21.7-7.66 30.82c-5.11 9.12-12.09 16.21-20.92 21.26-8.85 5.05-18.7 7.58-29.58 7.58s-20.74-2.53-29.58-7.58c-8.85-5.05-15.82-12.14-20.93-21.26-5.11-9.12-7.66-19.39-7.66-30.82s2.55-21.67 7.66-30.73c5.11-9.06 12.08-16.12 20.93-21.17 8.84-5.05 18.7-7.58 29.58-7.58s20.74 2.53 29.58 7.58Zm-47.54 18.45c-5.17 3.08-9.2 7.5-12.11 13.27s-4.37 12.5-4.37 20.18 1.45 14.42 4.37 20.19c2.91 5.77 6.95 10.19 12.11 13.26 5.16 3.08 11.15 4.61 17.96 4.61s12.8-1.54 17.96-4.61c5.16-3.07 9.2-7.5 12.11-13.26 2.91-5.77 4.37-12.5 4.37-20.19s-1.46-14.42-4.37-20.18c-2.91-5.77-6.95-10.19-12.11-13.27-5.17-3.07-11.15-4.61-17.96-4.61s-12.8 1.54-17.96 4.61Z"
        className="cls-6"
      />
      <path
        d="M981.76 201.7v115.02h-23.07v-80.25l-29.5 80.25h-20.1l-29.5-79.92v79.92h-23.24V201.7h28.18l34.93 86.51 34.28-86.51h28.01Z"
        className="cls-3"
      />
      <path
        d="M629.91 185.14c12.54 7.17 22.45 17.19 29.7 30.05s10.88 27.41 10.88 43.62-3.63 30.79-10.88 43.74c-7.25 12.94-17.15 23-29.7 30.17-12.56 7.17-26.55 10.76-41.99 10.76s-29.43-3.59-41.98-10.76c-12.56-7.17-22.45-17.23-29.7-30.17s-10.88-27.52-10.88-43.74 3.63-30.75 10.88-43.62c7.25-12.86 17.15-22.88 29.7-30.05 12.55-7.17 26.55-10.76 41.98-10.76s29.43 3.59 41.99 10.76Zm-67.48 26.19c-7.33 4.37-13.06 10.64-17.19 18.83-4.13 8.18-6.2 17.74-6.2 28.65s2.06 20.46 6.2 28.65c4.13 8.18 9.86 14.46 17.19 18.83 7.33 4.37 15.82 6.55 25.49 6.55s18.16-2.18 25.5-6.55c7.33-4.36 13.06-10.64 17.19-18.83 4.13-8.19 6.2-17.74 6.2-28.65s-2.07-20.46-6.2-28.65c-4.13-8.19-9.86-14.46-17.19-18.83-7.33-4.36-15.83-6.55-25.5-6.55s-18.16 2.19-25.49 6.55Z"
        style={{
          fill: "#1f1c84",
          strokeWidth: 0,
        }}
      />
    </g>
  </svg>
  )
}
export default Logo
