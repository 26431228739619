import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuthentication} from "../../../../../stores/authentication";
import BackIcon from "../../../Rooms/chatroom/SVGComponents/back-icon";
import Icon from "../../../../icon/icon";
import {ButtonComponent} from "../../../../button-component/button-component";
import {Search} from "../../../../search-bar/search";
import {QuickListMembers} from "../../Groups/components/quick-list-members/quick-list-members";
import {useMeetings} from "../../../../../stores/meetings";
import {useGroups} from "../../../../../stores/groups";
import WindowLayout from "../../../../windowLayout/window-layout";
import {UserProfilePicture} from "../../../../user-profile-picture";
import {RoundedIcons} from "../../../../roundedIcons/rounded-icons";
import {MediaScreen} from "../../../Rooms/chatroom/encrypted/MediaScreen";


export const SpecificMeetingInvite = () => {
    const { selectedMeeting, addMemberMeeting, getSpecificMeeting, getMeetings, isLoading,inviteGroupToMeeting } = useMeetings();
    const { searchUser,groupByName,getGroups,searchGroupByName } = useGroups();
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const { isAuthenticated, user } = useAuthentication();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryGroup, setSearchQueryGroup] = useState('');
    const [foundUserInSearch,setFoundUserInSearch] = useState(null)
    const [errorUser,setErrorUser] = useState('')
    const [successUser,setSuccessUser] = useState('')
    const [responseAddGroupToMeeting,setResponseGroupToMeeting] = useState('')
    const {isMobile,isTablet} = MediaScreen()

    useEffect(() => {
        async function fetchData() {
            await getGroups();
            await getMeetings();
            await getSpecificMeeting(query.get('id'));

        }
        fetchData();

    }, []);
    useEffect(() => {
        if (selectedMeeting)
            setMeetingPicture(`https://gsvoiceapp.fra1.digitaloceanspaces.com/mts/${selectedMeeting._id}.png`)

    }, [selectedMeeting]);

    const [meetingPicture,setMeetingPicture] = useState(`https://gsvoiceapp.fra1.digitaloceanspaces.com/mts/${selectedMeeting ? selectedMeeting._id : ''}.png`)
    const [profilePicture,setProfilePicture] = useState(`https://gsvoiceapp.fra1.digitaloceanspaces.com/pfps/${foundUserInSearch?._id}.png`)

    function handleImageError() {
        setMeetingPicture('/images/group-image-default.png') // Change the image source to the fallback URL
    }


    if (isLoading || !selectedMeeting) {
        return <p>Loading...</p>;
    }
        return (
            <WindowLayout>
                <div className={'create-group-container'}>
                    <div className='back-button' style={{display: "flex", justifyContent: "space-between", flexDirection:isMobile ? 'column' : 'row', gap:isMobile ? '12px' : '0' }}>
                        <div className={'back-with-breadcrumbs'} style={{alignItems:isTablet ? 'flex-start': 'center'}}>
                            <div className='back-icon-container' style={{minWidth:52}} onClick={() => {
                                navigate(-1)
                            }}>
                                <BackIcon/>
                            </div>
                            <div className={'breadcrumbs-container'}>
                                <span>Manage Meetings</span>
                                <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                                <span onClick={() => {
                                    navigate('/manage-meetings')
                                }}>Upcoming Meetings</span>
                                <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                                <span onClick={() => {
                                    navigate(`/manage-meetings/my-meeting?id${selectedMeeting._id}`)
                                }}>{selectedMeeting.name} Meeting</span>
                                <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                                <span className={'active'}>Invite</span>
                            </div>
                        </div>
                        <div className={'global-button-group-settings'}>
                            <ButtonComponent
                                ClassName={'group-button-page'}
                                iconName={'empty-plus'}
                                style={{maxWidth:isMobile ? 'unset' : '',width:'100%'}}
                                bgOnHover={'transparent'}
                                buttonColor={'#42D3D8'}
                                IconColorHover={'#42D3D8'}
                                IconColor={'#fff'}
                                IconSize={20}
                                Text={!isTablet || isMobile ? 'Create A New Meeting' : ''}
                                OnClick={() => {
                                    navigate('/manage-meetings/create-meeting')
                                }}
                            />
                        </div>
                    </div>
                    <div className={'header-container'}>
                <span
                    className={'title-window'}>Invite New Members To {selectedMeeting.name} Meeting</span>
                        <span className={'under-title-window'}>{selectedMeeting.description}</span>
                    </div>

                    <div className={'content-meeting-details-container'}>
                        <div className={'left-container'}>
                            <img
                                src={meetingPicture}
                                onEnded={()=>
                                    setMeetingPicture(`https://gsvoiceapp.fra1.digitaloceanspaces.com/mts/${selectedMeeting._id}.png`)}
                                onError={()=>handleImageError()}
                                style={{borderRadius:4,maxWidth: 652, width: '100%',minHeight:450,objectFit:"cover", height: '100%',backgroundColor:'#EAE9EC'}}
                                alt='meeting-image'
                                width={652}
                                height={450}/>
                        </div>
                        <div className={'right-container'}>
                            <div className={'invite-members'}>
                                <span className={'title-window'} style={{textWrap:'unset'}}>Use the search bar and enter members email address</span>
                                <Search
                                    withKeyPress
                                    style={{maxWidth:'unset'}}
                                    value={searchQuery}
                                    placeholder={'Search people by email and add them to this meeting'}
                                    setSearchQuery={async (e) => {
                                    try {
                                        const result = await searchUser({email: e})
                                        if (result) {
                                            setFoundUserInSearch(result.user)
                                        }
                                        if(!result.success) {
                                            setFoundUserInSearch(null)
                                        }
                                        console.log(result)
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }}/>
                                {foundUserInSearch ? (
                                    <div className={'found-user-in-group'} style={{gap:0}}>
                                        {successUser ? (
                                            <>
                                                <span className={'title-window'} style={{marginBottom: 6}}>{successUser}</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className={'title-window'} style={{marginBottom: 6}}>{errorUser ? errorUser : 'Member Found'}</span>
                                                <span className={'under-title-window'} style={{textTransform: 'unset', marginBottom:48}}>Invite member to this meeting</span>
                                            </>
                                        )}
                                        <div className={'user-found-card-to-add'}>
                                            <div className='profile-image-container'
                                                 style={{height: 86, width: 86, minWidth: "unset"}}>
                                                <UserProfilePicture width={86} height={86} fontSize={18} userId={foundUserInSearch._id} userFirstName={foundUserInSearch.firstName} userLastName={foundUserInSearch.lastName[0]} />

                                            </div>
                                            <div className={'user-info-container'}>
                                        <span
                                            className={'user-name'}>{foundUserInSearch.firstName} {foundUserInSearch.lastName}</span>
                                                <span className={'user-email'}>{foundUserInSearch.email}</span>
                                            </div>
                                            <ButtonComponent
                                                ClassName={'add-to-this-group'}
                                                iconName={'empty-plus'}
                                                bgOnHover={'transparent'}
                                                buttonColor={'#42D3D8'}
                                                IconColor={'#fff'}
                                                IconSize={20}
                                                IconColorHover={'#42D3D8'}
                                                Text={'Add To This Meeting'}
                                                OnClick={async () => {
                                                    try {
                                                        const result = await addMemberMeeting({
                                                            meetingId: selectedMeeting._id,
                                                            memberId: foundUserInSearch._id
                                                        })
                                                        if (result.success) {
                                                            setSuccessUser("Invitation sent successfully!")
                                                        }
                                                        else if (result.reason === 'User already invited.')
                                                            setErrorUser('User already invited.')

                                                        console.log(result)
                                                        setTimeout(()=>{
                                                            setSearchQuery('')
                                                            setFoundUserInSearch(null)
                                                            setSuccessUser('')
                                                            setErrorUser('')
                                                        },3000)
                                                    } catch (e) {
                                                        console.log(e)
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>
                                ) : null}
                                <div className={'or-container'}>
                                    <span className={'or-line'}></span>
                                    <span className={'or-text'}>Or Invite A Group</span>
                                    <span className={'or-line'}></span>
                                </div>
                                <Search style={{maxWidth:'unset'}} placeholder={'Search a group by name and invite to this meeting'} setSearchQuery={(e)=>{
                                    searchGroupByName(e)
                                }}/>
                                {groupByName ? (
                                    <>
                                        <span className={'title-window'} style={{marginBottom: 6}}>{responseAddGroupToMeeting ? responseAddGroupToMeeting : 'Group Found'}</span>
                                        <span className={'under-title-window'} style={{textTransform: 'unset', marginBottom:48}}>Select and invite this group to this meeting</span>
                                        <div className={'group-found'}>
                                            <div className={'image-group'}>
                                                {groupByName?.picture ? (
                                                    <img src={groupByName.picture} alt='group-picture'/>

                                                ) : (
                                                    <img src={'/images/group-image-default.png'} alt='group-picture'
                                                         style={{objectFit:"cover",backgroundColor:'#EAE9EC'}}/>
                                                )}
                                                {/*<img src='/images/login-avt.png' alt={'image-avt'}/>*/}
                                            </div>
                                            <div className={'group-information'}>
                                                <span className={'group-name'}>{groupByName.name} Team</span>
                                                <span className={'group-description'}>{groupByName.description}</span>
                                                <RoundedIcons members={groupByName.members}/>

                                                <div className={'invites-information'}>
                                                    <Icon name={'users-crown'} size={24} color={'#1D2939'}/>
                                                    <span className={'people-invites'}>{groupByName.members.length} People In This Group</span>
                                                </div>
                                                <ButtonComponent
                                                    ClassName={'groups-button'}
                                                    iconName={'external-link-square'}
                                                    bgOnHover={'transparent'}
                                                    buttonColor={'#835FF7'}
                                                    IconColor={'#fff'}
                                                    IconSize={20}
                                                    iconPositionRight
                                                    IconColorHover={'#835FF7'}
                                                    Text={'Send Invitation To This Meeting'}
                                                    OnClick={async ()=>{
                                                        try {
                                                            const result = await inviteGroupToMeeting({
                                                                meetingId: selectedMeeting._id,
                                                                groupId: groupByName._id
                                                            })

                                                            if (result.success) {
                                                                setResponseGroupToMeeting('Group Invited to This Meeting')
                                                            }
                                                            else
                                                                setResponseGroupToMeeting('There Was An Error Inviting This Group')
                                                            setTimeout(()=>{
                                                                setResponseGroupToMeeting('')
                                                                searchGroupByName('')
                                                            },4000)
                                                            console.log(result)
                                                        } catch (e) {
                                                            console.log(e)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>

                    </div>
                    {/*
            <QuickListMembers selectedGroup={group} membersList={group.members}/>
*/}

                </div>
            </WindowLayout>
    )
}