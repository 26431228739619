import React, {useState} from "react";
import Icon from "../icon/icon";
import './search-bar-style.scss'

export const Search = ({style,placeholder, setSearchQuery,value, withKeyPress }) => {
    const [currentValue,setCurrentValue] = useState(value)
    const handleKeyPressEnter = (event)=>{
        if (event.key === 'Enter') {
            setSearchQuery(event.target.value)
        }
    }
    const pattern='/^(([^<>()[]\\.,;:\\s@"]+(.[^<>()[]\\.,;:\\s@"]+)*)|.(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/'

    return (
        <div className="search-input-container" style={style}>
            <Icon name="search" color="#141329" size={20} />
            <input
                type="text"
                value={currentValue}
                placeholder={placeholder ?? 'Search people by email and add them to this group'}
                onKeyPress={handleKeyPressEnter}
                onChange={(e) => {
                    setCurrentValue(e.target.value)
                    if (!withKeyPress) {
                        setSearchQuery(e.target.value)
                    }
                }}
            />
        </div>
    );
};