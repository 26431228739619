import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App"
import * as process from 'process';
import Axios from "axios"
// console.log(process.env.REACT_APP_WS)
const instance = Axios.create({
  baseURL: process.env.REACT_APP_WS,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
})


window.global = window;
window.process = process;
window.Buffer = [];
window.$http = instance 
const container = document.getElementById('app');
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>
)