import Icon from "components/icon/icon"
import Loader from "./loader"
import CreateRoom from "../Rooms/create";
import JoinRoom from "../Rooms/join";
import Footer from "../../layout/footer";

const Window = ({
  title="Authenticating",
  children,
  isLoading,
  message,
  error,
  success,
  minHeight="none"}) => {
  const loadingScreen = (
      <div>
          <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container'>
                  <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container home-page ">
                  <div className='dashboard-content'>
                          <div style={{display:"flex", gap: 16, alignItems:"center"}}>
                              <div style={{position:"relative", width: 48, height: 48}}>
                                  <Loader/>
                              </div>
                              <h2 className='dashboard-title' style={{marginBottom: 0}}>{title}</h2>
                          </div>
                          <p className="verify-email-text">{message}</p>
                  </div>
              </div>
          </div>
      </div>
  )
  const errorScreen = (
      <div>
          <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container'>
                  <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container home-page ">
                  <div className='dashboard-content'>
                      <div style={{display:"flex", gap: 16, alignItems:"center"}}>
                          <div style={{width:48, height: 48}}>
                              <Icon name="error" color={'#000'} size={48} className={'errorSvg'}/>
                          </div>
                          <h2 className='dashboard-title' style={{marginBottom: 0}}>{title}</h2>
                      </div>
                      <p className="verify-email-text">{error}</p>
                  </div>
              </div>
          </div>
      </div>
  )

  const successScreen = (
      <div>
          <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container'>
                  <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container home-page ">
                  <div className='dashboard-content'>
                      {success === 'Verification mail successful!' ? (
                              <div className={'email-verification-success-container'}>
                                  <div className={'email-verification-image-container'}>
                                      <img src="/images/verification-mail-success.png" alt={'verification-mail'}/>
                                  </div>
                                  <div className={'message-container'}>
                                      <span className={'message-title'}>Congratulations!!!</span>
                                      <span className={'message-description'}>You new email has been set successfully!</span>
                                      <span className={'message-description'}>Now you will be logged out automatically and it will be need to login in again with the new email.</span>
                                  </div>
                              </div>
                          )
                          : success === 'Verification successful!' ? (
                          <div className={'email-verification-success-container'}>
                              <div className={'email-verification-image-container'}>
                                  <img src="/images/verification-mail-success.png" alt={'verification-mail'}/>
                              </div>
                              <div className={'message-container'}>
                                  <span className={'message-title'}>Congratulations!!!</span>
                                  <span className={'message-description'}>You registered your account successfully!</span>
                                  <button>Start Meeting Now</button>
                              </div>
                          </div>
                      ) : (
                          <>
                              <div style={{display:"flex", gap: 16, alignItems:"center"}}>
                                  <div style={{width:48, height: 48}}>
                                      <Icon name="success" color={'#ab91fa'} size={48} className={'successSvg'}/>
                                  </div>
                                  <h2 className='dashboard-title' style={{marginBottom: 0,textWrap:'nowrap'}} >{title}</h2>
                              </div>
                              <p className="verify-email-text">{success}</p>
                          </>
                      )}

                  </div>
              </div>
          </div>
      </div>
  )
  return (
    <div className="authenticationWindow" style={{
      minHeight,height:'100%'
    }}>
      {
        isLoading ? loadingScreen : error ? errorScreen : success ? successScreen : children 
      }
    </div>
  )
}
export default Window