import { usePreferences } from "stores/preferences";
import CopyLink from "../../../../windowLayout/title-bar/room-controls/copy-link";
import React, {useState} from "react";
import { getInitials } from "utils";
import {LinkSendEmail} from "../../../../windowLayout/title-bar/room-controls/link-send-email";
import {ActivityLog} from "../logs/activity-log";
import {logActivity} from "../../../../../stores/log-activity";
const Participant = ({name, isAdmin, self, kick, id}) => {
    const [showMoreOptionsGuest,setShowMoreOptionsGuest] = useState(false)
    "eh"
    return (
        <div className='participant'>
            <div className='participant-informations'>
                <div style={{
                    display: "flex", // Daca se aproba liber sa-l preiei in clasa undeva.
                    height: "36px",
                    width: "36px",
                    borderRadius: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#090721",
                    color: "835ff7",
                    fontWeight: "600"
                }}>{typeof name === "string" && getInitials(name)}</div>
                {/* <img src='/images/avt.png' width={36} height={36} alt='avatar'/> */}
                <div className='informations'>
                    <span className='participant-name' style={{textTransform: "capitalize"}}>{name}</span>
                    {isAdmin && self &&<span className='participant-status'>Admin</span>}
                </div>
            </div>
            {
                !self ? (
                    <>
                        <div className='action-on-participant'>
                            {/* <img src='/images/grey-comment-alt.svg' alt='comment' width={20} height={20}/> */}
                            {
                                isAdmin ? (
                                    <div style={{position:"relative"}} onClick={()=>setShowMoreOptionsGuest(!showMoreOptionsGuest)}>
                                        <img src='/images/grey-dots-vertical.svg' alt='dots' width={20} height={20}/>
                                        {showMoreOptionsGuest ? (
                                        <div className='options-guests'>
                                                <div className='options-guests-more'>
                                                    <div className='container-more'>
                                                        <div className='item-more' onClick={()=>kick({roomId: window.location.search.split("?room=")[1], userToRemoveId: id})}>
                                                            <span>Kick</span>
                                                        </div>
                                                    </div>
                                                </div>

                                        </div>
                                        ) : null}
                                    </div>
                                ) : null
                            }

                        </div>
                    </>
                    
                ) : null
            }
        </div>
    )
}
export const SeeParticipants = ({session, contacts, current, toAdd=false,deafened, toggleDeafened, setShowInviteScreen, showInviteScreen, kick}) => {
    const {nickname} = usePreferences()
    const [inviteEmail, setInviteEmail] = useState("")
    const [alreadySent, setAlreadySent] = useState([])
    const {addItem} = logActivity()
    const validEmail = inviteEmail && inviteEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
    const sendMailInvitation = () => {
        if(validEmail && inviteEmail && inviteEmail.length > 4) {
            addItem({
                label:'Email',
                value: inviteEmail
            })
            if(!alreadySent.includes(inviteEmail)) {
                window.$comms.request("mailInvite", { name: current.name, email: inviteEmail, link: window.location.href })
                setAlreadySent([...alreadySent, inviteEmail])
                setTimeout(()=>{
                    setInviteEmail("")
                }, 3000)
            } else {
                setTimeout(()=>{
                    setInviteEmail("")
                }, 3000)
            }
        }
    }
    return(
        <div className={`container-right ${toAdd ? 'toadd' : ''}`}>
            {toAdd ? (
                <div className='link-invite-container'>
                    <img src='/images/magic-wand.svg' className={'magic-wand'} style={{marginBottom: '16px'}} alt='magic-wand' width={40} height={40}/>
                    <span className='title'>Your Meeting is Live <br/> Get Started By Inviting New People</span>
                    <div className='invite-via-email' style={{width:'100%'}}>
                        <img src='/images/white-envelope.svg' alt='envelope'/>
                        <input type='email' value={inviteEmail} onChange={e=>setInviteEmail(e.target.value)} placeholder="Recipents email"/>
                    </div>
                    {!validEmail && inviteEmail.length > 2 ? <p style={{color: "red", width: "100%", marginBottom: "10px"}}>Please enter a valid E-mail address!</p> : null}
                    <LinkSendEmail disabled={!validEmail} onClick={sendMailInvitation} />
                    <div className='alternative-text'>
                        <span className='line'></span>
                        <span>Or</span>
                        <span className='line'></span>
                    </div>
                    <CopyLink/>
                    <ActivityLog forEmail/>
                </div>
            ) : (
                <>
                    <div className='MeetingWindowRightHeader' style={{padding: 0}}>
                        <span className='TitleWindowRight'>Participants</span>
                        <span className='new-messages-number'>{contacts && contacts.length ? contacts.length+1 : 1}</span>
                    </div>
                    <div className='container-show-people'>
                        <Participant name={nickname} isAdmin={current && current.isOwner} self={true} kick={kick}/>
                        {
                            contacts.map((contact,index) => {
                                return (
                                    <Participant key={`participant-${index}`} id={contact.userData.username} name={contact && contact.userData && contact.userData.nickname} isAdmin={current && current.isOwner} kick={kick}/>
                                )
                            })
                        }
                    </div>
                    <div >
                        <div className='mute-all-participants' onClick={toggleDeafened}>
                            <img src={deafened ? `/images/red-microphone-slash%201.svg` : '/images/microphone.svg'} alt='microphone' width={20} height={20}/>
                            <span>{!deafened ? "Mute All" : "Unmute All"}</span>
                        </div>
                        <button className='invite-person' onClick={()=>setShowInviteScreen(!showInviteScreen)}>
                            <img src='/images/white-plus.svg' alt='plus' width={20} height={20}/>
                            <span>Invite</span>
                        </button>
                    </div>
                </>
            )}

        </div>
    )
}