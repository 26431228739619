import {ModalGlobal} from "./modalGlobal";
import {shopModal} from "./shopModal";
import {GroupModal} from "../group-modal/groupModal";

const Content = (props) => {
    const {modalGroups} = shopModal()
  return (
    <div className="layoutContent">
      {props.children}
        <ModalGlobal />
    </div>
  )
}
export default Content