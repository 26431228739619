import axios from "axios"
import { create } from "zustand"

export const useCountryLocation = create((set) => ({
  countryCode: "",
  async getLocationInformation() {
    try {
      const { data } = await axios.get("https://ipapi.co/json/")
      setTimeout(()=>{
        set({countryCode: data.country_code})
      }, 1000)
    } catch (e) {
      set({countryCode: "ae"})
    }
  }
}))