import { useRooms } from "stores/rooms";
import AttemptJoinRoom from "../attempt-room-join";
import RoomControl from "./encrypted/RoomControl";

const Chatroom = () => {
  const { current } = useRooms()
  const isInRoom = !!current
  return (
     !isInRoom ? <AttemptJoinRoom/>
    : <RoomControl/>
  )
}

export default Chatroom