import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Cropper from 'react-cropper'; // You need to install react-cropper for cropping functionality
import 'cropperjs/dist/cropper.css';
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import Input from "../../../input/input";
import {ButtonComponent} from "../../../button-component/button-component";
import {useGroups} from "../../../../stores/groups";
import {QuickListGroups} from "./components/quick-list-groups/quick-list-groups";
import {Manage} from "../../../../stores/manage";
import useNavigationStore from "../../../../stores/navigation-bar";
import Icon from "../../../icon/icon";
import WindowLayout from "../../../windowLayout/window-layout";
import {useAuthentication} from "../../../../stores/authentication";

export const CreateGroup = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const { isAuthenticated }= useAuthentication()
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [avatarUrl,setNewAvatarUrl ] =useState()
    const [cropper,setCropper] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [imageError, setImageError] = useState(false);
    const [notResizedImage,setNotResizedImage]=useState()
    const { createGroup} = useGroups()

    useEffect(() => {
        if(!isAuthenticated)
            navigate('/login')
    }, []);
    const clearState = () => {
        setName("")
        setDescrion('')
        setFile(null),
            setNewAvatarUrl()
        setIsLoading(false)
        setError("")
        setSuccess("")
    }
    const getNewAvatarUrl = (e) => {
        setFile(null)
        if (e.target.files) {
            setNewAvatarUrl(URL.createObjectURL(e.target.files[0]));
        }
    };

    const filePickHandler = (files) => {
        const file = files
        const objectUrl = URL.createObjectURL(file)
        const img = document.createElement("img")
        img.onload = () => {
            if(img.width !== 128 || img.height !== 128) {
                setError("Image needs to be 128px by 128px in dimensions!")
            }
            URL.revokeObjectURL(objectUrl)
        }
        img.src = objectUrl
        img.setAttribute("style", "display: none")
        document.body.appendChild(img)
        console.log(files)
        setFile(files)
    }
    const getCropData = async () => {
        if (cropper) {
            const canvas = cropper.getCroppedCanvas();

            // Convert the canvas to a Blob with a specified quality
            const blobPromise = new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 1); // Adjust the quality as needed
            });

            const blob = await blobPromise;

            const resizedBlob = await resizeImageWidthHeight(blob, 600);

            const file = new File([resizedBlob], `${name.toLowerCase()}.jpg`, { type: "image/jpeg" });

            if (file) {
                filePickHandler(file)
            }
        }
    };
    async function resizeImageWidthHeight(blob, targetWidth, targetHeight) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                let newWidth;
                let newHeight;

                // Calculate new dimensions to maintain aspect ratio
                if (img.width > img.height) {
                    newWidth = targetWidth;
                    newHeight = targetWidth / aspectRatio;
                } else {
                    newHeight = targetHeight;
                    newWidth = targetHeight * aspectRatio;
                }

                // Create a canvas to perform the resizing
                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(resolve, 'image/jpeg', 0.9); // Adjust the quality as needed
            };
            img.onerror = reject;
        });
    }
    const resizeImage = async (blob, maxSize) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const scaleFactor = Math.min(1, maxSize / (img.width * img.height));

                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;

                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob((resizedBlob) => {
                        resolve(resizedBlob);
                    }, 'image/jpeg', 0.9); // Adjust the quality as needed
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(blob);
        });
    };

    const [errors, setErrors] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(true)
    const groupNameValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        }
    ]
    const groupDescriptionValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        }
    ]
    const checkGroupName = () =>{
        for (let i = 0; i < groupNameValidations.length; i++) {
            if (!groupNameValidations[i].check(name)) {
                setErrors(errors + ' groupName')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkGroupDescription = () =>{
        for (let i = 0; i < groupDescriptionValidations.length; i++) {
            if (!groupDescriptionValidations[i].check(description)) {
                setErrors(errors + ' groupDescription')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }

    const checkCreatGroupForm = ()=>{
        if (avatarUrl && !file) {
            setImageError(true)
            setTimeout(()=>{
                setImageError(false)

            },5000)
            return false
        }
        if(checkGroupName() &&
            checkGroupDescription()){
            return true
        }
    }


    const attemptCreate = async () => {
        if (!checkCreatGroupForm())
            return
        setIsLoading(true)
        try {
            const response = await createGroup({
                name,
                description,
                file
            })
            if(response === true) {
                console.log('success')
                navigate('/manage-groups')
                clearState()
            } else {
                throw(response)
            }
        } catch (e) {
            setError(e)
        }
        setIsLoading(false)
    }



        return (
            <WindowLayout>
                <div className={'create-group-container'}>
                <div className='back-button'>
                    <div className={'back-with-breadcrumbs'}>
                        <div className='back-icon-container' style={{minWidth:52}} onClick={() => {
                            navigate('/')
                        }}>
                            <BackIcon/>
                        </div>
                        <div className={'breadcrumbs-container'}>
                            <span>Manage groups</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span className={'active'}>Create Group</span>
                        </div>
                    </div>
                </div>
                <div className={'header-container'}>
                    <span className={'title-window'}>Create New Group</span>
                    <span className={'under-title-window'}>Create a new meeting group</span>
                </div>
                <div className={'form-create-group-container'}>
                    <Input
                        onChange={(e) => {
                            setName(e.target.value)
                            checkGroupName()}}
                        required
                        capitalize
                        validations={groupNameValidations}
                        minLength="2"
                        value={name}
                        foundError={errors.includes('groupName')}
                        placeholder={'E.g.: Design group, Development group'}
                        label={'Group Name'}/>
                    <Input
                        maxLength={'250'}
                        minLength={'3'}
                        required
                        validations={groupDescriptionValidations}
                        value={description}
                        foundError={errors.includes('groupDescription')}
                        onChange={(e) => {
                            setDescription(e.target.value)
                            checkGroupDescription()
                        }}
                        placeholder={'Enter Group Description'}
                        label={'Group Description'}/>

                    {file && avatarUrl ? (
                        <Input
                            imageUrl={avatarUrl}
                            onChange={(e) => {
                                getNewAvatarUrl(e)
                            }}
                            addFile
                            style={{whiteSpace: 'pre-line'}}
                            placeholder={'Click or drag your image here to upload \n (Or we will load a default group image)'}/>
                    ) : avatarUrl ? (
                        <>
                            <Cropper
                                    autoCropArea={1}
                                    dragMode={"none"}
                                src={avatarUrl}
                                style={{height: "100%", width: "100%", maxHeight: '300px', marginBottom: 24}}
                                initialAspectRatio={16 / 9}
                                guides={false}
                                cropBoxResizable={false}
                                checkOrientation={false}
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                            />
                            <ButtonComponent
                                ClassName={'groups-button'}
                                bgOnHover={'transparent'}
                                buttonColor={'rgb(131, 95, 247)'}
                                Text={'Crop'}
                                OnClick={getCropData}
                            />
                        </>
                    ) : (
                        <Input
                            onChange={(e) => {
                                getNewAvatarUrl(e)
                            }}
                            addFile
                            style={{whiteSpace: 'pre-line'}}
                            placeholder={'Click or drag your image here to upload \n (Or we will load a default group image)'}/>

                    )}
                    {imageError ? (
                        <div className="inputError" key={`Incorrect email or password.`}>
                            <img className='error-icon-inputs' src='/images/danger-triangle.svg' alt="error-icon"/>
                            The image needs to be cropped to upload it.
                        </div>
                    ) : null}
                    <span className={'upload-image-message'} style={{marginBottom:12}}>It takes up to 1min to upload the image.</span>
                    <ButtonComponent
                        ClassName={'groups-button'}
                        iconName={'empty-plus'}
                        bgOnHover={'transparent'}
                        buttonColor={'#42D3D8'}
                        IconColor={'#fff'}
                        IconSize={20}
                        Disabled={disableSubmit}
                        IconColorHover={'#42D3D8'}
                        Text={'Create A New Group'}
                        OnClick={attemptCreate}
                    />
                </div>
                <QuickListGroups/>

            </div>
            </WindowLayout>
        );
}

