import React, {useEffect, useState} from "react";

export const TimeLeft = ({meetingDate,meetingTime}) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [format, setFormat] = useState('');

    useEffect(() => {
        const currentYear = currentTime.getFullYear();
        const currentMonth = currentTime.getMonth();
        const currentDay = currentTime.getDate();
        const meetingYear = parseInt(meetingDate.year, 10);
        const meetingMonth = new Date(`${meetingDate.month} 1, 2023`).getMonth();
        const meetingDay = parseInt(meetingDate.day, 10);

        if (meetingYear !== currentYear) {
            setFormat('year,month,day');
        } else if (meetingMonth !== currentMonth) {
            setFormat('month,day,hour');
        } else if (meetingDay !== currentDay) {
            setFormat('day,hour,minutes');
        } else {
            setFormat('hour,minutes,seconds');
        }
    }, [meetingDate, currentTime]);

    useEffect(() => {
        if (format === 'hour,minutes,seconds') {
            const interval = setInterval(() => {
                setCurrentTime(new Date());
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [format]);

    const displayContent = () => {
        const hourOffset = meetingTime.amPm.toUpperCase() === 'PM' && meetingTime.hour !== 12 ? 12 : 0;
        const normalizedHour = meetingTime.hour % 12 + hourOffset;
        const meetingTimeString = `${meetingDate.year}-${('00' + (new Date(`${meetingDate.month} 1`).getMonth() + 1)).slice(-2)}-${('00' + meetingDate.day).slice(-2)}T${('00' + normalizedHour).slice(-2)}:${('00' + meetingTime.minute).slice(-2)}:00`;
        const meetingDateTime = new Date(meetingTimeString);
        const timeDifference = meetingDateTime - currentTime;

        if (isNaN(meetingDateTime.getTime())) {
            return "Invalid meeting time! Please check the input.";
        }

        const hoursLeft = Math.floor(timeDifference / 3600000);
        const minutesLeft = Math.floor((timeDifference % 3600000) / 60000);
        const secondsLeft = Math.floor((timeDifference % 60000) / 1000);

        switch (format) {
            case 'year,month,day':
                return `${meetingDate.year}, ${meetingDate.month}, ${meetingDate.day}D`;
            case 'month,day,hour':
                return `${meetingDate.month}, ${meetingDate.day}D, ${meetingTime.hour}H`;
            case 'day,hour,minutes':
                return `${meetingDate.day}D, ${meetingTime.hour}H:${meetingTime.minute}M`;
            case 'hour,minutes,seconds':
                if (timeDifference < 0) {
                    return "Time's up!";
                }
                return `${hoursLeft.toString().padStart(2, '0')}H:${minutesLeft.toString().padStart(2, '0')}M:${secondsLeft.toString().padStart(2, '0')}S`;
            default:
                return 'Loading...';
        }
    };
    return(
        <div className={'time-left-container'}>
            <span className={'label-time-left'}>Join Meeting</span>
            <div className={'time-container'}>
                <span className={'value-time'} style={{textWrap:'nowrap'}}>{displayContent()}</span>
            </div>
        </div>
    )
}