import { Link } from "react-router-dom"
import Icon from "./icon/icon"
import {useEffect, useState} from "react";

const Checkbox = ({value, onClick, label="", linkLabel, linkTo, required, foundError}) => {
    const [error,setError] = useState(false)

    useEffect(()=>{
        if(value || foundError) {
            setError(!value)
        } else {
            if(error > 0) {
                setError(false)
            }
        }
    },[foundError,value])
  return (
    <div className={`checkBoxContainer ${error ? 'foundError' : ''}`} >
      <div className={`checkBox ${value && "checked"}`} onClick={onClick}>
        <input
          type="checkbox"
          name={label}
          id={label}
          style={{opacity: 0, width: "1px", height: "1px"}}
          required={required}
          checked={value}
          onClick={()=>{}}
          onChange={()=>{}}/>
        {value && <Icon name="check"/>}
      </div>
      <label htmlFor={label} className="checkBoxLabel">{label}</label>
        {linkTo ? (<Link to={linkTo}>{linkLabel}</Link>) : null}

    </div>
  ) 
}
export default Checkbox