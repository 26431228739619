import 'react-toastify/dist/ReactToastify.css';
import "./style/global.scss"
import { useConnection } from "stores/connection"
import Maintenance from 'components/pages/Maintenance/maintenance';
import { useMaintenance } from 'stores/maintenance';
import Connecting from 'components/pages/Connecting/connecting';
import Pages from 'components/pages';
import { useCountryLocation } from 'stores/location';

const App = () => {
  const {isConnected} = useConnection()
  const {isUnderMaintenance} = useMaintenance()
  const { countryCode } = useCountryLocation()
  return (
    isUnderMaintenance ? <Maintenance/> : (
      isConnected && countryCode ? <Pages /> : <Connecting/>
    )
  )
}
export default App;