import WindowLayout from "components/windowLayout/window-layout"
import Icon from "components/icon/icon"
import Loader from "components/loader"
import {Link, Route, useNavigate, useSearchParams} from "react-router-dom"
import { useState } from "react"
import { useEffect } from "react"
import Countdown from "components/countdown"
import { useRooms } from "stores/rooms"
import { usePreferences } from "stores/preferences"
import { useAuthentication } from "stores/authentication"
import Input from "components/input/input"
const AttemptJoinRoom = () => {
  const navigate = useNavigate()
  const [currentState, setCurrentState] = useState("query")
  const [searchParams, setSearchParams] = useSearchParams();
  const { nickname, setNickname } = usePreferences()
  const { isAuthenticated } = useAuthentication()
  const room = searchParams.get('room');
  const { queryRoom: roomExists, joinRoom } = useRooms() 
  const displayOptions = {
    query: (
      <>
          <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container'>
                  <img style={{paddingTop: 0}} src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container "  style={{marginTop: 0}} >
                  <div className='dashboard-content'>
                      <div style={{display:"flex", alignItems:"center",gap:'15px'}}>
                          <Loader/>
                          <h2 className='dashboard-title' style={{marginBottom: 0}}>Checking if Room exists</h2>
                      </div>
                  </div>
              </div>
          </div>
      </>
    ),
    joinRoom: (
      <>
          <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container'>
                  <img style={{paddingTop: 0}} src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container "  style={{marginTop: 0}} >
                  <div className='dashboard-content'>
                      <div style={{display:"flex", alignItems:"center",gap:'15px'}}>
                          <Loader/>
                          <h2 className='dashboard-title'  style={{marginBottom: 0}}>Joining Room</h2>
                      </div>
                  </div>
              </div>
          </div>
      </>
    ),
    chooseName: (
      <>
        <div style={{color: "#4423b6"}}>
          <Icon name="question" size={100}/>
        </div>

        <br />
        <h2>Choose Your Name</h2>
        <br />
        <div className="container-fluid" style={{width: "450px", maxWidth: "98%"}}>
          <form onSubmit={(e)=>{
            e.preventDefault()
            connectToRoom()
          }}>
            <p className="text-center">What should we call you in the chat?</p>
            <Input
              name="nickname"
              label="Enter Desired Name"
              autoComplete="nickname"
              type="text"
              capitalize
              minLength="2"
              maxLength="32"
              value={nickname}
              required
              style={{marginBottom: "6px"}}
              onChange={(e) => setNickname(e.target.value)} />
            <div className="d-flex gap-1">
              <input type="button" className="mainButton" value={"Cancel"} onClick={()=>{navigate("/")}}/>
              <input type="submit" className={`secondaryButton ${nickname.length <2 ? "disabled" : ""}`} value={"Join Room"}/>
            </div>
          </form>
        </div>
      </>
    ),
    invalidLink: (
      <>
          <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container'>
                  <img style={{paddingTop: 0}} src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container "  style={{marginTop: 0}} >
                  <div className='dashboard-content'>
                      <h2 className='dashboard-title'>Invalid Link!</h2>
                      <p className="verify-email-text">Please make sure to use a valid meeting link.</p>
                      <p className="verify-email-text">You will be redirected home in: <b><Countdown time={6} onEnded={()=>navigate("/")}/>s
                      </b></p>
                  </div>
              </div>
          </div>
      </>
    ),
    roomNotExist: (
      <>
          <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container'>
                  <img style={{paddingTop: 0}} src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container "  style={{marginTop: 0}} >
                  <div className='dashboard-content'>
                      <h2 className='dashboard-title'>Room doesn't exist!</h2>
                      <p className="verify-email-text">If it did, it has probably expired.</p>
                      <p className="verify-email-text">You will be redirected home in: <b><Countdown time={6} onEnded={()=>navigate("/")}/>s
                      </b></p>
                  </div>
              </div>
          </div>
      </>
    ),
  }
  const connectToRoom = async () => {
    setCurrentState("joinRoom")
    const success = await joinRoom({
      roomId: room,
      name: nickname
    })
    if(success !== true) {
      setCurrentState("invalidLink")
    }
  }
  const start = async () => {
    if(await roomExists({roomId: room})) {
      if(isAuthenticated) {
        connectToRoom()
      } else {
        if(!nickname) {
          navigate(`/?room=${room}`)
        } else {
          connectToRoom()
        }
        // setCurrentState("chooseName")
      }
    } else {
      setCurrentState("roomNotExist")
    }
  }
  useEffect(()=>{
    if(!room || (room && !room.match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/))) {
      setCurrentState("invalidLink")
    } else {
      start()
    }
  },[])

  return (
      <WindowLayout className="attemptJoinRoom centerRooms d-flex flex-column">
        { displayOptions[currentState] }
      </WindowLayout>
  )
}

export default AttemptJoinRoom