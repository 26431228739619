import './maintenance-animation.scss'
import Icon from "../icon/icon";
export const MaintenanceAnimation = () => {
    return(
        <div className='maintenanceAnimation'>
            <Icon name="gear-maintenance" size={60} color={'#000000'} className="gear-maintenance-first"/>
            <Icon name="gear-maintenance" size={60} color={'#000000'}  className="gear-maintenance-second"/>
            <Icon name="gear-maintenance" size={60} color={'#000000'}  className="gear-maintenance-third"/>
            <Icon name="gear-maintenance" size={60} color={'#000000'}  className="gear-maintenance-four"/>
            <Icon name="maintenance-key" size={90} className="maintenance-key"/>
            <Icon name="nut" size={24} className="nut"/>
        </div>
    )
}