import create from 'zustand';

// Define the store
const useNavigationStore = create((set, get) => ({
    // Array to hold all navigation URLs
    allNavigations: ['/'],

    // Function to get the last navigation URL
    getLastNavigation: () => {
        const navigations = get().allNavigations;
        return navigations[navigations.length - 1]; // Return the last item in the array
    },

    // Function to add a new navigation URL
    addLastNavigation: (newPath) => set((state) => ({
        allNavigations: [...state.allNavigations, newPath]
    }))
}));

export default useNavigationStore;