import React, {useEffect, useState} from "react";
import {Manage} from "../../../../../../stores/manage";
import Icon from "../../../../../icon/icon";
import './big-group-card.scss'
import {ButtonComponent} from "../../../../../button-component/button-component";
import {useNavigate} from "react-router-dom";
import {useGroups} from "../../../../../../stores/groups";
import {useAuthentication} from "../../../../../../stores/authentication";
import {UserProfilePicture} from "../../../../../user-profile-picture";
import {shopModal} from "../../../../../layout/shopModal";
export const BigGroupCard = ({item,invitation}) => {
    const {deleteGroup,selectGroup,acceptGroupInvitation,declineGroupInvitation } = useGroups()
    const navigate = useNavigate()
    const { user } = useAuthentication();
    const {accept,setAccept, setShow} = shopModal()

    const deleteGroupAction = async ()=>{
        if (accept) {
            try {
                const result = await deleteGroup({id: item._id})
                if (result) {
                    console.log(result)
                    navigate('/manage-groups')
                    console.log('sucsess delete')
                }
                console.log(result)
            } catch (e) {
                console.log(e)
            }
            setAccept(false)

        }
    }
    useEffect(()=>{
        deleteGroupAction()
    },[accept])

    return (
        <div className={'bit-group-card-container'}>
            <div className={'left-content'}>
                <div style={{display:"flex", gap:12,flexDirection:"column"}}>
                    <UserProfilePicture groupImage width={'100%'} maxWidth={400} height={230} groupImageURL={item.picture} />
                    {invitation ? (
                        <div style={{display:"flex",gap:12}}>
                                <ButtonComponent
                                    style={{width:'100%'}}
                                    ClassName={'group-button-page'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#42D875'}
                                    Text={'Join'}
                                    OnClick={async ()=>{
                                        try {
                                            const result = await acceptGroupInvitation({groupId:item._id})
                                            if (result) {
                                                console.log(result)
                                                console.log('sucsess accept')
                                            }
                                            console.log(result)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                />
                                <ButtonComponent
                                    style={{width:'100%'}}
                                    ClassName={'group-button-page'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#F75F5F'}
                                    Text={'Decline'}
                                    OnClick={async ()=>{
                                        try {
                                            const result = await declineGroupInvitation({groupId:item._id})
                                            if (result) {
                                                console.log(result)
                                                console.log('sucsess decline')
                                            }
                                            console.log(result)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                />
                            </div>
                    ) : (
                        <ButtonComponent
                            ClassName={'group-button-page'}
                            iconName={'empty-plus'}
                            bgOnHover={'transparent'}
                            buttonColor={'#42D3D8'}
                            IconColorHover={'#42D3D8'}
                            IconColor={'#fff'}
                            IconSize={20}
                            Text={'Create A New Meeting'}
                            OnClick={()=>{
                                selectGroup(item._id)
                                navigate(`/manage-meetings/create-meeting?id=${item._id}`)
                            }}
                        />
                    )}

                </div>
                {invitation || user._id !== item.owner ? (
                    <div style={{display:"flex", gap:10, flexDirection:"column"}}>
                        <ButtonComponent
                            ClassName={'button-card'}
                            iconName={'settings'}
                            bgOnHover={'#1D2939'}
                            buttonColor={'#fff'}
                            textColor={'#1D2939'}
                            IconColorHover={'#fff'}
                            IconColor={'#1D2939'}
                            IconSize={20}
                            Text={'Group Details'}
                            OnClick={()=>{
                                selectGroup(item._id)
                                navigate(`/manage-groups/my-group?id=${item._id}`)
                            }}
                        />
                    </div>
                ) :  (
                    <div style={{display:"flex", gap:10, flexDirection:"column"}}>
                        <ButtonComponent
                            ClassName={'button-card'}
                            iconName={'settings'}
                            bgOnHover={'#1D2939'}
                            buttonColor={'#fff'}
                            textColor={'#1D2939'}
                            IconColorHover={'#fff'}
                            IconColor={'#1D2939'}
                            IconSize={20}
                            Text={'Manage Group'}
                            OnClick={()=>{
                                selectGroup(item._id)
                                navigate(`/manage-groups/my-group?id=${item._id}`)
                            }}
                        />
                        <ButtonComponent
                            ClassName={'button-card'}
                            iconName={'trash'}
                            bgOnHover={'#F75F5F'}
                            buttonColor={'#fff'}
                            textColor={'#F75F5F'}
                            IconColorHover={'#fff'}
                            IconColor={'#F75F5F'}
                            IconSize={20}
                            Text={'Detele'}
                            OnClick={()=>{
                                setShow(true)
                                if (accept) {
                                    deleteGroupAction()
                                }
                            }}
                        />
                    </div>
                )}
            </div>
            <div className={'right-content'}>
                <div className={'big-group-card-informations'}>
                    <span className={'group-name'}>{item.name}</span>
                    <span className={'group-members-length'}>{item.members.length} {item.members.length === 0 ? 'Members' : item.members.length === 1 ? 'Member' : 'Members' }</span>
                </div>
                <div className={'group-members-container'}>
                    {item.members?.slice(0, 4).map((member,index)=>{
                        return(
                            <div key={`member-${item.name}-${index}`} className={'member-item'}>
                                <div className={'member-icon'}>
                                  <UserProfilePicture userId={member._id} userFirstName={member.firstName} userLastName={member.lastName[0]} />
                                </div>
                                <div className={'member-information'}>
                                    <span className={'member-name'}>{member.firstName} {member.lastName}</span>
                                    <span className={'member-email'}>{member.email}</span>
                                </div>
                            </div>
                        )
                    })}
                    {item.members.length > 4  ? (
                        <div className={'member-item load-button-more'}>
                            <div className={'member-icon'}>
                                <Icon name={'load-more-users'} color={'835FF7'} withStroke size={20}/>
                            </div>
                            <div className={'member-information'}>
                                <span className={'member-name'}  >Load More</span>
                                <span className={'member-email'}>See Full List <Icon name={'long-right-arrow'} color={'#835FF7'} size={24}/></span>
                            </div>
                        </div>
                    ) : null}


                </div>
            </div>
        </div>
    )
}