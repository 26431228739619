import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../../../../stores/authentication";
import {useGroups} from "../../../../stores/groups";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import {ButtonComponent} from "../../../button-component/button-component";
import Icon from "../../../icon/icon";
import {SelectRole} from "../../../select-role/selectRole";
import {GroupPastMeetings} from "../../../group-modal/group-past-meetings";
import {Search} from "../../../search-bar/search";
import {QuickListMembers} from "./components/quick-list-members/quick-list-members";
import WindowLayout from "../../../windowLayout/window-layout";
import {UserProfilePicture} from "../../../user-profile-picture";
import {MediaScreen} from "../../Rooms/chatroom/encrypted/MediaScreen";
import {shopModal} from "../../../layout/shopModal";

export const SpecificGroup = () => {
    const query = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const { isAuthenticated,user} = useAuthentication()
    const [groupId, setGroupId] =useState(query.get('id')) ;
    const {selectGroup,selectedGroup,groupOwner,removeMember,getGroups,isLoading,deleteGroup,declineGroupInvitation } = useGroups()
    const [searchQuery, setSearchQuery] = useState('');
    const [newUser,setNewUser] = useState(false)
    const [windowShow,setWindowShow] = useState('membersExi')
    const {isMobile,isTablet} = MediaScreen()
    const {accept,setAccept, setShow,kickMember,setKickMember} = shopModal()
    const [memberIdKick,setMemberIdKick] = useState()
    const [statusMemberMember,setStatusMember] = useState('')


    const [filteredGroupsBySearch,setFilteredGroupsBySearch] = useState([])
    useEffect(() => {
        if (!isAuthenticated)
            navigate('/login')
        async function fetchData() {
            await getGroups();
            await selectGroup(query.get('id'));
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (!selectedGroup)
            return;
        console.log(selectedGroup)
        const combinedGroups = [
            ...selectedGroup?.members.map(group => ({ ...group, isInvitation: false }))
        ];

        // Then, filter the combined groups based on the search query
        if (!searchQuery) {
            setFilteredGroupsBySearch(combinedGroups);
            return
        }
        const filteredGroups = combinedGroups.filter(member =>
            (member.firstName + " " + member.lastName).toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredGroupsBySearch(filteredGroups);
    }, [selectedGroup,searchQuery]);



    useEffect(()=>{
        if(selectedGroup?.members.length > 1 )
            setWindowShow('membersExist')
        else if(selectedGroup?.members.length === 1)
            setWindowShow('membersEmpty')
    },[groupId,newUser,selectedGroup])


    const memberStatusMeeting = (id) => {
        const accepted = selectedGroup?.members.find((itm)=>itm._id === id)
        const invited = selectedGroup?.invited.find((itm)=>itm._id === id)
        return accepted ? '' : invited ? '(Pending)' : ''
    }

    const isModerator = (memberId) => {
        // Check if the member ID is in the list of moderators
        return selectedGroup.moderators.some(mod => mod._id === memberId);
    }


    const deleteGroupAction = async ()=>{
        if (accept) {
            try {
                const result = await deleteGroup({id: selectedGroup._id})
                if (result) {
                    console.log(result)
                    navigate('/manage-groups')
                    console.log('sucsess delete')
                }
                console.log(result)
            } catch (e) {
                console.log(e)
            }
            setAccept(false)
        }
    }
    const kickMemberAction = async ()=>{
        if (accept) {
            try {
                const result = await removeMember({groupId:selectedGroup._id,memberId:memberIdKick})
                if (result) {
                    console.log('sucsess kick')
                }
                setMemberIdKick(null)
                console.log(result)
            } catch (e) {
                console.log(e)
            }
            setKickMember(false)
            setAccept(false)
        }
    }
    useEffect(()=>{
        if (kickMember)
            kickMemberAction()
        else
            deleteGroupAction()
    },[accept,kickMember])


    const renderContent = () => {
        switch (windowShow) {
            case 'membersExist':
                return (
                    <div className={'list-of-members-container'}>
                        <div className={'header-list'}>
                            <span className={'title-window'}>{statusMemberMember ? statusMemberMember : 'Group members'}</span>
                            <span className={'under-title-window'} style={{textTransform: 'unset'}}>Your Group Members</span>
                        </div>
                        <div className={'owner-card-in-members'}>
                            <UserProfilePicture userId={groupOwner._id} userFirstName={groupOwner.firstName} userLastName={groupOwner.lastName[0]} />

                            <div className={'owner-informations'}>
                                <div className={'owner-name'} style={{width:"fit-content",maxWidth:"unset"}}><span className={'text'}>{groupOwner?.firstName} {groupOwner?.lastName}</span>
                                    <div className={'admin-badge'}>
                                        <Icon name={'crown'} size={16} color={'#fff'}/>
                                        <span>Admin</span>
                                    </div>
                                </div>
                                <span className={'owner-email'} style={{maxWidth:"unset"}}>{groupOwner?.email}</span>
                            </div>

                        </div>
                        {filteredGroupsBySearch.length ? (
                            <div className={'list-members'}>
                                {filteredGroupsBySearch.map((member,idx)=>{

                                    if (member._id !== groupOwner._id)
                                        return member.isInvitation ? (
                                            <div key={`member-group-invited-${idx}`} className={'member-item-container'}>
                                                <UserProfilePicture userId={member._id} userFirstName={member.firstName} userLastName={member.lastName[0]} />
                                                <div className={'member-informations'}>
                                                    <div style={{display:"flex",gap:10}}>
                                                        <span className={'member-name'}>{member?.firstName} {member?.lastName}</span>
                                                        <span className={'status-member-meeting'}>{memberStatusMeeting(member._id)}</span>
                                                    </div>
                                                    <span className={'member-email'}>{member?.email}</span>
                                                </div>
                                                {groupOwner._id === user._id ? (
                                                    <div style={{display:"flex",gap: 10}}>
                                                        <ButtonComponent
                                                            ClassName={'quick-kick-user-button'}
                                                            iconName={'trash'}
                                                            bgOnHover={'transparent'}
                                                            buttonColor={'#F75F5F'}
                                                            IconColor={'#fff'}
                                                            IconSize={20}
                                                            IconColorHover={'#F75F5F'}
                                                            Text={'Kick'}
                                                            OnClick={async ()=>{
                                                                setKickMember(true)
                                                                setShow(true)
                                                                setMemberIdKick(member._id)
                                                                if (accept)
                                                                    kickMemberAction()
                                                            }}
                                                        />
                                                     {/*   <SelectRole
                                                            className={'selected'}
                                                            groupId={selectedGroup._id}


                                                            memberId={member._id}/>*/}

                                                    </div>
                                                ) : null
                                                    /* (
                                                    <SelectRole
                                                        noAdmin
                                                        className={'selected'}/>

                                                )*/}
                                            </div>
                                        ) : (
                                            <div key={`member-group-invited-${idx}`} className={'member-item-container'}>
                                                <UserProfilePicture userId={member._id} userFirstName={member.firstName} userLastName={member.lastName[0]} />

                                                <div className={'member-informations'}>
                                                    <div style={{display:"flex",gap:10}}>
                                                        <span className={'member-name'}>{member?.firstName} {member?.lastName}</span>
                                                        <span className={'status-member-meeting'}>{memberStatusMeeting(member._id)}</span>
                                                    </div>
                                                    <span className={'member-email'}>{member?.email}</span>
                                                </div>
                                                {groupOwner._id === user._id ? (
                                                    <div style={{display:"flex",gap: 10}}>
                                                        <ButtonComponent
                                                            ClassName={'quick-kick-user-button'}
                                                            iconName={'trash'}
                                                            bgOnHover={'transparent'}
                                                            buttonColor={'#F75F5F'}
                                                            IconColor={'#fff'}
                                                            IconSize={20}
                                                            IconColorHover={'#F75F5F'}
                                                            Text={'Kick'}
                                                            OnClick={async ()=>{
                                                                setKickMember(true)
                                                                setShow(true)
                                                                setMemberIdKick(member._id)
                                                                if (accept)
                                                                    kickMemberAction()
                                                            }}
                                                        />

                                                    </div>
                                                ) : null /*(
                                                    <SelectRole noAdmin className={'selected'}/>

                                                )*/
                                                }
                                            </div>

                                        )
                                })}
                            </div>
                        ) : (
                            <div style={{display:"flex",flexDirection:"column",gap:24}}>
                                <span className={'under-title-window'} style={{textTransform: 'unset',marginBottom:0}}>Your group doesn't have any members with this name. You can try to search the user with his email if you know it.</span>
                                <ButtonComponent
                                    style={{width: '100%'}}
                                    ClassName={'button-card'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#835FF7'}
                                    textColor={'#fff'}
                                    Text={'Add Members To This Group'}
                                    OnClick={()=>{
                                        navigate(`/manage-groups/my-group/invite?id=${selectedGroup._id}`)
                                    }}
                                />
                            </div>
                        )}

                    </div>
                );
            case 'membersEmpty':
                return (
                    <div className={'list-of-members-container'}>
                        <div className={'header-list'}>
                            <span className={'title-window'}>Group members</span>
                            <span className={'under-title-window'} style={{textTransform: 'unset'}}>Your Group Members</span>
                        </div>
                        <div className={'owner-card-in-members'}>
                            <UserProfilePicture userId={groupOwner._id} userFirstName={groupOwner.firstName} userLastName={groupOwner.lastName[0]} />

                            <div className={'owner-informations'}>
                                <div className={'owner-name'} style={{width:"fit-content",maxWidth:"unset"}}><span className={'text'}>{groupOwner?.firstName} {groupOwner?.lastName}</span>
                                    <div className={'admin-badge'}>
                                        <Icon name={'crown'} size={16} color={'#fff'}/>
                                        <span>Admin</span>
                                    </div>
                                </div>
                                <span className={'owner-email'} style={{maxWidth:"unset"}}>{groupOwner?.email}</span>
                            </div>

                        </div>

                        <div style={{display:"flex",flexDirection:"column",gap:24}}>
                            <span className={'under-title-window'} style={{textTransform: 'unset',marginBottom:0}}>Your meeting doesn't have any members, invite a member or a group to join your meeting.</span>
                            <ButtonComponent
                                style={{width: '100%'}}
                                ClassName={'button-card'}
                                bgOnHover={'transparent'}
                                buttonColor={'#835FF7'}
                                textColor={'#fff'}
                                Text={'Add Members To This Group'}
                                OnClick={()=>{
                                    navigate(`/manage-groups/my-group/invite?id=${selectedGroup._id}`)
                                }}
                            />
                        </div>
                    </div>
                );
            default:
                return <p>Another</p>;
        }
    };

    if (!selectedGroup || !groupOwner) {
        return <p>Loading...</p>;
    }
    return (
        <WindowLayout>
            <div className={'group-settings-container'}>
                <div className='back-button' style={{display: "flex", justifyContent: "space-between", flexDirection:isMobile ? 'column' : 'row', gap:isMobile ? '12px' : '0' }}>
                    <div className={'back-with-breadcrumbs'} style={{alignItems:isTablet ? 'flex-start': 'center'}}>
                        <div className='back-icon-container' style={{minWidth:52}} onClick={() => {
                            navigate(-1)
                        }}>
                            <BackIcon/>
                        </div>
                        <div className={'breadcrumbs-container'}>
                            <span>Manage groups</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span onClick={() => {
                                navigate('/manage-groups')
                            }}>My Groups</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span className={'active'}>{selectedGroup?.name}</span>
                        </div>

                    </div>
                    <div className={'global-button-group-settings'}>
                        <ButtonComponent
                            ClassName={'group-button-page'}
                            iconName={'empty-plus'}
                            style={{maxWidth:isMobile ? 'unset' : '',width:'100%'}}
                            bgOnHover={'transparent'}
                            buttonColor={'#42D3D8'}
                            IconColorHover={'#42D3D8'}
                            IconColor={'#fff'}
                            IconSize={20}
                            Text={!isTablet || isMobile ? 'Create A New Group' : ''}
                            OnClick={() => {
                                navigate('/manage-groups/create-group')
                            }}
                        />
                    </div>
                </div>
                <div className={'header-container'} style={{flexDirection: "row", alignItems: "center",flexWrap:"wrap"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: 12, flex: 1}}>
                    <span className={'title-window with-edit'}>
                        {selectedGroup?.name}
                        {groupOwner._id === user._id ? (
                            <Icon
                                onClick={()=>{navigate(`/manage-groups/edit-group?id=${selectedGroup._id}`)}}
                                className={'edit-icon'}
                                name={'edit'}
                                size={22}
                                withStroke
                                color={'#090721'}/>
                        ) : null}

                    </span>
                        <span
                            className={'under-title-window'}>{selectedGroup?.description ?? 'Lets gather some ideas together'}</span>
                    </div>
                    <div style={{maxWidth: '565px',width:isMobile ? '100%' :'50%'}}>
                        <Search placeholder={'Search people by email'} value={searchQuery} setSearchQuery={(e) => {
                            setSearchQuery(e)
                        }}/>
                    </div>

                </div>
                <div className={'content-group-details-container'}>
                    <div className={'left-container'} style={{gap:30}}>
                        <UserProfilePicture groupImage width={'100%'} maxWidth={652} minHeight={450} height={'100%'} groupImageURL={selectedGroup.picture} />
                        <div style={{display:"flex",flexDirection:"column",gap:24}}>
                            {(selectedGroup.members.length > 1 || selectedGroup?.invited?.length > 0) && user._id === groupOwner._id ? (
                                <ButtonComponent
                                    style={{width: '100%'}}
                                    ClassName={'button-card'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#835FF7'}
                                    textColor={'#fff'}
                                    Text={'Add Members To This Group'}
                                    OnClick={()=>{
                                        navigate(`/manage-groups/my-group/invite?id=${selectedGroup._id}`)
                                    }}
                                />
                            ) : null}

                            {user._id === groupOwner._id ? (
                                <ButtonComponent
                                    style={{width: '100%'}}
                                    ClassName={'button-card'}
                                    iconName={'trash'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#F75F5F'}
                                    IconColor={'#fff'}
                                    IconSize={20}
                                    IconColorHover={'#F75F5F'}
                                    Text={'Delete Group'}
                                    OnClick={async ()=>{
                                        setShow(true)
                                        if (accept) {
                                            deleteGroupAction()
                                        }
                                    }}
                                />
                            ) : (
                                <ButtonComponent
                                    style={{width: '100%'}}
                                    ClassName={'button-card'}
                                    iconName={'trash'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#F75F5F'}
                                    IconColor={'#fff'}
                                    IconSize={20}
                                    IconColorHover={'#F75F5F'}
                                    Text={'Leave Group'}
                                    OnClick={async ()=>{
                                        try {
                                            const result = await declineGroupInvitation({groupId:selectedGroup._id})
                                            if (result) {
                                                console.log(result)
                                                navigate('/manage-groups')
                                                console.log('sucsess leave')
                                            }
                                            console.log(result)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                />
                            )}
                        </div>

                    </div>
                    <div className={'right-container'} style={{justifyContent:"flex-start"}}>
                        {renderContent()}
                    </div>

                </div>
                <QuickListMembers selectedGroup={selectedGroup} membersList={selectedGroup?.members}/>
            </div>
        </WindowLayout>
    )
}