import {create, createStore} from "zustand"
import {useGroups} from "./groups";


export const useMeetings = create((set, get) => ({
    meetings: [],
    setMeetings: (meetings)=> {
        set({meetings: meetings})
    },
    meetingsInvitations: [],
    selectedMeeting: null,
    meetingOwner: null,
    setSpecificMeeting(selectedMeeting){
        console.log(selectedMeeting,'selectedMeeting')
        set({selectedMeeting:selectedMeeting})
    },
    getMeetings: async () => {
        try {
            const response = await window.$comms.request("meetings")
            console.log(response,'response get meetings')
            set({meetingsInvitations:response.invitedToMeetings})
            set({meetings: response.meetings })
            return true
        } catch (e) {
            return false
        }
    },
    meetingStart: async ({meetingId}) => {
        const { meetings } = get()
        try {
            const response = await window.$comms.request("meetingStart",{meetingId})
            console.log(response,'response meetings start')
            if (response.success)


            return response
        } catch (e) {
            return e
        }
    },
    getSpecificMeeting(id){
        const { meetings } = get()
        const selectedMeeting = meetings.find(g => g._id === id);
        if (selectedMeeting) {
            const owner = selectedMeeting.owner;
            const meetingOwner = selectedMeeting.invited.find(invite => invite._id === owner) ;
            set({ selectedMeeting: selectedMeeting  });
            set({meetingOwner:meetingOwner})
        }
    },
    inviteGroupToMeeting: async ({meetingId,groupId}) => {
        try {
            const response = await window.$comms.request("meetingInviteGroup", {meetingId,groupId})
            console.log(response,'group invited to meeting')
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },
    addMemberMeeting: async ({meetingId, memberId}) => {
        try {
            const response = await window.$comms.request("meetingAddUser", {memberId,meetingId})
            console.log(response,'user added to meeting')
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },
    addModeratorMeeting: async ({memberId, meetingId,canKick}) => {
        try {
            const response = await window.$comms.request("meetingAddModerator", {memberId,meetingId,canKick})
            console.log(response,'user moderaotr  added to meeting')
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },
    removeModeratorMeeting: async ({memberId, meetingId}) => {
        try {
            //const {groups} = get()
            const response = await window.$comms.request("meetingRemoveModerator", {memberId, meetingId})
            console.log(response,'response in method remove moderator')
            return response
        } catch (e) {
            return e
        }
    },
    deleteMeeting: async ({meetingId}) => {
        try {
            const { meetings } = get();
            const response = await window.$comms.request("meetingDelete", { meetingId });

            if (response.success) {
                // Remove the meeting with the specified meetingId from the meetings array
                const updatedMeetings = meetings.filter(meeting => meeting._id !== meetingId);
                set({ meetings: updatedMeetings });
            }
            console.log(response,'meeting deleted')
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },
    deleteMeetingPicture: async ({meetingId}) => {
        try {
            console.log('ajunge')
            console.log(meetingId)
            const response = await window.$comms.request("meetingDeletePicture",{meetingId})
            console.log(response)
            return response
        } catch (e) {
            console.log(e)
            return e
        }
    },
    removeMemberMeeting: async ({meetingId, memberId}) => {
        try {
            const { selectedMeeting } = get()
            const response = await window.$comms.request("meetingRemoveUser", {memberId,meetingId})

            if (response.success) {
                const updatedInvited = selectedMeeting.invited.filter(invite => invite._id !== memberId);
                const updatedMeeting = { ...selectedMeeting, invited: updatedInvited };
                set({ selectedMeeting: updatedMeeting });
            }
            console.log(response,'user removed from meeting')
            return true
        } catch (e) {
            console.log(e)
            return false
        }
    },
    createMeeting: async ({date, name,description}) => {
        try {
            const {selectedGroup, groups} = get()

            const response = await window.$comms.request("createMeeting", {date: date, name: name,description:description, id: selectedGroup._id})
            console.log(response,'response in request')
            if(response && response.group) {
                const finalGroups = [
                    response.group,
                    ...groups.filter(group=>{return group._id !== response.group._id})
                ]
                set({
                    groups: finalGroups,
                    selectedGroup: response.group
                })
            }
            return true
        } catch (e) {
            return false
        }
    },
    updateMeeting: async ({meetingId,name,description,date,file}) => {
        console.log(date)
        try {
            const response = await window.$comms.request("modifyMeeting", {meetingId,name,description,date,file})
            console.log(response,'response in method')
            return true
        } catch (e) {

            console.log(e,'in uptate meeting')
            return false
        }
    },
    acceptOrDeclineMeetingInvitation: async ({memberId,meetingId,accept}) => {
        try {
            const response = await window.$comms.request("meetingAcceptOrDecline",{memberId,meetingId,accept})
            console.log(response,'response in method')
            const { meetings,meetingsInvitations } = get()
            if (response.success) {
                if (accept) {
                    console.log('ajunge aici')
                    // Move meeting from invitations to meetings
                    const selectedMeeting = meetingsInvitations.find(meeting => meeting._id === meetingId);
                    if (selectedMeeting) {
                        const updatedMeetings = [...meetings, selectedMeeting];
                        const updatedInvitations = meetingsInvitations.filter(meeting => meeting._id !== meetingId);
                        console.log(updatedMeetings,'updatedMeetings')
                        console.log(updatedInvitations,'updatedInvitations')
                        set({ meetings: updatedMeetings, meetingsInvitations: updatedInvitations });
                    }
                } else {
                    // Remove meeting from invitations
                    const updatedInvitations = meetingsInvitations.filter(meeting => meeting._id !== meetingId);
                    set({ meetingsInvitations: updatedInvitations });
                }
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log('intra in cacth')
            console.log(e)
            return false
        }
    },
}))
window.$comms.addListener("meetingStarted", (meeting) => {
    const meetings = useMeetings.getState().meetings
    const newMeetings = meetings.map(m => m._id === meeting._id ? meeting : m);
    useMeetings.setState({meetings: newMeetings})
});
window.$comms.addListener("meetingEnded", (meeting) => {
    const meetings = useMeetings.getState().meetings
    const newMeetings = meetings.map(m => m._id === meeting._id ? meeting : m);
    useMeetings.setState({meetings: newMeetings})
});
window.$comms.addListener("meetingDeleted", (meeting) => {
    const meetings = useMeetings.getState().meetings
    const meetingsInvitations = useMeetings.getState().meetingsInvitations
    const remainingMeetings = meetings.filter(g => g._id !== meeting);
    const remainingMeetingsInvitations = meetingsInvitations.filter(g => g._id !== meeting);
    useMeetings.setState({ meetings: remainingMeetings,meetingsInvitations:remainingMeetingsInvitations });
});
window.$comms.addListener("meetingInvitedToMeeting", (meeting) => {
    const meetings = useMeetings.getState().meetingsInvitations
    meetings.push(meeting)
    useMeetings.setState({meetingsInvitations: meetings})
});
window.$comms.addListener("meetingUserAcceptedMeetingInvitation", (meeting) => {
    // Retrieve the current state
    const meetings = useMeetings.getState().meetings;
    const meetingInvitations = useMeetings.getState().meetingsInvitations;

    console.log(meeting, 'accepted meeting');

    // Check if the meeting already exists in the meetings array
    const existingMeetingIndex = meetings.findIndex(m => m._id === meeting._id);

    // If it exists, update the meeting information
    if (existingMeetingIndex !== -1) {
        meetings[existingMeetingIndex] = meeting;
    } else {
        // Otherwise, add it to the meetings array
        meetings.push(meeting);
    }

    // Remove the meeting from invitations
    const remainingInvitations = meetingInvitations.filter(m => m._id !== meeting._id);

    // Update the state with the new lists
    useMeetings.setState({
        meetings: [...meetings], // Use spread to trigger state change
        meetingsInvitations: remainingInvitations
    });
});

window.$comms.addListener("meetingUserDeclinedMeetingInvitation", (meeting) => {
    // Retrieve the current state
    const meetings = useMeetings.getState().meetings;
    const meetingInvitations = useMeetings.getState().meetingsInvitations;

    console.log(meeting, 'declined meeting');

    // Find the index of the meeting in the meetings array
    const existingMeetingIndex = meetings.findIndex(m => m._id === meeting._id);

    // If it exists, update the meeting information
    if (existingMeetingIndex !== -1) {
        meetings[existingMeetingIndex] = meeting;
    }

    // Remove the meeting from invitations
    const remainingInvitations = meetingInvitations.filter(m => m._id !== meeting._id);

    // Update the state with the new lists
    useMeetings.setState({
        meetings: [...meetings], // Spread operator to update the meetings array
        meetingsInvitations: remainingInvitations
    });
});