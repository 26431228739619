import Icon from "components/icon/icon"
import "./maintenance.scss"
import Logo from "components/logo"
import Countdown from "../../countdown";
import {MaintenanceAnimation} from "../../animations-svgs/maintenance-animation";
import WindowLayout from "../../windowLayout/window-layout";
const Maintenance = () => {
  return (
      <WindowLayout>
          <div className="w-100 flex-row d-flex dashboad-container maintenanceContainer">
              <div className='dashboard-image-container'>
                  <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container ">
                  <div className='dashboard-content'>
                      <MaintenanceAnimation />
                      <h2 className='dashboard-title' style={{marginBottom: 0}}>Under Maintenance</h2>
                      <div className='dashboard-tabs-buttons-container'>
                          <span className='dashboard-login-title' style={{marginBottom: 16}}>We're working on improving your overall experience.</span>
                      </div>
                      <p className="verify-email-text">Please check back shortly.</p>

                  </div>
              </div>
          </div>
      </WindowLayout>




  )
}

export default Maintenance