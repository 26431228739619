import React, {useEffect, useState} from "react";
import {useAuthentication} from "../../../../stores/authentication";
import {Manage} from "../../../../stores/manage";
import {useGroups} from "../../../../stores/groups";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import Icon from "../../../icon/icon";
import {ButtonComponent} from "../../../button-component/button-component";
import {Search} from "../../../search-bar/search";
import {BigGroupCard} from "./components/big-group-card/big-group-card";
import Loader from "../../../loader";
import {useNavigate} from "react-router-dom";
import WindowLayout from "../../../windowLayout/window-layout";
import {MediaScreen} from "../../Rooms/chatroom/encrypted/MediaScreen";


export const MyGroups = () => {
    const { isAuthenticated,user } = useAuthentication()
    const { getGroups,groups,invitedGroups,deleteGroup } = useGroups()
    const navigate = useNavigate()
    const [searchQuery,setSearchQuery]= useState('')
    const [displayLimit, setDisplayLimit] = useState(2);
    const {isMobile,isTablet} = MediaScreen()

    const [filteredGroupsBySearch,setFilteredGroupsBySearch] = useState([])
    useEffect(() => {
        if(!isAuthenticated) {
            navigate("/login")
        }
        getGroups()
    }, [isAuthenticated]);




    useEffect(() => {
        const combinedGroups = [
            ...invitedGroups.map(group => ({ ...group, isInvitation: true })),
            ...groups.map(group => ({ ...group, isInvitation: false }))
        ];
        console.log(combinedGroups,'combinedGroups')
        console.log(invitedGroups,'invitedGroups')

        // Then, filter the combined groups based on the search query
        if (!searchQuery) {
            setFilteredGroupsBySearch(combinedGroups);
            return
        }        const filteredGroups = combinedGroups.filter(group =>
            group.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        setFilteredGroupsBySearch(filteredGroups);
    }, [invitedGroups,groups,searchQuery]);
    const handleLoadMore = () => {
        setDisplayLimit(displayLimit + 4);
    };
    console.log(invitedGroups,'invitedGroups')

    console.log(filteredGroupsBySearch,'filteredGroupsBySearch')
        return(
            <WindowLayout>
                <div className={'my-groups-container'}>
                    <div className='back-button' style={{display:"flex", justifyContent:"space-between", flexDirection:isMobile ? 'column' : 'row', gap:isMobile ? '12px' : '0' }}>
                        <div className={'back-with-breadcrumbs'}>
                            <div className='back-icon-container' style={{minWidth:52}} onClick={()=>{
                                navigate(-1)
                            }}>
                                <BackIcon/>
                            </div>
                            <div className={'breadcrumbs-container'}>
                                <span>Manage groups</span>
                                <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                                <span className={'active'}>My Groups</span>
                            </div>
                        </div>
                        <div className={'global-button-group-settings'}>
                            <ButtonComponent
                                ClassName={'group-button-page'}
                                iconName={'empty-plus'}
                                bgOnHover={'transparent'}
                                style={{maxWidth:isMobile ? 'unset' : '',width:'100%'}}
                                buttonColor={'#42D3D8'}
                                IconColorHover={'#42D3D8'}
                                IconColor={'#fff'}
                                IconSize={20}
                                Text={!isTablet || isMobile ? 'Create A New Meeting' : ''}
                                OnClick={()=>{
                                    navigate('/manage-groups/create-group')
                                }}
                            />
                        </div>
                    </div>
                    {groups?.length || invitedGroups.length ? (
                        <>
                            <div className={'header-container'}
                                 style={{flexDirection: "row", alignItems: "center",flexWrap:"wrap"}}>
                                <div style={{display: "flex", flexDirection: "column", gap: 12, flex: 1}}>
                                    <span className={'title-window'}>My Groups</span>
                                    <span className={'under-title-window'}>Create or manage groups</span>
                                </div>
                                <div style={{maxWidth: '565px', width:isMobile ? '100%' :'50%'}}>
                                    <Search placeholder={'Search Group'} setSearchQuery={(e) => {
                                        setSearchQuery(e)
                                    }}/>
                                </div>

                            </div>
                            <div className={'groups-list-container'}>
                                    <span
                                        className={'all-groups-header'}>All Groups ({filteredGroupsBySearch?.length})</span>
                                {filteredGroupsBySearch.length > 0 ? (
                                    <div className={'list-of-groups'}>
                                        {filteredGroupsBySearch.slice(0, displayLimit).map((item, index) => {
                                            return item.isInvitation ? (
                                                <BigGroupCard invitation key={`invited-group-${index}`} item={item} />
                                            ) : (
                                                <BigGroupCard key={`group-${index}`} item={item} />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className={'empty-meetings'}>
                                        <img src='/images/empty-meetings.png' style={{minHeight:309}} alt={'empty-meetings'}/>
                                        <div className={'what-to-do'}>
                                            <span className={'header-message'}>No Groups Found</span>
                                            <span className={'subtext-message'}>You are not part of any group with this name.</span>
                                        </div>
                                    </div>
                                )}


                                {displayLimit < filteredGroupsBySearch.length && (
                                    <ButtonComponent
                                        ClassName={'load-more-groups'}
                                        iconName={'long-right-arrow'}
                                        bgOnHover={'transparent'}
                                        buttonColor={'#2B2A48'}
                                        IconColor={'#fff'}
                                        IconSize={24}
                                        iconPositionRight
                                        IconColorHover={'#2B2A48'}
                                        Text={'Load More'}
                                        OnClick={() => {
                                            handleLoadMore()
                                        }}
                                    />
                                )}

                            </div>
                        </>
                    ) : (
                        <div className={'empty-meetings'}>
                            <img src='/images/empty-meetings.png' style={{minHeight:309}} alt={'empty-meetings'}/>
                            <div className={'what-to-do'}>
                                <span className={'header-message'}>No Active Groups</span>
                                <span className={'subtext-message'}>You are not part of any groups yet. Start by creating a new group to collaborate and share ideas!</span>
                            </div>
                            <ButtonComponent
                                ClassName={'create-meeting-button-page'}
                                iconName={'empty-plus'}
                                bgOnHover={'transparent'}
                                buttonColor={'#42D3D8'}
                                IconColorHover={'#42D3D8'}
                                IconColor={'#fff'}
                                IconSize={20}
                                Text={'Create A New Group'}
                                OnClick={() => {
                                    navigate('/manage-groups/create-group')
                                }}
                            />
                        </div>
                    )}
                </div>
            </WindowLayout>
        )
}