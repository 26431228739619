import React, {useEffect, useState} from "react";
import {Manage} from "../../../../../../stores/manage";
import {useAuthentication} from "../../../../../../stores/authentication";
import {useNavigate} from "react-router-dom";
import {useGroups} from "../../../../../../stores/groups";
import Icon from "../../../../../icon/icon";
import {ButtonComponent} from "../../../../../button-component/button-component";
import './quick-list-members.scss'
import {UserProfilePicture} from "../../../../../user-profile-picture";
export const QuickListMembers = ({membersList,selectedGroup}) => {
    const [displayLimit, setDisplayLimit] = useState(3);

    const handleLoadMore = () => {
        setDisplayLimit(displayLimit + 6);
    };

    if(membersList)
        return(
            <div className={'groups-list-quick-view-container'}>
                <div className={'members-header-quick-list'}>
                    <span className={'members-group-title'}>{selectedGroup.name} Members</span>
                    <span className={'subtext'}>Your group members</span>
                </div>

                <div className={'quick-list-container'}>
                    {membersList?.slice(0, displayLimit).map((item,index)=>{
                        return(
                            <div key={`quick-group-${index}`} className={'quick-group-item-container'}>

                                <UserProfilePicture userId={item._id} userFirstName={item.firstName} userLastName={item.lastName[0]} />

                                <div className={'quick-group-item-information'}>
                                    <span className={'item-title'}>{item.firstName} {item.lastName}</span>
                                    <p className={'item-description'}>{item.email }</p>
                                </div>

                            </div>
                        )
                    })}
                </div>
                {displayLimit < membersList.length && (
                    <ButtonComponent
                        ClassName={'quick-load-more-button'}
                        iconName={'long-right-arrow'}
                        bgOnHover={'transparent'}
                        buttonColor={'#2B2A48'}
                        IconColor={'#fff'}
                        IconSize={24}
                        iconPositionRight
                        IconColorHover={'#2B2A48'}
                        Text={'Load More'}
                        OnClick={()=>{
                            handleLoadMore()
                        }}
                    />
                )}

            </div>
        )
    else
        return null

}