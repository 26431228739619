import Icon from "../icon/icon";
import {useState} from "react";

export const ButtonComponent = ({style,iconPositionRight=false,number,withStroke,buttonColor='#42D3D8',bgOnHover ,textColor='#fff',ClassName,OnClick,Disabled,ButtonType='submit',Text='',iconName,IconSize,IconColor,IconColorHover,}) => {
    const [buttonStyle,setButtonStyle] = useState({
        bgCol: buttonColor,
        txtCol:textColor,
        iconCol: IconColor,
        borderCol: 'transparent'
    })
    const [hover,setHover] = useState(false)

    return (
        <button
            onMouseEnter={()=>{
                setButtonStyle({...buttonStyle,
                    bgCol: bgOnHover ? bgOnHover : 'transparent',
                    txtCol: buttonColor,
                    iconCol: buttonColor,
                    borderCol: buttonColor
                })
                setHover(true)
            }}
            onMouseLeave={()=>{
                setButtonStyle({...buttonStyle,
                    bgCol: buttonColor,
                    txtCol:textColor,
                    iconCol: IconColor,
                    borderCol: 'transparent',
                })
                setHover(false)
            }}
            type={ButtonType}
            className={`buttonComponent ${ClassName}`}
            onClick={(e)=> {
                console.log('aici')
                e.preventDefault()
                OnClick(e)
            }}
            disabled={Disabled}
            style={{
                height:52,
                color: buttonStyle.txtCol,
                backgroundColor: buttonStyle.bgCol,
                border: `1px solid ${buttonStyle.borderCol}`,
                opacity: Disabled ? 0.3 : 1,
                cursor: Disabled ? 'not-allowed' : 'pointer',
                fontSize: 16,
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 300,
                display:"flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: iconPositionRight ? 'row-reverse' : 'row',
                ...style
            }}
        >
            {iconName &&
                <Icon onClick={OnClick} withStroke={withStroke} name={iconName} isParentHovered={hover} size={IconSize} color={IconColor} colorHover={IconColorHover}/>
            }
           {Text}
            {number ? (
                <span
                    style={{
                        backgroundColor:hover ? buttonStyle.bgCol : buttonStyle.txtCol,
                        color:hover ? buttonStyle.txtCol : buttonStyle.bgCol
                    }}
                    className={'with-number'}>{number}</span>
            ) : null}
        </button>
    )
}