import {useMediaQuery} from "react-responsive";


export const MediaScreen = (customQuery='100px') => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1200px)'
    })
    const isSmallLaptopScreen = useMediaQuery({
        query: '(max-width: 1200px)'
    })
    const isTablet = useMediaQuery({ query: '(max-width: 900px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 490px)' })
    const isCustomQuery = useMediaQuery({ query: `(max-width: ${customQuery})` })
    const is981px = useMediaQuery({ query: '(max-width: 981px)' })
    const is950px = useMediaQuery({ query: '(max-width: 950px)' })
    const is923px = useMediaQuery({ query: '(max-width: 923px)' })
    const is861px = useMediaQuery({ query: '(max-width: 861px)' })
    const is795px = useMediaQuery({ query: '(max-width: 795px)' })
    const is735px = useMediaQuery({ query: '(max-width: 735px)' })
    const is669px = useMediaQuery({ query: '(max-width: 669px)' })
    const is607px = useMediaQuery({ query: '(max-width: 607px)' })
    const is543px = useMediaQuery({ query: '(max-width: 543px)' })
    const is490px = useMediaQuery({ query: '(max-width: 490px)' })
    return {
        isDesktopOrLaptop: isDesktopOrLaptop,
        isSmallLaptopScreen: isSmallLaptopScreen,
        isTablet: isTablet,
        isMobile: isMobile,
        isCustomQuery: isCustomQuery,
        is981px: is981px,
        is923px: is923px,
        is861px: is861px,
        is795px: is795px,
        is735px: is735px,
        is669px: is669px,
        is950px: is950px,
        is607px: is607px,
        is543px: is543px,
        is490px: is490px
    }
}