import React, {useState} from "react";
import './bigMeetingCard.scss'
import Icon from "../../../../../icon/icon";
import {useAuthentication} from "../../../../../../stores/authentication";
import {ButtonComponent} from "../../../../../button-component/button-component";
import {Manage} from "../../../../../../stores/manage";
import {useNavigate} from "react-router-dom";
import {useMeetings} from "../../../../../../stores/meetings";
import {RoundedIcons} from "../../../../../roundedIcons/rounded-icons";
import {TimeLeft} from "../time-left";
import {MeetingStartedButton} from "./MeetingStartedButton";
export const BigMeetingCard = ({item,index,invitation}) => {
    const {setSpecificMeeting,acceptOrDeclineMeetingInvitation,getMeetings,meetingStart} = useMeetings()
    const {user} = useAuthentication()
    const navigate = useNavigate()

    const responseToMeeting = async (status) => {
        console.log('aici')
        try {
            const response = await acceptOrDeclineMeetingInvitation({
                memberId:user._id,
                meetingId:item._id,
                accept:status
            })
            console.log(response,'response')
        } catch (e) {
            console.log(e)
        }
    }

    const [profilePicture,setProfilePicture] = useState(`https://gsvoiceapp.fra1.digitaloceanspaces.com/mts/${item._id}.png`)
    function handleImageError() {
        setProfilePicture('/images/group-image-default.png') // Change the image source to the fallback URL
    }
    function isModerator (memberId) {
        return  !!item.moderators.find((moderator) => memberId === moderator._id)
    }

    return(
        <div className={'big-meeting-card-container'}>
            <div className={'left-container'}>
                <img
                    src={profilePicture}
                    onEnded={()=>
                        setProfilePicture(`https://gsvoiceapp.fra1.digitaloceanspaces.com/mts/${item._id}.png`)}
                    onError={()=>handleImageError()}
                    style={{borderRadius:4,maxWidth: 400, width: '100%',minHeight:250,objectFit:"cover", height: '100%',backgroundColor:'#EAE9EC'}}
                    alt='meeting-image'
                    width={184}
                    height={184}/>

                <div className={'details-meeting'}>
                    {typeof item.date === 'string' ? (
                        <p>din vechi</p>
                    ) : (
                        <>
                            <span className={'header-details'}>Time And Date</span>
                            <div className={'date-information'}>
                                <span className={'information-label'}>Date:</span>
                                <span
                                    className={'information-value'}>{item.date.meetingDate.day} {item.date.meetingDate.month} {item.date.meetingDate.year}</span>
                            </div>
                            <div className={'information-time'}>
                                <span className={'information-label'}>Hour:</span>
                                <span
                                    className={'information-value'}>{item.date.meetingTime.hour}:{item.date.meetingTime.minute} {item.date.meetingTime.amPm} {item.date.meetingTime.timeZone}:00</span>

                            </div>
                        </>
                    )}

                </div>
            </div>
            <div className={'right-container'}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <span className={'group-name'}>{item.name}</span>
                    <span className={'meeting-description'}>{item.description}</span>
                    <RoundedIcons members={item.accepted} />
                    <div className={'invites-information'}>
                        <Icon name={'users-crown'} size={24} color={'#1D2939'}/>
                        <span className={'people-invites'}>{item.accepted.length} People Confirmed</span>
                    </div>
                    <div className={'invites-information'}>
                        <Icon name={'paper-plane'} size={24} color={'#1D2939'}/>
                        <span className={'people-invites'}>{item.invited.length} Invite Sent</span>
                    </div>
                </div>
                {invitation ? (
                    <div className={'actions-members'}>
                        <div className={'participate-actions'}>
                            <ButtonComponent
                                ClassName={'button-participate-actions'}
                                iconName={'check'}
                                bgOnHover={'#fff'}
                                buttonColor={'#42D875'}
                                textColor={'#fff'}
                                IconColorHover={'#42D875'}
                                IconColor={'#fff'}
                                IconSize={20}
                                Text={'Join'}
                                OnClick={()=>{
                                    responseToMeeting(true)
                                }}
                            />
                            <ButtonComponent
                                ClassName={'button-participate-actions'}
                                iconName={'times'}
                                IconColorHover={'#F75F5F'}
                                IconColor={'#fff'}
                                IconSize={20}
                                bgOnHover={'#fff'}
                                buttonColor={'#F75F5F'}
                                textColor={'#fff'}
                                Text={'Decline'}
                                OnClick={ async ()=>{
                                    responseToMeeting(false)
                                }}
                            />
                        </div>
                        {item.started ? (
                            <MeetingStartedButton roomId={item.link}/>
                        ) : (
                            <TimeLeft meetingTime={item.date.meetingTime} meetingDate={item.date.meetingDate}/>
                        )}

                    </div>
                ) : user._id === item.owner || isModerator(user._id) ? (
                    <div className={'actions-owner'}>
                        <ButtonComponent
                            ClassName={'button-card'}
                            iconName={'settings'}
                            bgOnHover={'#1D2939'}
                            buttonColor={'#fff'}
                            textColor={'#1D2939'}
                            IconColorHover={'#fff'}
                            IconColor={'#1D2939'}
                            IconSize={20}
                            Text={'Manage Meeting'}
                            OnClick={()=>{
                                setSpecificMeeting(item)
                                navigate(`/manage-meetings/my-meeting?id=${item._id}`)
                            }}
                        />
                        <ButtonComponent
                            ClassName={'button-card'}
                            bgOnHover={'transparent'}
                            buttonColor={'#835FF7'}
                            textColor={'#fff'}
                            Text={'Add Members To This Meeting'}
                            OnClick={()=>{
                                setSpecificMeeting(item)
                                navigate(`/manage-meetings/my-meeting/invite?id=${item._id}`)
                            }}
                        />
                        {item.ended ? (
                            <ButtonComponent
                                style={{width:'100%'}}
                                bgOnHover={'transparent'}
                                buttonColor={'rgb(247, 95, 95)'}
                                Text={'Meeting Ended'}
                                OnClick={()=>{}}
                            />
                        ) : item.started ? (
                            <MeetingStartedButton roomId={item.link}/>
                        ) : (
                            <ButtonComponent
                                style={{width:'100%'}}
                                bgOnHover={'transparent'}
                                buttonColor={'#42D3D8'}
                                Text={'Start Meeting'}
                                OnClick={async ()=>{
                                    try {
                                        const result = await meetingStart({meetingId:item._id})
                                        console.log(result)
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }}
                            />
                        )}

                    </div>
                ) : (
                    <div className={'actions-members'}>
                        <ButtonComponent
                            ClassName={'button-card'}
                            bgOnHover={'transparent'}
                            buttonColor={'#835FF7'}
                            textColor={'#fff'}
                            Text={'Meeting Members'}
                            OnClick={()=>{
                                setSpecificMeeting(item)
                                navigate(`/manage-meetings/my-meeting?id=${item._id}`)
                            }}
                        />
                        {item.ended ? (
                        <ButtonComponent
                            style={{width:'100%'}}
                            bgOnHover={'transparent'}
                            buttonColor={'rgb(247, 95, 95)'}
                            Text={'Meeting Ended'}
                            OnClick={()=>{}}
                        />
                            ): item.started ? (
                            <MeetingStartedButton roomId={item.link}/>
                        ) : (
                            <TimeLeft meetingTime={item.date.meetingTime} meetingDate={item.date.meetingDate}/>
                        )}

                    </div>
                )}
            </div>
        </div>
    )
}