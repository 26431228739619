import Icon from "../../../../icon/icon";
import React, {useEffect, useRef, useState} from "react";
import {MediaScreen} from "../encrypted/MediaScreen";
import DevicesModal from "../encrypted/DevicesModal";
import CopyLink from "../../../../windowLayout/title-bar/room-controls/copy-link";
import {LinkSendEmail} from "../../../../windowLayout/title-bar/room-controls/link-send-email";
import {logActivity} from "../../../../../stores/log-activity";
import {UserProfilePicture} from "../../../../user-profile-picture";
import {CloseIcon} from "../SVGComponents/closeIcon";

export const ToolBar = ({
    desktop,
    emptyRoom ,
    hideToolBar,
    whiteBoard,
    session,
    subscribedStreams,
    localStream,
    setOptions,
    contacts,
    clearRoom,
    backgroundImage,
    setBackgroundImage,
    noiseCancelling,
    setNoiseCancelling,
    videoEffect,
    setVideoEffect,
    conversation,
    current,
    displayName,
    kick,
    changeCamera,
    changeMicrophone,
    audioDevices,
    videoDevices,
    userMediaDevices,
    muted,
    toggleMute,
    sharing,
    toggleScreenSharing,
    videoMuted,
    toggleVideoMute,
    toggleDeafened,
    shareStream,
    processedStream,
    isMovingUp,chatroomRef,
    isFullscreen,
    toggleWhiteBoard,
    toggleSettings,
    toggleAddPeopleFullActive,
    toggleVideoActive,
    toggleMicActive,
    containerRef,
    toggleShowSettingsModalEmpty,
    actionSettingsFullActive,
    actionAddPeopleFullActive,
    actionMicFullActive,
    actionVideoFullActive,
    displayChatroom,
    setDisplayChatroom,
    setShowMessagesFullActive,
    showMessagesFullActive,
    setAddPeopleRightFullActive,
    addPeopleRightFullActive,
    setShowGuests,
    setVideoFullActive,
    videoFullActive,
    setAddPeopleFullActive,
    addPeopleFullActive,
    setMicFullActive,
    micFullActive,
    setSettingsFullActive,
    settingsFullActive,
    showWhiteBoard,
    setShowWhiteBoard



    }) => {
    const [ disableMuted, setDisableMuted ] = useState(false)
    const [invitePeopleEmptyRoom, setInvitePeopleEmptyRoom] = useState(emptyRoom)
    const {is981px,is923px,is861px,is795px,is735px,is669px,is607px,is543px,is490px} = MediaScreen()
    const [inviteEmail, setInviteEmail] = useState("")
    const [alreadySent, setAlreadySent] = useState([])
    const validEmail = inviteEmail && inviteEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
    const [hoverMic,setHoverMic] = useState(false)
    const [hoverVid,setHoverVid] = useState(false)
    const [hoverPeople,setHoverPeople] = useState(false)
    const [hoverShareScreen,setHoverShareScreen] = useState(false)
    const [hoverChat,setHoverChat] = useState(false)
    const [hoverSettings,setHoverSettings] = useState(false)
    const [hoverFullScreen,setHoverFullScreen] = useState(false)
    const [hoverCanvas,setHoverCanvas] = useState(false)
    const { addItem,lastMembersInvited } = logActivity()
    const [currentCamera,setCurrentCamera] = useState(null)
    const [currentMicrophone,setCurrentMicrophone] = useState(null)

    const handleCloseContainer = () => {
        setMicFullActive(false)
        setVideoFullActive(false)
        setAddPeopleFullActive(false)
        setSettingsFullActive(false)
    };

    const handleContainerClick = (event) => {
        if (containerRef.current && containerRef.current === event.target) {
            handleCloseContainer();
        }
    };



    const sendMailInvitation = (valueEmail) => {
        if((validEmail && inviteEmail && inviteEmail.length > 4) || valueEmail ) {
            const sendEmailTo = valueEmail ?? inviteEmail
            addItem({
                label:'Email',
                value: sendEmailTo
            })
            if(!alreadySent.includes(sendEmailTo)) {
                window.$comms.request("mailInvite", { name: current.name, email: sendEmailTo, link: window.location.href })
                setAlreadySent([...alreadySent, sendEmailTo])
                setTimeout(()=>{
                    setInviteEmail("")
                }, 3000)
            } else {
                setTimeout(()=>{
                    setInviteEmail("")
                }, 3000)
            }
        }
    }

     const toggleFullscreen = () => {
        if(chatroomRef && chatroomRef.current) {
            if(!isFullscreen) {
                chatroomRef.current.requestFullscreen()
            } else {
                document.exitFullscreen()
            }
        }
    }

    const findUsedCamera = () => {
        if (currentCamera === null) {
            navigator.mediaDevices.enumerateDevices()
                .then(function (devices) {
                    const videoDevices = devices.filter(device => device.kind === 'videoinput');
                    if (videoDevices.length > 0) {
                        setCurrentCamera(videoDevices[0].deviceId); // Set the first found video device as the current camera
                    } else {
                        console.log("No video input devices found.");
                        setCurrentCamera('no camera');
                    }
                })
                .catch(function (err) {
                    setCurrentCamera('no camera');
                    console.error('Error listing devices', err);
                });
        }
    };
    const findUsedMicrophone = () => {
        if (currentMicrophone === null || currentMicrophone === 'default') {
            navigator.mediaDevices.enumerateDevices()
                .then(function (devices) {
                    const audioDevices = devices.filter(device => device.kind === 'audioinput');
                    if (audioDevices.length > 0) {
                        setCurrentMicrophone(audioDevices[2].deviceId); // Set the first found audio device as the current microphone
                    } else {
                        setCurrentMicrophone('no microphone');
                    }
                })
                .catch(function (err) {
                    setCurrentMicrophone('no microphone');
                });
        }
    };
    return(
        <>
            {!hideToolBar && (
                <>
                    <div className='call-options'>
                        <div className='triangle' id={'movingTriangle'}>
                            <img src='/images/Polygon_1.svg' alt='polygon' />
                        </div>
                        <div className={`options-container ${showWhiteBoard ? 'whiteBoard' : ''}`}>
                            <div
                                onMouseEnter={() => {
                                    setHoverMic(true)
                                }
                            }
                                onMouseLeave={() => {
                                    setHoverMic(false)
                                }}
                                className='option'>
                                <div className={`item-container ${micFullActive || hoverMic ? 'opened-menu-attach' : ''} ${!is669px ? 'with-no-arrow' : ''}`}>
                                    <div className='item-image'
                                         onClick={()=>{
                                             if(!disableMuted) {
                                                 toggleMute()
                                             }
                                         }}>
                                        {muted || !processedStream || processedStream && !processedStream.isAudioEnabled() ? (
                                            <img src='/images/red-microphone-slash%201.svg' alt={'microphone'}/>
                                        ) :  <Icon
                                            name={'microphone'}
                                            color={'#282828'}
                                            isParentHovered={micFullActive || hoverMic}
                                            colorHover={'#fff'}/>}
                                    </div>
                                    {!is669px ? (
                                        <div className='arrow-image'  onClick={()=> {
                                            findUsedMicrophone()
                                            setMicFullActive(!micFullActive)
                                            toggleMicActive(!micFullActive)
                                            setVideoFullActive(false)
                                            setAddPeopleFullActive(false)
                                            setSettingsFullActive(false)
                                        }}>
                                            <Icon
                                                name={'black-angle-down'}
                                                color={'#282828'}
                                                isParentHovered={micFullActive || hoverMic}
                                                colorHover={'#fff'}/>
                                        </div>
                                    ) : null }

                                </div>
                            </div>
                            <div
                                onMouseEnter={() => {
                                    setHoverVid(true)
                                }}
                                onMouseLeave={() => {
                                    setHoverVid(false)
                                }}
                                className='option'>
                                <div className={`item-container ${videoFullActive || hoverVid ? 'opened-menu-attach' : ''} ${is669px ? 'with-no-arrow' : ''}`}>
                                    <div className='item-image' onClick={()=> {
                                        toggleVideoMute()
                                    }}>
                                        { videoMuted || !processedStream || processedStream && !processedStream.isVideoEnabled() ? (
                                            <Icon name="video-cut" color="#f75f5f"/>
                                        ) : videoFullActive || hoverVid ? (
                                            <Icon name="video-normal" color="white"/>
                                        ) : (
                                            <Icon name="video-normal" color="black"/>
                                        )}
                                    </div>
                                    {!is669px ? (
                                        <div className='arrow-image' onClick={()=> {
                                            findUsedCamera()
                                            setVideoFullActive(!videoFullActive)
                                            toggleVideoActive(!videoFullActive)
                                            setMicFullActive(false)
                                            setAddPeopleFullActive(false)
                                            setSettingsFullActive(false)
                                        }}>
                                            <Icon
                                                name={'black-angle-down'}
                                                color={'#282828'}
                                                isParentHovered={videoFullActive || hoverVid}
                                                colorHover={'#fff'}/>
                                        </div>
                                    ) : null}

                                </div>
                            </div>
                            {(is795px && invitePeopleEmptyRoom) || (is795px && displayChatroom) || is490px ? null : (
                                <div
                                    onMouseEnter={() => {
                                        setHoverPeople(true)
                                    }}
                                    onMouseLeave={() => {
                                        setHoverPeople(false)
                                    }}
                                    className='option'>
                                    <div className={`item-container ${addPeopleRightFullActive || hoverPeople ? 'opened-menu-attach' : addPeopleFullActive ? 'opened-menu-attach center-popup' : ''}`}>
                                        <div className='item-image'
                                             onClick={()=> {
                                                 if(!emptyRoom){
                                                     if (!displayChatroom && !addPeopleRightFullActive){
                                                         setDisplayChatroom(true)
                                                         setAddPeopleRightFullActive(true)
                                                     }
                                                     else if(displayChatroom && !addPeopleRightFullActive) {
                                                         setDisplayChatroom(true)
                                                         setAddPeopleRightFullActive(true)
                                                         setShowMessagesFullActive(false)
                                                     }
                                                     else if(displayChatroom && addPeopleRightFullActive){
                                                         setDisplayChatroom(false)
                                                         setAddPeopleRightFullActive(false)
                                                     }
                                                 }
                                             }}
                                            >
                                            <Icon
                                                name={'user-add'}
                                                color={emptyRoom ? 'grey' : addPeopleFullActive ? '#835FF7' :'#282828'}
                                                isParentHovered={addPeopleRightFullActive || hoverPeople}
                                                colorHover={'#fff'}/>

                                        </div>
                                        <div className='arrow-image'
                                             onClick={()=>{
                                                 setAddPeopleFullActive(!addPeopleFullActive)
                                                 setAddPeopleFullActive(!addPeopleFullActive)
                                                 setVideoFullActive(false)
                                                 setMicFullActive(false)
                                                 setSettingsFullActive(false)
                                                 setShowGuests(false)
                                             }}>
                                            <Icon
                                                name={'black-angle-down'}
                                                color={addPeopleFullActive ? '#835FF7' :'#282828'}
                                                isParentHovered={addPeopleRightFullActive || hoverPeople}
                                                colorHover={'#fff'}/>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(is861px && invitePeopleEmptyRoom) || (is861px && displayChatroom) || is543px ? null : (
                                <div className='option'
                                     onMouseEnter={()=>{
                                         setHoverShareScreen(true)
                                     }}
                                     onMouseLeave={()=>{
                                         setHoverShareScreen(false)
                                     }}
                                >
                                    <div className={`item-container with-no-arrow ${sharing || hoverShareScreen ? 'opened-menu-attach' : ''}`} onClick={toggleScreenSharing}>
                                        <div className='item-image'>

                                            <Icon
                                                name={'share'}
                                                color={sharing ? '#fff' :'#282828'}
                                                isParentHovered={sharing || hoverShareScreen}
                                                colorHover={'#fff'}/>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(is735px && invitePeopleEmptyRoom) || (is735px && displayChatroom) ? null : (
                                <div className='option'
                                     onMouseEnter={()=>{
                                         setHoverChat(true)
                                     }}
                                     onMouseLeave={()=>{
                                         setHoverChat(false)
                                     }}
                                >
                                    <div className={`item-container with-no-arrow ${showMessagesFullActive || (hoverChat && !emptyRoom) ? 'opened-menu-attach' : ''}`} onClick={()=> {
                                        if(!emptyRoom) {
                                            if (!displayChatroom && !showMessagesFullActive){
                                                setDisplayChatroom(true)
                                                setShowMessagesFullActive(true)
                                            }
                                            else if(displayChatroom && !showMessagesFullActive) {
                                                setDisplayChatroom(true)
                                                setShowMessagesFullActive(true)
                                                setAddPeopleRightFullActive(false)
                                            }
                                            else if(displayChatroom && showMessagesFullActive){
                                                setDisplayChatroom(false)
                                                setShowMessagesFullActive(false)
                                            }
                                        }

                                    }}>
                                        <div className='item-image'>
                                            <Icon
                                                name={'comment-alt'}
                                                color={emptyRoom ? 'grey' : showMessagesFullActive ? '#835FF7' :'#282828'}
                                                isParentHovered={showMessagesFullActive || (hoverChat && !emptyRoom)}
                                                colorHover={'#fff'}/>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className='option' onMouseEnter={()=>setHoverSettings(true)} onMouseLeave={()=>setHoverSettings(false)}>
                                <div id={'settings'} className={`item-container with-no-arrow ${settingsFullActive || hoverSettings ? 'opened-menu-attach' : ''}`}
                                     onClick={(e)=>{
                                         if(emptyRoom && is669px) {
                                             toggleShowSettingsModalEmpty(true)
                                         }
                                         else {
                                             setSettingsFullActive(!settingsFullActive)
                                             toggleSettings(!settingsFullActive)
                                             setAddPeopleFullActive(false)
                                             setVideoFullActive(false)
                                             setMicFullActive(false)
                                             setShowGuests(false)
                                         }
                                     }}>
                                    <div className='item-image'>
                                        <Icon
                                            name={'settings'}
                                            color={settingsFullActive ? '#fff' :'#282828'}
                                            isParentHovered={settingsFullActive || hoverSettings}
                                            colorHover={'#fff'}/>
                                    </div>
                                </div>
                            </div>
                            {(is923px && invitePeopleEmptyRoom) || (is923px && displayChatroom) || is607px ? null : (
                                <div className='option' onMouseEnter={()=>setHoverFullScreen(true)} onMouseLeave={()=>setHoverFullScreen(false)}>
                                    <div onClick={toggleFullscreen} className={`item-container with-no-arrow ${isFullscreen || hoverFullScreen ? 'opened-menu-attach' : ''}`}>
                                        <div className='item-image'>
                                            <Icon
                                                name={'expand'}
                                                color={isFullscreen ? '#fff' :'#282828'}
                                                isParentHovered={isFullscreen || hoverFullScreen}
                                                colorHover={'#fff'}/>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(is981px && invitePeopleEmptyRoom) || (is981px && displayChatroom) || is669px ? null: (
                                <div className='option' onMouseEnter={()=>setHoverCanvas(true)} onMouseLeave={()=>setHoverCanvas(false)}>
                                    <div onClick={()=> {

                                        setShowWhiteBoard(!showWhiteBoard)
                                        toggleWhiteBoard(!showWhiteBoard)
                                    }} className={`item-container with-no-arrow `}>
                                        <div className='item-image'>

                                            {showWhiteBoard || hoverCanvas? (
                                                <img src='/images/draw-purple.svg' alt={'expand'}/>
                                            ): (
                                                <img src='/images/draw.svg' alt={'expand'}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>
                    {micFullActive || videoFullActive || settingsFullActive || addPeopleFullActive ? (
                        <div className={`popup-ahead-option-menu ${micFullActive ? 'mic-options small-popup' : videoFullActive ? 'video-options small-popup' : settingsFullActive ? 'large-popup set-options' : addPeopleFullActive ? 'center-screen' : ''}`}>
                            <div className='popup-position'
                                 ref={containerRef}
                                 onClick={(e)=> {
                                     handleContainerClick(e)
                                 }}>
                                <div className={`container-content-popup ${desktop ? 'desktop' : ''} ${addPeopleFullActive ? 'middle-screen' : ''}`}>
                                    {micFullActive ? (
                                        <div>
                                            <div className='section-header-popup' >
                                                <img src='/images/white-microphone.svg' alt='microphone'/>
                                                <span> Select Microphone</span>
                                            </div>
                                            <div className='options-container-popup'>
                                                {
                                                    [{label: "No Microphone", value: ""}, ...audioDevices].map(
                                                        (microphone, micIdx) => {
                                                            return (
                                                                <div key={`micIdx${micIdx}`} onClick={()=>{
                                                                    changeMicrophone(microphone)
                                                                    setCurrentMicrophone(microphone.value)
                                                                    setMicFullActive(false)
                                                                }} className={`item-pop-select ${microphone.value === currentMicrophone ? 'active' : ''}`}>
                                                                <span>
                                                                    {microphone.label}
                                                                    {microphone.value === currentMicrophone}
                                                                </span>
                                                                </div>
                                                            )
                                                        }
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) : videoFullActive ? (
                                        <div>
                                            <div className='section-header-popup'>
                                                <img src='/images/white-video-camera.svg' alt='camera'/>
                                                <span> Video Device</span>
                                            </div>
                                            <div className='options-container-popup'>
                                                {
                                                    [{label: "No Camera", value: ""}, ...videoDevices].map(
                                                        (camera, camIdx) => {
                                                            return (
                                                                <div key={`cam${camIdx}`} onClick={(e)=>{
                                                                    changeCamera(camera)
                                                                    setCurrentCamera(camera.value)
                                                                    setVideoFullActive(false)
                                                                }} className={`item-pop-select ${camera.value === currentCamera ? 'active' : ''}`}>
                                                            <span>
                                                                {camera.label.replace(/\s*\(.*?\)/g, "")}
                                                            </span>
                                                                </div>
                                                            )
                                                        }
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) :  settingsFullActive ? (
                                        <DevicesModal
                                            stream={localStream}
                                            processedStream={processedStream}
                                            changeCamera={changeCamera}
                                            backgroundImage={backgroundImage}
                                            setBackgroundImage={setBackgroundImage}
                                            changeMicrophone={changeMicrophone}
                                            audioDevices={audioDevices}
                                            videoDevices={videoDevices}
                                            noiseCancelling={noiseCancelling}
                                            setNoiseCancelling={setNoiseCancelling}
                                            videoEffect={videoEffect}
                                            setVideoEffect={setVideoEffect}
                                            setWhiteBoard={setShowWhiteBoard}
                                            whiteBoard={showWhiteBoard}
                                            setShareScreen={toggleScreenSharing}
                                            shareScreen={sharing}
                                            fullScreen={isFullscreen}
                                            setAddPeople={()=>{
                                                setSettingsFullActive(false)
                                                setAddPeopleFullActive(true)
                                            }}
                                            setFullScreen={toggleFullscreen}
                                        />
                                    ) : addPeopleFullActive ? (
                                        <div className='container-popup-add-people'>
                                            <div className='link-invite-container' style={{position:"relative"}}>
                                                {is669px ? (
                                                    <button className="close-add-people-icon" onClick={()=>{
                                                        setAddPeopleFullActive(false)
                                                    }}>
                                                        <CloseIcon fill={'#fff'}/>
                                                    </button>
                                                ) : null}

                                                <img src='/images/magic-wand.svg' style={{marginBottom: '28px'}} alt='magic-wand' width={40} height={40}/>
                                                <span className='title'>Invite People To <br/> Get Started Your Meeting</span>
                                                <div className='invite-via-email'>
                                                    <img src='/images/white-envelope.svg' alt='envelope'/>
                                                    <input
                                                        type='email'
                                                        value={inviteEmail}
                                                        onChange={e=>setInviteEmail(e.target.value)}
                                                        placeholder="Recipents email"/>
                                                </div>
                                                {!validEmail && inviteEmail.length > 2 ? <p style={{color: "red", width: "100%", marginBottom: "10px"}}>Please enter a valid E-mail address!</p> : null}
                                                <LinkSendEmail disabled={!validEmail} onClick={sendMailInvitation} />
                                                <div className='alternative-text' >
                                                    <span>Or</span>
                                                </div>
                                                <CopyLink/>
                                            </div>
                                            <div className='list-previously-invited'>
                                                <span className='popup-title'>Previously Invited</span>
                                                {lastMembersInvited.length ? (
                                                    <div className='container-list-people'>
                                                        {lastMembersInvited.map((member,index)=>{
                                                            return(
                                                                <div className='guest-item' key={`previous-member-${index}`}>
                                                                    <div className='more-information-container'>
                                                                        <div className='profile-image-container'>
                                                                            <UserProfilePicture width={36} height={36} userId={member.userId} userFirstName={member.firstName} userLastName={member.lastName[0]} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='information'>
                                                                              <span className='person-name' style={{textTransform:'capitalize'}}>
                                                                                {member.firstName} {member.lastName}
                                                                              </span>
                                                                        <span className='person-email'>{member.email}</span>
                                                                    </div>
                                                                    <button className='invite-button' onClick={()=>{
                                                                        sendMailInvitation(member.email)
                                                                    }}>Invite</button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                ) : (
                                                    <span className='tab-text' style={{
                                                        display: 'block',
                                                        color: '#fff',
                                                        fontFamily: '"Poppins", "sans-serif"',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 300,
                                                        lineHeight:'normal',
                                                        marginBottom: '16px'
                                                    }}>No previous members found...</span>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            )}
            {hideToolBar && emptyRoom ? (
                <div className={'mobile-empty-room-settings-menu'}>
                    <div className={`container-content-popup middle-screen`}>
                        <DevicesModal
                            mobile
                            stream={localStream}
                            processedStream={processedStream}
                            changeCamera={changeCamera}
                            backgroundImage={backgroundImage}
                            setBackgroundImage={setBackgroundImage}
                            changeMicrophone={changeMicrophone}
                            audioDevices={audioDevices}
                            videoDevices={videoDevices}
                            noiseCancelling={noiseCancelling}
                            setNoiseCancelling={setNoiseCancelling}
                            videoEffect={videoEffect}
                            setVideoEffect={setVideoEffect}
                            toggleCloseSettingsModal={toggleShowSettingsModalEmpty}
                        />
                    </div>
                </div>
            ) : null}
        </>
    )

}