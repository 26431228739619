import Filter from "bad-words"
import {
	RegExpMatcher,
	englishDataset,
	englishRecommendedTransformers,
} from "obscenity";
let BadWords = new Filter({
  placeHolder: "*"
})
BadWords.addWords(
  "retardat",
  "retarded",
  "oligofren",
  "dreq",
  "sugimapula",
  "sugipula",
  "bagpula"
)
const matcher = new RegExpMatcher({
	...englishDataset.build(),
	...englishRecommendedTransformers,
});
function isProfane(input) {
  const lower = input.toLowerCase()
  const hasMatch = matcher.hasMatch(lower) && input !== "fu"
  if(BadWords.isProfane(lower) || hasMatch) {
    return true
  }
  return false
}
export default isProfane