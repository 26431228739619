import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Icon from "../../../../icon/icon";
import './select-date-time.scss'

export const SelectDate = ({value, label, onDateChange }) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    const currentDate = new Date(); // Get the current date
    const currentDay = currentDate.getDate(); // Current day
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed
    const currentYear = currentDate.getFullYear(); // Current year
    console.log(currentMonth)
    const [showSelector, setShowSelector] = useState('');
    const [selectedDate, setSelectedDate] = useState(value? { label: value.day, value: value.day } :{ label: currentDay.toString(), value: currentDay.toString() });
    const [selectedMonth, setSelectedMonth] = useState(value ? { label: value.month, value: '1' } : { label: monthNames[currentMonth - 1], value: currentMonth.toString() });
    const [selectedYear, setSelectedYear] = useState(value ? { label: value.year, value: value.year } :{ label: currentYear.toString(), value: currentYear.toString() });
    const selectDate = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectDate.current && !selectDate.current.contains(event.target)) {
                setShowSelector('');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const allMonths = useMemo(() => {
        const months = [
            { label: "January", value: "1" }, { label: "February", value: "2" },
            { label: "March", value: "3" }, { label: "April", value: "4" },
            { label: "May", value: "5" }, { label: "June", value: "6" },
            { label: "July", value: "7" }, { label: "August", value: "8" },
            { label: "September", value: "9" }, { label: "October", value: "10" },
            { label: "November", value: "11" }, { label: "December", value: "12" }
        ];
        // Filter months to only include current or future months in the current year
        return months.filter(month => parseInt(month.value) >= currentMonth || selectedYear.value > currentYear.toString());
    }, [currentMonth, selectedYear.value, currentYear]);

    const nextFourYears = useMemo(() => {
        const thisYear = new Date().getFullYear();
        // Generate years from the current year forward
        return Array.from({ length: 4 }, (_, i) => thisYear + i);
    }, []);

    const daysInSelectedMonth = useMemo(() => {
        const maxDay = new Date(selectedYear.value, selectedMonth.value, 0).getDate();
        return Array.from({ length: maxDay }, (_, i) => {
            const day = i + 1;
            // Allow all days in future months or years, but limit days in the current month of the current year
            return day >= currentDay || selectedMonth.value > currentMonth.toString() || selectedYear.value > currentYear.toString() ? day : null;
        }).filter(day => day !== null);
    }, [selectedMonth.value, selectedYear.value, currentDay, currentMonth, currentYear]);


    useEffect(() => {
        const dateObj = {
            day: parseInt(selectedDate.value),
            month: allMonths.find(m => m.value === selectedMonth.value)?.label,
            year: parseInt(selectedYear.value)
        };
        onDateChange(dateObj);
    }, [selectedDate, selectedMonth, selectedYear, allMonths]);

    const handleSelectorClick = useCallback((type) => {
        setShowSelector(showSelector === type ? '' : type);
    }, [showSelector]);

    const setDaySelected = useCallback((day) => {
        setSelectedDate({ label: day.toString(), value: day.toString() });
        setShowSelector('');
    }, []);

    const setMonthSelected = useCallback((month, monthIndex) => {
        setSelectedMonth({ label: month, value: monthIndex.toString() });
        setShowSelector('');
    }, []);

    const setYearSelected = useCallback((year) => {
        setSelectedYear({ label: year.toString(), value: year.toString() });
        setShowSelector('');
    }, []);
    return (
        <>
            <span className={'select-date-time-label'}>{label}</span>
            <div className={'select-date-time-container'} ref={selectDate}>
                <div className={'select-container'}>
                    <div className={'selected-value'} onClick={() => handleSelectorClick('day')}>
                        <span>{selectedDate.label}</span>
                        <Icon name={'caret-down'} color={'#909090'} size={12}/>
                    </div>
                    {showSelector === 'day' && (
                        <div className={'list-selector-container'}>
                            {daysInSelectedMonth.map((day, index) => (
                                <span onClick={() => setDaySelected(day)} key={`day-${index}`} className={'list-item'}>
                                    {day}
                                </span>
                            ))}
                        </div>
                    )}
                </div>

                <div className={'select-container'}>
                    <div className={'selected-value'} onClick={() => handleSelectorClick('month')}>
                        <span>{selectedMonth.label}</span>
                        <Icon name={'caret-down'} color={'#909090'} size={12}/>
                    </div>
                    {showSelector === 'month' && (
                        <div className={'list-selector-container'}>
                            {allMonths.map(({ label, value }, index) => (
                                <span onClick={() => setMonthSelected(label, value)} key={`month-${index}`} className={'list-item'}>
                                    {label}
                                </span>
                            ))}
                        </div>
                    )}
                </div>

                <div className={'select-container'}>
                    <div className={'selected-value'} onClick={() => handleSelectorClick('year')}>
                        <span>{selectedYear.label}</span>
                        <Icon name={'caret-down'} color={'#909090'} size={12}/>
                    </div>
                    {showSelector === 'year' && (
                        <div className={'list-selector-container'}>
                            {nextFourYears.map((year, index) => (
                                <span onClick={() => setYearSelected(year)} key={`year-${index}`} className={'list-item'}>
                                    {year}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};