import Input from "components/input/input"
import Authentication from "../authentication"
import Window from "../window"
import { useState } from "react"
import Checkbox from "components/checkbox"
import { useAuthentication } from "stores/authentication";
import { useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import CreateRoom from "../../Rooms/create";
import JoinRoom from "../../Rooms/join";
import Footer from "../../../layout/footer";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import WindowLayout from "../../../windowLayout/window-layout";

const ChangeEmail = () => {
  const navigate = useNavigate()
  const { changeEmailRequest, isAuthenticated } = useAuthentication()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [email, setEmail] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [termsAgreed, setTermsAgreed] = useState(false)
  const [errors, setErrors] = useState('')
  const emailValidations = [
    {
      message: 'This format is not accepted',
      check: (input) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(input);
      }
    },
    {
      message: "Requires not to be empty",
      check: (input) => {
        return input.length >= 0;
      }
    },
    {
      message: "Requires an '@' symbol",
      check: (input) => {
        return input.includes('@');
      }
    },
    {
      message: "Requires a domain name after '@'",
      check: (input) => {
        let parts = input.split('@');
        return parts[1] && parts[1].includes('.');
      }
    },
    {
      message: "Requires a '.' after the domain name",
      check: (input) => {
        let domainPart = input.substring(input.lastIndexOf("@") + 1);
        return domainPart.includes('.') && domainPart.indexOf('.') !== 0;
      }
    },
    {
      message: "Requires a valid top-level domain (e.g., .com, .ro)",
      check: (input) => {
        let domainPart = input.substring(input.lastIndexOf("@") + 1);
        return domainPart.match(/\.[a-zA-Z]{2,}$/);
      }
    },
    {
      message: "Cannot contain '<', '>', or other unsafe characters",
      check: (input) => {
        return !input.match(/[<>]/);
      }
    },
    {
      message: "Email must not contain spaces or special characters like '()<>[]:,;\\\"!#$%&'*+/=?^_`{|}~'",
      check: (input) => {
        return !input.match(/[\s()<>[\]:,;\\"!#$%&'*+/=?^_`{|}~]/);
      }
    }
  ];

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login")
    }
  }, [])
  const resetState = () => {
    setIsLoading(false)
    setError("")
    setSuccess("")
    setEmail("")
    setTermsAgreed(false)
  }
  const checkTermsAgree = () => {
    if (termsAgreed === false) {
      setDisableSubmit(true)
      setErrors(errors + ' agree')
      return false
    }
    else {
      setErrors('')
      setDisableSubmit(false)
      return true;
    }
  }
  const checkEmail = () => {
    for (let i = 0; i < emailValidations.length; i++) {
      if (!emailValidations[i].check(email)) {
        setErrors(errors + ' email')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkCreatRoomForm = ()=>{
    if(checkEmail() && checkTermsAgree()){
      return true
    }
  }

  const attemptChangeEmail = async (e) => {
    e.preventDefault()
    if(!checkCreatRoomForm())
      return
    setIsLoading(true)
    const result = await changeEmailRequest({
      newEmail: email.toLowerCase(),
    })
    if (result.success === true) {
      resetState()
      setSuccess(`We've sent an email to ${email}, follow the instructions there to continue.`)
      setTimeout(()=>{
        navigate(`/verify-email?email=${email}`)
      },2000)
    } else {
      setError("Something went wrong!")
      setIsLoading(false)
      setTimeout(() => {
        setError("")
      }, 2000)
    }
  }





  return (
      <WindowLayout >
        <Window
            title="Set New Email"
            message="Requesting Recovery"
            isLoading={isLoading}
            minHeight="235px"
            error={error}
            success={success}>
          <div>
            <div className="w-100 flex-row d-flex dashboad-container change-email-page">
              <div className='dashboard-image-container'>
                <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container home-page ">
                <div className='dashboard-content'>
                  <div className='back-button'>
                    <Link to={'/user-profile/update'}>
                      <div className='back-icon-container'>
                        <BackIcon/>
                      </div>
                    </Link>
                  </div>
                  <h2 className='dashboard-title'>Change Your Email</h2>
                  <div className='dashboard-tabs-buttons-container'>
                    <span className='dashboard-login-title'>First Insert New current Email</span>
                  </div>
                  <div className='dashboard-tabs-buttons-container'>
                  </div>
                  <form noValidate onSubmit={attemptChangeEmail}>
                    <Input
                        name="email"
                        label="New Email"
                        type="email"
                        value={email}
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                        foundError={errors.includes('email')}
                        validations={emailValidations}
                        placeholder={'Enter Your Email'}
                        autoComplete="username"
                        required
                        onChange={(e) => {
                          setEmail(e.target.value)
                          checkEmail()
                        }} />
                    <Checkbox
                        value={termsAgreed}
                        onClick={() => {
                          setTermsAgreed(!termsAgreed)
                          checkTermsAgree()
                        }}
                        required
                        label="I agree to the"
                        foundError={errors.includes('agree')}
                        linkLabel="Booom Disclaimer"
                        linkTo="/disclaimer" />
                    <input
                        className={
                          `SecondaryButton halfDashboardButton ${!email || !termsAgreed ?
                              "disabled" : ""
                          }`}
                        type="submit"
                        value="Send Verification"
                        required />
                  </form>

                </div>
                <Footer />

              </div>
            </div>
          </div>
        </Window>
      </WindowLayout>
  )
}
export default ChangeEmail