import React from "react";
import {logActivity} from "../../../../../stores/log-activity";

export const ActivityLog = ({forEmail=false}) =>{
    const { items } = logActivity()

    return (
        <div className={'container-logs-email-sent'}>

                {forEmail && items.length ? (
                    <div className={'container-items'} style={{gap:5}}>
                        <span className={'label-item'} style={{marginBottom:0}}>Email sent to:</span>
                        {items?.map((item,index)=>{
                            return(
                                <div className={'item'} key={`log-${index}`}>
                                    <span className={'email-item'}>{item.value}</span>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className={'container-items'}>
                        {items?.map((item,index)=>{
                            return(
                                <div className={'item'} key={`log-${index}`}>
                                    <span className={'label-item'}>{item.label} sent to:</span>
                                    <span className={'email-item'}>{item.value}</span>
                                </div>
                            )
                        })}
                    </div>
                )}
        </div>
    )
}