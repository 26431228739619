import React, {useEffect, useState} from "react";
import WindowLayout from "../../windowLayout/window-layout";
import BackIcon from "../Rooms/chatroom/SVGComponents/back-icon";
import Icon from "../../icon/icon";
import {ButtonComponent} from "../../button-component/button-component";
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../../../stores/authentication";
import './user-profile.scss'
import Input from "../../input/input";
import SelectCountryPhoneCode from "../../select-country-phone/SelectCountryPhoneCode";
import {useCountryLocation} from "../../../stores/location";
import ImageUploadAndCrop from "../../upload-image/image-upload-and-crop";
import {UserProfilePicture} from "../../user-profile-picture";
import Window from "../Authentication/window";
export const UpdateUserProfile = () => {
    const navigate = useNavigate();
    const {user,isAuthenticated,update,logout} = useAuthentication()
    useEffect(() => {
        if(!isAuthenticated)
            navigate('/login')
    }, [user]);
    const { countryCode } = useCountryLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [firstName, setFirstName] = useState(user ? user?.firstName : '')
    const [lastName, setLastName] = useState(user ? user?.lastName : '')
    const [email, setEmail] = useState(user ? user?.email : '')
    const [currentPassword,setCurrentPassword] = useState('')
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [termsAgreed, setTermsAgreed] = useState(true)
    const [phone, setPhone] = useState(user ? user?.phoneNumber  : '')
    const [uploadImage,setUploadImage] = useState(false)
    const [hideDeleteImage,setHideDeleteImage] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [errors, setErrors] = useState('')
    console.log(phone,'phone')
    const emailValidations = [
        {
            message: 'This format is not accepted',
            check: (input) => {
                const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return pattern.test(input);
            }
        },
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length >= 0;
            }
        },
        {
            message: "Requires an '@' symbol",
            check: (input) => {
                return input.includes('@');
            }
        },
        {
            message: "Requires a domain name after '@'",
            check: (input) => {
                let parts = input.split('@');
                return parts[1] && parts[1].includes('.');
            }
        },
        {
            message: "Requires a '.' after the domain name",
            check: (input) => {
                let domainPart = input.substring(input.lastIndexOf("@") + 1);
                return domainPart.includes('.') && domainPart.indexOf('.') !== 0;
            }
        },
        {
            message: "Requires a valid top-level domain (e.g., .com, .ro)",
            check: (input) => {
                let domainPart = input.substring(input.lastIndexOf("@") + 1);
                return domainPart.match(/\.[a-zA-Z]{2,}$/);
            }
        },
        {
            message: "Cannot contain '<', '>', or other unsafe characters",
            check: (input) => {
                return !input.match(/[<>]/);
            }
        },
        {
            message: "Email must not contain spaces or special characters like '()<>[]:,;\\\"#'*/?`|'",
            check: (input) => {
                return !input.match(/[\s()<>[\]:,;\\"#'*/?`|]/);
            }
        }
    ];
    const nameValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        }
    ]
    const phoneValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        },
        {
            message: "Requires min. 6 numbers",
            check: (input) => {
                return input.length >= 6;
            }
        },
        {
            message: "Requires max. 14 numbers",
            check: (input) => {
                return input.length <= 14;
            }
        }
    ]
    const passwordValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        },
        {
            message: "Requires at least 6 characters",
            check: (input) => {
                return input.length >= 6
            }
        },
        {
            message: "Requires at least one uppercase letter (A-Z)",
            check: (input) => {
                return input.match(/[A-Z]/)
            }
        },
        {
            message: "Requires at least one lowercase letter (a-z)",
            check: (input) => {
                return input.match(/[a-z]/)
            }
        }
    ]
    const repeatPassowrdValidations = [
        {
            message: "Don't match",
            check: (input)=>{
                const pattern  = `(?<![\w\d])${password}(?![\w\d])`
                return input.match(pattern)
            }
        },
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        },
        {
            message: "Requires at least 6 characters",
            check: (input) => {
                return input.length >= 6
            }
        },
        {
            message: "Requires at least one uppercase letter (A-Z)",
            check: (input) => {
                return input.match(/[A-Z]/)
            }
        },
        {
            message: "Requires at least one lowercase letter (a-z)",
            check: (input) => {
                return input.match(/[a-z]/)
            }
        }
    ]
    const checkEmail = () => {
        for (let i = 0; i < emailValidations.length; i++) {
            if (!emailValidations[i].check(email)) {
                setErrors(errors + ' email')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkPassword = () =>{
        console.log('check password')
        for (let i = 0; i < passwordValidations.length; i++) {
            if (!passwordValidations[i].check(password)) {
                setErrors(errors + ' password')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkNewPassword = () =>{
        console.log('check new password')
        for (let i = 0; i < passwordValidations.length; i++) {
            if (!passwordValidations[i].check(password)) {
                setErrors(errors + ' new-password')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }

    const checkRepeatPassword = () =>{
        for (let i = 0; i < repeatPassowrdValidations.length; i++) {
            if (!repeatPassowrdValidations[i].check(confirmPassword)) {
                setErrors(errors + ' confirmPassword')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkPhone = () =>{
        for (let i = 0; i < phoneValidations.length; i++) {
            console.log(phone,'phone in check')
            const phoneNumber = String(phone);

            if (!phoneValidations[i].check(phoneNumber)) {
                setErrors(errors + ' phone')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkFirstName = () =>{
        for (let i = 0; i < nameValidations.length; i++) {
            if (!nameValidations[i].check(firstName)) {
                setErrors(errors + ' firstName')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkLastName = () =>{
        for (let i = 0; i < nameValidations.length; i++) {
            if (!nameValidations[i].check(lastName)) {
                setErrors(errors + ' lastName')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkTermsAgree = () => {
        if (termsAgreed === false) {
            setDisableSubmit(true)
            setErrors(errors + ' agree')
            return false
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const [firstSubmit,setFirstSubmit] = useState(false)
    useEffect(()=>{
        if(firstSubmit)
            checkCreatRoomForm()
    },[firstSubmit,termsAgreed,firstName,lastName,password,confirmPassword,phone,email])
    const checkCreatRoomForm = ()=>{
        if(checkFirstName() &&
            checkLastName() &&
            checkPhone() &&
            checkEmail() &&
            checkTermsAgree()){
            if(password) {
                console.log('intra in password check')
                if (checkPassword() && checkNewPassword() && checkRepeatPassword())
                    return true
            }
            else {
                console.log('ajunge aici')

                return true
            }
        }
    }

    const attemptRegistration = async (e) => {
        e.preventDefault()
        if (!checkCreatRoomForm())
            return
        setIsLoading(true)
        const result = await update({
            firstName: firstName.toLowerCase(),
            lastName: lastName.toLowerCase(),
            phoneNumber: Number(phone).toString(),
            confirmPassword:confirmPassword,
            password: password
        })
        if(result === true) {
            if (password) {
                setSuccess("Password Changed Successfully!")
                const response = await logout()
                if(response === true) {
                    const timeout = setTimeout(()=>{
                        navigate("/login")
                        clearTimeout(timeout)
                    }, 4000)
                } else {
                    setError("Bad Logout, Refreshing!")
                    const timeout = setTimeout(()=>{
                        window.location.reload()
                        clearTimeout(timeout)
                    }, 2000)
                }
            }
            else {
                window.location.reload()
            }

        } else {
            setIsLoading(false)
            setTimeout(()=>{
                setError("")
            }, 2000)
        }
    }

    if (!user)
        return <p>Loading...</ p>

    return (
        <WindowLayout>
            <Window
                title="Update Profile"
                message="Profile Updated"
                isLoading={isLoading}
                minHeight="280px"
                error={error === "Something went wrong!" && error}
                success={success}>
                    <div className={'create-group-container'}>
                <div className='back-button' style={{display: "flex", justifyContent: "space-between"}}>
                    <div className={'back-with-breadcrumbs'}>
                        <div className='back-icon-container' style={{minWidth:52}} onClick={() => {
                            navigate(-1)
                        }}>
                            <BackIcon/>
                        </div>
                        <div className={'breadcrumbs-container'}>
                            <span onClick={()=>navigate('/user-profile')}>Profile</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span className={'active'}>Update Profile</span>

                        </div>
                    </div>
                </div>
                <div className={'header-container'} style={{flexDirection: "row", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: 12, flex: 1}}>
                        <span className={'title-window with-edit'}>Update {user ? user.firstName : ''} Profile</span>
                        <span className={'under-title-window'}>Update your information</span>
                    </div>
                </div>
                <div className={'user-profile-container-content'}>
                    <div className={'content'}>
                        {uploadImage ? (
                            <ImageUploadAndCrop onFinish={(e)=>{
                                if(e) {
                                    setUploadImage(false)
                                }
                            }} />

                        ) : (
                            <div className={'user-profile-picture'}>
                                <UserProfilePicture foundImage={(e)=>{
                                    if (e)
                                        setHideDeleteImage(e)
                                }} width={184} height={184} fontSize={40} userId={user._id} userFirstName={user.firstName} userLastName={user.lastName[0]} />

                                <div className={'details-picture-container'}>
                                    <div style={{display:"flex",flexDirection:"column",gap:8}}>
                                        <span className={'picture-header'}>Update Profile Picture</span>
                                        <span className={'picture-details'}>It takes up to 1min to upload the image.</span>
                                    </div>
                                    <div style={{display:"flex", gap:12}}>
                                        {!hideDeleteImage ? (
                                            <ButtonComponent
                                                style={{padding:'14px 32px'}}
                                                ClassName={'group-button-page delete-profile-picture'}
                                                bgOnHover={'transparent'}
                                                buttonColor={'#F75F5F'}
                                                Text={'Delete'}
                                                OnClick={ async () => {
                                                    try{
                                                        const response = await window.$comms.request("profilePictureDelete")
                                                        if (response.success)
                                                            window.location.reload()
                                                    }
                                                    catch (e) {
                                                        console.log(e)
                                                    }

                                                }}
                                            />
                                        ) : null}
                                        <ButtonComponent
                                            style={{padding:'14px 32px'}}
                                            ClassName={'group-button-page upload-profile-picture'}
                                            bgOnHover={'transparent'}
                                            buttonColor={'#24233E'}
                                            Text={'Upload New Photo'}
                                            OnClick={() => {
                                                setUploadImage(true)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={'basic-information'}>
                            <div style={{display:"flex",flexDirection:"column",gap:8}}>
                                <span className={'picture-header'}>Basic Information</span>
                                <span className={'picture-details'}></span>
                            </div>

                            <div className={'inputs-container'}>
                                <div className='two-inputs-container'>
                                    <Input
                                        minLength="2"
                                        name="firstname"
                                        label="First Name"
                                        placeholder='Enter first Name'
                                        pattern="[A-Za-z]{1,32}"
                                        capitalize
                                        validations={nameValidations}
                                        foundError={errors.includes('firstName')}
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value)
                                            checkFirstName()
                                        }}
                                    />
                                    <Input
                                        minLength="2"
                                        name="lastname"
                                        label="Last Name"
                                        placeholder='Enter last Name'
                                        pattern="[A-Za-z]{1,32}"
                                        value={lastName}
                                        validations={nameValidations}
                                        foundError={errors.includes('lastName')}
                                        capitalize
                                        onChange={(e) => {
                                            setLastName(e.target.value)
                                            checkLastName()
                                        }}/>
                                </div>
                                <SelectCountryPhoneCode
                                    value={phone}
                                    countryCode={countryCode}
                                    style={{marginBottom:0,width:'100%'}}
                                    foundError={errors.includes('phone')}
                                    validations={phoneValidations}
                                    onChange={(e)=>{
                                        console.log(e)
                                        setPhone(e)
                                        checkPhone()
                                    }}
                                />
                                <Input
                                    minLength="2"
                                    name="email"
                                    style={{width:'100%'}}
                                    type={'email'}
                                    label="Email Address"
                                    maxLength='64'
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                    value={email}
                                    disabled
                                    autoComplete="email"
                                    foundError={errors.includes('email')}
                                    validations={emailValidations}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        checkEmail()
                                    }}
                                    capitalize/>
                                <ButtonComponent
                                    style={{width:'100%'}}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#42D3D8'}
                                    Text={'Change Email'}
                                    OnClick={() => {
                                        navigate('/change-email')
                                    }}
                                />

                            </div>


                        </div>
                        <div className={'basic-information'}>
                            <div style={{display:"flex",flexDirection:"column",gap:8}}>
                                <span className={'picture-header'}>Password</span>
                                <span className={'picture-details'}></span>
                            </div>

                            <div className={'inputs-container'}>
                                <Input
                                    name="current-password"
                                    style={{width:'100%'}}
                                    label="Current Password"
                                    placeholder='***************************'
                                    type="password"
                                    value={currentPassword}
                                    /*Aici trebuie regantite astea pentru verificare cu parola actuala*/
                                    validations={passwordValidations}
                                    foundError={errors.includes('password')}
                                    minLength="6"
                                    maxLength="32"
                                    required
                                    autoComplete={false}
                                    pattern="^(?=.*[a-z])(?=.*[A-Z]).{6,32}$"
                                    onChange={(e)=>{
                                        setCurrentPassword(e.target.value)
                                        /*SI asta de aici*/
                                        checkPassword()
                                    }}
                                    capitalize
                                />
                                <Input
                                    name="new-password"
                                    label="New Password"
                                    style={{width:'100%'}}
                                    pattern="^(?=.*[a-z])(?=.*[A-Z]).{6,32}$"
                                    type="password"
                                    value={password}
                                    validations={passwordValidations}
                                    foundError={errors.includes('new-password')}
                                    minLength="6"
                                    maxLength="32"
                                    required
                                    placeholder={'Enter New Passwprd'}
                                    capitalize
                                    onChange={(e)=>{
                                        setPassword(e.target.value)
                                        checkNewPassword()

                                    }}
                                />
                                <Input
                                    minLength="2"
                                    name="confirm-new-password"
                                    title="Passwords must match!"
                                    type={'password'}
                                    validations={repeatPassowrdValidations}
                                    foundError={errors.includes('confirmPassword')}
                                    label="Confirm New Password"
                                    pattern={`(?<![\w\d])${password}(?![\w\d])`}
                                    value={confirmPassword}
                                    required
                                    placeholder={'Confirm New Passwprd'}
                                    capitalize
                                    style={{marginBottom:0,width:'100%'}}
                                    onChange={(e)=>{
                                        setConfirmPassword(e.target.value);
                                        checkRepeatPassword()


                                    }}
                                />

                            </div>


                        </div>
                        <div style={{display:"flex",flexDirection:"column",gap:24,width:'100%'}}>
                            <ButtonComponent
                                style={{width:'100%'}}
                                iconName={'save'}
                                bgOnHover={'transparent'}
                                buttonColor={'#42D3D8'}
                                IconColorHover={'#42D3D8'}
                                IconColor={'#fff'}
                                IconSize={20}
                                Text={'Update Profile'}
                                OnClick={(e) => {
                                    attemptRegistration(e)
                                }}
                            />
                            <div className={'back-to-profile-container'}>
                               <span onClick={()=>{
                                   navigate('/user-profile')
                               }}>Back To Profile</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </Window>
        </WindowLayout>
    )
}