const reqSvgs = require.context ( 'assets/icons', true, /\.svg$/ )
let paths = reqSvgs.keys()
paths = paths.filter(p=>!p.includes("./"))
const result = {}
paths.map( path => {
  let name = path.split("assets/icons/")[1]
  name = name.split(".svg")[0]
  result[name] = reqSvgs(path)
})

export default result