import "./window-layout.scss"
import TitleBar from "./title-bar/title-bar"
import { ToastContainer } from "react-toastify"
import ToastHandler from "../Toast/ToastHandler"
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {auto} from "@popperjs/core";
import {MediaScreen} from "../pages/Rooms/chatroom/encrypted/MediaScreen";
import {useAuthentication} from "../../stores/authentication";
import {useEffect} from "react";

export const WindowLayout = ({children, className}) => {
    const location = useLocation();
    const navigate = useNavigate()

    const { pathname } = location;
    const {is950px} = MediaScreen()
    const { isAuthenticated } = useAuthentication()

    const overflowStyle = {
        overflow: pathname.includes('/manage-groups')  || pathname.includes('/manage-meetings') || pathname.includes('/user-profile') || pathname.includes('/register')  ?  'auto' :  'hidden',

    }
    const heightFullScreen = {
        height: pathname.includes('/manage-groups') || pathname.includes('/manage-meetings') || pathname.includes('/user-profile') || pathname.includes('/register') ?  'unset' : 'calc(100svh - 91px)'
    }

  return (
      <div
          style={overflowStyle}
          id={className}
          className={`windowLayout w-100 mx-auto text-dark bg-white ${className}`}>
        <div className="fixcenter">
          <TitleBar />
          <div style={heightFullScreen} className="windowLayoutContent">
            {children}
          </div>
        </div>
        <ToastContainer/>
        <ToastHandler/>
      </div>
  )
}

export default WindowLayout