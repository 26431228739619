import Icon from "components/icon/icon"
import { MediaScreen } from "components/pages/Rooms/chatroom/encrypted/MediaScreen"
import { useEffect } from "react"
import { useState } from "react"

const CopyLink = ({dark}) => {
  const [ displayCopied, setDisplayCopied ] = useState(false)
  const [showSocialMedia,setShowSocialMedia] = useState(false)
  const [isHovered, setIsHovered] = useState(false);

  useEffect(()=>{
    let tout = null
    if(displayCopied) {
      tout = setTimeout(()=>{
        setDisplayCopied(false)
        setShowSocialMedia(true)
      }, 3000)
    }
    if(showSocialMedia) {
      tout = setTimeout(()=>{
        setShowSocialMedia(false)
      }, 10000)
    }
    return () => {
      clearTimeout(tout)
    }
  }, [displayCopied, setDisplayCopied])
  const invitation = `You have been invited to join ${window.location.href}`
  return (
    <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      className={`copy-link-button default-button ${dark ? 'dark' : ''} ${showSocialMedia ? 'social-media' : ''}`}
      onClick={() => {
        navigator.clipboard.writeText(window.location.href)
        setDisplayCopied(true)
      }}>
      {showSocialMedia ? (
          <div className='social-media-container'>
            <a href={`https://telegram.me/share/url?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">
              <img src='/images/telegram.svg0.svg' alt='telegram' width={22} height={22}/>
            </a>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer">
              <img src='/images/facebook.svg' alt='facebook'  width={22} height={22}/>
            </a>
            <a href={`https://wa.me/?text=${invitation}`} target="_blank" rel="noopener noreferrer">
              <img src='/images/whatsapp.svg' alt='whatsapp'  width={22} height={22}/>
            </a>
            <a href='https://zoom.us/signin#/login' target="_blank" rel="noopener noreferrer">
              <img src='/images/zoomus.svg' alt='zoomus' width={22} height={22}/>
            </a>
          </div>
      ) : (
          <>
            {displayCopied ? (
                <>
                  <Icon name={'check'} isParentHovered={isHovered} size={21} color={'#fff'} colorHover={dark? '#24233E' : '#42D3D8'}/>
                  <p>Link Copied!</p>
                </>
            ) : (
                <>
                  <Icon name={'white-copy'} isParentHovered={isHovered} size={21} color={'#fff'} colorHover={dark? '#24233E' : '#42D3D8'}/>
                  <p>Copy Meeting Link</p>
                </>
            )}

          </>
      )}

    </button>
  )
  }

export default CopyLink