import React, {useEffect, useState} from "react";
import Loader from "../../../loader";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import Icon from "../../../icon/icon";
import {ButtonComponent} from "../../../button-component/button-component";
import {Manage} from "../../../../stores/manage";
import {useAuthentication} from "../../../../stores/authentication";
import {useGroups} from "../../../../stores/groups";
import {useNavigate} from "react-router-dom";
import {BigMeetingCard} from "./components/big-meeting-card/bigMeetingCard";
import {useMeetings} from "../../../../stores/meetings";
import WindowLayout from "../../../windowLayout/window-layout";
import MeetingListenerComponent from "../../../meeting-listener";
import {MediaScreen} from "../../Rooms/chatroom/encrypted/MediaScreen";

export const MyMeetings = () => {
    const {setManageMeetings} = Manage()
    const {getMeetings,meetings,meetingsInvitations} = useMeetings()
    const navigate = useNavigate()
    const { isAuthenticated,user } = useAuthentication()
    const { getGroups,groups } = useGroups()
    const [groupList, setGroupList] = useState()
    const [meetingList,setMeetingList] = useState()
    const [displayLimit, setDisplayLimit] = useState(2);
    const [isLoading,setLoading] = useState(true)
    const {isMobile,isTablet} = MediaScreen()

    useEffect(()=>{
        if(!isAuthenticated) {
            navigate("/login")
        }
        getMeetings()
        getGroups()

    },[])

    useEffect(()=>{
        const glist = []
        const meetings = []
        if(groups) {
            groups.forEach((group, i) => {
                if(group) {
                    glist.push(group)
                    setLoading(false)
                }
            })
        }
        if(glist.length)
            glist.forEach((group,index)=>{
                if(group.meetings.length)
                    group.meetings.forEach((meeting,idx)=>{
                        meetings.push({
                            group: group,
                            meeting: meeting
                        })
                    })
            })
        setLoading(false)
        setMeetingList(meetings)
        setGroupList(glist)
    },[groups])
    const handleLoadMore = () => {
        setDisplayLimit(displayLimit + 4);
    };
    return(
        <WindowLayout>
            <div className={'my-groups-container'}>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        <div className='back-button' style={{display:"flex", justifyContent:"space-between", flexDirection:isMobile ? 'column' : 'row', gap:isMobile ? '12px' : '0' }}>
                            <div className={'back-with-breadcrumbs'} style={{alignItems:isTablet ? 'flex-start': 'center'}}>
                                <div className='back-icon-container' style={{minWidth:52}} onClick={()=> {
                                    navigate(-1)
                                }}>
                                    <BackIcon/>
                                </div>
                                <div className={'breadcrumbs-container'}>
                                    <span style={{cursor:'unset'}}>Manage Meetings</span>
                                    <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                                    <span className={'active'}>Upcoming Meeting</span>
                                </div>
                            </div>
                            <div className={'global-button-group-settings'}>
                                <ButtonComponent
                                    ClassName={'group-button-page'}
                                    iconName={'empty-plus'}
                                    style={{maxWidth:isMobile ? 'unset' : '',width:'100%'}}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#42D3D8'}
                                    IconColorHover={'#42D3D8'}
                                    IconColor={'#fff'}
                                    IconSize={20}
                                    Text={!isTablet || isMobile ? 'Create A New Meeting' : ''}
                                    OnClick={()=>{
                                        navigate('/manage-meetings/create-meeting')
                                    }}
                                />
                            </div>
                        </div>
                        {meetings.length || meetingsInvitations.length > 0 ? (
                            <>
                                <div className={'header-container'} style={{flexDirection:"row", alignItems:"center"}}>
                                    <div style={{display:"flex",flexDirection:"column",gap:12,flex:1}}>
                                        <span className={'title-window'}>Upcoming Meeting</span>
                                        <span className={'under-title-window'}>Your scheduled meetings</span>
                                    </div>

                                </div>
                                <div className={'groups-list-container'}>
                                    <span className={'all-groups-header'}>All Upcoming Meetings ({meetings?.length + meetingsInvitations?.length})</span>
                                    <div className={'list-of-groups'}>
                                        {meetingsInvitations?.slice(0, displayLimit).map((item, index) => {
                                            return (<BigMeetingCard invitation key={`meeting-${index}`} index={index} item={item}/>)
                                        })}
                                        {meetings?.slice(0, displayLimit).map((item, index) => {
                                            return (<BigMeetingCard key={`meeting-${index}`} index={index} item={item}/>)
                                        })}
                                    </div>

                                    {displayLimit < meetings.length && (
                                        <ButtonComponent
                                            ClassName={'load-more-groups'}
                                            iconName={'long-right-arrow'}
                                            bgOnHover={'transparent'}
                                            buttonColor={'#2B2A48'}
                                            IconColor={'#fff'}
                                            IconSize={24}
                                            iconPositionRight
                                            IconColorHover={'#2B2A48'}
                                            Text={'Load More'}
                                            OnClick={()=>{
                                                handleLoadMore()
                                            }}
                                        />
                                    )}

                                </div>
                            </>
                        ) : (
                            <div className={'empty-meetings'}>
                                <img src='/images/empty-meetings.png' style={{minHeight:309}} alt={'empty-meetings'}/>
                                <div className={'what-to-do'}>
                                    <span className={'header-message'}>No Upcoming Meetings</span>
                                    <span className={'subtext-message'}>You Have No Upcoming meeting. Please create one or join a new meeting</span>
                                </div>
                                <ButtonComponent
                                    ClassName={'create-meeting-button-page'}
                                    iconName={'empty-plus'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#42D3D8'}
                                    IconColorHover={'#42D3D8'}
                                    IconColor={'#fff'}
                                    IconSize={20}
                                    Text={'Create A New Meeting'}
                                    OnClick={()=>{
                                        navigate('/manage-meetings/create-meeting')
                                    }}
                                />
                            </div>
                        )}

                    </>
                )}



            </div>
        </WindowLayout>
    )
}