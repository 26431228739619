import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuthentication} from "../../../../../stores/authentication";
import BackIcon from "../../../Rooms/chatroom/SVGComponents/back-icon";
import Icon from "../../../../icon/icon";
import {ButtonComponent} from "../../../../button-component/button-component";
import {Search} from "../../../../search-bar/search";
import {QuickListMembers} from "../../Groups/components/quick-list-members/quick-list-members";
import {useMeetings} from "../../../../../stores/meetings";
import {useGroups} from "../../../../../stores/groups";
import WindowLayout from "../../../../windowLayout/window-layout";
import {SelectRole} from "../../../../select-role/selectRole";
import {UserProfilePicture} from "../../../../user-profile-picture";
import {MediaScreen} from "../../../Rooms/chatroom/encrypted/MediaScreen";
import {shopModal} from "../../../../layout/shopModal";


export const SpecificMeeting = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuthentication();
    const { selectedMeeting, getSpecificMeeting, deleteMeeting, getMeetings, meetingOwner, removeMemberMeeting, acceptOrDeclineMeetingInvitation } = useMeetings();
    const [filteredGroupsBySearch,setFilteredGroupsBySearch] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const {isMobile,isTablet} = MediaScreen()
    const {accept,setAccept, setShow,kickMember,setKickMember} = shopModal()
    const [memberIdKick,setMemberIdKick] = useState()
    const [statusMemberMember,setStatusMember] = useState('')
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
        async function fetchData() {
            await getMeetings();
            await getSpecificMeeting(query.get('id'));
        }
        fetchData();
    }, [query.get('id')]);

    useEffect(() => {
        if (!selectedMeeting)
            return;
        const invitedWithoutOwner = selectedMeeting.invited.filter(member => member._id !== selectedMeeting.owner);


        // Then, filter the combined groups based on the search query
        if (!searchQuery) {
            setFilteredGroupsBySearch(invitedWithoutOwner);
            return
        }
        const filteredGroups = invitedWithoutOwner.filter(member => {
                if((member.firstName + " " + member.lastName).toLowerCase().includes(searchQuery.toLowerCase()))
                    return member
            }
        );
        setFilteredGroupsBySearch(filteredGroups);
    }, [selectedMeeting,searchQuery]);

    const [profilePicture,setProfilePicture] = useState(`https://gsvoiceapp.fra1.digitaloceanspaces.com/mts/${selectedMeeting? selectedMeeting?._id : ''}.png`)

    const memberStatusMeeting = (id) => {
        const accepted = selectedMeeting.accepted.find((itm)=>itm._id === id)
        const decline = selectedMeeting.declined.find((itm)=>itm._id === id)
        return accepted ? '' : decline ? '(Declined)' : '(Pending)'
    }
    const isModerator = (memberId) => {
        // Check if the member ID is in the list of moderators
        return selectedMeeting.moderators.some(mod => mod._id === memberId);
    }

    function handleImageError() {
        setProfilePicture('/images/group-image-default.png') // Change the image source to the fallback URL
    }

    const deleteMeetingAction = async ()=>{
        if (accept) {
            try {
                const result = await deleteMeeting({meetingId:selectedMeeting._id})
                if (result) {
                    console.log(result)
                    navigate('/manage-meetings')
                    console.log('sucsess delete')
                }
                console.log(result)
            } catch (e) {
                console.log(e)
            }
            setAccept(false)
        }
    }
    const kickMemberAction = async ()=>{
        if (accept) {
            try {
                const result = await removeMemberMeeting({meetingId: selectedMeeting._id, memberId: memberIdKick})
                if (result) {
                    console.log(result)
                    console.log('sucsess kick')
                }
                setMemberIdKick(null)
                console.log(result)
            } catch (e) {
                console.log(e)
            }
            setKickMember(false)
            setAccept(false)
        }
    }
    useEffect(()=>{
        if (kickMember)
            kickMemberAction()
        else
            deleteMeetingAction()
    },[accept,kickMember])


    if (!selectedMeeting || !meetingOwner) {
        return <p>Loading...</p>;
    }
    return (
        <WindowLayout>
            <div className={'create-group-container'}>
                <div className='back-button' style={{display: "flex", justifyContent: "space-between", flexDirection:isMobile ? 'column' : 'row', gap:isMobile ? '12px' : '0'}}>
                    <div className={'back-with-breadcrumbs'} style={{alignItems:isTablet ? 'flex-start': 'center'}}>
                        <div className='back-icon-container' style={{minWidth:52}} onClick={() => {
                            navigate(-1)
                        }}>
                            <BackIcon/>
                        </div>
                        <div className={'breadcrumbs-container'} >
                            <span>Manage Meetings</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span onClick={() => {
                                navigate('/manage-meetings')
                            }}>Upcoming Meetings</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span className={'active'} onClick={() => {
                                navigate(`/manage-meetings/my-meeting?id${selectedMeeting._id}`)
                            }}>{selectedMeeting.name} Meeting</span>
                        </div>
                    </div>
                    <div className={'global-button-group-settings'}>
                        <ButtonComponent
                            ClassName={'group-button-page'}
                            iconName={'empty-plus'}
                            style={{maxWidth:isMobile ? 'unset' : '',width:'100%'}}
                            bgOnHover={'transparent'}
                            buttonColor={'#42D3D8'}
                            IconColorHover={'#42D3D8'}
                            IconColor={'#fff'}
                            IconSize={20}
                            Text={!isTablet || isMobile ? 'Create A New Meeting' : ''}
                            OnClick={() => {
                                navigate('/manage-meetings/create-meeting')
                            }}
                        />
                    </div>
                </div>
                <div className={'header-container'} style={{flexDirection: "row", alignItems: "center",flexWrap:"wrap"}}>
                    <div style={{display: "flex", flexDirection: "column", gap: 12, flex: 1}}>
                        <span className={'title-window with-edit'}>Manage {selectedMeeting.name} Meeting
                            {meetingOwner._id === user._id ? (
                                <Icon
                                    onClick={()=>{
                                        navigate(`/manage-meetings/edit-meeting?id=${selectedMeeting._id}`)}}
                                    className={'edit-icon'}
                                    name={'edit'}
                                    size={22}
                                    withStroke
                                    color={'#090721'}/>
                            ) : null}
                        </span>
                        <span className={'under-title-window'}>{selectedMeeting.description}</span>
                    </div>
                    <div style={{maxWidth: '565px',width:isMobile ? '100%' :'50%'}}>
                        <Search
                            value={searchQuery}
                            placeholder={'Search people by email'}
                            setSearchQuery={(e) => {
                                setSearchQuery(e)
                            }}/>
                    </div>

                </div>
                <div className={'content-meeting-details-container'}>
                    <div className={'left-container'}>
                        <img
                            src={profilePicture}
                            onEnded={()=>
                                setProfilePicture(`https://gsvoiceapp.fra1.digitaloceanspaces.com/mts/${selectedMeeting._id}.png`)}
                            onError={()=>handleImageError()}
                            style={{borderRadius:4,maxWidth: 652, width: '100%',minHeight:450,objectFit:"cover", height: '100%',backgroundColor:'#EAE9EC'}}
                            alt='meeting-image'
                            width={652}
                            height={450}/>

                        <div style={{display:"flex",flexDirection:"column",gap:30}}>
                            {(selectedMeeting.accepted.length > 1 || selectedMeeting.declined.length > 0 || selectedMeeting.invited > 0) && user._id === selectedMeeting.owner ? (
                                <ButtonComponent
                                    style={{width: '100%'}}
                                    ClassName={'button-card'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#835FF7'}
                                    textColor={'#fff'}
                                    Text={'Add Members To This Meeting'}
                                    OnClick={()=>{
                                        navigate(`/manage-meetings/my-meeting/invite?id=${selectedMeeting._id}`)
                                    }}
                                />
                            ) : null}

                            {user._id === selectedMeeting.owner ? (
                                <ButtonComponent
                                    style={{width: '100%'}}
                                    ClassName={'button-card'}
                                    iconName={'trash'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#F75F5F'}
                                    IconColor={'#fff'}
                                    IconSize={20}
                                    IconColorHover={'#F75F5F'}
                                    Text={'Delete Meeting'}
                                    OnClick={async ()=>{
                                        setShow(true)
                                        if (accept) {
                                            deleteMeetingAction()
                                        }
                                    }}
                                />
                            ) : (
                                <ButtonComponent
                                    style={{width: '100%'}}
                                    ClassName={'button-card'}
                                    iconName={'trash'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'#F75F5F'}
                                    IconColor={'#fff'}
                                    IconSize={20}
                                    IconColorHover={'#F75F5F'}
                                    Text={'Leave Meeting'}
                                    OnClick={async ()=>{
                                        try {
                                            const result = await acceptOrDeclineMeetingInvitation({memberId:user._id,meetingId:selectedMeeting._id,accept:false})
                                            if (result) {
                                                console.log(result)
                                                navigate('/manage-meetings')
                                                console.log('sucsess leave')
                                            }
                                            console.log(result)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                />
                            )}
                        </div>



                    </div>
                    <div className={'right-container'}>
                        <div className={'invite-members'}>
                            <div className={'list-of-members-container'}>
                                <div className={'header-list'}>
                                    <span className={'title-window'} style={{marginBottom:6}}>{statusMemberMember ? statusMemberMember : 'Meeting members'}</span>
                                    <span className={'under-title-window'} style={{textTransform: 'unset',marginBottom:0}}>Your Meeting Members</span>
                                </div>
                                <div className={'owner-card-in-members'}>
                                    <UserProfilePicture userId={meetingOwner._id} userFirstName={meetingOwner.firstName} userLastName={meetingOwner.lastName[0]} />
                                    <div className={'owner-informations'}>
                                        <div className={'owner-name'} style={{width:"fit-content",maxWidth:"unset"}}><span className={'text'}>{meetingOwner?.firstName} {meetingOwner?.lastName}</span>
                                            <div className={'admin-badge'}>
                                                <Icon name={'crown'} size={16} color={'#fff'}/>
                                                <span>Admin</span>
                                            </div>
                                        </div>
                                        <span className={'owner-email'} style={{maxWidth:"unset"}}>{meetingOwner?.email}</span>
                                    </div>

                                </div>

                                {filteredGroupsBySearch.length > 0 ? (
                                    <div className={'list-members'}>
                                        {filteredGroupsBySearch.map((member,idx)=>{
                                            if (member?._id !== selectedMeeting?.owner)
                                                return(
                                                    <div className={'member-item-container'} key={`member-meeting-${idx}`}>
                                                        <UserProfilePicture userId={member._id} userFirstName={member.firstName} userLastName={member.lastName[0]} />

                                                        <div className={'member-informations'}>
                                                            <div style={{display:"flex",gap:10}}>
                                                                <span className={'member-name'}>{member?.firstName} {member?.lastName}</span>
                                                                <span className={'status-member-meeting'}>{memberStatusMeeting(member._id)}</span>
                                                            </div>
                                                            <span className={'member-email'}>{member?.email}</span>
                                                        </div>
                                                        {meetingOwner?._id === user?._id || isModerator(user._id) ? (
                                                            <div style={{display:"flex",gap: 10}}>
                                                                <ButtonComponent
                                                                    ClassName={'quick-kick-user-button'}
                                                                    iconName={'trash'}
                                                                    bgOnHover={'transparent'}
                                                                    buttonColor={'#F75F5F'}
                                                                    IconColor={'#fff'}
                                                                    IconSize={20}
                                                                    IconColorHover={'#F75F5F'}
                                                                    Text={'Kick'}
                                                                    OnClick={async ()=>{
                                                                        setKickMember(true)
                                                                        setShow(true)
                                                                        setMemberIdKick(member._id)
                                                                        if (accept)
                                                                            kickMemberAction()
                                                                    }}
                                                                />
                                                                {meetingOwner?._id === user?._id && memberStatusMeeting(member._id) !== '(Pending)' && memberStatusMeeting(member._id) !== '(Declined)' ? (
                                                                    <SelectRole
                                                                        inMeeting
                                                                        isModerator={isModerator(member._id)}
                                                                        groupId={selectedMeeting._id}
                                                                        memberId={member._id}
                                                                        statusChange={(e)=>{
                                                                            setStatusMember(e)
                                                                            setTimeout(()=>{
                                                                                setStatusMember('')
                                                                            },3000)
                                                                        }}
                                                                        className={'selected'}/>
                                                                ) : null}

                                                            </div>
                                                        ) :  memberStatusMeeting(member._id) !== '(Pending)' && memberStatusMeeting(member._id) !== '(Declined)' ? (
                                                            <SelectRole noAdmin className={'selected'}/>
                                                        ) : null}
                                                    </div>
                                                )
                                        })}
                                    </div>
                                ) : (
                                    <div style={{display:"flex",flexDirection:"column",gap:24}}>
                                        <span className={'under-title-window'} style={{textTransform: 'unset',marginBottom:0}}>Your meeting doesn't have any members, invite a member or a group to join your meeting.</span>
                                        <ButtonComponent
                                            style={{width: '100%'}}
                                            ClassName={'button-card'}
                                            bgOnHover={'transparent'}
                                            buttonColor={'#835FF7'}
                                            textColor={'#fff'}
                                            Text={'Add Members To This Meeting'}
                                            OnClick={()=>{
                                                navigate(`/manage-meetings/my-meeting/invite?id=${selectedMeeting._id}`)
                                            }}
                                        />
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </WindowLayout>
    )
}