import Icon from "components/icon/icon"
import { MediaScreen } from "components/pages/Rooms/chatroom/encrypted/MediaScreen"
import { useEffect } from "react"
import { useState } from "react"
export const LinkSendEmail = ({onClick,disabled})=>{
    const [ displayCopied, setDisplayCopied ] = useState(false)
    const [showSocialMedia,setShowSocialMedia] = useState(false)
    const [isHovered, setIsHovered] = useState(false);


    useEffect(()=>{
        let tout = null
        if(displayCopied) {
            tout = setTimeout(()=>{
                setDisplayCopied(false)
                setShowSocialMedia(true)
            }, 3000)
        }
        if(showSocialMedia) {
            tout = setTimeout(()=>{
                setShowSocialMedia(false)
            }, 10000)
        }
        return () => {
            clearTimeout(tout)
        }
    }, [displayCopied, setDisplayCopied])

    return (
        <button
            disabled={disabled}
            style={disabled ? {cursor:'not-allowed'} : {cursor:'pointer'}}
            onMouseEnter={() => {
                setIsHovered(true)
            }}
            onMouseLeave={() => setIsHovered(false)}
            className={`send-via-email default-button ${showSocialMedia ? 'social-media' : ''}`}
            onClick={() => {
                    onClick()
                    setDisplayCopied(true)
            }}>
            {displayCopied ? (
                <>
                    <Icon name={'check'} isParentHovered={isHovered} size={21} color={'#fff'} colorHover={'#835FF7'}/>
                    <p>Link Send!</p>
                </>
            ) : (
                <>
                    <p>Send Meeting Link</p>
                </>
            )}
        </button>
    )

}