import React, {useEffect, useState} from "react";
import './quick-list-groups.scss'
import Icon from "../../../../../icon/icon";
import {ButtonComponent} from "../../../../../button-component/button-component";
import {useAuthentication} from "../../../../../../stores/authentication";
import {useNavigate} from "react-router-dom";
import {useGroups} from "../../../../../../stores/groups";
import {Manage} from "../../../../../../stores/manage";
import {UserProfilePicture} from "../../../../../user-profile-picture";
export const QuickListGroups = () => {
    const {setManageGroups} = Manage()

    const { isAuthenticated,user } = useAuthentication()
    const navigate = useNavigate()
    const { getGroups,groups } = useGroups()
    const [groupList, setGroupList] = useState()
    const [displayLimit, setDisplayLimit] = useState(3);


    useEffect(()=>{
        if(!isAuthenticated) {
            navigate("/login")
        }
        getGroups()
    },[])
    useEffect(()=>{
        const glist = []
        if(groups) {
            groups.forEach((group, i) => {
                if(group) {
                    glist.push(group)
                }
            })
        }
        setGroupList(glist)
    },[groups])

    const handleLoadMore = () => {
        setDisplayLimit(displayLimit + 6);
    };
    const viewGroup = (id) => {
        setManageGroups('Specific Group')
        navigate(`/manage-groups/my-group?id=${id}`)

    }

    if(groupList)
    return(
        <div className={'groups-list-quick-view-container'}>
            <span className={'numbers-of-list'} onClick={()=>{
                navigate('/manage-groups')
                }}
            >List Groups ({groupList.length})</span>

            <div className={'quick-list-container'}>
                {groupList?.slice(0, displayLimit).map((item,index)=>{
                    return(
                        <div key={`quick-group-${index}`} className={'quick-group-item-container'}>
                            <UserProfilePicture groupImage width={53} height={53} groupImageURL={item.picture} />

                            <div className={'quick-group-item-information'}>
                                <span className={'item-title'}>{item.name}</span>
                                <p className={'item-description'}>{item.description ?? 'Lets gather some ideas together'}</p>
                            </div>
                            <div className={'quick-group-item-view'} onClick={()=>viewGroup(item._id)}>
                                <span>View Group</span>
                            </div>

                        </div>
                    )
                })}
            </div>
            {displayLimit < groupList.length && (
                <ButtonComponent
                    ClassName={'quick-load-more-button'}
                    iconName={'long-right-arrow'}
                    bgOnHover={'transparent'}
                    buttonColor={'#2B2A48'}
                    IconColor={'#fff'}
                    IconSize={24}
                    iconPositionRight
                    IconColorHover={'#2B2A48'}
                    Text={'Load More'}
                    OnClick={()=>{
                        handleLoadMore()
                    }}
                />
            )}

        </div>
        )
    else
        return null
}