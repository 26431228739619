import React from "react";
import {ButtonComponent} from "../../../../../button-component/button-component";
import {useNavigate} from "react-router-dom";

export const MeetingStartedButton = ({roomId}) => {
    const navigate = useNavigate()

    return(
        <ButtonComponent
            style={{width:'100%'}}
            bgOnHover={'transparent'}
            buttonColor={'#42D3D8'}
            Text={'Join Meeting'}
            OnClick={()=>{
                navigate(`/chatroom?room=${roomId}`)
            }}
        />
    )
}