import { useEffect } from "react"
import "./connecting.scss"
import Logo from "components/logo"
import { useConnection } from "stores/connection"
import { useCountryLocation } from "stores/location"
import Countdown from "../../countdown";
import Footer from "../../layout/footer";
import Loader from "../Authentication/loader";
import WindowLayout from "../../windowLayout/window-layout";

const Connecting = () => {
  const {connect} = useConnection()
  const {getLocationInformation} = useCountryLocation()
  const { pathname } = location;

  useEffect(()=>{
    connect()
    getLocationInformation()
  }, [])
  return (

      <WindowLayout className={'connecting'}>
        {pathname.includes('/chatroom') ? (
            <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container'>
                <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container connecting ">
                <div className='dashboard-content'>
                  <div style={{display:"flex", gap: 16, alignItems:"center"}}>
                    {/*<div style={{position:"relative", width: 48, height: 48}}>
                  <Loader/>
                </div>*/}
                    <h2 className='dashboard-title' style={{marginBottom: 0}}>Connecting to Booom Servers</h2>
                  </div>

                  <div className='dashboard-tabs-buttons-container'>
                    <span className='dashboard-login-title'>Just a moment while we link you to the network.</span>
                  </div>
                </div>
              </div>
            </div>
        ) : (
            <div className="w-100 flex-row d-flex dashboad-container">
              <div className='dashboard-image-container' style={{height:'100vh'}}>
                <img src="/images/frame-boom-dashboard.png" style={{paddingTop:114}} alt="frame-boom-dashboard" draggable="false" />
              </div>
              <div className="dashboard-content-container home-page ">
                <div className='dashboard-content'>
                  <div style={{display:"flex", gap: 16, alignItems:"center"}}>
                    <div style={{position:"relative", width: 48, height: 48}}>
                      <Loader/>
                    </div>
                    <h2 className='dashboard-title' style={{marginBottom: 0}}>Loading</h2>
                  </div>
                </div>
              </div>
            </div>
        )}
      </WindowLayout>





  )
}
export default Connecting