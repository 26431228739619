export function getClasses(input) {
  const result = []
  Object.keys(input).forEach(key=>{
    if(input[key]) {
      result.push(key)
    }
  })
  return result.toString().replaceAll(",", " ")
}
export function getInitials(name) {
  if(name) {
      // Split the name into an array of words
      const words = name.trim().split(' ');
      // Initialize an empty string for the initials
      let initials = '';
      // Check if there is at least one word
      if (words.length > 0) {
        // Always take the first letter of the first word
        initials += words[0][0].toUpperCase();
        // If there is a second word, take the first letter of the second word
        if (words.length > 1) {
          initials += words[1][0].toUpperCase();
        }
      }
      // Return the initials
      return initials;
  } else {
    return " "
  }
}