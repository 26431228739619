import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRooms } from "stores/rooms";
import { ButtonComponent } from "../../../button-component/button-component";
import { shopModal } from "../../../layout/shopModal";

const LeaveRoom = () => {
  const navigate = useNavigate();
  const { clearRoom } = useRooms();
  const { accept,setAccept, setShow, leaveLiveMeeting, setLeaveLiveMeeting } = shopModal();

  // Function to handle the actual exit
  const exitRoom = () => {
    navigate("/");
    clearRoom();
  };

  // Effect to handle leaving the room based on conditions
  useEffect(() => {
    if (accept) {
      setLeaveLiveMeeting(false)
      setAccept(false)
      navigate("/");
      clearRoom();
    }
  }, [accept, leaveLiveMeeting]);  // Dependency array ensures this effect runs only when `accept` or `leaveLiveMeeting` changes

  return (
      <ButtonComponent
          ClassName={'controlButtonDanger'}
          OnClick={() => {
            setShow(true);
            setLeaveLiveMeeting(true);
          }}
          Disabled={false}
          Text={'Leave'}
          buttonColor={'#F75F5F'}
          textColor={'#fff'}
      />
  );
};

export default LeaveRoom;
