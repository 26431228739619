import React from "react";
import {UserProfilePicture} from "../user-profile-picture";

export const RoundedIcons = ({members,iconSize=50,fontSize=18}) => {
    return(
        <div style={{display: "flex", gap: 10, alignItems: "center", marginBottom: 24}}>
            <div className={'icons-container'}>
                {members?.slice(0,4).map((member,index)=>{
                    return(
                        <div key={`rounded-icon-member-${member.firstName}-${member.lastName}`} className={'white-border'}>
                            <div className={'user-picture'}>
                                <UserProfilePicture userId={member._id} userFirstName={member.firstName} userLastName={member.lastName} width={iconSize} height={iconSize} fontSize={fontSize} />
                            </div>
                        </div>
                    )
                })}
                {members.length > 4 ? (
                    <div className={'white-border'}>
                        <div className={'user-picture'} style={{width:iconSize,height:iconSize, backgroundColor: '#F75F5F',justifyContent:"center",alignItems:"center",display:"flex"}}>
                            <span style={{
                                color:'#fff',
                                fontFamily:'Poppins, sans-serif',
                                fontSize: 17,
                                fontWeight: 400
                            }}>+ {members.length - 4}</span>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}