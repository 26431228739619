import React, { useRef, useState, useEffect } from "react";
import "./canva.scss";
import {ButtonComponent} from "../button-component/button-component";
import {CloseIcon} from "../pages/Rooms/chatroom/SVGComponents/closeIcon";
import Icon from "../icon/icon";


export const Canvas = ({isOpen,onClose}) => {

    //de aici poti sterge sau modifica

    const [isDrawing, setIsDrawing] = useState(false);
    const [color, setColor] = useState("#3B3B3B");
    const [size, setSize] = useState("3");
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const timeout = useRef(null);
    const [cursor, setCursor] = useState("default");

    useEffect(() => {
        const canvas = canvasRef.current;
        ctx.current = canvas.getContext("2d");
        const videoContainer = document.querySelector('.Video');

        //Resizing
        canvas.height = videoContainer.offsetHeight;
        canvas.width =videoContainer.offsetWidth;

        //Load from locastorage
        const canvasimg = localStorage.getItem("canvasimg");
        if (canvasimg) {
            var image = new Image();
            ctx.current = canvas.getContext("2d");
            image.onload = function () {
                ctx.current.drawImage(image, 0, 0);
                setIsDrawing(false);
            };
            image.src = canvasimg;
        }

    }, [ctx]);

    const startPosition = ({ nativeEvent }) => {
        setIsDrawing(true);
        draw(nativeEvent);
    };

    const finishedPosition = () => {
        setIsDrawing(false);
        ctx.current.beginPath();
    };

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) {
            return;
        }
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect(); // Get canvas position and size
        const x = nativeEvent.clientX - rect.left; // Adjust X coordinate
        const y = nativeEvent.clientY - rect.top; // Adjust Y coordinate
        ctx.current = canvas.getContext("2d");
        ctx.current.lineWidth = size;
        ctx.current.lineCap = "round";
        ctx.current.strokeStyle = color;

        ctx.current.lineTo(x, y);
        ctx.current.stroke();
        ctx.current.beginPath();
        ctx.current.moveTo(x, y);

        if (timeout.current !== undefined) clearTimeout(timeout.current);
        timeout.current = setTimeout(function () {
            var base64ImageData = canvas.toDataURL("image/png");
            localStorage.setItem("canvasimg", base64ImageData);
        }, 400);
    };

    const clearCanvas = () => {
        localStorage.removeItem("canvasimg");
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.fillStyle = "white";
        context.fillRect(0, 0, canvas.width, canvas.height);

        //Passing clear screen
        if (timeout.current !== undefined) clearTimeout(timeout.current);
        timeout.current = setTimeout(function () {
            var base64ImageData = canvas.toDataURL("image/png");
            localStorage.setItem("canvasimg", base64ImageData);
        }, 400);
    };

    const getPen = () => {
        setCursor("default");
        setSize("3");
        setColor("#3B3B3B");
    };

    const eraseCanvas = () => {
        setCursor("grab");
        setSize("20");
        setColor("#FFFFFF");

        if (!isDrawing) {
            return;
        }
    };

    //pana aici poti sterge
    //de aici am nevoie

    const colorInputRef = useRef();
    const handleButtonClick = () => {
        colorInputRef.current.click();
    };

    return (
        <div className={`canva-container ${isOpen ? 'active' : ''}`}>
            <button className="canva-close-icon " onClick={() => onClose(false)}>
                <CloseIcon fill={'#000'}/>
            </button>
            <div className="canvas-btn">
                <div className="color-picker" onMouseEnter={handleButtonClick} onClick={handleButtonClick}>
                    <ButtonComponent
                        iconName={'color-palette-tool'}
                        bgOnHover={'#F0EBFF'}
                        buttonColor={'transparent'}
                        IconColor={'#000'}
                        IconSize={20}
                        IconColorHover={'#835ff7'}
                        OnClick={handleButtonClick} // Attach the click event handler
                    />
                    <input
                        ref={colorInputRef} // Attach the ref to the input
                        type="color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                    />
                </div>
                <ButtonComponent OnClick={clearCanvas} iconName={'clear-all'} bgOnHover={'#F0EBFF'} buttonColor={'transparent'} IconColor={'#000'} IconSize={20} IconColorHover={'#835ff7'}/>

            </div>
            <canvas
                style={{ cursor: cursor }}
                onMouseDown={startPosition}
                onMouseUp={finishedPosition}
                onMouseMove={draw}
                ref={canvasRef}
            />
        </div>
    );
}