import {create} from "zustand";

export const Manage = create((set, get) => ({
    manageGroups: 'Create A New Group',
    manageMeeting: 'My Meetings',
    specificMeeting: null,
    setManageGroups(manageGroups){
        set({manageGroups})
    },
    setManageMeetings(manageMeeting){
        set({manageMeeting})
    },
    setSpecificMeeting(specificMeeting){
        set({specificMeeting})
    }
}))
