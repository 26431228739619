import {shopModal} from "./shopModal";
import React, {useState} from "react";
import Input from "../input/input";
import {useGroups} from "../../stores/groups";

export const ModalGlobal = ({children}) => {
    const {showModal,
        forDelete,
        deleteMe,isOwner,
        deleteEveryone,accept,
        modalGroups,
        kickMember,
        setKickMember,
        setModalGroups,
        setIsOwner,
        setForDelete,
        setAccept,
        setShow,
        leaveLiveMeeting,
        setLeaveLiveMeeting,
        scheduleMeeting,
        scheduleMeetingContent,
        scheduleMeetingName,
        setScheduleMeetingName,
        setScheduleMeetingContent} = shopModal()
    const [meetingName,setMeetingName] = useState('')
    const [errors, setErrors] = useState('')
    const [meetingTime,setMeetingTime] = useState('2024-04-19T10:33')
    const [success,setSuccess] = useState(false)
    const {createMeeting} = useGroups()

    const checkScheduleRoomForm = ()=>{
        if(meetingName.length < 3)
            setErrors(errors + ' meetingName')
        else {
            setErrors('')
            return true
        }
    }
    if (showModal && leaveLiveMeeting) {
        return (
            <div className='global-modal-verification'>
                <div className='content-container'>
                    <span className='text'>Are you sure you want to leave this meeting?</span>
                    <div className='action-buttons-container'>
                        <button className='approve' type={"button"} onClick={(e)=> {
                            e.preventDefault()
                            setAccept(true)
                            setShow(false)
                            setLeaveLiveMeeting(false)
                        }}>Yes</button>
                        <button className='decline' type={"button"} onClick={(e)=> {
                            e.preventDefault()
                            setAccept(false)
                            setShow(false)
                            setLeaveLiveMeeting(false)
                        }}>No</button>
                    </div>

                </div>

            </div>
        )
    }

    if (showModal && scheduleMeeting) {
       return (
            <div className='global-modal-verification'>
                <div className='content-container' style={{padding: 24}}>
                    <span className='text'>Schedule A New Meeting</span>
                    <Input
                        dark
                        name="MeetingName"
                        label="Meeting Description"
                        type="text"
                        placeholder='Meeting name'
                        value={meetingName}
                        autoComplete="none"
                        capitalize
                        minLength="3"
                        validations={[
                            {
                                message: "Requires at least 3 characters",
                                check: (input) => {
                                    return input.length >= 3
                                }
                            }
                        ]}
                        required
                        foundError={errors.includes('meetingName')}
                        onChange={(e) => {
                            setScheduleMeetingName(e.target.value)
                            setMeetingName(e.target.value)
                        }} />
                    {success ? (
                        <span className='text'>Meeting Scheduled Successfully</span>
                    ) : (
                        <div className='action-buttons-container'>
                            <button className='approve' type={"button"} onClick={ async (e)=> {
                                e.preventDefault()
                                if(checkScheduleRoomForm()) {
                                    const result = await createMeeting({date: meetingTime, name: meetingName})
                                    if (result) {
                                        setSuccess(true)
                                        setTimeout(() => {
                                            setShow(false)
                                            setSuccess(false)
                                        }, 2000);
                                    }
                                    console.log(result)
                                }
                            }}>Create</button>
                            <button className='decline' type={"button"} onClick={(e)=> {
                                e.preventDefault()
                                setShow(false)
                            }}>Decline</button>
                        </div>

                    )}
                </div>
            </div>
        )
    }


    if (modalGroups && showModal) {
        return (
            <div className='global-modal-verification'>
                <div className='content-container' style={{padding: 0}}>
                    {children}
                </div>
            </div>
        )
    }
    else if(showModal && !forDelete)
    return(
        <div className='global-modal-verification'>
            <div className='content-container'>
                <span className='text'>Do you want to proceed?</span>
                <div className='action-buttons-container'>
                    <button className='approve' type={"button"} onClick={(e)=> {
                        e.preventDefault()
                        setAccept(true)
                        setShow(false)
                    }}>Yes</button>
                    <button className='decline' type={"button"} onClick={(e)=> {
                        e.preventDefault()
                        setAccept(false)
                        setShow(false)
                    }}>No</button>
                </div>

            </div>

        </div>
    )
    else if(showModal && kickMember)
        return(
            <div className='global-modal-verification'>
                <div className='content-container'>
                    <span className='text'>Do you want to kick this member?</span>
                    <div className='action-buttons-container'>
                        <button className='approve' type={"button"} onClick={(e)=> {
                            e.preventDefault()
                            setAccept(true)
                            setKickMember(false)
                            setShow(false)
                        }}>Yes</button>
                        <button className='decline' type={"button"} onClick={(e)=> {
                            e.preventDefault()
                            setAccept(false)
                            setKickMember(false)
                            setShow(false)
                        }}>No</button>
                    </div>

                </div>

            </div>
        )

    else if(showModal && forDelete) {
        return (
            <div className='global-modal-verification'>
                <div className='content-container'>
                    <span className='text'>Do you want to proceed?</span>
                    <div className='action-buttons-container'>
                        <button className='approve' type={"button"} onClick={(e)=> {
                            e.preventDefault()
                            deleteMe()
                            setForDelete(false)
                            setShow(false)
                            setIsOwner(false)
                        }}>Yes, Delete For Me</button>
                        {isOwner && (
                            <button className='approve' type={"button"} onClick={(e)=> {
                                e.preventDefault()
                                deleteEveryone()
                                setForDelete(false)
                                setShow(false)
                                setIsOwner(false)
                            }}>Yes, Delete For Everyone</button>
                        )}
                        <button className='decline' type={"button"} onClick={(e)=> {
                            e.preventDefault()
                            setAccept(false)
                            setForDelete(false)
                            setShow(false)
                            setIsOwner(false)
                        }}>No</button>
                    </div>

                </div>

            </div>
        )
    }
}