import "./logout.scss"
import Icon from "components/icon/icon"
import Authentication from "../authentication"
import Window from "../window"
import { Link, useNavigate } from "react-router-dom"
import { useAuthentication } from "stores/authentication"
import { useState } from "react"
import { useEffect } from "react"
import ReactCodeInput from "react-verification-code-input";
import WindowLayout from "../../../windowLayout/window-layout";

const Logout = () => {
  const navigate = useNavigate()
  const { logout, isAuthenticated } = useAuthentication()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(()=>{
    if(!isAuthenticated) {
      navigate("/")
    }
  },[])

  const initiateLogout = async () => {
    setIsLoading(true)
    const response = await logout()
    if(response === true) {
      setSuccess("Logged out successfuly!")
      const timeout = setTimeout(()=>{
        navigate("/")
        clearTimeout(timeout)
      }, 2000)
    } else {
      setError("Bad Logout, Refreshing!")
      const timeout = setTimeout(()=>{
        window.location.reload()
        clearTimeout(timeout)
      }, 2000)
    }
    setIsLoading(false)
  }

  return (
      <WindowLayout >
        <Window
            title="Logout"
            message="Logging out"
            isLoading={isLoading}
            minHeight="259px"
            error={error}
            success={success}>

          <div className="w-100 flex-row d-flex dashboad-container">
            <div className='dashboard-image-container'>
              <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
            </div>
            <div className="dashboard-content-container " >
              <div className='dashboard-content'>
                <h2 className='dashboard-title'>Logout?</h2>
                <p className="verify-email-text">Are you sure you wish to logout?</p>
                <div style={{maxWidth: '300px', display:"flex", gap:'15px'}}>
                  <Link className="secondaryButton" to={'..'}>Go Back</Link>
                  <Link className="mainButton" onClick={initiateLogout}>Logout</Link>
                </div>
              </div>
            </div>
          </div>
        </Window>
      </WindowLayout>

  )
}
export default Logout