import React, {useEffect, useState} from "react";
import './selected-role.scss'
import Icon from "../icon/icon";
import {useGroups} from "../../stores/groups";
import {useMeetings} from "../../stores/meetings";
export const SelectRole = ({className,inMeeting,isModerator,groupId,memberId,noAdmin=false,statusChange}) => {
    const [showOptions,setShowOptions] = useState(false)
    const {addModerator,removeModerator} = useGroups()
    const {addModeratorMeeting,removeModeratorMeeting} = useMeetings()
    const option = [
        {
            label:"Moderator",
            value: "moderator",
        },
        {
            label:"Member",
            value: "member",
        }

    ]
    // Initial state for selectedOption is determined by isModerator prop
    const [selectedOption, setSelectedOption] = useState(isModerator ? option[0] : option[1]);

    // Update selectedOption whenever isModerator changes
    useEffect(() => {
        setSelectedOption(isModerator ? option[0] : option[1]);
    }, [isModerator]);


    return(
        <div className={`selector-rol-container ${className ? className : ''}`}>
            <div className='selector-container' style={!noAdmin ? {cursor:"pointer"} : {cursor:"unset"}} onClick={()=>setShowOptions(!showOptions)}>
                <p>{selectedOption.label}</p>
                {!noAdmin ? (
                    <Icon name={'arrow-down-fullsvg'} color={'#000'} size={12}/>
                ) : null}
            </div>
            {showOptions && !noAdmin ? (
                <div className={'options-container'}>
                    {option.map((e,index)=>{
                        return(
                            <div key={`${e.label}-${index}`} className={'option'} onClick={ async ()=>{
                                setSelectedOption({
                                    label: e.label,
                                    value: e.value
                                })
                                if (e.value === 'moderator') {
                                    try {
                                        let result
                                        if (inMeeting) {
                                            result = await addModeratorMeeting({
                                                memberId: memberId,
                                                meetingId: groupId,
                                                canKick: true
                                            })
                                        }
                                        else {
                                            result = await addModerator({
                                                memberId: memberId,
                                                groupId: groupId,
                                                canKick: true
                                            })
                                        }
                                        if (result) {
                                            console.log(result)
                                            statusChange('Success Change Status To Moderator')
                                        }
                                        console.log(result)
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }
                                if (e.value === 'member') {
                                    try {
                                        let result
                                        if(inMeeting) {
                                            result = await removeModeratorMeeting({
                                                memberId: memberId,
                                                meetingId: groupId
                                            })
                                        }
                                        else {
                                            result = await removeModerator({
                                                memberId: memberId,
                                                groupId: groupId
                                            })
                                        }


                                        if (result) {
                                            console.log(result)
                                            statusChange('Success Change Status to Member')
                                        }
                                        console.log(result)
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }
                                setShowOptions(false)
                            }}>
                                <p>{e.label}</p>
                            </div>
                        )
                    })}
                </div>
            ) : null}
        </div>

    )
}