import { create } from 'zustand'
import axios from 'axios'
export const useMaintenance = create((set) => ({
  isUnderMaintenance: process.env.REACT_APP_MAINTENANCE == 1,
  setMaintenance: (value)=>{
    set({isUnderMaintenance: value})
  }
}))
const handler = async ()=>{
  try {
    const response = await axios.get("/status.json")
    if(response.data) {
      const { isUnderMaintenance, setMaintenance } = useMaintenance.getState()
      if(isUnderMaintenance !== response.data.maintenance) {
        setMaintenance(response.data.maintenance)
        if(isUnderMaintenance === true && response.data.maintenance) {
          window.location.reload()
        }
      }
    }
  } catch (e) {}
}
handler()
const interval = setInterval(handler, 5000)