import { create } from 'zustand'
import usePeers from './peers'
import {MediaDeviceType, MediaStreamTrackType} from "../components/hardcoded/streamHelper";
import {vi} from "date-fns/locale";

function storeSettings(object) {
  window.localStorage.setItem("lastDeviceSettings", JSON.stringify(object))
}
function retrieveSettings () {
  const JSONSettings = window.localStorage.getItem("lastDeviceSettings")
  if(JSONSettings) {
    return JSON.parse(JSONSettings)
  }
  return false
}

const useDevices = create((set, get) => ({
  devicesAreReady: false,
  mediaStream: null,
  ownStream: new MediaStream(),
  viewStream: new MediaStream(),
  screenShareTrack: null,
  isStreaming: false,
  isMuted: false,
  isDeafened: false,
  isSettingsLoaded: false,
  lastVideoDeviceId: null,
  lastAudioDeviceId: null,
  lastSinkDeviceId: null,
  setIsDeafened(val) {
    set({isDeafened: val})
    const { deviceUpdate, saveSettings } = get()
    const type = val ? 'deafen' : 'undeafen'
    deviceUpdate.dispatchEvent({ type: type })
    saveSettings()
  },
  setIsMuted(val) {
    const { getMediaStream, saveSettings } = get()
    getMediaStream().getAudioTracks().forEach(track => {
      track.enabled = !val
    })
    set({isMuted: val})
    saveSettings()
  },
  deviceUpdate: {
    eventList: {},
    addEventListener(type, callback) {
      if (!(type in this.eventList)) {
        this.eventList[type] = []
      }
      this.eventList[type].push(callback)
    },
    removeEventListener(type, callback) {
      if (!(type in this.eventList)) {
        return
      }
      let stack = this.eventList[type]
      for (let i = 0, l = stack.length; i < l; i++) {
        if (stack[i] === callback) {
          stack.splice(i, 1);
          return this.removeEventListener(type, callback);
        }
      }
    },
    dispatchEvent(event) {
      if (!(event.type in this.eventList)) {
        return;
      }
      const stack = this.eventList[event.type];
      event.target = this;
      for (let i = 0, l = stack.length; i < l; i++) {
        stack[i].call(this, event);
      }
    }
  },
  getMediaStream() {
    let { mediaStream, addOwnTrack, removeOwnTrack } = get()
    if (!mediaStream) {
      console.log('Setting mediaStream')
      mediaStream = new MediaStream()
      mediaStream.addEventListener('addtrack', (event) => {
        console.log(`onAddTrack Triggered for ${event.track.kind} track ${event.track.id} `)
        addOwnTrack(event.track)
      })
      mediaStream.addEventListener('removetrack', (event) => {
        console.log(`onRemoveTrack Triggered for ${event.track.kind} track ${event.track.id} `)
        removeOwnTrack(event.track)
      })
      set({ mediaStream: mediaStream })
    }
    return mediaStream
  },
  addOwnTrack(newTrack) {
    const { ownStream, viewStream } = get()
    if (newTrack) {
      if (!ownStream.getTrackById(newTrack.id)) {
        const addTrackEvent = new MediaStreamTrackEvent('addtrack', {track: newTrack})
        newTrack.enabled = true
        ownStream.addTrack(newTrack)
        ownStream.dispatchEvent(addTrackEvent)
        if (newTrack.kind === MediaStreamTrackType.Video && !viewStream.getTrackById(newTrack)) {
          viewStream.getVideoTracks().forEach(track => viewStream.removeTrack(track) )
          viewStream.addTrack(newTrack)
          viewStream.dispatchEvent(addTrackEvent)
        }
        set({ ownStream: ownStream, viewStream: viewStream })
      }
    }
  },
  // only use this when the track will never be used again in the future
  removeOwnTrack(oldTrack) {
    const { ownStream, viewStream } = get()
    if (oldTrack) {
      const getLastLiveTrack = () => {
        const videoTracks = ownStream.getVideoTracks()
        for (let i = videoTracks.length - 1; i >= 0; i--) {
          if (videoTracks[i].readyState === 'live' && videoTracks[i] !== oldTrack) {
            return videoTracks[i]
          }
        }
        return null
      }
      const removeTrackEvent = new MediaStreamTrackEvent('removetrack', {track: oldTrack})
      if (ownStream.getTrackById(oldTrack.id)) {
        ownStream.removeTrack(oldTrack)
        ownStream.dispatchEvent(removeTrackEvent)
      }
      if (viewStream.getTrackById(oldTrack.id)) {
        const lastLiveTrack = getLastLiveTrack()
        viewStream.removeTrack(oldTrack)
        viewStream.dispatchEvent(removeTrackEvent)
        if (lastLiveTrack) {
          viewStream.addTrack(lastLiveTrack)
          viewStream.dispatchEvent(new MediaStreamTrackEvent('addtrack', {track: lastLiveTrack}))
        }
        set({ viewStream: viewStream })
      }
    }
  },
  setMediaTrack(newTrack, kind = newTrack?.kind) {
    if (!newTrack && !kind) {
      throw 'Cannot setMediaTrack with both arguments set to null.'
    }
    if (newTrack && newTrack.kind !== kind) {
      throw 'Cannot setMediaTrack of a different kind.'
    }
    console.log(`setMediaTrack called with ${kind ?? 'null'} newTrack: `, newTrack)
    const mediaStream = get().getMediaStream()
    mediaStream.getTracks().forEach(track => {
      if (track.kind === kind && track !== newTrack) {
        mediaStream.removeTrack(track)
        mediaStream.dispatchEvent(new MediaStreamTrackEvent('removetrack', {track: track}))
        track.stop()
      }
    })
    if (newTrack) {
      mediaStream.addTrack(newTrack)
      mediaStream.dispatchEvent(new MediaStreamTrackEvent('addtrack', {track: newTrack}))
    }
    set({ mediaStream: mediaStream })
  },
  async getDeviceList() {
    const devices = {
      input: [],
      output: [],
      video: []
    }
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    if (mediaDevices.length > 0) {
      mediaDevices.forEach(device => {
        // real devices only
        if (device.deviceId.length !== 0) {
          if (device.kind === MediaDeviceType.AudioInput) {
            if (!devices.input.filter(fDevice => fDevice.groupId === device.groupId).length) {
              devices.input.push(device)
            }
          } else if (device.kind === MediaDeviceType.AudioOutput) {
            if (!devices.output.filter(fDevice => fDevice.groupId === device.groupId).length) {
              devices.output.push(device)
            }
          } else if (device.kind === MediaDeviceType.VideoInput) {
            if (!devices.video.filter(fDevice => fDevice.groupId === device.groupId).length) {
              devices.video.push(device)
            }
          }
        }
      })
    }
    return devices
  },
  async getDefaultDeviceList() {
    const devices = {
      input: null,
      output: null,
      video: null
    }
    const getDefaultDeviceId = (deviceList, kind) => {
      const realDeviceIdLength = 64
      const defaultDevice = deviceList.filter(device => device.deviceId === 'default' && device.kind === kind).shift()
      if (defaultDevice) {
        const foundDevice = deviceList.filter(device => device.groupId === defaultDevice.groupId && device.deviceId !== defaultDevice.deviceId).shift()
        return foundDevice ?? defaultDevice
      }
      return deviceList.filter(device => device.kind === kind && device.deviceId.length !== 0).shift() ?? null
    }
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    if (mediaDevices.length > 0) {
      devices.input = getDefaultDeviceId(mediaDevices, MediaDeviceType.AudioInput)
      devices.output = getDefaultDeviceId(mediaDevices, MediaDeviceType.AudioOutput)
      devices.video = getDefaultDeviceId(mediaDevices, MediaDeviceType.VideoInput)
    }
    return devices
  },
  async setDevices (devices) {
    const { requestDevice, setMediaTrack } = get()
    if (devices.input) {
      const audioTrack = await requestDevice(devices.input.kind, devices.input.deviceId)
      if (audioTrack) {
        setMediaTrack(audioTrack)
      }
    }
    if (devices.video) {
      const videoTrack = await requestDevice(devices.video.kind, devices.video.deviceId)
      if (videoTrack) {
        setMediaTrack(videoTrack)
      }
    }
    set({
      devicesAreReady: true,
      lastAudioDeviceId: devices.input?.deviceId ?? '',
      lastVideoDeviceId: devices.video?.deviceId ?? '',
      lastSinkDeviceId: devices.output?.deviceId ?? '',
    })
  },
  isSharingScreen: false,
  saveSettings: () => {
    const { isStreaming, isMuted, isDeafened, lastAudioDeviceId, lastVideoDeviceId } = get()
    storeSettings({
      isStreaming: isStreaming,
      isMuted: isMuted,
      isDeafened: isDeafened,
      lastAudioDeviceId: lastAudioDeviceId,
      lastVideoDeviceId: lastVideoDeviceId,
    })
  },
  loadLastSettings: async () => {
    const storedSettings = retrieveSettings()
    if (storedSettings) {
      const { requestDevice, setMediaTrack } = get()
      let isAnyDeviceReady = false
      if (storedSettings.lastAudioDeviceId) {
        const audioTrack = await requestDevice(MediaStreamTrackType.Audio, storedSettings.lastAudioDeviceId)
        if (audioTrack) {
          setMediaTrack(audioTrack)
          isAnyDeviceReady = true
        }
      }
      if (storedSettings.lastVideoDeviceId) {
        const videoTrack = await requestDevice(MediaStreamTrackType.Video, storedSettings.lastVideoDeviceId)
        if (videoTrack) {
          isAnyDeviceReady = true
          setMediaTrack(videoTrack)
        }
      }
      set({
        devicesAreReady: isAnyDeviceReady,
        isSettingsLoaded: true,
        isStreaming: storedSettings.isStreaming,
        isMuted: storedSettings.isMuted,
        isDeafened: storedSettings.isDeafened,
        lastAudioDeviceId: storedSettings.lastAudioDeviceId,
        lastVideoDeviceId: storedSettings.lastVideoDeviceId,
      })

      return isAnyDeviceReady
    }
    return false
  },
  audioSinkId: "",
  startScreenSharing: async () => {
    const { requestScreenShareTrack, setMediaTrack } = get()
    const videoTrack = await requestScreenShareTrack()
    const isSharing = !!videoTrack
    if (isSharing) {
      setMediaTrack(videoTrack)
    }
    set({ isSharingScreen: isSharing, screenShareTrack: videoTrack })
    return isSharing
  },
  stopScreenSharing: async () => {
    const { screenShareTrack, lastVideoDeviceId, requestDevice, setMediaTrack } = get()

    if (lastVideoDeviceId) {
      const lastVideoTrack = await requestDevice(MediaStreamTrackType.Video, lastVideoDeviceId)
      if (lastVideoTrack) {
        setMediaTrack(lastVideoTrack)
      }
    } else {
      setMediaTrack(null, MediaStreamTrackType.Video)
    }

    if (screenShareTrack && screenShareTrack.readyState === 'live') {
      screenShareTrack.stop()
    }

    set({ isSharingScreen: false, screenShareTrack: null })
  },
  requestScreenShareTrack: async () => {
    try {
      const shareStream = await navigator.mediaDevices.getDisplayMedia()
      if (shareStream) {
        const screenShareTrack = shareStream.getVideoTracks()[0]
        await screenShareTrack.applyConstraints({frameRate: {min: 5, ideal: 30}});
        return screenShareTrack
      }
    } catch (e) {
      console.log("screen capturing failed with error:")
      console.log(e)
    }
    set({isSharingScreen: false})
    return false
  },
  requestDevice: async (kind, deviceId = null, constraints = {}) => {
    try {
      console.log(`Requested device access for ${kind} id ${deviceId}`, constraints)
      const deviceList = await navigator.mediaDevices.enumerateDevices()
      const foundDevice = deviceList.filter(deviceInfo => deviceInfo.deviceId === deviceId && kind === kind)
      if (foundDevice.length || !deviceId) {
        const type = kind.substring(0, 5)
        constraints[type] = constraints[type] ?? {}
        if (kind === MediaDeviceType.VideoInput && (!constraints[type].width || !constraints[type].height)) {
          constraints[type].width = "1280"
          constraints[type].height = 720
          constraints[type].frameRate = {min: 5, ideal: 30}
        }
        if (deviceId) {
          constraints[type].deviceId = { exact: deviceId }
        }
        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
          console.log('Found mediaStream: ', mediaStream, constraints)
          return mediaStream?.getTracks()[0] ?? null
        } catch (e) {
          console.log(`requestDevice: Cannot access ${kind} device ${deviceId}`, e)
        }
      }
    } catch (e) {
      console.log(`requestDevice: Cannot access ${kind} device ${deviceId} `, e)
    }

    return null;
  },
  setMode: (input) => {
    set({mode: input})
  },
  switchOutputDevice(deviceId) {
    set({audioSinkId: deviceId})
  },
  setupNoDevices() {
    set({
      // mediaStream: new MediaStream(),
      devicesAreReady: true
    })
  },
  resetDevices() {
    const {mediaStream, ownStream, shareScreenTrack} = get()
    if(mediaStream) {
      mediaStream.getTracks().forEach(track => {
        mediaStream.removeTrack(track)
        track.stop()
      })
    }
    if(ownStream) {
      ownStream.getTracks().forEach(track => {
        ownStream.removeTrack(track)
        track.stop()
      })
    }
    if(shareScreenTrack) {
      shareScreenTrack.stop()
    }
    set({
      devicesAreReady: false,
      mediaStream: null,
      ownStream: new MediaStream(),
      isStreaming: false,
      deviceList: {
        input: [],
        output: [],
        video: []
      },
      shareScreenTrack: null,
      isSharingScreen: false,
      audioSinkId: "",
    })
  }
}))
export default useDevices
