import { create } from 'zustand'
export const useChatroom = create((set, get) => ({
  displayChatroom: false,
  setDisplayChatroom: (input)=>{
    set({displayChatroom: input})
  },
  selectedParticipant: null,
  SelectParticipant(participantId) {
    set({selectedParticipant: participantId || null})
  }
}))