import Countdown from "components/countdown"
import Logo from "components/logo"
import { useNavigate } from "react-router-dom"
import CreateRoom from "./Rooms/create";
import JoinRoom from "./Rooms/join";
import Footer from "../layout/footer";
import WindowLayout from "../windowLayout/window-layout";

const NotFound = () => {
  const navigate = useNavigate()
  return (
      <WindowLayout>
        <div className="w-100 flex-row d-flex dashboad-container">
          <div className='dashboard-image-container'>
            <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
          </div>
          <div className="dashboard-content-container home-page ">
            <div className='dashboard-content'>
              <h2 className='dashboard-title'>404</h2>
              <div className='dashboard-tabs-buttons-container'>
                <span className='dashboard-login-title'>This page doesn't exist</span>
              </div>
              <p className="verify-email-text">You will be redirected home in: <b><Countdown time={6} onEnded={()=>navigate("/")}/>s
              </b></p>
            </div>
            <Footer />

          </div>
        </div>
      </WindowLayout>
  )
}

export default NotFound