import React, {useEffect, useState} from "react";
import { useLocation, useNavigate} from "react-router-dom";
import {useGroups} from "../../../../stores/groups";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import Input from "../../../input/input";
import {ButtonComponent} from "../../../button-component/button-component";
import {QuickListGroups} from "../Groups/components/quick-list-groups/quick-list-groups";
import Icon from "../../../icon/icon";
import {SelectDate} from "./components/select-date";
import {SelectTime} from "./components/select-time";
import {useAuthentication} from "../../../../stores/authentication";
import WindowLayout from "../../../windowLayout/window-layout";
import {useMeetings} from "../../../../stores/meetings";
import {SelectGroup} from "./components/select-group";
import {Search} from "../../../search-bar/search";
import Checkbox from "../../../checkbox";
import {UserProfilePicture} from "../../../user-profile-picture";
import Cropper from "react-cropper";
import {MediaScreen} from "../../Rooms/chatroom/encrypted/MediaScreen";

export const EditMeeting = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);
    const { isAuthenticated } = useAuthentication()
    const { searchUser } = useGroups()
    const [addModeratorView,setAddModeratorView] = useState(false)
    const {selectedMeeting,getSpecificMeeting,getMeetings,updateMeeting,addModeratorMeeting,deleteMeetingPicture} = useMeetings()
    const [searchQuery, setSearchQuery] = useState('');
    const [foundUserInSearch,setFoundUserInSearch] = useState(null)
    const [responseModerator, setResponseModerator] = useState('')
    const [name, setName] = useState(selectedMeeting ? selectedMeeting.name : '')
    const [description, setDescription] = useState(selectedMeeting?.description || '')
    const [date, setDate] = useState(selectedMeeting?.date.meetingDate || '')
    const [time, setTime] = useState(selectedMeeting?.date.meetingTime || '')
    console.log(selectedMeeting?.date,'date')
    const [canKick,setCanKick] = useState(false)
    const [file, setFile] = useState(null);
    const [avatarUrl,setNewAvatarUrl ] =useState()
    const [cropper,setCropper] = useState()
    const [error, setError] = useState(false);
    const [imageError, setImageError] = useState(false);

    const {isMobile,isTablet} = MediaScreen()

    useEffect(() => {
        async function fetchData() {
            await getMeetings()
            await getSpecificMeeting(query.get('id'));
        }

        console.log('intra aici')
        if (selectedMeeting){
            setName(selectedMeeting.name)
            setDescription(selectedMeeting.description)
            setDate(selectedMeeting.date.meetingDate )
            setTime(selectedMeeting.date.meetingTime)
        }
        fetchData();
    }, [query.get('id'),selectedMeeting?.name]);
    const clearState = () => {
        setName("")
        setDescription('')
    }

    const getNewAvatarUrl = (e) => {
        setFile(null)
        if (e.target.files) {
            setNewAvatarUrl(URL.createObjectURL(e.target.files[0]));
        }
    };

    const filePickHandler = (files) => {
        const file = files
        const objectUrl = URL.createObjectURL(file)
        const img = document.createElement("img")
        img.onload = () => {
            if(img.width !== 128 || img.height !== 128) {
                setError("Image needs to be 128px by 128px in dimensions!")
            }
            URL.revokeObjectURL(objectUrl)
        }
        img.src = objectUrl
        img.setAttribute("style", "display: none")
        document.body.appendChild(img)
        console.log(files)
        setFile(files)
    }
    const getCropData = async () => {
        if (cropper) {
            const canvas = cropper.getCroppedCanvas();

            // Convert the canvas to a Blob with a specified quality
            const blobPromise = new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 0.9); // Adjust the quality as needed
            });

            const blob = await blobPromise;

            const resizedBlob = await resizeImageWidthHeight(blob, 600);

            const file = new File([resizedBlob], `${name.toLowerCase()}.jpg`, { type: "image/jpeg" });

            if (file) {
                filePickHandler(file)
            }
        }
    };
    async function resizeImageWidthHeight(blob, targetWidth, targetHeight) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                let newWidth;
                let newHeight;

                // Calculate new dimensions to maintain aspect ratio
                if (img.width > img.height) {
                    newWidth = targetWidth;
                    newHeight = targetWidth / aspectRatio;
                } else {
                    newHeight = targetHeight;
                    newWidth = targetHeight * aspectRatio;
                }

                // Create a canvas to perform the resizing
                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(resolve, 'image/jpeg', 0.9); // Adjust the quality as needed
            };
            img.onerror = reject;
        });
    }
    const resizeImage = async (blob, maxSize) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const scaleFactor = Math.min(1, maxSize / (img.width * img.height));

                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;

                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob((resizedBlob) => {
                        resolve(resizedBlob);
                    }, 'image/jpeg', 0.9); // Adjust the quality as needed
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(blob);
        });
    };

    const [errors, setErrors] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(false)
    const meetingNameValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        }
    ]
    const meetingDescriptionValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        }
    ]
    const checkMeetingName = () =>{
        for (let i = 0; i < meetingNameValidations.length; i++) {
            if (!meetingNameValidations[i].check(name)) {
                setErrors(errors + ' meetingName')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkMeetingDescription = () =>{
        for (let i = 0; i < meetingDescriptionValidations.length; i++) {
            if (!meetingDescriptionValidations[i].check(description)) {
                setErrors(errors + ' meetingDescription')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }

    const checkCreatMeetingForm = ()=>{
        if (avatarUrl && !file) {
            setImageError(true)
            setTimeout(()=>{
                setImageError(false)

            },5000)
            return false
        }
        if(checkMeetingName() &&
            checkMeetingDescription()){
            return true
        }
    }



    const attemptCreate = async (e) => {
        e.preventDefault()
        if (!checkCreatMeetingForm())
            return
        try {

            const result = await updateMeeting(
                {
                    meetingId: selectedMeeting._id,
                    date: {
                        meetingDate: date,
                        meetingTime : time
                    },
                    name: name,
                    description: description,
                    file: file
            })
            if (result) {
                clearState()
                navigate('/manage-meetings')
            }
            console.log(result)
        } catch (e) {
            console.log(e)
        }
    }


    if (!selectedMeeting) {
        return <p>Loading...</p>;
    }




    return (
        <WindowLayout>
            <div className={'create-group-container'}>
                <div className='back-button'>
                    <div className={'back-with-breadcrumbs'} style={{alignItems:isTablet ? 'flex-start': 'center'}}>
                        <div className='back-icon-container' style={{minWidth:52}} onClick={()=>navigate(-1)}>
                            <BackIcon/>
                        </div>
                        <div className={'breadcrumbs-container'}>
                            <span style={{cursor:'unset'}}>Manage Meetings</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span onClick={()=>navigate('/manage-meetings')}>Upcoming Meetings</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span onClick={()=>navigate(`/manage-meetings/my-meeting?id=${selectedMeeting._id}`)}>{selectedMeeting.name}</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span className={'active'}>Edit Meeting</span>
                        </div>
                    </div>
                </div>
                <div className={'header-container'}>
                    <span className={'title-window'}>Edit Meeting {selectedMeeting ? selectedMeeting.name : 'Meeting Name'}</span>
                    <span className={'under-title-window'}>Setup new meeting details</span>
                </div>

                {addModeratorView ? (
                    <div className={'form-create-group-container add-moderator'}>
                        <Search style={{maxWidth:'unset'}} withKeyPress value={searchQuery} setSearchQuery={async (e) => {
                            try {
                                const result = await searchUser({email: e})
                                if (result) {
                                    setFoundUserInSearch(result.user)
                                    console.log('success find User')
                                }
                                if(!result.success) {
                                    setFoundUserInSearch(null)
                                    setTimeout(() => {
                                    }, 2000);
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        }}/>
                        {foundUserInSearch ? (
                            <div className={'found-user-in-group'}>
                                <div className={'found-message'}>
                                    <span className={'title-window'}>{responseModerator ? responseModerator : 'Add Moderator To This Meeting'}</span>
                                    <span className={'under-title-window'}>{responseModerator ? '' : 'We have found a member matching your search'}</span>
                                </div>
                                <div style={{display:"flex",flexDirection:"column",gap:15}}>
                                    <Checkbox
                                        value={canKick}
                                        onClick={() =>  setCanKick(!canKick)}
                                        label="This Moderator Can Kick?"
                                    />
                                    <div className={'user-found-card-to-add'}>
                                        <div className='profile-image-container'
                                             style={{height: 86, width: 86, minWidth: "unset"}}>
                                            <UserProfilePicture width={86} height={86} fontSize={18} userId={foundUserInSearch._id} userFirstName={foundUserInSearch.firstName} userLastName={foundUserInSearch.lastName[0]} />
                                        </div>
                                        <div className={'user-info-container'}>
                                        <span
                                            className={'user-name'}>{foundUserInSearch.firstName} {foundUserInSearch.lastName}</span>
                                            <span className={'user-email'}>{foundUserInSearch.email}</span>
                                        </div>
                                        <ButtonComponent
                                            ClassName={'add-to-this-group'}
                                            iconName={'empty-plus'}
                                            bgOnHover={'transparent'}
                                            buttonColor={'#42D3D8'}
                                            IconColor={'#fff'}
                                            IconSize={20}
                                            IconColorHover={'#42D3D8'}
                                            Text={'Add Moderator This Meeting'}
                                            OnClick={async () => {
                                                try {
                                                    const result = await addModeratorMeeting({
                                                        memberId: foundUserInSearch._id,
                                                        meetingId: selectedMeeting._id,
                                                        canKick: canKick
                                                    })
                                                    if (result.success) {
                                                        setResponseModerator('Moderator Added Successfully!')
                                                    }
                                                    else {
                                                        setResponseModerator(result.reason)
                                                    }
                                                    setTimeout(()=>{
                                                        setFoundUserInSearch(null)
                                                        setSearchQuery('')
                                                        setResponseModerator('')
                                                    },[5000])
                                                    console.log(result)
                                                } catch (e) {
                                                    console.log(e)
                                                }
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>
                        ) :(
                            <>
                                <span className={'under-title-window'} style={{textTransform: 'unset'}}>To add a moderator to your list, type the email of a BOOOM member in the search box above and hit 'Enter'.</span>
                            </>
                        )}

                        <ButtonComponent
                            ClassName={'groups-button'}
                            bgOnHover={'transparent'}
                            buttonColor={'#835FF7'}
                            Text={'Back To Edit Group'}
                            OnClick={()=>setAddModeratorView(false)}
                        />
                    </div>

                ) : (
                    <div className={'form-create-meeting-group-container'}>

                        <Input
                            required
                            capitalize
                            validations={meetingNameValidations}
                            minLength="2"
                            value={name}
                            foundError={errors.includes('meetingName')}
                            onChange={(e) => {
                                setName(e.target.value)
                                checkMeetingName()
                            }}
                            placeholder={'E.g.: Brain Storming'}
                            label={'Meeting Name'} />
                        <Input
                            required
                            validations={meetingNameValidations}
                            value={description}
                            foundError={errors.includes('meetingDescription')}
                            onChange={(e) => {
                                setDescription(e.target.value)
                                checkMeetingDescription()
                            }}
                            maxLength={'250'}
                            minLength={'3'}
                            placeholder={'Enter here your meeting description'}
                            label={'Meeting Description'} />
                        {file && avatarUrl ? (
                            <Input
                                imageUrl={avatarUrl}
                                onChange={(e) => {
                                    getNewAvatarUrl(e)
                                }}
                                addFile
                                style={{whiteSpace: 'pre-line'}}
                                placeholder={'Click or drag your image here to upload \n (Or we will load a default meeting image)'}/>
                        ) : avatarUrl ? (
                            <>
                                <Cropper
                                    autoCropArea={1}
                                    dragMode={"none"}
                                    src={avatarUrl}
                                    style={{height: "100%", width: "100%", maxHeight: '300px', marginBottom: 24}}
                                    initialAspectRatio={16 / 9}
                                    guides={false}
                                    cropBoxResizable={false}
                                    checkOrientation={false}
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                />
                                <ButtonComponent
                                    ClassName={'groups-button'}
                                    bgOnHover={'transparent'}
                                    buttonColor={'rgb(131, 95, 247)'}
                                    Text={'Crop'}
                                    OnClick={getCropData}
                                />
                            </>
                        ) :
                            (
                                <div style={{position:"relative"}}>
                                    <ButtonComponent
                                        ClassName={'groups-button'}
                                        style={{position: 'absolute',top: 15, right: 15, zIndex:20, width: 50}}
                                        iconName={'trash'}
                                        IconColorHover={'#F75F5F'}
                                        bgOnHover={'transparent'}
                                        buttonColor={'#F75F5F'}
                                        IconColor={'#fff'}
                                        IconSize={20}
                                        OnClick={async()=>{
                                            try {
                                                console.log('intra')
                                                const response = await deleteMeetingPicture({meetingId:selectedMeeting._id})
                                                console.log(response)

                                            } catch (e) {
                                                setError(e)
                                            }
                                        }}
                                    />
                                    <Input
                                        onChange={(e) => {
                                            getNewAvatarUrl(e)
                                        }}
                                        imageUrl={`https://gsvoiceapp.fra1.digitaloceanspaces.com/mts/${selectedMeeting? selectedMeeting?._id : ''}.png`}
                                        addFile
                                        style={{whiteSpace: 'pre-line'}}
                                        placeholder={'Clickk or drag your image here to upload \n (Or we will load a default group image)'}/>

                                </div>
                            )}
                        {imageError ? (
                            <div className="inputError" key={`Incorrect email or password.`}>
                                <img className='error-icon-inputs' src='/images/danger-triangle.svg' alt="error-icon"/>
                                The image needs to be cropped to upload it.
                            </div>
                        ) : null}
                        <span className={'upload-image-message'}>It takes up to 1min to upload the image.</span>

                        <div className={'divider-blue'}></div>
                        <SelectDate value={date} label={'Select Meeting Date'} onDateChange={(e)=>{
                            setDate(e)
                        }}/>

                        <SelectTime value={time} label={'Select Meeting Time'} onTimeChange={(e)=>{
                            setTime(e)
                        }}/>

                        <ButtonComponent
                            ClassName={'groups-button'}
                            iconName={'save'}
                            bgOnHover={'transparent'}
                            buttonColor={'#42D3D8'}
                            IconColor={'#fff'}
                            IconColorHover={'#42D3D8'}
                            IconSize={20}
                            Disabled={disableSubmit}
                            Text={'Update Meeting'}
                            OnClick={attemptCreate}
                        />
                        <ButtonComponent
                            ClassName={'groups-button'}
                            iconName={'external-link-square'}
                            IconColorHover={'#835FF7'}
                            iconPositionRight
                            bgOnHover={'transparent'}
                            buttonColor={'#835FF7'}
                            IconColor={'#fff'}
                            IconSize={20}
                            Text={'Add Moderator To This Meeting'}
                            OnClick={()=>setAddModeratorView(true)}
                        />
                    </div>

                )}


                <QuickListGroups />

            </div>
        </WindowLayout>
    );
}