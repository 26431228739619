import Authentication from "components/pages/Authentication/authentication"
import Window from "../window"
import {Link, useNavigate, useSearchParams} from "react-router-dom"
import ReactCodeInput from "react-verification-code-input"
import { useState } from "react"
import { useEffect } from "react"
import { useAuthentication } from "stores/authentication"
import CreateRoom from "../../Rooms/create";
import JoinRoom from "../../Rooms/join";
import Footer from "../../../layout/footer";
import Icon from "../../../icon/icon";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import WindowLayout from "../../../windowLayout/window-layout";

const Verify = () => {
  const navigate = useNavigate()
  const {resend, verify, isAuthenticated} = useAuthentication()
  const [searchParams] = useSearchParams()
  const emailParam = searchParams.get("email")
  const [timeLeft, setTimeLeft] = useState(null)
  const [verificationCode, setVerificationCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  console.log(error)
  useEffect(() => {
    if (!emailParam || isAuthenticated) {
      navigate("/register")
    }
  }, [])
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null)
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft])
  const initiateResend = () => {
    if(timeLeft === null) {
      resend({email: emailParam})
      setTimeLeft(30)
    }
  }
  const attemptVerification = async () => {
    if(verificationCode && verificationCode.length === 4) {
      setIsLoading(true)
      try {
        const result = await verify({email: emailParam, verificationCode: Number(verificationCode)})
        if(result === true) {
          setSuccess("Verification successful!")
          setIsLoading(false)
          setTimeout(()=>{
            navigate("/")
          },2000)
        } else {
          setError(result)
          setIsLoading(false)
          setTimeout(()=>{
            setSuccess("")
          },2000)
        }
      } catch (e) {
        setError("Something went wrong!")
        setIsLoading(false)
        setTimeout(()=>{
          setError("")
        },2000)
      }
    }
  }
  return (
      <WindowLayout >
        <Window
            title="Verify Email"
            message="Verifying code validity"
            isLoading={isLoading}
            minHeight="280px"
            error={error === "Something went wrong!" && error}
            success={success}>
          <div className="w-100 flex-row d-flex dashboad-container">
            <div className='dashboard-image-container'>
              <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
            </div>
            <div className="dashboard-content-container " >
              <div className='dashboard-content' style={{display:"flex", flexDirection: "column", justifyContent: "center", flex:1  }}>
                <div className='back-button'>
                  <Link to={'/register'}>
                    <div className='back-icon-container'>
                      <BackIcon/>
                    </div>
                  </Link>
                </div>
                <h2 className='dashboard-title email-verify-code-container'>Please Verify Your Email!</h2>
                {emailParam ? (
                    <>
                      <p className="verify-email-text">Enter verification code received at: <span className={'special'}>{emailParam}</span></p>
                      <div className={`d-flex w-100 code-verification ${error === 'Invalid Code' ? 'error' : ''}`}>
                        <ReactCodeInput
                            placeholder={["#", "#", "#", "#"]}
                            fields={4}
                            values={verificationCode && [verificationCode[0],verificationCode[1],verificationCode[2],verificationCode[3]]}
                            className={'verify-code-inputs'}
                            onChange={(e) => {
                              setVerificationCode(e)
                            }}
                        />
                      </div>
                      <br />
                      {error === 'Invalid Code' ? (
                          <div className={'error-container-info'} style={{display: "flex", gap: 8, alignItems:"center"}}>
                            <Icon name={'info-circle'} size={20} color={'#909090'}/>
                            <p className={'text-error'}>The code is invalid <span className={'special'}>Try Again</span></p>
                          </div>
                      ) : null }

                      <div style={{ display:"flex",flexDirection:"column", gap:'25px'}}>
                        {/*<button style={{width: '150px'}} onClick={initiateResend} className={`secondaryButton ${timeLeft !== null ? "disabled" : ""}`}>
                        {
                          timeLeft !== null ? (
                              timeLeft > 28 ? "Sent!" : `Resend (${timeLeft}s)`
                          ) : (
                              "Resend"
                          )
                        }
                      </button>*/}
                        <button
                            style={{maxWidth: '290px',fontSize:16,fontFamily:'Poppins, sans-serif',fontWeight:300}}
                            className={`secondaryButton ${verificationCode && verificationCode.length === 4 ? "" : "disabled"}`}
                            onClick={attemptVerification}
                            disabled={verificationCode === undefined || verificationCode.length !== 4}>Verify Your Account</button>
                        <p className={'send-angain'}>Not received the code yet? <span onClick={initiateResend} className={'special'}>Send Me Code Again</span></p>
                      </div>
                    </>
                ) : null}
              </div>
              <Footer />
            </div>
          </div>
        </Window>
      </WindowLayout>


  )
}

export default Verify