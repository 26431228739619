import React, {useEffect, useState} from "react";
import WindowLayout from "../../windowLayout/window-layout";
import BackIcon from "../Rooms/chatroom/SVGComponents/back-icon";
import Icon from "../../icon/icon";
import {ButtonComponent} from "../../button-component/button-component";
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../../../stores/authentication";
import './user-profile.scss'
import Input from "../../input/input";
import SelectCountryPhoneCode from "../../select-country-phone/SelectCountryPhoneCode";
import {useCountryLocation} from "../../../stores/location";
import {UserProfilePicture} from "../../user-profile-picture";
import {MediaScreen} from "../Rooms/chatroom/encrypted/MediaScreen";
export const UserProfile = () => {
    const navigate = useNavigate();
    const {user,isAuthenticated} = useAuthentication()
    const { countryCode } = useCountryLocation()
    const {isMobile,isTablet} = MediaScreen()


    useEffect(() => {
        if (!isAuthenticated)
            navigate('/login')
    }, []);

    return (
       <WindowLayout>
           <div className={'create-group-container'}>
               <div className='back-button' style={{display: "flex", justifyContent: "space-between"}}>
                   <div className={'back-with-breadcrumbs'}>
                       <div className='back-icon-container' style={{minWidth:52}} onClick={() => {
                           navigate(-1)
                       }}>
                           <BackIcon/>
                       </div>
                       <div className={'breadcrumbs-container'}>
                           <span className={'active'}>Profile</span>
                       </div>
                   </div>
                   <div className={'global-button-group-settings'}>
                       <ButtonComponent
                           ClassName={'group-button-page'}
                           iconName={'save'}
                           bgOnHover={'transparent'}
                           buttonColor={'#42D3D8'}
                           IconColorHover={'#42D3D8'}
                           IconColor={'#fff'}
                           IconSize={20}
                           style={{maxWidth:isMobile ? 'unset' : '',width:'100%'}}
                           Text={'Update Profile'}
                           OnClick={() => {
                               navigate('/user-profile/update')
                           }}
                       />
                   </div>
               </div>
               <div className={'header-container'} style={{flexDirection: "row", alignItems: "center"}}>
                   <div style={{display: "flex", flexDirection: "column", gap: 12, flex: 1}}>
                        <span className={'title-window with-edit'}>{user.firstName} Profile</span>
                       <span className={'under-title-window'}>Your User Information</span>
                   </div>
               </div>
               <div className={'user-profile-container-content'}>
                   <div className={'content'}>
                       <div className={'user-profile-picture'}>
                           <UserProfilePicture width={184} height={184} fontSize={40} userId={user._id} userFirstName={user.firstName} userLastName={user.lastName[0]} />

                           <div className={'details-picture-container'}>
                               <div style={{display:"flex",flexDirection:"column",gap:8}}>
                                   <span className={'picture-header'}>{user.firstName} Profile Picture</span>
                                   <span className={'picture-details'}>This image it's shows on groups and meetings</span>
                               </div>
                             </div>
                       </div>
                       <div className={'basic-information'}>
                           <div style={{display:"flex",flexDirection:"column",gap:8}}>
                               <span className={'picture-header'}>Basic Information</span>
                               <span className={'picture-details'}></span>
                           </div>

                           <div className={'inputs-container'}>
                               <div className='two-inputs-container'>
                                   <Input
                                       minLength="2"
                                       name="firstname"
                                       label="First Name"
                                       capitalize
                                       disabled
                                       value={user.firstName}
                                   />
                                   <Input
                                       minLength="2"
                                       name="lastname"
                                       label="Last Name"
                                       disabled
                                       value={user.lastName}
                                       capitalize/>
                               </div>
                               <Input
                                   minLength="2"
                                   name="email"
                                   label="Email Address"
                                   disabled
                                   style={{width:'100%'}}
                                   value={user.email}
                                   capitalize/>
                               <SelectCountryPhoneCode
                                   disabled={true}
                                   countryCode={countryCode}
                                   value={user.phoneNumber}
                                   style={{width:'100%',marginBottom:0}}

                               />
                           </div>


                       </div>
                       <div className={'basic-information'}>
                           <div style={{display:"flex",flexDirection:"column",gap:8}}>
                               <span className={'picture-header'}>Password</span>
                               <span className={'picture-details'}></span>
                           </div>

                           <div className={'inputs-container'}>
                               <Input
                                   minLength="2"
                                   style={{width:'100%'}}
                                   name="current-password"
                                   label="Cuurrent Password"
                                   capitalize
                                   disabled
                                   value={'***************************'}
                               />
                           </div>


                       </div>
                   </div>
               </div>
           </div>
       </WindowLayout>
    )
}