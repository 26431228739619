import React, {useEffect, useState} from "react";
import {useGroups} from "../../../../../stores/groups";
import {useAuthentication} from "../../../../../stores/authentication";
import {useNavigate} from "react-router-dom";
import './select-group.scss'
import Icon from "../../../../icon/icon";
import {UserProfilePicture} from "../../../../user-profile-picture";
export const SelectGroup = ({label,onSelectGroup}) => {
    const query = new URLSearchParams(location.search);
    const { getGroups,groups,selectGroup,selectedGroup } = useGroups()
    const [groupId, setGroupId] =useState(query.get('id')) ;
    const { isAuthenticated,user } = useAuthentication()
    const navigate = useNavigate()
    const [groupList, setGroupList] = useState()
    const [showSelectGroup,setShowSelectGroup] = useState(false)
    useEffect(()=>{
        if(!isAuthenticated) {
            navigate("/login")
        }
        getGroups()
        if(groupId)
            selectGroup(groupId)
    },[groupId])
    useEffect(()=>{
        const glist = []
        if(groups) {
            groups.forEach((group, i) => {
                if(group) {
                    glist.push(group)
                }
            })
        }
        setGroupList(glist)
    },[groups])

    useEffect(() => {
        if (selectedGroup) {
            onSelectGroup(selectedGroup);
        }
    }, [selectedGroup, onSelectGroup]);

    return(
        <div className={'selector-group-container'}>
            <span className={'label-selector-group'}>{label ?? 'Select Group'}</span>
            <div className={'selector-value-container'} onClick={()=>{
                if (groupList.length)
                    setShowSelectGroup(!showSelectGroup)
            }}>
                {selectedGroup ? (
                    <>
                        <UserProfilePicture groupImage width={35} height={35} groupImageURL={selectedGroup.picture} />
                        <div className={'information'} style={{width:'85%'}}>
                            <span className={'item-title'}>{selectedGroup.name}</span>
                            <p className={'item-description'}>{selectedGroup.description ?? 'Lets gather some ideas together'}</p>
                        </div>

                    </>
                ) : (
                    <>
                        <img src={'/images/group-image-default.png'} alt='group-picture' style={{
                            backgroundColor: '#EAE9EC',
                            maxWidth: 35,
                            maxHeight: 35,

                            width: '100%',
                            height: '100%',
                            objectFit: "cover",
                            borderRadius: 4
                        }}/>
                        <div className={'information'} style={{width:'85%'}}>
                            <span className={'item-title'}>You don't have any group</span>
                            <p className={'item-description'}> You will have to add manualy the members You will have to add manualy the members You will have to add manualy the members You will have to add manualy the members</p>
                        </div>
                    </>
                )}
                <Icon className={'arrow-absolut'} name={'caret-down'} color={'#909090'} size={12}/>

                {showSelectGroup && groupList?.lenght !== 0 ? (
                    <div className={'list-selector-container'}>
                        <div className={'list-overflow-container'}>
                            {groupList.map((group, index) => {
                                return(
                                    <div onClick={()=>{
                                        setGroupId(group._id)
                                    }} key={`group-${index}`} className={'item-group'}>

                                        <UserProfilePicture groupImage width={35} height={35} groupImageURL={group.picture} />

                                        <div className={'information'} style={{width:'85%'}}>
                                            <span className={'item-title'}>{group.name}</span>
                                            <p className={'item-description'}>{group.description ?? 'Lets gather some ideas together'}</p>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>

                    </div>
                ) : null}


            </div>
        </div>
    )
}