import { useRef } from "react"
import { ChatIcon } from "../SVGComponents/chatIcon"
import { useEffect } from "react"
import Button from "../encrypted/Button"
import { useState } from "react"
import Message from "./Message"
import BadWords from "badwords"
import { useChatroom } from "stores/chatroom"
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { toArray } from "react-emoji-render";
import Icon from "../../../../icon/icon";
const parseEmojis = value => {
  const emojisArray = toArray(value);

  // toArray outputs React elements for emojis and strings for other
  const newValue = emojisArray.reduce((previous, current) => {
    if (typeof current === "string") {
      return previous + current;
    }
    return previous + current.props.children;
  }, "");

  return newValue;
};
const Chat = ({messages, sendMessage, userData, forceUpdate}) => {
  const { displayChatroom } = useChatroom()
  const scrollableContainerRef = useRef(null);
  const [newMessage, setNewMessage] = useState(``)
  const [displayPicker, setDisplayPicker] = useState(false)
  const messageList = []
  if(messages) {
    messages.forEach((message, index) => {
      const parsedMessage = JSON.parse(message.content)
      if(parsedMessage.show) {
        messageList.push(<Message key={`${index}`} isOwner={message.sender_id === undefined} sendMessage={sendMessage} userData={userData} forceUpdate={forceUpdate} sender={message.sender && message.sender.userData ? message.sender.userData : message.sender} message={parsedMessage} date={`${message.time.getHours()}:${message.time.getMinutes()+3}`} messages={messages}/>)
      }
    })
  }
  const sendMsg = () => {
    if (newMessage!=='') {
      sendMessage(JSON.stringify({
        text: BadWords(newMessage) ? "****" : newMessage,
        uuid: crypto.randomUUID(),
        show: true
      }), userData)
      setNewMessage("")
    }
  }
  useEffect(() => {
    if(displayChatroom){
      const container = scrollableContainerRef.current;
      container.scrollIntoView({behavior: 'smooth'})
      container.scrollTo(0,99999999)
    }

  }, [displayChatroom, messages]);

  function getCurrentDateFormatted() {
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December"
    ];

    const date = new Date();
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  }

  return displayChatroom ? (
    <div className={`MeetingChatContainer ${displayChatroom ? 'chatActive': ''}`}>
      <div className='MeetingWindowRightHeader'>
        <span className='TitleWindowRight'>Chat</span>
      </div>
      <div className='ChatMessagesContainer' style={{ overflow: 'auto' }}>
        <div className='messages' >
          {/*  Start of the map messages*/}
          <span className='currentDate'>{getCurrentDateFormatted()}</span>
          {messageList}
          {/*  End of the map messages*/}

          <span ref={scrollableContainerRef}/>
        </div>
      </div>
      <div className='writeMessageTextArea'>
        <textarea  placeholder={'Type your message here...' } value={newMessage} onChange={(e=>{
          if(e.target.value === "\n") {
            e.preventDefault()
          } else {
            setNewMessage(parseEmojis(e.target.value))
          }
        })} onKeyDown={e=>{
          if (e.key === 'Enter' && e.shiftKey === false && e.target.value !== '') {
            e.preventDefault()
            if(e.target.value.length > 0) {
              sendMsg()
            } else {
              setNewMessage("")
            }
          }
        }}></textarea>
      </div>
      <div className='options-send-message'>
        <div className={`show-picker-emoji ${displayPicker ? 'active-picker' : ''} `} onClick={(e)=>{e.stopPropagation(); setDisplayPicker(!displayPicker)}}>
          <Icon  name={'grey-smile'} size={20} color={'#979797'}/>
        </div>
        {/*<div>
          <img src='/images/grey-picture.svg' alt='picture' width={20} height={20}/>
        </div>
        <div>
          <img src='/images/grey-file.svg' alt='file' width={20} height={20}/>
        </div>
        <div>
          <img src='/images/grey-clip.svg' alt='clip' width={20} height={20}/>
        </div>
        <div>
          <img src='/images/grey-dots-vertical.svg' alt='dots' width={20} height={20}/>
        </div>*/}
        <div className='picker-container'>
          {
              displayPicker && (
                  <Picker
                      data={data}
                      onEmojiSelect={(e)=>{setNewMessage(newMessage+e.native)}}
                      onClickOutside={(e)=>{setDisplayPicker(false)}} />
              )
          }
        </div>

        <button className='submit-message' onClick={sendMsg} type={'submit'}>
          <img src='/images/white-paper-plane.svg' alt='paper-plane' width={16} height={16}/>
        </button>
      </div>
    </div>
  ) : (
    <></>
  )

}
export default Chat