import Input from "components/input/input"
import Window from "../window"
import { useState } from "react"
import Checkbox from "components/checkbox"
import { useCountryLocation } from "stores/location";
import { useAuthentication } from "stores/authentication";
import { useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import Footer from "../../../layout/footer";
import SelectCountryPhoneCode from "../../../select-country-phone/SelectCountryPhoneCode";
import WindowLayout from "../../../windowLayout/window-layout";
import {MediaScreen} from "../../Rooms/chatroom/encrypted/MediaScreen";

const Register = () => {
  const navigate = useNavigate()
  const { register, isAuthenticated } = useAuthentication() 
  const { countryCode } = useCountryLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  const [phone, setPhone] = useState("")
  const {isCustomQuery} = MediaScreen('900px')

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [errors, setErrors] = useState('')
  const emailValidations = [
    {
      message: 'This format is not accepted',
      check: (input) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(input);
      }
    },
    {
      message: "Requires not to be empty",
      check: (input) => {
        return input.length >= 0;
      }
    },
    {
      message: "Requires an '@' symbol",
      check: (input) => {
        return input.includes('@');
      }
    },
    {
      message: "Requires a domain name after '@'",
      check: (input) => {
        let parts = input.split('@');
        return parts[1] && parts[1].includes('.');
      }
    },
    {
      message: "Requires a '.' after the domain name",
      check: (input) => {
        let domainPart = input.substring(input.lastIndexOf("@") + 1);
        return domainPart.includes('.') && domainPart.indexOf('.') !== 0;
      }
    },
    {
      message: "Requires a valid top-level domain (e.g., .com, .ro)",
      check: (input) => {
        let domainPart = input.substring(input.lastIndexOf("@") + 1);
        return domainPart.match(/\.[a-zA-Z]{2,}$/);
      }
    },
    {
      message: "Cannot contain '<', '>', or other unsafe characters",
      check: (input) => {
        return !input.match(/[<>]/);
      }
    },
    {
      message: "Email must not contain spaces or special characters like '()<>[]:,;\\\"#'*/?`|'",
      check: (input) => {
        return !input.match(/[\s()<>[\]:,;\\"#'*/?`|]/);
      }
    }
  ];
  const nameValidations = [
    {
      message: "Requires not to be empty",
      check: (input) => {
        return input.length > 0;
      }
    }
  ]
  const phoneValidations = [
    {
      message: "Requires not to be empty",
      check: (input) => {
        return input.length > 0;
      }
    },
    {
      message: "Requires min. 6 numbers",
      check: (input) => {
        return input.length >= 6;
      }
    },
    {
      message: "Requires max. 14 numbers",
      check: (input) => {
        return input.length <= 14;
      }
    }
  ]
  const passwordValidations = [
    {
      message: "Requires not to be empty",
      check: (input) => {
        return input.length > 0;
      }
    },
    {
      message: "Requires at least 6 characters",
      check: (input) => {
        return input.length >= 6
      }
    },
    {
      message: "Requires at least one uppercase letter (A-Z)",
      check: (input) => {
        return input.match(/[A-Z]/)
      }
    },
    {
      message: "Requires at least one lowercase letter (a-z)",
      check: (input) => {
        return input.match(/[a-z]/)
      }
    }
  ]
  const repeatPassowrdValidations = [
    {
      message: "Don't match",
      check: (input)=>{
        const pattern  = `(?<![\w\d])${password}(?![\w\d])`
        return input.match(pattern)
      }
    },
    {
      message: "Requires not to be empty",
      check: (input) => {
        return input.length > 0;
      }
    },
    {
      message: "Requires at least 6 characters",
      check: (input) => {
        return input.length >= 6
      }
    },
    {
      message: "Requires at least one uppercase letter (A-Z)",
      check: (input) => {
        return input.match(/[A-Z]/)
      }
    },
    {
      message: "Requires at least one lowercase letter (a-z)",
      check: (input) => {
        return input.match(/[a-z]/)
      }
    }
  ]
  const checkEmail = () => {
    for (let i = 0; i < emailValidations.length; i++) {
      if (!emailValidations[i].check(email)) {
        setErrors(errors + ' email')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkPassword = () =>{
    for (let i = 0; i < passwordValidations.length; i++) {
      if (!passwordValidations[i].check(password)) {
        setErrors(errors + ' password')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkRepeatPassword = () =>{
    for (let i = 0; i < repeatPassowrdValidations.length; i++) {
      if (!repeatPassowrdValidations[i].check(confirmPassword)) {
        setErrors(errors + ' confirmPassword')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkPhone = () =>{
    for (let i = 0; i < phoneValidations.length; i++) {
      if (!phoneValidations[i].check(phone)) {
        setErrors(errors + ' phone')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkFirstName = () =>{
    for (let i = 0; i < nameValidations.length; i++) {
      if (!nameValidations[i].check(firstName)) {
        setErrors(errors + ' firstName')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkLastName = () =>{
    for (let i = 0; i < nameValidations.length; i++) {
      if (!nameValidations[i].check(lastName)) {
        setErrors(errors + ' lastName')
        setDisableSubmit(true)
        return false;
      }
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const checkTermsAgree = () => {
    if (termsAgreed === false) {
      setDisableSubmit(true)
      setErrors(errors + ' agree')
      return false
    }
    setErrors('')
    setDisableSubmit(false)
    return true;
  }
  const [firstSubmit,setFirstSubmit] = useState(false)
  useEffect(()=>{
    if(firstSubmit)
      checkCreatRoomForm()
  },[firstSubmit,termsAgreed,firstName,lastName,password,confirmPassword,phone,email])
  const checkCreatRoomForm = ()=>{
    if(checkFirstName() &&
        checkLastName() &&
        checkPhone() &&
        checkEmail() &&
        checkPassword() &&
        checkRepeatPassword() && checkTermsAgree()){
      return true
    }
  }

  const [marginLeft,setMarginLeft] = useState()
  useEffect(()=>{
    if(isAuthenticated) {
      navigate("/")
    }
    const dashboardImage= document.querySelector('.dashboard-image-container')
    if (dashboardImage)
      setMarginLeft(dashboardImage.offsetWidth)
  },[])
  const resetState = () => {
    setIsLoading(false)
    setError("")
    setSuccess("")
    setFirstName("")
    setLastName("")
    setEmail("")
    setPassword("")
    setConfirmPassword("")
    setTermsAgreed(false)
    setPhone("")
  }
  const attemptRegistration = async (e) => {
    e.preventDefault()
    if (!checkCreatRoomForm())
      return
    setIsLoading(true)
    const result = await register({
      email: email.toLowerCase(),
      password,
      firstName: firstName.toLowerCase(),
      lastName: lastName.toLowerCase(),
      phoneNumber: Number(phone)
    })
    if(result === true) {
      resetState()
      setSuccess("Registered successfully!")
      setTimeout(()=>{
        navigate(`/verify?email=${email}`)
      }, 2000)
    } else {
      setError(result)
      setIsLoading(false)
      setTimeout(()=>{
        setError("")
        if(result === "Please verify your email.") {
          navigate(`/verify?email=${email}`)
        }
      }, 2000)
    }
  }
  console.log(errors)
  return (
      <WindowLayout>
        <Window
          title="Register"
          message="Processing your application"
          isLoading={isLoading}
          minHeight="545px"
          error={error}
          success={success}>

          <div className="w-100 flex-row d-flex register dashboad-container">
            <div className='dashboard-image-container' style={{position:"fixed",height:'100vh',left:0, top:0}}>
              <img src="/images/frame-boom-dashboard.png" style={{paddingTop:114}} alt="frame-boom-dashboard" draggable="false" />
            </div>
            <div className="login-register register dashboard-content-container" style={{marginLeft: `${marginLeft}px`,opacity:`${isCustomQuery ? 1 : marginLeft ? 1 : 0}`}}>
              <div className='dashboard-content'>
                <div className='back-button'>
                  <Link to={'/'}>
                    <div className='back-icon-container'>
                      <BackIcon/>
                    </div>
                  </Link>
                </div>
                <h2 className='dashboard-title'>Welcome To Boom!</h2>
                <div className='dashboard-tabs-buttons-container'>
                  <span className='dashboard-login-title' onClick={()=>{setShowTab('createMeeting')}}>Create Your Account</span>
                </div>
                <form noValidate onSubmit={attemptRegistration}>
                  <div className='two-inputs-container'>
                    <Input
                        minLength="2"
                        name="firstname"
                        label="First Name"
                        placeholder='Enter first Name'
                        pattern="[A-Za-z]{1,32}"
                        required
                        capitalize
                        validations={nameValidations}
                        foundError={errors.includes('firstName')}
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value)
                          checkFirstName()
                        }} />
                    <Input
                        minLength="2"
                        name="lastname"
                        label="Last Name"
                        placeholder='Enter last Name'
                        pattern="[A-Za-z]{1,32}"
                        value={lastName}
                        required
                        validations={nameValidations}
                        foundError={errors.includes('lastName')}
                        capitalize
                        onChange={(e) => {
                          setLastName(e.target.value)
                          checkLastName()
                        }} />

                  </div>
                  <div className='two-inputs-container' >
                    <SelectCountryPhoneCode
                        value={phone}
                        countryCode={countryCode}
                        validations={phoneValidations}
                        required
                        foundError={errors.includes('phone')}
                        onChange={(e)=>{
                          setPhone(e)
                          checkPhone()
                        }} />
                    <Input
                        name="email"
                        label="Email"
                        type="email"
                        value={email}
                        autoComplete="email"
                        required
                        maxLength='64'
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                        placeholder={'Enter email address'}
                        foundError={errors.includes('email')}
                        validations={emailValidations}
                        onChange={(e) => {
                          setEmail(e.target.value)
                          checkEmail()
                        }} />

                  </div>
                  <div className='two-inputs-container'>
                    <Input
                        name="password"
                        label="Password"
                        autoComplete="new-password"
                        placeholder='Enter password'
                        type="password"
                        minLength="6"
                        maxLength="32"
                        value={password}
                        validations={passwordValidations}
                        foundError={errors.includes('password')}
                        pattern="^(?=.*[a-z])(?=.*[A-Z]).{6,32}$"
                        required
                        onChange={(e) => {
                          setPassword(e.target.value)
                          checkPassword()
                        }} />
                    <Input
                        name="confirmPassword"
                        label="Repeat Password"
                        autoComplete="new-password"
                        placeholder='Repeat password'
                        type="password"
                        foundError={errors.includes('confirmPassword')}
                        title="Passwords must match!"
                        validations={repeatPassowrdValidations}
                        pattern={`(?<![\w\d])${password}(?![\w\d])`}
                        value={confirmPassword}
                        required
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                          checkRepeatPassword()
                        }} />

                  </div>
                 <Checkbox
                      value={termsAgreed}
                      onClick={() => {
                        setFirstSubmit(true)
                        setTermsAgreed(!termsAgreed)
                        checkTermsAgree()
                      }}
                      required
                      label="I agree to the"
                      foundError={errors.includes('agree')}
                      linkLabel="Booom Disclaimer"
                      linkTo="/disclaimer" />
                  <input
                      className={
                        `SecondaryButton halfDashboardButton ${
                            disableSubmit ?
                                "disabled" : ""
                        }`}
                      type="submit"
                      value="Sign Up"
                      required />
                  <div className='link-to-register-login'>
                    <p className="">Already have an account? </p>
                    <Link to={'/login'}>Sign In</Link>
                  </div>
                </form>
              </div>
              <Footer />
            </div>
          </div>
        </Window>
      </WindowLayout>
  )
}
export default Register