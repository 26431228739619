import Checkbox from "components/checkbox"
import Icon from "components/icon/icon"
import Input from "components/input/input"
import { useState } from "react"
import {Link, useNavigate} from "react-router-dom"
import Footer from "../../layout/footer";
import { usePreferences } from "stores/preferences"
import { useAuthentication } from "stores/authentication"

const CreateRoom = (props) => {
  const navigate = useNavigate()
  const {isAuthenticated} = useAuthentication()
  const [roomName, setGroupName] = useState("")
  const [yourName, setYourName] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  const {nickname, setNickname} = usePreferences()
    const [errors, setErrors] = useState('')

    const checkCreatRoomForm = ()=>{
        if(roomName.length < 3)
            setErrors(errors + ' meetingName')
        else if (nickname.length < 3)
            setErrors(errors + ' yourName')
        else if (termsAgreed === false)
            setErrors(errors + ' agree')
        else {
            setErrors('')
            return true
        }
    }

  const proceed = (e) => {
    e.preventDefault()
    checkCreatRoomForm()
    if(checkCreatRoomForm())
        navigate(`/createRoom?roomName=${roomName}`)
  }
  return (
    <div className={`${props.className} d-flex flex-column justify-content-center align-items-center`}>
      <form onSubmit={proceed} noValidate className="w-100">
        <Input
          name="create-room"
          label="Meeting Name"
          type="text"
          placeholder='Meeting name'
          value={roomName}
          autoComplete="none"
          capitalize
          minLength="3"
          validations={[
              {
                  message: "Requires at least 3 characters",
                  check: (input) => {
                      return input.length >= 3
                  }
              }
          ]}
          required
          foundError={errors.includes('meetingName')}
          onChange={(e) => setGroupName(e.target.value)} />
          <Input
              name="your-name"
              label="Your Name"
              type="text"
              placeholder='Your Name'
              value={nickname}
              autoComplete="none"
              minLength="3"
              capitalize
              required
              foundError={errors.includes('yourName')}
              validations={[
                  {
                      message: "Requires at least 3 characters",
                      check: (input) => {
                          return input.length >= 3
                      }
                  }
              ]}
              onChange={(e) => setNickname(e.target.value)} />
        <Checkbox
          value={termsAgreed}
          onClick={() => setTermsAgreed(!termsAgreed)}
          foundError={errors.includes('agree')}
          required
          label="I agree to the"
          linkLabel="Booom Disclaimer"
          linkTo="/disclaimer" />
        <input
          className={
            `SecondaryButton halfDashboardButton ${termsAgreed && roomName && roomName.length >= 3 ?
              "" : "disabled"
            } mw-[290px]`}
          type="submit"
          value="Join Room"
          required />
          {
            isAuthenticated ? null : (
              <>
                <div className='link-to-register-login'>
                    <p className="">Have an account? </p>
                    <Link to={'/login'}>Sign In</Link>
                </div>
                <div className='link-to-register-login' style={{marginTop:10}}>
                    <p className="">Don't have an account? </p>
                    <Link to={'/register'}>Register</Link>
                </div>
              </>
            )
          }
      </form>
    </div>
  )
}
export default CreateRoom