import { useToast } from "stores/toast"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


const ToastHandler = () => {
  const {link, icon, message, addToast, count, lastCount, equalizeCount} = useToast()
  const navigate = useNavigate()
  if(!window.$addToast) {
    window.$addToast = addToast
  }
  useEffect(()=>{
      if(lastCount !== count) {
        toast(<>
          <div className="OurToastStyle" onClick={ link ? ()=>navigate(link) : null}>
          {icon === "info" ? (
            <p className="tIcon">ℹ</p>
          ) : null}
          {icon === "friend" ? (
            <p className="tIcon">F</p> // Aici svg n-ai nevoie de p
          ) : null}
          {icon === "meeting" ? (
            <p className="tIcon">M</p> // Aici svg n-ai nevoie de p
          ) : null}
            <p>{message}</p>
          </div>
        </>,
        {
          position: "top-right",
          draggable: true,
          pauseOnHover: true,
          closeButton: false,
        })
        equalizeCount()
      }
  },[link, message, count, equalizeCount])
}

export default ToastHandler