import { setLogLevel, VideoStream, useUserMediaDevices, useCameraStream, useConversation, useConversationContacts, useConversationStreams, useSession, useStreamApplyVideoProcessor } from "@apirtc/react-lib";
import Encrypted from "./Encrypted";
import { useState } from "react";
import { useRooms } from "stores/rooms";
import { usePreferences } from "stores/preferences";
import { useMemo } from "react";
import { getInitials } from "utils";
import { useEffect } from "react";
import {EmptyRoomChanel} from "./EmptyRoomChanel";
import { useRef } from "react";

const RoomControl = () => {
  setLogLevel("error")
  const [ muted, setMuted ] = useState(false)
  const [backgroundImage, setBackgroundImage] = useState("https://miro.medium.com/v2/resize:fit:1024/1*ZquHdlRMY9OxfUTviM84Ow.png")
  const [noiseCancelling, setNoiseCancelling] = useState(false)
  const [videoEffect, setVideoEffect] = useState("none")
  const { clearRoom, current, kick } = useRooms()
  const { nickname: displayName } = usePreferences()
  const [options, setOptions] = useState({
    
  })
  const [showSettings, setShowSettings] = useState(false)
  const [ shareStream, setShareStream ] = useState(null)
  const [ sharing, setSharing ] = useState(false)
  const { session } = useSession({ apiKey: 'a7cde107bdec57bb24c312fac6d69b89'})
  const { userMediaDevices } = useUserMediaDevices(session)
  const { stream: localStream } = useCameraStream(session, options);
  const { stream: processedStream } = useStreamApplyVideoProcessor(localStream, videoEffect, {
    backgroundImageUrl: backgroundImage,
  })
  const [ videoMuted, setVideoMuted] = useState(false)
  const { conversation } = useConversation(session, current.id, true)
  const { contacts } = useConversationContacts(conversation);
  const [forceUpdate, setForceUpdate] = useState(0)
  const [initializedStreamUpdateListener, setInitializedStreamUpdateListener] = useState(false)
  const streamsToPublish = useMemo(
    () => (processedStream ? [{ stream: processedStream }] : []),
    [processedStream]
  );
  const { publishedStreams, subscribedStreams } = useConversationStreams(
    conversation,
    streamsToPublish
  );
  const audioInputList = Object.keys(userMediaDevices.audioinput).filter(device=>{
    return device !== "Communications" && device !== "default"
  })
  const videoInputList = Object.keys(userMediaDevices.videoinput).filter(device=>{
    return device !== "Communications" && device !== "default"
  })
  let audioDevices = []
  audioInputList.forEach(identifier => {
    const device = userMediaDevices.audioinput[identifier]
    let formattedLabel = device.label
    if(formattedLabel.includes("Microphone ")) {
      formattedLabel = formattedLabel.replace("Microphone ", "")
    }
    if(formattedLabel.includes("(")) {
      formattedLabel = formattedLabel.replaceAll("(", "")
    }
    if(formattedLabel.includes(")")) {
      formattedLabel = formattedLabel.replaceAll(")", "")
    }
    if(!formattedLabel.includes("Communications - ")) {
      audioDevices.push({
        label: formattedLabel,
        value: device.id
      })
    }
  })
  let videoDevices = []
  videoInputList.forEach(identifier => {
    const device = userMediaDevices.videoinput[identifier]
    videoDevices.push({
      label: device.label.replace(/\s*\(.*?\)/g, ""),
      value: device.id
    })
  })
  const changeMicrophone = async (value) => {
    if(value.label === "No Microphone") {
      localStream.disableAudio()
      setMuted(true)
    } else {
      setMuted(false)
      localStream.enableAudio()
      setMuted(false)
      const settings = await localStream.getSettings()
      const createStreamOptions = {
        audioInputId: value.value,
        videoInputId: settings.video.deviceId
      }
      setOptions(createStreamOptions)
    }
  }
  const toggleMute = async () => {
    if(localStream) {
      if(muted) {
        localStream.enableAudio()
        if(sharing && shareStream) {
          shareStream.enableAudio()
        }
      } else {
        localStream.disableAudio()
        if(sharing && shareStream) {
          shareStream.disableAudio()
        }
      }
      setMuted(!muted)
    }
  }
  const toggleScreenSharing = async () => {
    if(!sharing) {
      const displayMediaStreamConstraints = {
        video: {
            cursor: "always"
        },
        audio: {
            // echoCancellation: true,
            // noiseSuppression: true,
            // sampleRate: 44100
        }
      }
      const shareScreenStream = await apiRTC.Stream.createDisplayMediaStream(displayMediaStreamConstraints, false)
      let localAudioData
      if(localStream) {
        localAudioData = localStream.data.getAudioTracks()[0]
        if(localAudioData) {
          shareScreenStream.data.addTrack(localAudioData.clone())
        }
      } 
      shareScreenStream.on('stopped', ()=>{
        conversation.unpublish(shareScreenStream)
        if(processedStream) {
          conversation.publish(processedStream)
        }
        setSharing(false)
        setShareStream(null)
      })
      const success = shareScreenStream !== undefined
      if(success) {
        conversation.publish(shareScreenStream)
        if(processedStream) {
          conversation.unpublish(processedStream)
        }
        setShareStream(shareScreenStream)
        setSharing(true)
      }
    } else {
        if(processedStream) {
          conversation.publish(processedStream)
        }
        conversation.unpublish(shareStream)
        setSharing(false)
        shareStream.release()
        setShareStream(null)

    }
  }
  const toggleVideoMute = async () => {
    if(processedStream) {
      if(videoMuted) {
        localStream.enableVideo()
        if(sharing && shareStream) {
          shareStream.enableVideo()
        }
      } else {
        localStream.disableVideo()
        if(sharing && shareStream) {
          shareStream.disableVideo()
        }
      }
      setVideoMuted(!videoMuted)
    }
  }
  const toggleDeafened = async () => {
    setDeafened(!deafened)
  }
  const toggleSettings = () => {
    setShowSettings(!showSettings)
  }
  const changeCamera = async (value) => {
    if(value.label === "No Camera") {
      localStream.disableVideo()
    } else {
      localStream.enableVideo()
      setMuted(false)
      const settings = await localStream.getSettings()
      const createStreamOptions = {
        audioInputId: settings.audio.deviceId,
        videoInputId: value.value
      }
      setOptions(createStreamOptions)
    }
  }
  window.forceUpdate = forceUpdate
  const incrementForceUpdate = () => {
    setForceUpdate(forceUpdate+1)
  }
  const incrementForceUpdateRef = useRef(incrementForceUpdate)
  incrementForceUpdateRef.current = incrementForceUpdate
  if(!initializedStreamUpdateListener && conversation) {
    setInitializedStreamUpdateListener(true)
    conversation.on("streamListChanged", (e)=>{
      setTimeout(()=>{
        if(incrementForceUpdateRef.current) {
          console.log(incrementForceUpdateRef)
          incrementForceUpdateRef.current()
        }
      }, 200)
    })
  }
  useEffect(()=>{
    return () => {
      clearRoom()
      // setTimeout(()=>{window.location.reload(), 100})
    }
  }, [clearRoom])
  const copyMeetingLink = () => {
    navigator.clipboard()
  }
  return (
    contacts && contacts.length > 0 ? (
      <Encrypted
        session={session}
        toggleMute={toggleMute}
        kick={kick}
        subscribedStreams={subscribedStreams}
        localStream={localStream}
        processedStream={processedStream}
        backgroundImage={backgroundImage}
        setBackgroundImage={setBackgroundImage}
        noiseCancelling={noiseCancelling}
        setNoiseCancelling={setNoiseCancelling}
        videoEffect={videoEffect}
        setVideoEffect={setVideoEffect}
        setOptions={setOptions}
        contacts={contacts}
        clearRoom={clearRoom}
        conversation={conversation}
        current={current}
        displayName={displayName}
        audioDevices={audioDevices}
        videoDevices={videoDevices}
        changeCamera={changeCamera}
        changeMicrophone={changeMicrophone}
        userMediaDevices={userMediaDevices}
        muted={muted}
        setMuted={setMuted}
        sharing={sharing}
        toggleScreenSharing={toggleScreenSharing}
        shareStream={shareStream}
        toggleDeafened={toggleDeafened}
        toggleVideoMute={toggleVideoMute}
        videoMuted={videoMuted}
        showSettings={showSettings}
        toggleSettings={toggleSettings}
      />
    ) : (
      <div>
        <div className="left">
          {
            <EmptyRoomChanel
              setOptions={setOptions}
              muted={muted}
              toggleMute={toggleMute}
              current={current}
              conversation={conversation}
              displayName={displayName}
              localStream={localStream}
              processedStream={processedStream}
              changeCamera={changeCamera}
              changeMicrophone={changeMicrophone}
              audioDevices={audioDevices}
              videoDevices={videoDevices}
              noiseCancelling={noiseCancelling}
              setNoiseCancelling={setNoiseCancelling}
              backgroundImage={backgroundImage}
              setBackgroundImage={setBackgroundImage}
              videoEffect={videoEffect}
              setVideoEffect={setVideoEffect}
              sharing={sharing}
              videoMuted={videoMuted}
              toggleScreenSharing={toggleScreenSharing}
              shareStream={shareStream}
              toggleDeafened={toggleDeafened}
              toggleVideoMute={toggleVideoMute}
              addShareScreenFullActive={sharing}
              userMediaDevices={userMediaDevices}
              showSettings={showSettings}
              toggleSettings={toggleSettings}
            />
          }
        </div>
        <div className="right">
          <div onClick={copyMeetingLink}></div>
        </div>
      </div>
    )
  )
}
export default RoomControl