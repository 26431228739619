import { io } from "socket.io-client"

class Comms {
  constructor(address) {
    this.initialized = false
    this.address = address
    this.socket = null
    this.awaitingResponse = {}
    this.listeners = {}
    this.listenersId = 0
    this.special = {}
  }
  setSpecial(what, action) {
    this.special[what] = action
  }
  activateSpecialHandlers() {
    Object.keys(this.special).forEach(key => {
      this.socket.on(key, this.special[key])
    })
  }
  awaitingResponseHandler(event, args) {
    if(Object.keys(this.awaitingResponse).includes(event)) {
      this.awaitingResponse[event](args)
    }
  }
  listenersHandler(event, args) {
    if(Object.keys(this.listeners).includes(event)) {
      this.listeners[event].forEach(listener => {
        listener.handler(args)
      })
    }
  }
  addListener(event, func) {
    if(!this.listeners[event]) {
      this.listeners[event] = []
    }
    this.listeners[event].push({id: this.listenersId, handler: func})
    return this.listenersId++
  }
  removeListener(id) {
    const keys = Object.keys(this.listeners)
    for(let i=0; i<keys.length;i++) {
      const key = keys[i]
      for(let x=0;x<this.listeners[key].length;x++){
        if(this.listeners[key][x].id === id) {
          // delete(this.listeners[key][x])
          this.listeners[key].splice(x,1)
          if(this.listeners[key].length === 0) {
            delete(this.listeners[key])
          }
          return true
        }
      }
    }
    return false
  }
  initialize() {
    if(!this.initialized) {
      this.socket = io(this.address)
      this.activateSpecialHandlers()
      this.socket.onAny((event, args) => {
        this.awaitingResponseHandler(event, args)
        this.listenersHandler(event, args)
      })
      this.initialized = true
    }
  }
  request(event, args) {
    if(Object.keys(this.awaitingResponse).includes(event)){
      throw(new Error(`Error: Already waiting for event called ${event}`))
    }
    this.socket.emit(event, args)
    const result = new Promise((resolve, reject)=>{
      let vm = this
      this.awaitingResponse[event] = function(args) {
        if(args.success) {
          resolve(args)
        } else {
          reject(args)
        }
        delete(vm.awaitingResponse[event])
      }
    })
    return result
  }
}
const TrueComms = new Comms(process.env.REACT_APP_WS)
if(!window.$comms) {
  window.$comms = TrueComms
}

export default TrueComms