import Countdown from "components/countdown"
import Logo from "components/logo"
import { useNavigate } from "react-router-dom"
import Footer from "../layout/footer";
import WindowLayout from "../windowLayout/window-layout";

const MaxTime = () => {
  const navigate = useNavigate()
  return (
      <WindowLayout>
        <div className="w-100 flex-row d-flex dashboad-container">
          <div className='dashboard-image-container'>
            <img src="/images/frame-boom-dashboard.png" alt="frame-boom-dashboard" draggable="false" />
          </div>
          <div className="dashboard-content-container home-page ">
            <div className='dashboard-content'>
              <h2 className='dashboard-title'>Session Ended</h2>
              <div className='dashboard-tabs-buttons-container'>
                <span className='dashboard-login-title'>This page doesn't exist</span>
              </div>
              <p className="verify-email-text">Maximum room time of 1 hour has been exceeded, create or join a new room to continue your conversation.</p>
              <p className="verify-email-text">You will be returned home in <Countdown time={15} onEnded={()=>{navigate("/")}}/>s...</p>
            </div>
            <Footer />

          </div>
        </div>
      </WindowLayout>
  )
}

export default MaxTime
{/* <p className="w-55 p-1">You will be returned home in <Countdown onEnded={()=>{navigate("/")}}/>s...</p> */}