import { VideoStream } from "@apirtc/react-lib";
import Input from "components/input/input";
import React, {useEffect, useRef} from "react";
import { useState } from "react";
import ReactSelect from "react-select";
import { usePreferences } from "stores/preferences";
import Button from "./Button";
import {shopModal} from "../../../../layout/shopModal";
import Icon from "../../../../icon/icon";
import {ButtonComponent} from "../../../../button-component/button-component";
import {LeftArrow} from "../../../../icon/LeftArrow";
import {CloseIcon} from "../SVGComponents/closeIcon";
import {MediaScreen} from "./MediaScreen";
const MenuItem = ({label, icon, onClick,active}) => {
  return (
      <div className={`item-menu ${active ? 'active' : ''}`} onClick={onClick}>
        <img src={icon}/>
        <span>{label}</span>
      </div>
  )
}
const ImageSquare = ({image, active, onClick, text}) => {
  return (
    <div className='image-container'>
      {image && <img onClick={onClick} src={image} className={active ? 'active' : ''} alt='image2'/>}
      {!image && (
        <div onClick={onClick} className={active ? 'active' : ''}style={{width: "150px", height: "100px", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#190721"}}>
          <p>
            {text}
          </p>
        </div>
      )}
    </div>
  )
}
const AddBackground = ({forceUpdate})=>{
  const ref = useRef()
  const handleClick = () => {
    if(ref && ref.current && ref.current.click) {
      ref.current.click()
    }
  }
  const handleChange = (e) => {
    var file    = document.querySelector('input[type=file]').files[0];
    var reader  = new FileReader();
    reader.readAsDataURL(file)
    reader.onloadend = ()=>{
      const item = window.$customBackgroundImageList
      const customBackgroundImageList = item ? item : []
      const base64data = reader.result
      window.$customBackgroundImageList = [...customBackgroundImageList, base64data]
    }
    setTimeout(()=>{
      forceUpdate()
    }, 250)
  }
  return(
    <>
      <input onChange={handleChange} ref={ref} style={{visibility:"hidden", display: "none"}} type="file" id="backgroundImage" name="backgroundImage" accept="image/png, image/jpeg" />
      <ButtonComponent
          ClassName={'button-card'}
          style={{margin:'16px 0'}}
          iconName={'image-plus'}
          IconColor={'#fff'}
          IconSize={25}
          IconColorHover={'#835FF7'}
          bgOnHover={'transparent'}
          buttonColor={'#835FF7'}
          textColor={'#fff'}
          withStroke
          Text={'Add Background'}
          OnClick={handleClick}
      />
    </>
  )
}
function dataURItoBlob(dataURI)
{
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;

    if(dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for(var i = 0; i < byteString.length; i++)
    {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
}
const DevicesModal = ({
  mobile,
  stream,
  processedStream,
  changeCamera,
  changeMicrophone,
  audioDevices,
  videoDevices,
  noiseCancelling,
    whiteBoard,
    setWhiteBoard,
    shareScreen,
    setShareScreen,
    fullScreen,
    setFullScreen,
    addPeople,
    setAddPeople,
  setNoiseCancelling,
  backgroundImage,
  setBackgroundImage,
  videoEffect,
  setVideoEffect,
  toggleCloseSettingsModal
}) => {
  const [forceUpdate, setForceUpdate] = useState(false)
  const toggleForceUpdate = () => {
    setForceUpdate(!forceUpdate)
  }
  const {nickname, setNickname} = usePreferences()
  const [desiredName, setDesiredName] = useState(nickname)
  const imageList = [
    "image1.png",
    "image2.png"
  ]
  const backgroundImages = [
    {
      type: "text",
      value: "none"
    },
    {
      type: "text",
      value: "blur"
    },
  ]
  imageList.forEach(img=>{
    backgroundImages.push({
      type: "image",
      value: "https://"+window.location.host+"/images/"+img
    })
  })
  const storedImages = window.$customBackgroundImageList || []
  if(!storedImages) {
    window.$customBackgroundImageList = []
  }
  storedImages.forEach(img => {
    const blob = dataURItoBlob(img);
    const imgURL = URL.createObjectURL(blob);
    console.dir(imgURL)
    backgroundImages.push({
      type: "image",
      value: imgURL
    })
  })
  const [menus, setMenus] = useState([
    {
      label: "Audio Device",
      icon: "/images/white-microphone.svg",
    },
    {
      label: "Video Device",
      icon: "/images/white-video-camera.svg",
    },
    {
      label: "Background",
      icon: "/images/white-picture.svg",
    },
    {
      label: "Profile",
      icon: "/images/white-user-add.svg",
    },
  ]);
  const [menu, selectMenu] = useState(!mobile ? menus[0].label : '')
  const {showModal,accept,setAccept, setShow} = shopModal()
  const [statusChangeName,setStatusChangeName] = useState(false)
  const {is981px,is923px,is861px,is795px,is735px,is669px,is607px,is543px,is490px} = MediaScreen()

  useEffect(() => {
    if (menus[4] === undefined && (is981px || is669px)) {
     setMenus([
        ...menus,
        {
          label: "Tools",
          icon: "/images/more-grid.svg",
        },
      ]);
    }
    else if(menus[4] !== undefined && menus[4].label === 'Tools' && (is981px || is669px)) {
      menus.pop()
    }
  }, [is981px,is669px]);
  useEffect(()=>{
    onApplyName()
  },[accept])
  useEffect(()=>{
    if(statusChangeName)
      setTimeout(()=>{
        setStatusChangeName(false)
      },3000)
  },[statusChangeName])
  const onApplyName = () => {
    if(typeof desiredName === "string" && desiredName.length > 2 && accept) {
      setNickname(desiredName);
      setStatusChangeName(true);
      setAccept(false)
    }
  }
  const [desiredNameErrors,setDesiredNameError] = useState('')
  const validateName = () => {
    if(desiredName.length < 3) {
      setDesiredNameError("meetingName")
    }
    else setDesiredNameError('')
  }
  useEffect(()=>{
    validateName()
  },[desiredName])

  return (
    <div className={`container-popup-settings`}>
        {!menu  && mobile ? (
            <div className="options-menu">
              <div style={{display:"flex", justifyContent:'space-between'}}>
                <span className='title'>Settings</span>
                <div className={'back-button-settings'} onClick={()=> {
                  toggleCloseSettingsModal(false)
                }}>
                  <CloseIcon fill={'#fff'}/>
                </div>
              </div>
              {
                menus.map((m,midx)=>{
                  return(
                      <MenuItem key={`menu-idx=${midx}`} active={menu === m.label} label={m.label} icon={m.icon} onClick={()=>selectMenu(m.label)}/>
                  )
                })
              }
            </div>
        ) : menu && !mobile ? (
            <div className="options-menu">
              <span className='title'>Settings</span>
              {
                menus.map((m,midx)=>{
                  return(
                      <MenuItem key={`menu-idx=${midx}`} active={menu === m.label} label={m.label} icon={m.icon} onClick={()=>selectMenu(m.label)}/>
                  )
                })
              }
            </div>
        ) : null}
      {menu && (
          <div className='option-item-menu'>
            {menu && mobile ? (
                <div className={'header-menu-settings-mobile'}>
                  <div className={'back-button-settings'} onClick={()=>selectMenu('')}>
                    <LeftArrow />
                  </div>
                  <span className='item-title'>{menu}</span>
                </div>
            ) : (
                <span className='item-title'>{menu}</span>
            )}
            { // Audio Devices
              menu === 'Audio Device' ? (
                  <>
                    <ReactSelect
                        placeholder={audioDevices.length ? "Audio input device" : 'No microphones detected'}
                        isDisabled={!audioDevices.length}
                        isSearchable={false}
                        className={'react-select-container'}
                        classNamePrefix="react-select"
                        options={[...audioDevices, {label: "No Microphone", value: ""}]}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: '#3D33A4',
                            color:'#fff',
                            // Conditional border color: if the control is focused, set it to red, else keep it grey
                            borderColor: state.isFocused ? 'white' : 'grey',
                            // Add boxShadow if you want to highlight the border when focused
                            boxShadow: state.isFocused ? '0 0 0 1px white' : null,
                            '&:hover': {
                              // You can also change the borderColor on hover if needed
                              borderColor: state.isFocused ? 'white' : 'grey'
                            }
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: 'white', // Set placeholder text color to white
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: 'white', // Change text color to white
                            '&:hover': {
                              borderColor:'white'
                            },
                            '&:focus': {
                              borderColor:'white'
                            },
                          }),
                          singleValue: (baseStyles) => ({
                            ...baseStyles,
                            color: 'white', // Change text color to white
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            color: 'white', // Change arrow color to white
                            '&:hover': {
                              color: 'white', // Override hover color
                            },
                            '&:focus': {
                              color: 'white', // Override focus color
                            },
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: '#3D33A4', // Set dropdown background to red
                          }),
                          option: (baseStyles, { isFocused, isSelected }) => ({
                            ...baseStyles,
                            backgroundColor: isFocused || isSelected ? '#835FF7' : '#3D33A4', // Change background color to black on hover or selection
                            color: isFocused || isSelected ? 'white' : 'white', // Change text color to white on hover or selection
                            '&:hover': {
                              backgroundColor: '##835FF7', // Override background color on hover
                            },
                          }),

                        }}
                        onChange={(value)=>{
                          changeMicrophone(value)}}
                    />
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                      <span className='item-name'>Noise Cancelling</span>
                      <div className={`switcher-button ${noiseCancelling ? 'active' : ''}`} onClick={(e)=>{e.stopPropagation(); setNoiseCancelling(!noiseCancelling)}}>
                        <span className='circle'></span>
                      </div>
                    </div>
                  </>
              ) : null
            }
            { // Video Devices
              menu === 'Video Device' ? (
                  <div>
                    <span className='tab-text'>Current Stream:</span>
                    <div style={{width: "150px",minHeight:'112px', marginBottom: "16px", borderRadius: "8px", overflow: "hidden"}}>
                      {stream && <VideoStream muted={true} stream={stream}/>}
                    </div>
                    <div>
                      <ReactSelect
                          placeholder={videoDevices.length ? "Video input device" : 'No cameras detected'}
                          isDisabled={!videoDevices.length}
                          isSearchable={false}
                          className={'react-select-container'}
                          classNamePrefix="react-select"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: '#3D33A4',
                              color:'#fff',
                              // Conditional border color: if the control is focused, set it to red, else keep it grey
                              borderColor: state.isFocused ? 'white' : 'grey',
                              // Add boxShadow if you want to highlight the border when focused
                              boxShadow: state.isFocused ? '0 0 0 1px white' : null,
                              '&:hover': {
                                // You can also change the borderColor on hover if needed
                                borderColor: state.isFocused ? 'white' : 'grey'
                              }
                            }),
                            placeholder: (baseStyles) => ({
                              ...baseStyles,
                              color: 'white', // Set placeholder text color to white
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              color: 'white', // Change text color to white
                              '&:hover': {
                                borderColor:'white'
                              },
                              '&:focus': {
                                borderColor:'white'
                              },
                            }),
                            singleValue: (baseStyles) => ({
                              ...baseStyles,
                              color: 'white', // Change text color to white
                            }),
                            dropdownIndicator: (baseStyles, state) => ({
                              ...baseStyles,
                              color: 'white', // Change arrow color to white
                              '&:hover': {
                                color: 'white', // Override hover color
                              },
                              '&:focus': {
                                color: 'white', // Override focus color
                              },
                            }),
                            menu: (baseStyles) => ({
                              ...baseStyles,
                              backgroundColor: '#3D33A4', // Set dropdown background to red
                            }),
                            option: (baseStyles, { isFocused, isSelected }) => ({
                              ...baseStyles,
                              backgroundColor: isFocused || isSelected ? '#835FF7' : '#3D33A4', // Change background color to black on hover or selection
                              color: isFocused || isSelected ? 'white' : 'white', // Change text color to white on hover or selection
                              '&:hover': {
                                backgroundColor: '##835FF7', // Override background color on hover
                              },
                            }),
                          }}
                          options={[...videoDevices, {label: "No Camera", value: ""}]}
                          onChange={(value)=>{
                            changeCamera(value)}}
                      />
                    </div>
                  </div>
              ) : null
            }
            { // Background Image Processing
              menu === 'Background' ? (
                  <div className='content-overflow'>
                    <span className='tab-text'>Current Stream:</span>
                    <div style={{width: "150px",minHeight:'112px', marginBottom: "16px", borderRadius: "8px", overflow: "hidden"}}>
                      {processedStream ? <VideoStream stream={processedStream} muted={true}/> : null}
                    </div>
                    <AddBackground forceUpdate={toggleForceUpdate}/>
                    <div className='background-images-container'>
                      {
                        backgroundImages.map((img, imgidx)=>{
                          if(img.type === "text") {
                            if(img.value === "none") {
                              return <ImageSquare key={"imgidx"+imgidx} text={img.value} active={videoEffect === "none"} onClick={()=>setVideoEffect("none")}/>
                            }
                            if(img.value === "blur") {
                              return <ImageSquare key={"imgidx"+imgidx} text={img.value} active={videoEffect === "blur"} onClick={()=>setVideoEffect("blur")}/>
                            }
                          } else {
                            return(
                                <ImageSquare
                                    key={"imgidx"+imgidx}
                                    image={img.value}
                                    active={backgroundImage === img.value}
                                    onClick={
                                      ()=>{
                                        setBackgroundImage(img.value)
                                        setVideoEffect("backgroundImage")
                                      }
                                    }
                                />
                            )
                          }
                        })
                      }
                    </div>
                  </div>
              ) : null
            }
            { // User Profile
              menu === 'Profile' ? (
                  <div className='content-overflow'>
                    <span className='tab-text'>Name On Stream:</span>
                    <Input
                        type="text"
                        maxLength="30"
                        capitalize={true}
                        value={desiredName}
                        validations={[
                          {
                            message: "Requires at least 3 characters",
                            check: (input) => {
                              return input.length >= 3
                            }
                          }
                        ]}
                        foundError={desiredNameErrors.includes('meetingName')}
                        onChange={(e)=> {
                          setDesiredName(e.target.value)
                        }}
                        placeholder="Your Name" />
                    <ButtonComponent
                        ClassName={'save-changes'}
                        OnClick={()=>{
                          setShow(true)
                          onApplyName()
                        }}
                        Disabled={desiredNameErrors.length > 0}
                        Text={statusChangeName ? 'Success!' :'Change Name'}
                        buttonColor={'#835FF7'}
                        textColor={'#fff'}
                        iconName={statusChangeName ? 'check' : null}
                        IconColor={'#fff'}
                        IconSize={22}
                        IconColorHover={'#835FF7'}
                    />

                  </div>
              ) : null
            }
            {
              menu === 'Tools' ? is981px || is669px ? (
                  <div className='content-overflow' style={{display:"flex",flexDirection:"column",gap:15}}>
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                      <span className='item-name'>White Board</span>
                      <div className={`switcher-button ${whiteBoard ? 'active' : ''}`} onClick={(e)=>{e.stopPropagation(); setWhiteBoard(!whiteBoard)}}>
                        <span className='circle'></span>
                      </div>
                    </div>
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                      <span className='item-name'>Share Screen</span>
                      <div className={`switcher-button ${shareScreen ? 'active' : ''}`} onClick={(e)=>{e.stopPropagation(); setShareScreen(!shareScreen)}}>
                        <span className='circle'></span>
                      </div>
                    </div>
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                      <span className='item-name'>Full Screen</span>
                      <div className={`switcher-button ${fullScreen ? 'active' : ''}`} onClick={(e)=>{e.stopPropagation(); setFullScreen(!fullScreen)}}>
                        <span className='circle'></span>
                      </div>
                    </div>
                    <ButtonComponent
                        ClassName={'button-card'}
                        iconName={'group'}
                        IconColor={'#fff'}
                        IconSize={25}
                        IconColorHover={'#835FF7'}
                        bgOnHover={'transparent'}
                        buttonColor={'#835FF7'}
                        textColor={'#fff'}
                        Text={'Add People'}
                        OnClick={(e)=>{setAddPeople(true)}}
                    />

                  </div>
              ) : null : null
            }
          </div>
      )}

    </div>
  )
}
export default DevicesModal