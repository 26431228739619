import Checkbox from "components/checkbox"
import Icon from "components/icon/icon"
import Input from "components/input/input"
import { useEffect } from "react"
import { useState } from "react"
import {Link, useNavigate} from "react-router-dom"
import { useAuthentication } from "stores/authentication"
import { usePreferences } from "stores/preferences"

const JoinRoom = (props) => {
  const navigate = useNavigate()
  const {isAuthenticated} = useAuthentication()
  const [room, setRoom] = useState(props.defaultRoom ? window.location.href : "")
  const {nickname, setNickname} = usePreferences()

  const [termsAgreed, setTermsAgreed] = useState(false)

    const [errors, setErrors] = useState('')

    const checkCreatRoomForm = ()=>{
        if(room.length < 30)
            setErrors(errors + ' meetingName')
        else if (nickname.length < 3)
            setErrors(errors + ' yourName')
        else if (termsAgreed === false)
            setErrors(errors + ' agree')
        else {
            setErrors('')
            return true
        }
    }


  const proceed = (e) => {
    console.log(room)
    e.preventDefault()
      checkCreatRoomForm()
      if(checkCreatRoomForm()){
          if(!room.includes("?")) {
              navigate(`/chatroom?room=${room}`)
          } else {
              const query = new URLSearchParams(room.split("?")[1])
              const roomCode = query.get("room")
              navigate(`/chatroom?room=${roomCode}`)
          }
      }

  }
  return (
    <div className={`${props.className} d-flex flex-column justify-content-center align-items-center`}>
      <form onSubmit={proceed} noValidate className="w-100">
        <Input
          name="join-room"
          label="Meeting Link"
          type="text"
          value={room}
          placeholder={'Meeting Link'}
          autoComplete="none"
          minLength="30"
          maxLength="200"
          required
          validations={[
              {
                  message: "Requires at least 30 characters",
                  check: (input) => {
                      return input.length >= 30
                  }
              }
          ]}
          foundError={errors.includes('meetingName')}
          onChange={(e) => setRoom(e.target.value)} />
          <Input
              name="your-name"
              label="Your Name"
              type="text"
              placeholder='Your Name'
              value={nickname}
              autoComplete="none"
              capitalize
              minLength="3"
              required
              foundError={errors.includes('yourName')}
              validations={[
                  {
                      message: "Requires at least 3 characters",
                      check: (input) => {
                          return input.length >= 3
                      }
                  }
              ]}
              onChange={(e) => setNickname(e.target.value)} />
        <Checkbox
          value={termsAgreed}
          onClick={() => setTermsAgreed(!termsAgreed)}
          required
          foundError={errors.includes('agree')}
          label="I agree to the"
          linkLabel="Booom Disclaimer"
          linkTo="/disclaimer" />
        <input
          className={
            `SecondaryButton halfDashboardButton ${termsAgreed && room && room.length >= 3 ?
              "" : "disabled"
            }`}
          type="submit"
          value="Join Meeting"
          required />
          {
            isAuthenticated ? null : (
              <>
                <div className='link-to-register-login'>
                    <p className="">Have an account? </p>
                    <Link to={'/login'}>Sign In</Link>
                </div>
                <div className='link-to-register-login' style={{marginTop:10}}>
                    <p className="">Don't have an account? </p>
                    <Link to={'/register'}>Register</Link>
                </div>
              </>
            )
          }
      </form>
    </div>
  )
}
export default JoinRoom