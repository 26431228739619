import { ReactSVG } from "react-svg";
import svgs from "./icons";
const Icon = ({
                  withStroke=false,
                  name,
                  color,
                  colorHover,
                  onClick,
                  size,
                  className,
                  isParentHovered }) => {
    //isParentHovered detect if the parent it's on hover
    //colorHover enter the color for the svg on hover of the parent
    return (
        <div
            onClick={onClick}
            style={{ display: 'flex',justifyContent:"center",alignItems:"center" }}>
            <ReactSVG
                style={{ color, fill: isParentHovered ? colorHover : color }}
                beforeInjection={(svg) => {
                    if (size) {
                        svg.setAttribute('style', `width: ${size}px; height: ${size}px;`);
                    }
                    const paths = svg.querySelectorAll('path');
                    if(withStroke) {
                        paths.forEach(path => {
                            path.setAttribute('stroke', isParentHovered ? colorHover : color);
                        });
                    }
                    else {
                        paths.forEach(path => {
                            path.setAttribute('fill', isParentHovered ? colorHover : color);
                        });
                    }

                    if (className) {
                        svg.classList.add(className);
                    }
                }}
                src={svgs[name]}
            />
        </div>
    );
};

export default Icon;
