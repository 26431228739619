import Icon from "components/icon/icon";
import "./user-control.scss"
import "./user-control.css"
import React, {useEffect, useState} from "react";
import { useAuthentication } from "stores/authentication";
import {Link, useNavigate} from "react-router-dom";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import {ButtonComponent} from "../../../button-component/button-component";
import {MediaScreen} from "../../../pages/Rooms/chatroom/encrypted/MediaScreen";
import {UserProfilePicture} from "../../../user-profile-picture";
const UserControl = () => {
  const { isAuthenticated,user } = useAuthentication()
  const [showMenu, setShowMenu] = useState(false)
  const nodeRef = useRef(null)
  const {isMobile} = MediaScreen()
  const navigate = useNavigate()


  return (
    <div className="userControlContainer" onClick={()=>setShowMenu(!showMenu)}>


          {isAuthenticated ? (
              <div style={{cursor:'pointer'}}>
                <UserProfilePicture width={52} height={52} fontSize={18} userId={user._id} userFirstName={user.firstName} userLastName={user.lastName[0]} />
              </div>
          ) : (
              <ButtonComponent
                  ClassName={'group-button-page'}
                  bgOnHover={'transparent'}
                  buttonColor={'#3D33A4'}
                  IconColor={'#fff'}
                  IconSize={20}
                  IconColorHover={'#3D33A4'}
                  iconName={isMobile ? 'single-user' : ''}
                  Text={!isMobile ? 'Login' : ''}
              />
          )
              }
      {
        <CSSTransition
          nodeRef={nodeRef}
          in={showMenu}
          timeout={100}
          
          classNames={`userControlMenu${isAuthenticated ? "Authenticated" : ""}`}
          className={`userControlMenu${isAuthenticated ? "Authenticated" : ""}`}
          unmountOnExit>
          <div ref={nodeRef} onMouseLeave={()=>setShowMenu(false)}>
            {
              isAuthenticated ? (
                <>
                  <Link to="/user-profile" className="item-option">
                    Profile
                  </Link>
                  <Link to="/logout" className="item-option">
                      Logout
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/login" className="item-option">
                      Login
                  </Link>
                  <Link to="/register" className="item-option">
                      Register
                  </Link>
                </>
              )
            }
          </div>
        </CSSTransition>
      }
    </div>
  );
}
export default UserControl