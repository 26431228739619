import { Route, Routes } from "react-router-dom"
import Layout from "./layout/layout"
import Register from "./pages/Authentication/Register/register"
import Verify from "./pages/Authentication/Verify/verify"
import NotFound from "./pages/notFound"
import Login from "./pages/Authentication/Login/login"
import Recovery from "./pages/Authentication/Recovery/recovery"
import Recover from "./pages/Authentication/Recover/recover"
import Logout from "./pages/Authentication/Logout/logout"
import Rooms from "./pages/Rooms/rooms"
import Disclaimer from "./pages/Disclaimer/disclaimer"
import AttemptJoinRoom from "./pages/Rooms/attempt-room-join"
import CreateRoomOptions from "./pages/Rooms/create-room-options"
import Chatroom from "./pages/Rooms/chatroom/chatroom-selector"
import MaxTime from "./pages/maxTime"
import {GroupModal} from "./group-modal/groupModal";
import {MainMeetings} from "./pages/Manage/Meetings/main-meetings";
import {CreateGroup} from "./pages/Manage/Groups/create-group";
import {Group} from "./pages/Manage/Groups/main-groups";
import {EditGroup} from "./pages/Manage/Groups/edit-group";
import {CreateMeeting} from "./pages/Manage/Meetings/create-meeting";
import {MyMeetings} from "./pages/Manage/Meetings/my-meetings";
import {SpecificMeetingInvite} from "./pages/Manage/Meetings/components/specific-meeting-invite";
import {SpecificMeeting} from "./pages/Manage/Meetings/components/specific-meeting";
import {MyGroups} from "./pages/Manage/Groups/my-groups";
import {SpecificGroup} from "./pages/Manage/Groups/specific-group";
import {SpecificGroupInvite} from "./pages/Manage/Groups/specific-group-invite";
import {EditMeeting} from "./pages/Manage/Meetings/edit-meeting";
import {UserProfile} from "./pages/UserProfile/user-profile";
import {UpdateUserProfile} from "./pages/UserProfile/update-user-profile";
import ChangeEmail from "./pages/Authentication/ChangeEmail/change-email";
import VerifyNewEmail from "./pages/Authentication/Verify/verifyNewEmail";
import MeetingListenerComponent from "./meeting-listener";

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<Rooms/>}/>
        <Route path="disclaimer" element={<Disclaimer/>}/>
        <Route path="join" element={<AttemptJoinRoom/>}/>
        <Route path="createRoom" element={<CreateRoomOptions/>}/>
        <Route path="chatroom" element={<Chatroom/>}/>
        {/*<Route path="group" element={<GroupModal/>}/>*/}
        <Route exact path="manage-groups" element={<MyGroups/>}/>
        <Route exact path="manage-groups/create-group" element={<CreateGroup/>}/>
        <Route path="manage-groups/my-group" element={<SpecificGroup/>}/>
        <Route path="manage-groups/my-group/invite" element={<SpecificGroupInvite/>}/>
        <Route exact path="manage-groups/edit-group" element={<EditGroup/>}/>

        <Route exact path="manage-meetings" element={<MyMeetings />}/>
        <Route exact path="manage-meetings/create-meeting" element={<CreateMeeting />}/>
        <Route exact path="manage-meetings/my-meeting" element={<SpecificMeeting />}/>
        <Route exact path="manage-meetings/my-meeting/invite" element={<SpecificMeetingInvite />}/>
        <Route exact path="manage-meetings/edit-meeting" element={<EditMeeting/>}/>



        <Route path="register" element={<Register/>}/>
        <Route path="login" element={<Login/>}/>
        <Route path="verify" element={<Verify/>}/>
        <Route path="verify-email" element={<VerifyNewEmail/>}/>
        <Route path="change-email" element={<ChangeEmail/>}/>
        <Route path="recovery" element={<Recovery/>}/>
        <Route path="logout" element={<Logout/>}/>
        <Route path="maxtime" element={<MaxTime/>}/>
        <Route path="user-profile" element={<UserProfile/>}/>
        <Route path="user-profile/update" element={<UpdateUserProfile/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Route>
    </Routes>
  )
}

export default Pages