import SimplePeer from "simple-peer"
import { create } from "zustand"
import { Buffer } from "buffer"
import useDevices from "./devices"
import useMessages from "./messages"
import {MediaStreamTrackType} from "../components/hardcoded/streamHelper";
const usePeers = create((set, get) => ({
  selectedPeer: null,
  peers: {},
  count: 0,
  addCount() {
    const { count } = get()
    set({ count: count + 1 })
  },
  selectPeer(input){
    const { peers } = get()
    if(input === null) {
      set({selectedPeer: null})
    } else {
      set({selectedPeer: peers[input]})
    }
  },
  addPeer: (Socket, peerdetails, initiator = false) => {
    const { peers, updatePeerStream, preparePeerDisconnect } = get()
    const { deviceUpdate } = useDevices.getState()
    const peerOptions = {
      initiator,
      config: { 
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:global.stun.twilio.com:3478'},
          // {
          //   urls: 'turn:192.158.29.39:3478?transport=udp',
          //   credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
          //   username: '28224511:1379330808',
          // },
          // {
          //   urls: 'turn:192.158.29.39:3478?transport=tcp',
          //   credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
          //   username: '28224511:1379330808',
          // },
          // {
          //   urls: 'turn:openrelay.metered.ca:80',
          //   username: 'openrelayproject',
          //   credential: 'openrelayproject'
          // }
        ] },
    }
    const socketId = peerdetails.socketId
    peers[socketId] = new SimplePeer(peerOptions)
    const newPeerConnectTimeout = setTimeout(() => {
      try {
        if (!peers[socketId]) {
          clearTimeout(newPeerConnectTimeout)
        } else {
          if (peers[socketId]._pc.connectionState === 'new') {
            clearTimeout(newPeerConnectTimeout)
            console.log(`newPeerConnectTimeout: ${socketId} disconnected because it timed out.`)
            preparePeerDisconnect(peers[socketId], socketId)
          }
        }
      } catch (e) {
        console.log("newPeerConnectTimeout: ")
        console.dir(e)
      }
    }, 5000)
    window.addEventListener('beforeunload', () => {
      clearTimeout(newPeerConnectTimeout)
    })
    peers[socketId].nickname = peerdetails.name
    peers[socketId]._DEBUG_STREAM_COUNT = 0
    peers[socketId].currentStream = new MediaStream
    peers[socketId].peerStream = null
    peers[socketId].pendingRemoveTracks = new Map
    peers[socketId].on('error', err => preparePeerDisconnect(peers[socketId], socketId, err))
    peers[socketId].on('close', () => preparePeerDisconnect(peers[socketId], socketId))
    // internal
    peers[socketId].on("signal", data => { Socket.emit("signal", socketId, data) })
    // on stream change detected from peer
    peers[socketId].on("stream", (stream) => {
      console.log(`Peer ${socketId} set their stream to ${stream.id}`)
      if (peers[socketId]._DEBUG_STREAM_COUNT++ > 1) {
        // A peer should have a single stream in its lifetime
        // If the future specs will allow for more than one, it will need to be implemented to be used
        throw `Critical Implementation Error: setSteam was called a second time for peer ${socketId}`
      }
      stream.addEventListener('addtrack', event => {
        console.log(`peer.on(stream) addtrack: Stream ${stream.id} added ${event.track.kind} track ${event.track.id} on socket ${socketId}.`)
        console.log(event, stream, stream.getTracks())
        updatePeerStream(socketId)
        // if () {
          const { selectedPeer } = get()
          set({ peers })
          if (selectedPeer === peers[socketId]) {
            set({ selectedPeer: peers[socketId] })
          }
        // }
      })
      stream.addEventListener('removetrack', event => {
        console.log(`peer.on(stream) removetrack: Stream ${stream.id} removed ${event.track.kind} track ${event.track.id} from socket ${socketId}`)
        console.log(event, stream)

        // if (updatePeerStream(socketId)) {
        updatePeerStream(socketId)
          const { selectedPeer } = get()
          set({ peers })
          if (selectedPeer === peers[socketId]) {
            set({ selectedPeer: peers[socketId] })
          }
        // }
      })
      peers[socketId].peerStream = stream
      // if (updatePeerStream(socketId)) {
      updatePeerStream(socketId)
        set({peers})
      // }
    })
    peers[socketId].on('track', (track, stream) => {
      console.log(`Track event called for peer with id ${socketId}`, track, stream)
      track.addEventListener('mute', (event) => {
        console.log(`peer.on(track) mute: ${track.kind} track with id ${track.id} on socket ${socketId}`)
        // if ( updatePeerStream(socketId)) {
        updatePeerStream(socketId)
          set({ peers })
        // }
      })
      track.addEventListener('unmute', (event) => {
        console.log(`peer.on(track) unmute: ${track.kind} track with id ${track.id} on socket ${socketId}`)
        // if ( updatePeerStream(socketId)) {
        updatePeerStream(socketId)
          set({ peers })
        // }
      })
      track.addEventListener('ended', (event) => {
        // note: will never be triggered on track.stop()
        console.log(`peer.on(track) ended: ${track.kind} track with id ${track.id} on socket ${socketId} has ended`)
        // if (updatePeerStream(socketId)) {
        updatePeerStream(socketId)
          set({ peers })
        // }
      })
      if (!peers[socketId].peerStream) {
        peers[socketId].peerStream = stream
      }
      if (!peers[socketId].peerStream.getTrackById(track.id)) {
        peers[socketId].peerStream.addTrack(track)
        updatePeerStream(socketId)
        // if (updatePeerStream(socketId)) {
          set({ peers })
        // }
      }
    })
    peers[socketId].on("connect", () => {
      const { addJoinMessage } = useMessages.getState()
      addJoinMessage(peers[socketId].nickname)
    })
    peers[socketId].on("data", (data) => {
      const { addMessage } = useMessages.getState()
      addMessage(peers[socketId].nickname, Buffer.from(data).toString())
    })
    const { ownStream } = useDevices.getState()

    peers[socketId].listeners = {
      addtrack: event => {
        peers[socketId].addTrack(event.track, ownStream)
      },
      removetrack: event => {
        peers[socketId].removeTrack(event.track, ownStream)
      }
    }

    peers[socketId].deviceListeners = {
      deafen: event => {
        peers[socketId].currentStream.getAudioTracks().forEach(track => track.enabled = false)
      },
      undeafen: event => {
        peers[socketId].currentStream.getAudioTracks().forEach(track => track.enabled = true)
      }
    }

    ownStream.addEventListener('addtrack', peers[socketId].listeners.addtrack)
    ownStream.addEventListener('removetrack', peers[socketId].listeners.removetrack)

    Object.keys(peers[socketId].deviceListeners).forEach(type => {
      deviceUpdate.addEventListener(type, peers[socketId].deviceListeners[type])
    })

    peers[socketId].addStream(ownStream)
    set({peers})
  },
  clearPeers: () => {
    const { peers } = get()
    const peersList = Object.keys(peers)
    peersList.forEach(peer => {
      peers[peer].end()
    })
  },
  messagePeers: (message) => {
    console.log("Sending message:", message)
    const { peers } = get()
    const peersList = Object.keys(peers)
    peersList.forEach( peer => {
      peers[peer].send(message)
    })
  },
  preparePeerDisconnect: (peer, socketId, error = null) => {
    const { selectedPeer, peers } = get()
    try {
      if (error) {
        console.log(`Disconnected peer ${socketId} because of error.`)
        console.dir(error)
      }

      const {ownStream, deviceUpdate} = useDevices.getState()
      if (peer) {
        if (peer.currentStream) {
          peer.currentStream.getTracks().forEach(track => {
            peer.currentStream.removeTrack(track)
          })
        }
        if (ownStream && peer.listeners) {
          Object.keys(peer.listeners).forEach(key => {
            ownStream.removeEventListener(key, peer.listeners[key])
          })
        }
        if (peer.deviceListeners) {
          Object.keys(peer.deviceListeners).forEach(type => {
            deviceUpdate.removeEventListener(type, peer.deviceListeners[type])
          })
        }
        if (peer === selectedPeer) {
          // todo: hook switch to an active speaker
          set({selectedPeer: null})
        }
      }
    } catch (e) {
      console.log(`Error while cleaning after a disconnection on peer ${socketId}`, peers)
    }
    if (peers && peers[socketId]) {
      peers[socketId].destroy()
      delete(peers[socketId])
    }
    set({peers: peers})
  },
  updatePeerStream: (socketId) => {
    const { peers } = get()
    if (!peers[socketId]?.currentStream) {
      return false;
    }
    const { isDeafened } = useDevices.getState()
    console.log(`updatePeerStream triggered for peer ${socketId}`, peers[socketId])
    const getLastLiveTrack = (kind) => {
      const peerTracks = peers[socketId].peerStream.getTracks()
      for (let i = peerTracks.length - 1; i >= 0; i--) {
        if (peerTracks[i].kind === kind && peerTracks[i].readyState === 'live') {
          return peerTracks[i]
        }
      }
      return null
    }
    let changedFlag = false
    Object.values(MediaStreamTrackType).forEach(kind => {
      const getKindTracks = 'get' + kind.charAt(0).toUpperCase() + kind.slice(1) + 'Tracks'
      if (!peers[socketId].currentStream[getKindTracks]().length) {
        const lastLiveTrack = getLastLiveTrack(kind)
        if (lastLiveTrack) {
          changedFlag = true
          peers[socketId].currentStream.addTrack(lastLiveTrack)
          if (lastLiveTrack.kind === MediaStreamTrackType.Audio) {
            lastLiveTrack.enabled = !isDeafened
          }
        }
      } else {
        const lastLiveTrack = getLastLiveTrack(kind)
        if (lastLiveTrack) {
          const currentTrack = peers[socketId].currentStream.getTrackById(lastLiveTrack.id)
          if (!currentTrack) {
            changedFlag = true
            peers[socketId].currentStream[getKindTracks]().forEach(track => {
              peers[socketId].currentStream.removeTrack(track)
              if (track.kind === MediaStreamTrackType.Audio) {
                track.enabled = true
              }
            })
            peers[socketId].currentStream.addTrack(lastLiveTrack)
            if (lastLiveTrack.kind === MediaStreamTrackType.Audio) {
              lastLiveTrack.enabled = !isDeafened
            }
          }
        } else {
          changedFlag = true
          peers[socketId].currentStream[getKindTracks]().forEach(track => {
            peers[socketId].currentStream.removeTrack(track)
            if (track.kind === MediaStreamTrackType.Audio) {
              track.enabled = true
            }
          })
        }
      }
    })
    return changedFlag
  },
}))

export default usePeers
