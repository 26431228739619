import { create } from 'zustand'
const useMessages = create((set, get) => ({
    messages: [],
    addMessage: (sender, message) => {
        const messages = get().messages
        const currentTime = new Date()
        let currentMinutes = currentTime.getMinutes()
        currentMinutes = currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes
        set({messages: [...messages, {
            sender,
            message,
            date: `${currentTime.getHours()}:${currentMinutes}`
        }]})
    },
    addJoinMessage: (name) => {
        const { messages } = get()
        const currentTime = new Date()
        let currentMinutes = currentTime.getMinutes()
        currentMinutes = currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes
        const result = [
            ...messages,
            {
                sender: "System",
                message: `${name} connected!`,
                date: `${currentTime.getHours()}:${currentMinutes}`
            }
        ]
        set({messages: result})
    },
    clearMessages: () => {
        set({messages: []})
    }
}))
export default useMessages