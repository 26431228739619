import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import BackIcon from "../../Rooms/chatroom/SVGComponents/back-icon";
import Input from "../../../input/input";
import {ButtonComponent} from "../../../button-component/button-component";
import {useGroups} from "../../../../stores/groups";
import {QuickListGroups} from "./components/quick-list-groups/quick-list-groups";
import Icon from "../../../icon/icon";
import WindowLayout from "../../../windowLayout/window-layout";
import {useAuthentication} from "../../../../stores/authentication";
import {Search} from "../../../search-bar/search";
import Checkbox from "../../../checkbox";
import {clearImageCache} from "../../../../stores/clearCache";
import {MediaScreen} from "../../Rooms/chatroom/encrypted/MediaScreen";

export const EditGroup = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);
    const [groupId, setGroupId] =useState(query.get('id')) ;
    const { isAuthenticated} = useAuthentication()
    const {updateGroup,selectedGroup,deleteGroupPicture,selectGroup,addModerator,searchUser,getGroups } = useGroups()

    useEffect(() => {
        if (!isAuthenticated)
            navigate('/login')
        async function fetchData() {
            console.log('intra')
            await getGroups();
            await selectGroup(query.get('id'));
        }
        fetchData();
    }, []);

    const [imageCheck,setImageCheck] = useState('#000');
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);

    const [cropper,setCropper] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [avatarUrl,setNewAvatarUrl ] =useState()
    const [addModeratorView,setAddModeratorView] = useState(false)
    const [canKick,setCanKick] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [foundUserInSearch,setFoundUserInSearch] = useState(null)
    const [responseModerator, setResponseModerator] = useState('')
    const {isMobile,isTablet} = MediaScreen()
    const [imageError, setImageError] = useState(false);

    let profilePicture = useState(`https://gsvoiceapp.fra1.digitaloceanspaces.com/pfps/${foundUserInSearch?._id}.png`);
    const [showAlternativePictureProfile,setShowAlternativePictureProfile] = useState(false)
    useEffect(()=>{
        setShowAlternativePictureProfile(false)
    },[foundUserInSearch])
    function handleImageError() {
        setShowAlternativePictureProfile(true) // Change the image source to the fallback URL
    }



    const clearState = () => {
        setName("")
        setDescrion('')
        setFile(null),
            setNewAvatarUrl()
        setIsLoading(false)
        setError("")
        setSuccess("")
    }
    const getNewAvatarUrl = (e) => {
        setFile(null)
        if (e.target.files) {
            setNewAvatarUrl(URL.createObjectURL(e.target.files[0]));
        }
    };

    const filePickHandler = (files) => {
        const file = files
        const objectUrl = URL.createObjectURL(file)
        const img = document.createElement("img")
        img.onload = () => {
            if(img.width !== 128 || img.height !== 128) {
                setError("Image needs to be 128px by 128px in dimensions!")
            }
            URL.revokeObjectURL(objectUrl)
        }
        img.src = objectUrl
        img.setAttribute("style", "display: none")
        document.body.appendChild(img)
        if(file.size > 64000)
            setImageCheck('#790000')
        setImageCheck('#019900')
        console.log(files)
        setFile(files)
    }
    const getCropData = async () => {
        if (cropper) {
            const canvas = cropper.getCroppedCanvas();

            // Convert the canvas to a Blob with a specified quality
            const blobPromise = new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 0.9); // Adjust the quality as needed
            });

            const blob = await blobPromise;

            const resizedBlob = await resizeImageWidthHeight(blob, 600);

            const file = new File([resizedBlob], `${selectedGroup.name.toLowerCase()}.jpg`, { type: "image/jpeg" });

            if (file) {
                filePickHandler(file)
            }
            else {
                setImageCheck('#790000')
            }
        }
    };

    async function resizeImageWidthHeight(blob, targetWidth, targetHeight) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                let newWidth;
                let newHeight;

                // Calculate new dimensions to maintain aspect ratio
                if (img.width > img.height) {
                    newWidth = targetWidth;
                    newHeight = targetWidth / aspectRatio;
                } else {
                    newHeight = targetHeight;
                    newWidth = targetHeight * aspectRatio;
                }

                // Create a canvas to perform the resizing
                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(resolve, 'image/jpeg', 0.9); // Adjust the quality as needed
            };
            img.onerror = reject;
        });
    }

    const resizeImage = async (blob, maxSize) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const scaleFactor = Math.min(1, maxSize / (img.width * img.height));

                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;

                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob((resizedBlob) => {
                        resolve(resizedBlob);
                    }, 'image/jpeg', 0.9); // Adjust the quality as needed
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(blob);
        });
    };

    const [name, setName] = useState(selectedGroup?.name || '')
    const [description, setDescription] = useState(selectedGroup?.description || '')

    const [errors, setErrors] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(false)
    const groupNameValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        }
    ]
    const groupDescriptionValidations = [
        {
            message: "Requires not to be empty",
            check: (input) => {
                return input.length > 0;
            }
        }
    ]
    const checkGroupName = () =>{
        for (let i = 0; i < groupNameValidations.length; i++) {
            if (!groupNameValidations[i].check(name)) {
                setErrors(errors + ' groupName')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }
    const checkGroupDescription = () =>{
        for (let i = 0; i < groupDescriptionValidations.length; i++) {
            if (!groupDescriptionValidations[i].check(description)) {
                setErrors(errors + ' groupDescription')
                setDisableSubmit(true)
                return false;
            }
        }
        setErrors('')
        setDisableSubmit(false)
        return true;
    }

    const checkCreatGroupForm = ()=>{
        if (avatarUrl && !file) {
            setImageError(true)
            setTimeout(()=>{
                setImageError(false)

            },5000)
            return false
        }
        if(checkGroupName() &&
            checkGroupDescription()){
            return true
        }
    }


    const attemptCreate = async () => {
        if (!checkCreatGroupForm())
            return
        setIsLoading(true)
        try {
            const response = await updateGroup({
                groupId,
                name,
                description,
                file
            })
            if(response === true) {
                console.log('success')
                await clearImageCache()
                navigate(`/manage-groups/my-group?id=${groupId}`)
                clearState()
            } else {
                throw(response)
            }
        } catch (e) {
            setError(e)
        }
        setIsLoading(false)
    }

    console.log(selectedGroup,'selectedGroup')
    if (!selectedGroup ) {
        return <p>Loading...</p>;
    }



        return (
            <WindowLayout>
                <div className={'create-group-container'}>
                <div className='back-button'>
                    <div className={'back-with-breadcrumbs'} style={{alignItems:isTablet ? 'flex-start': 'center'}}>
                        <div className='back-icon-container' style={{minWidth:52}} onClick={() => {
                            navigate(-1)
                        }}>
                            <BackIcon/>
                        </div>
                        <div className={'breadcrumbs-container'}>
                            <span>Manage groups</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span onClick={() => {
                                navigate('/manage-groups')
                            }}>My Groups</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span onClick={() => {
                                navigate(`/manage-groups/my-group?id=${selectedGroup._id}`)
                            }}>{selectedGroup.name}</span>
                            <Icon name={'angle-double-right'} color={'#090721'} size={16}/>
                            <span className={'active'}>Edit Group</span>
                        </div>
                    </div>
                </div>
                <div className={'header-container'}>
                    <span className={'title-window'}>Edit {selectedGroup.name}</span>
                    <span className={'under-title-window'}>Edit your current Group details</span>
                </div>
                    {addModeratorView ? (
                        <div className={'form-create-group-container add-moderator'}>
                            <Search style={{maxWidth:'unset'}} withKeyPress value={searchQuery} setSearchQuery={async (e) => {
                                try {
                                    const result = await searchUser({email: e})
                                    if (result) {
                                        setFoundUserInSearch(result.user)
                                        console.log('success find User')
                                    }
                                    if(!result.success) {
                                        setFoundUserInSearch(null)
                                        setTimeout(() => {
                                        }, 2000);
                                    }
                                } catch (e) {
                                    console.log(e)
                                }
                            }}/>
                            {foundUserInSearch ? (
                                <div className={'found-user-in-group'}>
                                    <div className={'found-message'}>
                                        <span className={'title-window'} style={{textWrap:'unset'}}>{responseModerator ? responseModerator : 'Add Moderator To This Group'}</span>
                                        <span className={'under-title-window'}>{responseModerator ? '' : 'We have found a member matching your search'}</span>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",gap:15}}>
                                        <Checkbox
                                            value={canKick}
                                            onClick={() =>  setCanKick(!canKick)}
                                            label="This Moderator Can Kick?"
                                             />
                                        <div className={'user-found-card-to-add'}>
                                            <div className='profile-image-container'
                                                 style={{height: 86, width: 86, minWidth: "unset"}}>
                                                {showAlternativePictureProfile ? (
                                                    <div style={{width:86,height:86,display:"flex",justifyContent:"center",alignItems:"center",borderRadius:4,backgroundColor:'#F4F4F4'}}>
                                                        <p style={{margin:0, textTransform:"uppercase",color:'#090721',fontSize:18}}>{foundUserInSearch.firstName[0]}{foundUserInSearch.lastName[0]}</p>
                                                    </div>
                                                ) : (
                                                    <img src={profilePicture} onEnded={()=>setShowAlternativePictureProfile(false)} onError={()=>handleImageError()} style={{borderRadius:4}} alt='avatar' width={86} height={86}/>

                                                )}
                                            </div>
                                            <div className={'user-info-container'}>
                                        <span
                                            className={'user-name'}>{foundUserInSearch.firstName} {foundUserInSearch.lastName}</span>
                                                <span className={'user-email'}>{foundUserInSearch.email}</span>
                                            </div>
                                            <ButtonComponent
                                                ClassName={'add-to-this-group'}
                                                iconName={'empty-plus'}
                                                bgOnHover={'transparent'}
                                                buttonColor={'#42D3D8'}
                                                IconColor={'#fff'}
                                                IconSize={20}
                                                IconColorHover={'#42D3D8'}
                                                Text={'Add Moderator This Group'}
                                                OnClick={async () => {
                                                    try {
                                                        const result = await addModerator({
                                                            memberId: foundUserInSearch._id,
                                                            groupId: selectedGroup._id,
                                                            canKick: canKick
                                                        })
                                                        if (result.success) {
                                                            setResponseModerator('Moderator Added Successfully!')
                                                        }
                                                        else {
                                                            setResponseModerator(result.reason)
                                                        }
                                                        setTimeout(()=>{
                                                            setFoundUserInSearch(null)
                                                            setSearchQuery('')
                                                            setResponseModerator('')
                                                        },[5000])
                                                        console.log(result)
                                                    } catch (e) {
                                                        console.log(e)
                                                    }
                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            ) :(
                                <>
                                    <span className={'under-title-window'} style={{textTransform: 'unset'}}>To add a moderator to your list, type the email of a BOOOM member in the search box above and hit 'Enter'.</span>
                                </>
                            )}

                            <ButtonComponent
                                ClassName={'groups-button'}
                                bgOnHover={'transparent'}
                                buttonColor={'#835FF7'}
                                Text={'Back To Edit Group'}
                                OnClick={()=>setAddModeratorView(false)}
                            />
                        </div>
                    ) : (
                        <div className={'form-create-group-container'}>
                            <Input
                                onChange={(e) => {
                                    setName(e.target.value)
                                    checkGroupName()}}
                                required
                                capitalize
                                validations={groupNameValidations}
                                minLength="2"
                                value={name}
                                foundError={errors.includes('groupName')}
                                placeholder={'E.g.: Design group, Development group'}
                                label={'Group Name'}/>
                            <Input
                                maxLength={'250'}
                                minLength={'3'}
                                required
                                validations={groupDescriptionValidations}
                                value={description}
                                foundError={errors.includes('groupDescription')}
                                onChange={(e) => {
                                    setDescription(e.target.value)
                                    checkGroupDescription()
                                }}
                                placeholder={'Enter Group Description'}
                                label={'Group Description'}/>

                            {file && avatarUrl ? (
                                <Input
                                    imageUrl={avatarUrl}
                                    onChange={(e) => {
                                        getNewAvatarUrl(e)
                                    }}
                                    addFile
                                    style={{whiteSpace: 'pre-line'}}
                                    placeholder={'Click or drag your image here to upload \n (Or we will load a default group image)'}/>
                            ) : avatarUrl ? (
                                <>
                                    <Cropper
                                    autoCropArea={1}
                                    dragMode={"none"}
                                        src={avatarUrl}
                                        style={{height: "100%", width: "100%", maxHeight: '300px', marginBottom: 24}}
                                        initialAspectRatio={16 / 9}
                                        guides={false}
                                        cropBoxResizable={false}
                                        checkOrientation={false}
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                    />
                                    <ButtonComponent
                                        ClassName={'groups-button'}
                                        bgOnHover={'transparent'}
                                        buttonColor={'rgb(131, 95, 247)'}
                                        Text={'Crop'}
                                        OnClick={getCropData}
                                    />
                                </>
                            ) : (
                                <div style={{position:"relative"}}>
                                    {selectedGroup.picture ? (
                                        <ButtonComponent
                                            ClassName={'groups-button'}
                                            style={{position: 'absolute',top: 15, right: 15, zIndex:20, width: 50}}
                                            iconName={'trash'}
                                            IconColorHover={'#F75F5F'}
                                            bgOnHover={'transparent'}
                                            buttonColor={'#F75F5F'}
                                            IconColor={'#fff'}
                                            IconSize={20}
                                            textColor={selectedGroup?.picture}
                                            OnClick={async()=>{
                                                try {
                                                    const response = await deleteGroupPicture({groupId})
                                                    console.log(response)

                                                } catch (e) {
                                                    setError(e)
                                                }
                                            }}
                                        />
                                    ) : null }

                                    <Input
                                        onChange={(e) => {
                                            getNewAvatarUrl(e)
                                        }}
                                        imageUrl={selectedGroup?.picture}
                                        addFile
                                        style={{whiteSpace: 'pre-line'}}
                                        placeholder={'Clickk or drag your image here to upload \n (Or we will load a default group image)'}/>

                                </div>

                            )}
                            {imageError ? (
                                <div className="inputError" key={`Incorrect email or password.`}>
                                    <img className='error-icon-inputs' src='/images/danger-triangle.svg' alt="error-icon"/>
                                    The image needs to be cropped to upload it.
                                </div>
                            ) : null}
                            <span className={'upload-image-message'} style={{marginBottom:12}}>It takes up to 1min to upload the image.</span>

                            <ButtonComponent
                                ClassName={'groups-button'}
                                iconName={'save'}
                                IconColorHover={'#42D3D8'}
                                bgOnHover={'transparent'}
                                buttonColor={'#42D3D8'}
                                IconColor={'#fff'}
                                IconSize={20}
                                Disabled={disableSubmit}
                                Text={'Update This Group Details'}
                                OnClick={attemptCreate}
                            />
                            <ButtonComponent
                                ClassName={'groups-button'}
                                iconName={'external-link-square'}
                                IconColorHover={'#835FF7'}
                                iconPositionRight
                                bgOnHover={'transparent'}
                                buttonColor={'#835FF7'}
                                IconColor={'#fff'}
                                IconSize={20}
                                Text={'Add Moderator To This Group'}
                                OnClick={()=>setAddModeratorView(true)}
                            />
                        </div>
                    )}

                <QuickListGroups/>

            </div>
            </WindowLayout>
        );
}

