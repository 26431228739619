import React, { useState } from 'react';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Input from "../input/input";
import {ButtonComponent} from "../button-component/button-component"; // ensures Cropper's styles are loaded

const ImageUploadAndCrop = ({ width = 128, height = 128, onFinish }) => {
    const [testImage, setTestImage] = useState(null);
    const [testImageURL, setTestImageURL] = useState('');
    const [cropper, setCropper] = useState(null);
    const [showButton, setShowButton] = useState('crop')
    const [step,setStep] =useState(1)
    const getNewAvatarUrl = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setTestImageURL(URL.createObjectURL(e.target.files[0]));
            setTestImage(e.target.files[0]);
        }
    };

    const getCropData = async () => {
        if (cropper) {
            const canvas = cropper.getCroppedCanvas();
            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.9));
            const resizedBlob = await resizeImage(blob, 64000); // maxSize set to 64KB
            const file = new File([resizedBlob], "newAvatar.jpg", { type: "image/jpeg" });
            console.log(file, 'resizedBlob');
            filePickHandler([file]); // Ensure this is array to match expected parameter
        }
    };

    const filePickHandler = (files) => {
        const file = files[0];
        const objectUrl = URL.createObjectURL(file);
        const img = new Image();
        img.onload = () => {
            console.log(`Image dimensions: ${img.width}x${img.height}`);
            if (img.width !== width || img.height !== height) {
                console.log(`Image needs to be ${width}px by ${height}px in dimensions!`);
            }
            URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
        img.setAttribute("style", "display: none");
        document.body.appendChild(img);
        if (file.size > 64000) {
            console.log('File too large');
        }
        setTestImage(file);
        setStep(3)
        setShowButton('submit')
    };

    const resizeImage = (blob, maxSize) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const scaleFactor = Math.sqrt(maxSize / (img.width * img.height));
                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob(resolve, 'image/jpeg', 0.9);
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(blob);
        });
    };

    return (
        <div>

                <Input style={{display: step === 1 || step === 3 ? 'block' : 'none'}} onChange={(e)=> {
                    getNewAvatarUrl(e)
                    setStep(2)
                }} addFile placeholder={'Click or drag your image here to upload'}/>

                {testImageURL && step === 2 && (
                    <Cropper
                        autoCropArea={1}
                        dragMode={"none"}
                        src={testImageURL}
                        style={{ height: "100%", width: "100%", maxHeight: '300px', marginBottom: 24 }}
                        initialAspectRatio={1}
                        guides={false}
                        cropBoxResizable={false}
                        checkOrientation={false}
                        onInitialized={setCropper}
                    />
                )}



            {showButton === 'crop' && testImage ? (
                <ButtonComponent
                    ClassName={'groups-button'}
                    bgOnHover={'transparent'}
                    buttonColor={'rgb(131, 95, 247)'}
                    Text={'Crop'}
                    OnClick={getCropData} />
            ) : showButton === 'submit' ? (
                <ButtonComponent
                    ClassName={'groups-button'}
                    bgOnHover={'transparent'}
                    buttonColor={'#42D3D8'}
                    Text={'Upload Image'}
                    OnClick={async (e)=>{
                        e.preventDefault();
                        try {
                            console.log('loading');
                            const result = await $comms.request("profilePicture", { file: testImage });
                            console.log('stop loading');
                            if (result) {
                                window.location.reload()
                                onFinish(true)
                            }
                        } catch (e) {
                            console.log('error during image upload', e);
                        }
                    }} />
            ) : null}


        </div>
    );
};

export default ImageUploadAndCrop;
