import { CircleFlagProps } from 'react-circle-flags';

import { useState } from 'react';
import { CircleFlag } from 'react-circle-flags';

import { countries} from "../../assets/countries";


const Flag = ({ size, ...rest }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const country = rest.countryCode
  const sizeMap = {
    extraSmall: 20,
    small: 24,
    medium: 32,
    large: 50
  };
  return (
    <>
      <CircleFlag
        countryCode={country.code.toLowerCase()}
        height={rest.height || sizeMap[size || 'small']}
        title={countries.find(c => c.code === rest.countryCode)?.label}
        alt={`country-icon-${rest.countryCode}`}
        style={{
          display: isImageLoaded ? 'inline-block' : 'none',
          ...rest.style
        }}
        onLoad={() => setIsImageLoaded(true)}
      />
    </>
  );
};

export default Flag;
