import {create} from "zustand";

export const shopModal = create((set, get) => ({
    modalGroups: false,
    setModalGroups(modalGroups){
        set({modalGroups})
    },
    leaveLiveMeeting:false,
    setLeaveLiveMeeting(leaveLiveMeeting){
        set({leaveLiveMeeting})
    },
    kickMember: false,
    setKickMember(kickMember){
        set({kickMember})
    },
    showModal: false,
    setShow(showModal) {
        set({showModal})
    },
    accept: false,
    setAccept(accept) {
        set({accept})
    },
    isOwner:false,
    setIsOwner(isOwner){
        set({isOwner})
    },
    forDelete: false,
    setForDelete(forDelete){
        set({forDelete})
    },
    deleteMe: null,
    setDeleteMe(deleteMe){
        set({deleteMe})
    },
    deleteEveryone: null,
    setDeleteEveryone(deleteEveryone){
        set({deleteEveryone})
    },
    scheduleMeeting: null,
    setScheduleMeeting(scheduleMeeting){
        set({scheduleMeeting})
    },
    scheduleMeetingContent:null,
    setScheduleMeetingContent(scheduleMeetingContent){
        set({scheduleMeetingContent})
    },
    scheduleMeetingName: null,
    setScheduleMeetingName(scheduleMeetingName){
        set({scheduleMeetingName})
    },
}))